'use strict';

const { queryFirst, queryAll, addClass, removeClass, hasClass, debounce } = require('../domUtil');

const SCROLLABLE_CONTENT_SELECTOR = queryAll('.scrollable-content');
const LEFT_ARROW_SELECTOR = '.scroll-left-arrow';
const RIGHT_ARROW_SELECTOR = '.scroll-right-arrow';

function initSpecificCarousel(scrollableContainerEl) {
    const parentEl = scrollableContainerEl.parentElement;

    // Attaching events on Left arrow click
    const leftArrowEl = queryFirst(LEFT_ARROW_SELECTOR, parentEl);
    if (leftArrowEl) {
        leftArrowEl.addEventListener('click', () => {
            scrollableContainerEl.scrollTo({
                left: Math.max(0, scrollableContainerEl.scrollLeft - scrollableContainerEl.offsetWidth),
                behavior: 'smooth'
            });
        });
    }

    // Attaching events on Right arrow click
    const rightArrowEl = queryFirst(RIGHT_ARROW_SELECTOR, parentEl);
    if (rightArrowEl) {
        rightArrowEl.addEventListener('click', () => {
            scrollableContainerEl.scrollTo({
                left: Math.min(scrollableContainerEl.scrollWidth, scrollableContainerEl.scrollLeft + scrollableContainerEl.offsetWidth),
                behavior: 'smooth'
            });
        });
    }

    // Show/hide arrows
    if (scrollableContainerEl.scrollWidth <= scrollableContainerEl.offsetWidth) {
        addClass([leftArrowEl, rightArrowEl], 'hide');
    } else {
        removeClass([leftArrowEl, rightArrowEl], 'hide');
    }
}

module.exports = {
    initCarousel: function() {
        SCROLLABLE_CONTENT_SELECTOR.forEach(scrollableContainerEl => {
            initSpecificCarousel(scrollableContainerEl);
        });
    },
    initSpecificCarousel: initSpecificCarousel
};

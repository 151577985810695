'use strict';
const { queryFirst, addClass, removeClass, throttle, hasClass, hasAnyClass, setAttribute, queryAll, loadInput, validateDate } = require('../domUtil');
const formValidation = require('base/components/formValidation');
const { getTimeRemaining, getItemFromLocalStorage, formatPhoneNumber, getJSON, getNestedValue } = require('../util');
const { resetPassword } = require('../login/login');
const { MY_ACCOUNT } = require('../components/shippingPreference/constants');
const loyaltyProfile = getNestedValue(window, 'lillyUtils.profile') || {};
const { isLoyaltyProgramMember, accountLoyaltyTabLink } = loyaltyProfile;
const $body = $('body');
const hiddenClass = 'd-none';
const loyaltyWelcomeDialog = queryFirst('#loyalty-welcome-popup');
const loyaltyEnrollNowDialog = queryFirst('#loyalty-enrollnow-modal');
const invalidClass = 'is-invalid';
let mobileImg, desktopImg;
const root = document.documentElement;
const { initSpecificCarousel } = require('../components/carousel');
const loyaltyEarlyAccessRegisterForm = queryFirst('#loyalty-early-access-register');
const { ajaxFormInvalid, ajaxFormSubmit, enableFormSubmitButton, invalid } = require('../clientSideValidation');
const swatchImageDownload = '.swatch-image-download';
const swatchImageDownloaded = '.swatch-image-downloaded';
const downloadCtaDesktop = '.download-cta.desktop-show';
const downloadCtaMobile = '.download-cta.mobile-show';
const loyaltyQuiz = require('./loyaltyQuiz');

$body.on('hidden.bs.modal', function () {
    if (!queryFirst('.modal.show')) {
        $body.removeClass('referral-modal');
    }
});

function welcomeDialogEarlyAccessContent() {
    const welcomeDialogRegularFlowElms = queryAll('.js-loyalty-welcome-msg');
    const welcomeDialogEarlyAccessFlowElms = queryAll('.js-loyalty-welcome-early-access');

    addClass(welcomeDialogRegularFlowElms, hiddenClass);
    removeClass(welcomeDialogEarlyAccessFlowElms, hiddenClass);
}

/**
 * Handle the enroll now popup view
 */
function handleEarlyAccessEnrollModal() {
    $(loyaltyEnrollNowDialog)
        .modal('show')
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function () {
            if (!hasClass(loyaltyWelcomeDialog, 'show')) {
                location.reload();
            } else {
                welcomeDialogEarlyAccessContent();
                removeWelcomeModalShowSession();
            }
        });
    $(loyaltyWelcomeDialog)
        .off('hidden.bs.modal')
        .on('hidden.bs.modal', function (e) {
            location.reload();
        });
}

function enrollDialogLoggedInUsersContent(postalCode, isLogedIn) {
    // Hide guest elements and show form elements
    const loyaltyGuestEnrollEls = queryFirst('.js-guest-enrollment');
    const loyaltyEnrollForm = queryAll('.js-enrollment-form');
    if (isLogedIn) {
        removeClass(loyaltyEnrollForm, hiddenClass);
        addClass(loyaltyGuestEnrollEls, hiddenClass);
    } else {
        addClass(loyaltyEnrollForm, hiddenClass);
        removeClass(loyaltyGuestEnrollEls, hiddenClass);
    }

    // if do not get postal code from profile or template show ZIP input for user
    const loyaltyPostalCodeEls = queryAll('.js-loyalty-access-postal-code');
    const postalCodeInput = queryFirst('#loyalty-access-form-postalcode');
    if (!postalCode && postalCodeInput) {
        removeClass(loyaltyPostalCodeEls, hiddenClass);
    }
    if (postalCode) {
        postalCodeInput.value = postalCode;
    }
}

/**
 * Login form on PDP Early Access
 * @param {boolean} isLoyaltyMember - if the user is enrolled to loyalty or not
 * @param {string} postalCode - ZIP Code
 * @param {string} showWelcomeModal - When the enrollment is complete and the welcome modal needs to be displayed, it will be true
 */
function handlePdpEarlyAccessLoginRegistration(isLoyaltyMember, postalCode, showWelcomeModal) {
    if (showWelcomeModal) {
        welcomeDialogEarlyAccessContent();
        $(loyaltyWelcomeDialog)
            .modal('show')
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', function (e) {
                location.reload();
            });
        removeWelcomeModalShowSession();
    } else if (isLoyaltyMember) {
        location.reload();
    } else {
        enrollDialogLoggedInUsersContent(postalCode, true);
        handleEarlyAccessEnrollModal();
    }
}
/**
 * Remove show modal session attribute on close of welcome modal
 */
function removeWelcomeModalShowSession() {
    const closeButton = queryFirst('#loyalty-welcome-popup .js-club-lilly-welcome-session-url');
    getJSON(closeButton.dataset.welcomeSessionUrl, 'get');
}

/**
 * hide or show drawer image in mobile and desktop view
 * @param {string} drawerMobileImg - image for mobile view
 * @param {string} drawerDesktopImg - image for desktop view
 */
function hideShowDrawerImage(drawerMobileImg, drawerDesktopImg) {
    const desktopImageWrapper = queryFirst('.drawer-img-wrap-desktop');
    const mobileImageWrapper = queryFirst('.drawer-img-wrap-mobile');
    const drawerMobileImgEl = queryFirst('.drawer-image-mobile');
    const drawerDesktopImgEl = queryFirst('.drawer-image-desktop');

    // check if scene7 link is present for mobile or desktop or both
    if (drawerMobileImg !== 'null' && drawerMobileImgEl) {
        drawerMobileImgEl.src = drawerMobileImg;
    }

    loyaltyDrawerImageView(drawerMobileImg, mobileImageWrapper);

    if (drawerDesktopImg !== 'null' && drawerDesktopImgEl) {
        drawerDesktopImgEl.src = drawerDesktopImg;
    }

    loyaltyDrawerImageView(drawerDesktopImg, desktopImageWrapper);
}

/**
 * update the backgroud of referral section bassed on the configuration in content asset
 */
function updateReferralBg() {
    const loyaltyReferralBg = queryFirst('.referral-section-bg-color');
    if (!loyaltyReferralBg) {
        return;
    }
    const { bgColor, bgImageDesktop, bgImageMobile } = loyaltyReferralBg.dataset;
    if (bgImageDesktop) {
        root.style.setProperty('--referral-bg-img-desktop', `url(${bgImageDesktop})`);
    }
    if (bgImageMobile) {
        root.style.setProperty('--referral-bg-img-mobile', `url(${bgImageMobile})`);
    }
    if (bgColor) {
        root.style.setProperty('--referral-bg-color', bgColor);
    }
}

/**
 * handling hide or show of drawer image and background color in mobile and desktop view
 * @param {string} image - image of mobile/desktop view
 * @param {string} el - wrapper element of desktop/mobile
 */
function loyaltyDrawerImageView(drawerImage, el) {
    const loyaltyModalEl = queryFirst('#loyalty-program-modal .modal-content');
    const withImageEl = queryFirst('.drawer-with-img', el);
    const withoutImageEl = queryFirst('.drawer-without-img', el);
    const loyaltyDrawerBackground = 'loyalty-background';

    if (drawerImage && drawerImage !== 'null') {
        addClass(withoutImageEl, hiddenClass);
        removeClass(withImageEl, hiddenClass);
        addClass(loyaltyModalEl, loyaltyDrawerBackground);
    } else {
        removeClass(withoutImageEl, hiddenClass);
        addClass(withImageEl, hiddenClass);
        addClass(loyaltyModalEl, loyaltyDrawerBackground);
    }
}

/**
 * show how to earn drawer image in mobile and desktop view
 * @param {string} drawerMobileImg - image for mobile view
 * @param {string} drawerDesktopImg - image for desktop view
 * @param {string} drawerTitle - title for the drawer
 * @param {string} drawerCloseButtonLabel - close button label
 */
function showHowToEarnDrawerImage(drawerMobileImg, drawerDesktopImg, drawerTitle, drawerCloseButtonLabel) {
    const drawerMobileImgEl = queryFirst('.drawer-image-mobile');
    const drawerDesktopImgEl = queryFirst('.drawer-image-desktop');
    const drawerTitleE1 = queryFirst('#loyalty-how-to-earn-modal-label');
    const drawerCloseButtonE1 = queryFirst('#loyalty-how-to-earn-modal .close-current-modal');

    if (drawerTitle && drawerTitleE1) {
        drawerTitleE1.innerHTML = drawerTitle;
    }
    if (drawerCloseButtonLabel && drawerCloseButtonE1) {
        drawerCloseButtonE1.ariaLabel = drawerCloseButtonLabel;
    }
    if (drawerMobileImg !== 'null' && drawerMobileImgEl) {
        drawerMobileImgEl.src = drawerMobileImg;
    }
    if (drawerDesktopImg !== 'null' && drawerDesktopImgEl) {
        drawerDesktopImgEl.src = drawerDesktopImg;
    }
}
/**
 * Add the image URL in download CTA on select of wallpaper swatch
 * @param {HTMLElement} selectedWallpaperId - selected swatch element Id
 */
function updateImgURLOnDownloadCta(selectedWallpaperId) {
    const selectTab = selectedWallpaperId ? queryFirst(selectedWallpaperId) : queryFirst('.main-swatch-section .tab-pane.active');
    const imageElement = queryFirst('.wallpaper-main-image', selectTab);
    const swatchImageDownloadCtaDesktop = queryFirst(downloadCtaDesktop, queryFirst(swatchImageDownload));
    const swatchImageDownloadCtaMobile = queryFirst(downloadCtaMobile, queryFirst(swatchImageDownload));
    const swatchImageDownloadedCtaDesktop = queryFirst(downloadCtaDesktop, queryFirst(swatchImageDownloaded));
    const swatchImageDownloadedCtaMobile = queryFirst(downloadCtaMobile, queryFirst(swatchImageDownloaded));
    if (imageElement) {
        const { desktopDownloadedImage, mobileDownloadedImage } = imageElement.dataset;
        setAttribute(swatchImageDownloadCtaDesktop, 'href', desktopDownloadedImage);
        setAttribute(swatchImageDownloadCtaMobile, 'href', mobileDownloadedImage);
        setAttribute(swatchImageDownloadedCtaDesktop, 'href', desktopDownloadedImage);
        setAttribute(swatchImageDownloadedCtaMobile, 'href', mobileDownloadedImage);
    }
}

/**
 * Add click event listener on download cta to call the update image URL function
 */
function loyaltySwatchImgDownload() {
    updateImgURLOnDownloadCta();
    $body.on('click', '.img-wallpaper', function (e) {
        const selectedWallpaperId = this.closest('a').hash;
        updateImgURLOnDownloadCta(selectedWallpaperId);
        addClass(queryFirst(swatchImageDownloaded), hiddenClass);
        removeClass(queryFirst(swatchImageDownload), hiddenClass);
    });

    $body.on('click', '.download-cta.desktop-show, .download-cta.mobile-show', function () {
        addClass(queryFirst(swatchImageDownload), hiddenClass);
        removeClass(queryFirst(swatchImageDownloaded), hiddenClass);
    });
}

/**
 * Updated the counter timmer in every second
 * @param {string} countDataMsg - timmer string
 * @param {string} countDownTime - countDownTimmer
 * @param {HTMLElement} countDownEle - Html element where the timer needs to updated
 * @param {Object} timeinterval - OOS javascript function updated on every second
 * @param {string} countDataMsgNoDays - timmer string
 * @param {string} countDownMsgOneDay - timmer string
 */
function updateClock(countDataMsg, countDownTime, countDownEle, timeinterval, countDataMsgNoDays, countDownMsgOneDay) {
    const { tokenize } = require('../util');
    const timer = getTimeRemaining(countDownTime);
    const newCountDataMsgDays = timer.days === 1 ? countDownMsgOneDay : countDataMsg;
    const newCountDataMsg = timer.days === 0 ? countDataMsgNoDays : newCountDataMsgDays;
    countDownEle.innerHTML = tokenize(newCountDataMsg, timer, '[', ']');
    if (timer.total <= 0) {
        clearInterval(timeinterval);
    }
}

/**
 * Updated voucher Expiry date to formatted string
 * @param {string} expiryDate - API response expiry date string
 * @param {string} formatString - format string of resource message
 * @return {string} formattedString - formatted expiry date string
 */
function getFormatDateString(expiryDate, formatString) {
    if (!expiryDate) {
        return expiryDate;
    }
    const expiryDateStringArray = expiryDate.split(' ');
    const formattedString = expiryDateStringArray[0] + ' ' + formatString;
    return formattedString;
}
/**
 * Add click event listener on download cta to call the update image URL function
 */
function exclusiveExperiencesCountDown() {
    const exclusiveExperiencesTile = queryAll('.js-exclusive-tile');
    if (exclusiveExperiencesTile.length > 0) {
        for (let i = 0, n = exclusiveExperiencesTile.length; i < n; i++) {
            const exclusiveModule = exclusiveExperiencesTile[i];
            const { countDownTimer: countDownTime } = exclusiveModule.dataset; // Destructuring countDownTime
            const countDownEle = queryFirst('.count-down-timer', exclusiveModule);
            const countDownEleNoDays = queryFirst('.count-down-timer-nodays', exclusiveModule);
            const countDownEleOneDay = queryFirst('.count-down-timer-one-day', exclusiveModule);
            // Destructure counterMsg from the dataset of each element
            const { counterMsg: countDataMsg } = countDownEle.dataset;
            const { counterMsg: countDataMsgNoDays } = countDownEleNoDays.dataset;
            const { counterMsg: countDownMsgOneDay } = countDownEleOneDay.dataset;
            if (countDownTime) {
                // update the counter
                const timeinterval = setInterval(() => {
                    updateClock(countDataMsg, countDownTime, countDownEle, timeinterval, countDataMsgNoDays, countDownMsgOneDay);
                }, 1000);
            } else {
                addClass(countDownEle, hiddenClass);
            }
        }
    }
}

window.addEventListener('load', event => {
    const formElement = queryFirst('.loyalty-get-access');
    const referrerID = getItemFromLocalStorage('REFERRED_ID');
    if (referrerID && formElement) {
        loadInput(referrerID, formElement);
    }
    loyaltySwatchImgDownload();
});
/**
 * Function to initialize events
 */
function init() {
    exclusiveExperiencesCountDown();
}

module.exports = {
    loyaltyDrawer: function () {
        const loyaltyAppImage = queryFirst('.is-drawer-img');
        if (loyaltyAppImage) {
            const loyaltyDrawerContent = queryFirst('.loyalty-drawer-content');
            const { isAppDrawer } = loyaltyAppImage.dataset;
            const drawerAppImgEl = queryFirst('.drawer-image-mobile');
            if (isAppDrawer === 'true') {
                mobileImg = loyaltyAppImage.dataset.mobileImg;
                const appImageWrapper = queryFirst('.drawer-img-wrap-mobile');
                if (mobileImg && mobileImg !== 'null') {
                    drawerAppImgEl.src = mobileImg;
                }
                const appMobileImgEl = queryFirst('body');
                addClass(appMobileImgEl, 'loyalty-background-ios');
                loyaltyDrawerImageView(mobileImg, appImageWrapper);
            }
            removeClass(loyaltyDrawerContent, hiddenClass);
        }

        $body.on('click', '.loyalty-program-drawer-link, .loyalty-program-terms-condition, .loyalty-how-to-earn-drawer-link', function (e) {
            e.preventDefault();
            const isMobileSession = queryFirst('.mobile-session');

            if (isMobileSession) {
                return true;
            }

            const url = this.dataset.href;
            const isLoyaltyProgramDrawerClick = $(this).hasClass('loyalty-program-drawer-link');
            const hasJoinCta = hasAnyClass(this, 'loyalty-program-overview-from-header', 'loyalty-program-drawer-has-cta');
            const $loyaltyContainer = $('.loyalty-enrollnow-dialog');

            $loyaltyContainer.spinner().start();

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                success: function (data) {
                    if (data) {
                        if (isLoyaltyProgramDrawerClick) {
                            const loyaltyContainer = queryFirst('.loyalty-drawer-body');
                            loyaltyContainer.innerHTML = data;
                            const loyaltyDrawerContent = queryFirst('.loyalty-drawer-content');
                            removeClass(loyaltyDrawerContent, hiddenClass);
                            const guestEnrollment = queryFirst('.lilly-loyalty-container .guest-enrollment');
                            const memberSignInEnrollment = queryFirst('.lilly-loyalty-container .already-registered-log-in');
                            if (guestEnrollment && memberSignInEnrollment) {
                                if (hasJoinCta) {
                                    removeClass([guestEnrollment, memberSignInEnrollment], hiddenClass);
                                } else {
                                    addClass([guestEnrollment, memberSignInEnrollment], hiddenClass);
                                }
                            }
                            const loyaltyIsImage = queryFirst('.is-drawer-img', loyaltyContainer);
                            desktopImg = loyaltyIsImage.dataset.desktopImg;
                            mobileImg = loyaltyIsImage.dataset.mobileImg;
                            hideShowDrawerImage(mobileImg, desktopImg);
                        } else {
                            const loyaltyContainer = queryFirst('.loyalty-how-to-earn-drawer-body');
                            loyaltyContainer.innerHTML = data;
                            const loyaltyIsImage = queryFirst('.is-how-to-earn-drawer-img', loyaltyContainer);
                            desktopImg = loyaltyIsImage.dataset.desktopImg;
                            mobileImg = loyaltyIsImage.dataset.mobileImg;
                            const title = loyaltyIsImage.dataset.title;
                            const closeButtonLabel = loyaltyIsImage.dataset.closeButton;
                            showHowToEarnDrawerImage(mobileImg, desktopImg, title, closeButtonLabel);
                        }
                        $loyaltyContainer.spinner().stop();
                    }
                },
                error: function (err) {
                    $loyaltyContainer.spinner().stop();
                }
            });
        });
    },
    loyaltyEnrollNowDialog: function () {
        $body.on('click', '.loyalty-enrollnow-cta', function (e) {
            e.preventDefault();
            $(loyaltyEnrollNowDialog).modal('show');
            const loyaltyEnrollPostalCodeContainer = queryAll('.js-loyalty-access-postal-code');
            const loyaltyEnrollPostalEl = queryFirst('#loyalty-access-form-postalcode', loyaltyEnrollNowDialog);
            const loyaltyEnrollCheckboxEl = queryFirst('#optin-to-loyalty-program', loyaltyEnrollNowDialog);
            const loyaltyEnrollForm = queryFirst('form', loyaltyEnrollNowDialog);
            loyaltyEnrollForm.dataset.enrollNowSource = MY_ACCOUNT;
            const formElements = [];
            if (!hasClass(loyaltyEnrollPostalCodeContainer, hiddenClass)) {
                formElements.push(loyaltyEnrollPostalEl);
                loyaltyEnrollPostalEl.value = '';
            }
            if (loyaltyEnrollCheckboxEl) {
                formElements.push(loyaltyEnrollCheckboxEl);
                loyaltyEnrollCheckboxEl.checked = false;
            }
            removeClass(formElements, invalidClass);
        });
        $body.on('click', '.js-loyalty-early-access-welcome-cta', function (e) {
            location.reload();
        });
    },
    loyaltyEnrollNowSubmit: function () {
        $body.on('click', 'form .js-loyalty-enroll-cta', function () {
            const form = this.closest('form');
            const checkbox = queryFirst('.optin-to-loyalty-program', form);
            const errorMsgText = checkbox?.dataset.missingError;
            const errorMsg = queryFirst('.loyalty-terms-error', form);

            // Check if the checkbox is checked
            if (!checkbox?.checked) {
                addClass(checkbox, invalidClass);
                errorMsg.textContent = errorMsgText;
                removeClass(errorMsg, hiddenClass);
                return false;
            } else {
                removeClass(checkbox, invalidClass);
                errorMsg.textContent = '';
                addClass(errorMsg, hiddenClass);
            }
        });
        $body.on('submit', 'form.loyalty-get-access', function (e) {
            e.preventDefault();
            const $sheet = $('.loyalty-enrollnow-dialog');
            const postalCodeEl = queryFirst('.loyalty-access-form-postalcode');
            const referrerIDEl = queryFirst('.referrerID');
            const postalCodeRegex = postalCodeEl ? new RegExp(postalCodeEl.dataset.regexp) : '';
            const postalCode = postalCodeEl ? postalCodeEl.value : '';
            const source = this.dataset.enrollNowSource;
            const referrerID = referrerIDEl ? referrerIDEl.value : '';
            const invalidFeedback = queryFirst('#form-postalcode-error.invalid-feedback');
            if (postalCode && !postalCodeRegex.test(postalCode)) {
                if (invalidFeedback) {
                    removeClass(queryAll('.js-loyalty-access-postal-code'), hiddenClass);
                    addClass(postalCodeEl, invalidClass);
                    invalidFeedback.textContent = postalCodeEl.dataset.patternMismatch;
                }
            } else {
                $sheet.spinner().start();
                let requestData = {
                    postalCode,
                    lillyLoyaltyProgram: true,
                    source
                };
                if (referrerIDEl) {
                    requestData = {
                        postalCode,
                        lillyLoyaltyProgram: true,
                        referrerID
                    };
                }
                const url = this.dataset.href;
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: requestData,
                    dataType: 'html',
                    success: function (data) {
                        const responseData = JSON.parse(data);
                        if (responseData.success) {
                            removeClass(queryFirst('#loyalty-page'), 'loaded');
                            $(loyaltyEnrollNowDialog).modal('hide');
                            $(loyaltyWelcomeDialog).modal('show');
                        }
                        $sheet.spinner().stop();
                    },
                    error: function (err) {
                        $sheet.spinner().stop();
                    }
                });
            }
        });
        const loyaltyUnenrollDashboard = queryFirst('.loyalty-dashboard-unenroll');
        $(loyaltyWelcomeDialog).on('hidden.bs.modal', function () {
            if (loyaltyUnenrollDashboard) {
                location.reload();
            }
        });
        const accountLoyaltyPage = queryFirst('.js-loyalty-select.active');
        if (accountLoyaltyPage && accountLoyaltyPage.dataset.showWelcomePopup === 'true') {
            $('#loyalty-welcome-popup').modal('show');
            removeWelcomeModalShowSession();
        }
    },
    loyaltyDashboardShow: function () {
        $body.on('click', '.loyalty-dashboard-link', function (e) {
            e.preventDefault();
            const url = this.dataset.checkLoyaltyAccess;
            if (isLoyaltyProgramMember) {
                return (window.location.href = accountLoyaltyTabLink);
            }

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    if (!data) {
                        return false;
                    }
                    if (data.showEnrollmentModel) {
                        $body.trigger('clubLilly:EnrollModal');
                        if (data.imageUrls) {
                            const modelImages = data.imageUrls;
                            const enrollmentModel = $('.loyalty-enroll-now-container');
                            const welcomeModel = $('.loyalty-welcome-popup');
                            $(loyaltyEnrollNowDialog).modal('show');
                            enrollmentModel.find('.drawer-image-source').attr('srcset', modelImages.loyaltyaccesspopupmobile);
                            enrollmentModel.find('.drawer-image').attr('src', modelImages.LoyaltyguestenrollmentnonloyaltyD2x);
                            welcomeModel.find('.drawer-image-source').attr('srcset', modelImages.loyaltywelcomepopupmobile);
                            welcomeModel.find('.drawer-image').attr('src', modelImages.LoyaltywelcomeloyaltyD2x);
                        }
                    } else {
                        window.location.href = data.redirectUrl;
                    }
                },
                error: function () {}
            });
        });
    },
    loyaltyDashboardOffersTile: function () {
        const offerExpirationDateEl = queryAll('.offer-expiration-date');
        offerExpirationDateEl.forEach(el => {
            const expirationDateTime = el.textContent;
            el.textContent = expirationDateTime.split(' ')[0];
        });
        $body.on('click', '.flip-card-front', function (e) {
            e.preventDefault();
            const cardFlippedEl = this.closest('.offer-flip-card');
            const cardFront = queryFirst('.flip-card-front', cardFlippedEl);
            addClass(cardFlippedEl, 'offer-card-flipped');
            cardFront.style.pointerEvents = 'none';
        });
        $body.on('click', '.flip-card-back', function (e) {
            e.preventDefault();
            const cardFlippedEl = this.closest('.offer-flip-card');
            const cardFront = queryFirst('.flip-card-front', cardFlippedEl);
            removeClass(cardFlippedEl, 'offer-card-flipped');
            cardFront.style.pointerEvents = 'unset';
        });
        $body.on('click', '.view-copy-code-cta', function (e) {
            e.preventDefault();
            const { target, expiryDate, couponCode, formatString, rewardDesc } = e.target.dataset;
            const offerDiscountDialog = queryFirst(target);
            const offerDiscountModalData = queryFirst('.offer-code-modal-content', offerDiscountDialog);
            const offerDiscountTileData = queryFirst('.offer-tile-content', offerDiscountDialog);
            const offerCodeEl = queryFirst('.js-offers-discount-modal .js-code-section', offerDiscountDialog);
            const offerCodeExpiresEl = queryFirst('.js-offers-discount-modal .expiry-date', offerDiscountDialog);
            const offerDescriptionHeader = queryFirst('.js-offers-discount-modal .code-desc', offerDiscountDialog);
            addClass(offerDiscountTileData, hiddenClass);
            removeClass(offerDiscountModalData, hiddenClass);
            offerCodeEl.value = couponCode;
            offerCodeExpiresEl.textContent = getFormatDateString(expiryDate, formatString) + '.';
            offerDescriptionHeader.textContent = rewardDesc;
        });
    },
    loyaltyReferralDrawer: function () {
        $body.on('click', '.loyalty-referral-drawer-link', function (e) {
            const referralUrlElement = queryFirst('.referral-drawer-link');
            $('.referral-link').val(referralUrlElement.dataset.referralUrl.replace('https://', ''));
            $body.addClass('referral-modal');
        });
    },
    loyaltyCopyReferralUrl: function () {
        $body.on('click', '.referral-cta-link', function (e) {
            const copyReferralUrl = queryFirst('.referral-link');
            const copyCta = queryFirst('.referral-cta-link');
            const copiedLinkCta = queryFirst('.copied-link');
            const referralUrlElement = queryFirst('.referral-drawer-link');
            copyReferralUrl.select();
            copyReferralUrl.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text */
            navigator.clipboard.writeText(referralUrlElement.dataset.referralUrl).then(function () {
                /* clipboard successfully set */
                addClass(copyCta, hiddenClass);
                removeClass(copiedLinkCta, hiddenClass);
                setTimeout(function () {
                    removeClass(copyCta, hiddenClass);
                    addClass(copiedLinkCta, hiddenClass);
                }, 3000);
            });
        });
    },
    loyaltyCopyCode: function () {
        $body.on('click', function (e) {
            const copyButton = e.target.closest('.js-copy-code-cta');
            if (!copyButton) return;

            e.preventDefault();

            const codeField = copyButton.closest('.loyalty-copy');
            const codeSection = codeField.querySelector('.js-code-section');
            const copyCta = copyButton;
            const copiedCodeCta = codeField.querySelector('.js-code-copied');

            if (!codeSection || !copyCta || !copiedCodeCta) {
                console.error('Copy code elements not found');
                return;
            }

            const textToCopy = codeSection.value;

            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    copyCta.classList.add(hiddenClass);
                    copiedCodeCta.classList.remove(hiddenClass);

                    setTimeout(() => {
                        copyCta.classList.remove(hiddenClass);
                        copiedCodeCta.classList.add(hiddenClass);
                    }, 3000);
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        });
    },
    loyaltyOopsRefreshSection: function (e) {
        $body.on('click', '.error-refresh-link', function (e) {
            e.preventDefault();
            const url = this.dataset.referralUrl;
            const successServiceResData = this.closest('.service-response-section');
            if (!successServiceResData) {
                location.reload();
            }
            const tooltipModule = queryFirst('.tool-tip-content', successServiceResData);
            const tooltipInnerData = queryFirst('.' + tooltipModule.id);
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    if (!data.error) {
                        if (successServiceResData) {
                            successServiceResData.innerHTML = data.serviceResHTMLData;
                            const updatedToolTip = queryFirst('.tool-tip-content', successServiceResData);
                            if (updatedToolTip && tooltipInnerData) {
                                updatedToolTip.innerHTML = tooltipInnerData.innerHTML;
                                const popContent = queryFirst('.loyalty-tooltip-popover', updatedToolTip);
                                const tooltipIcon = queryFirst('.loyalty-tooltip-icon', updatedToolTip);
                                $(tooltipIcon).popover({
                                    trigger: 'click',
                                    placement: 'auto',
                                    html: true,
                                    content: popContent
                                });
                                $('[data-toggle="popover"]').on('shown.bs.popover', function (e) {
                                    const { target } = e;
                                    $body.one('click', function (clickEvent) {
                                        const clicked = clickEvent.target;
                                        if (clicked !== target && clicked.parentElement !== target && $('.popover.show').length) {
                                            $(target).trigger('click');
                                        }
                                    });
                                });
                                const scrollableContent = queryFirst('.scrollable-content', successServiceResData);
                                if (scrollableContent) initSpecificCarousel(scrollableContent);
                            }
                            updateReferralBg();
                            init();
                        }
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    $.spinner().stop();
                }
            });
        });
    },
    referralModuleBg: function (e) {
        updateReferralBg();
        const referralSection = queryFirst('.referral-section-bg-color');
        removeClass(referralSection, hiddenClass);
    },
    loyaltyEarlyAccessSignUp: function () {
        $body.on('click', '.early-access-registered .early-access-sign-up', function (e) {
            e.preventDefault();
            handlePdpEarlyAccessLoginRegistration();
        });

        $body.on('click', '.early-access-register-form-cancel', function (e) {
            e.preventDefault();
            $(loyaltyEarlyAccessRegisterForm).modal('hide');
        });
    },

    loyaltyQuizDrawer: function () {
        $body.on('click', '.js-loyalty-quiz-drawer-link', async e => {
            e.preventDefault();
            const $modalSpinner = $('#loyalty-quiz .sheet-dialog').spinner();

            $modalSpinner.start();

            const { target } = e;
            const { actionUrl } = target.dataset;

            const currentModal = $(e.target).closest('.lp-slideout-modal, .sheet');

            if (currentModal.length) {
                currentModal.modal('hide');
            }

            const quizModalElement = queryFirst('#loyalty-quiz');
            const modalContentBody = queryFirst('.js-loyalty-quiz-content-body', quizModalElement);
            const modalContentFooter = queryFirst('.js-loyalty-quiz-content-footer', quizModalElement);

            modalContentBody.innerHTML = '';
            modalContentFooter.innerHTML = '';

            const response = await fetch(actionUrl);
            const content = await response.text();

            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');

            const ctasContainers = queryAll('.js-style-quiz-buttons, .js-quiz-ctas-container, .js-thankyou-btn-container-ctas', doc);

            ctasContainers.forEach(ctasContainer => {
                if (ctasContainer) {
                    modalContentFooter.appendChild(ctasContainer);
                }
            });

            modalContentBody.innerHTML = doc.body.innerHTML;

            loyaltyQuiz.init();
            $modalSpinner.stop();
        });
    },

    init
};

window.addEventListener(
    'resize',
    throttle(() => {
        if (mobileImg || desktopImg) {
            hideShowDrawerImage(mobileImg, desktopImg);
        }
        updateReferralBg();
    })
);

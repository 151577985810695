'use strict';

const { queryFirst, queryAll, addClass, removeClass, scrollTo, hasClass } = require('../domUtil');
const { HIDDEN_CLASS } = require('../constants');
const swatchSelected = 'swatch-item-selected';
const swatchTextSelected = 'swatch-text-selected';
const swatchImgTitle = '.swatch-img-title';
const previouslySelectedRes = {};
let urlWithHash = new URL(window.location.href);
let bodyEventsInitialized = false;

/**
 * Show the latest selected answers for a quarion and add the selected classes to it
 * @param {Array} answerSelectedArr selected answers array
 */
function showSelectedAnswers(answerSelectedArr) {
    answerSelectedArr.forEach(item => {
        const selectedSwatchEl = queryFirst(`[data-option-title="${item}"]`);
        if (selectedSwatchEl) {
            const selectedSwatchContainer = selectedSwatchEl.closest('.js-swatch-selection-wrapper');
            const swatchTitleSelected = queryFirst(swatchImgTitle, selectedSwatchContainer);
            const { quizSelectionType } = selectedSwatchContainer.dataset;
            if (quizSelectionType === 'circle') {
                const selectedCircularSwatch = queryFirst('.swatch-img', selectedSwatchContainer);
                const selectedSwatchTextEl = queryFirst(swatchImgTitle, selectedSwatchContainer);
                addClass(selectedCircularSwatch, swatchSelected);
                addClass(selectedSwatchTextEl, swatchTextSelected);
            } else {
                addClass(selectedSwatchEl, swatchSelected);
                addClass(swatchTitleSelected, swatchTextSelected);
            }
        }
    });
}

/**
 * Reset state
 */
function resetState() {
    addClass(queryAll('.quiz-question-wrapper'), HIDDEN_CLASS);
    addClass(queryAll('.js-quiz-ctas-container'), HIDDEN_CLASS);
    addClass(queryAll('.js-validation-select-more'), HIDDEN_CLASS);
}

/**
 * Remove all the selected answers of the current questions
 * @param {number} questionCount Current Question number
 */
function removeAllSelectedAnswers(questionCount) {
    const currentQuestionEl = queryFirst(`.question-wrapper-${questionCount}`);
    const allSelectedResp = queryAll('.swatch-item-selected', currentQuestionEl);
    allSelectedResp.forEach(el => {
        if (hasClass(el, 'square-container')) {
            const swatchTitleSelected = queryFirst(swatchImgTitle, el);
            const categoryChecked = queryFirst('.select-swatch-checked', el);
            removeClass(el, swatchSelected);
            removeClass(swatchTitleSelected, swatchTextSelected);
        } else {
            const swatchCheckedEl = el.closest('.circular-container');
            const selectedSwatch = queryFirst('.select-swatch-checked', swatchCheckedEl);
            const selectedSwatchText = queryFirst(swatchImgTitle, swatchCheckedEl);
            if (hasClass(el, swatchSelected)) {
                removeClass(el, swatchSelected);
            }
            removeClass(selectedSwatchText, swatchTextSelected);
        }
    });
}

/**
 * Get Validation Message Element
 * @param {number} questionNumber Current Question Number
 * @returns {HTMLElement} result
 */
function getValidationMessageEl(questionNumber) {
    const questionWrapperEl = queryFirst(`.question-wrapper-${questionNumber}`);
    return queryFirst('.js-validation-select-more', questionWrapperEl);
}

/**
 * Refresh Validation Message
 * @param {number} questionNumber Current Question Number
 */
function refreshValidationMessage(questionNumber) {
    const validationSelectMoreMsgEl = getValidationMessageEl(questionNumber);
    const quizModalElement = queryFirst('#loyalty-quiz');
    const modalContentBody = queryFirst('.js-loyalty-quiz-content-body', quizModalElement);

    const { quizValidationPleaseSelectMsg } = modalContentBody.dataset;
    validationSelectMoreMsgEl.innerHTML = quizValidationPleaseSelectMsg;

    addClass(validationSelectMoreMsgEl, HIDDEN_CLASS);
}

/**
 * Tohggle Validation Message
 * @param {number} questionNumber Current Question Number
 * @param {boolean} show show validation message
 */
function toggleValidationMessage(questionNumber, show) {
    const validationSelectMoreMsgEl = getValidationMessageEl(questionNumber);

    if (show) {
        removeClass(validationSelectMoreMsgEl, HIDDEN_CLASS);
    } else {
        addClass(validationSelectMoreMsgEl, HIDDEN_CLASS);
    }
}

/**
 * Show the next question to be displayed
 * @param {number} questionNumber Current Question Number
 */
function showNextQuestion(questionNumber) {
    const nextQuestionNumber = questionNumber + 1;
    const nextQuestionEl = queryFirst(`.question-wrapper-${nextQuestionNumber}`);
    const nextCtasContainerEl = queryFirst(`.js-quiz-ctas-container-${nextQuestionNumber}`);
    const { questionStep } = queryFirst('.question-steps', nextQuestionEl).dataset;
    const quizModalElement = queryFirst('#loyalty-quiz');
    const sheetContentBody = queryFirst('.sheet-body-content', quizModalElement);
    refreshValidationMessage(nextQuestionNumber);
    urlWithHash.hash = questionStep;
    window.history.replaceState({}, '', urlWithHash.href);
    removeClass(nextQuestionEl, HIDDEN_CLASS);
    removeClass(nextCtasContainerEl, HIDDEN_CLASS);

    const nextQuestion = Object.keys(previouslySelectedRes)[questionNumber];
    const nextAnswer = previouslySelectedRes[nextQuestion];
    if (nextAnswer) {
        const categoryArray = nextAnswer.split(',');
        showSelectedAnswers(categoryArray);
    }

    sheetContentBody.scrollTo(sheetContentBody.offsetTop, 0);
}

/**
 * Show the previous question to be displayed
 * @param {number} questionNumber Current Question Number
 */
function showPreviousQuestion(questionNumber) {
    const prevQuestionNumber = questionNumber - 1;
    const previousQuestionEl = queryFirst(`.question-wrapper-${prevQuestionNumber}`);
    const prevCtasContainerEl = queryFirst(`.js-quiz-ctas-container-${prevQuestionNumber}`);
    const { questionStep } = queryFirst('.question-steps', previousQuestionEl).dataset;
    const quizModalElement = queryFirst('#loyalty-quiz');
    const sheetContentBody = queryFirst('.sheet-body-content', quizModalElement);
    refreshValidationMessage(prevQuestionNumber);
    urlWithHash.hash = questionStep;
    window.history.replaceState({}, '', urlWithHash.href);
    removeClass(previousQuestionEl, HIDDEN_CLASS);
    removeClass(prevCtasContainerEl, HIDDEN_CLASS);

    const previousQuestion = Object.keys(previouslySelectedRes)[questionNumber - 2];

    const previousAnswer = previouslySelectedRes[previousQuestion];
    if (previousAnswer) {
        const categoryArray = previousAnswer.split(',');
        showSelectedAnswers(categoryArray);
    }

    sheetContentBody.scrollTo(sheetContentBody.offsetTop, 0);
}

/**
 * Update the responses to the question if any changes are made
 * @param {string} questionNumber current question number
 * @returns {boolean} at least one selected
 */
function updateResponseSelected(questionNumber) {
    const questionNumberIntValue = parseInt(questionNumber, 10);
    const swatchContainerEl = queryFirst('.quiz-swatch-' + questionNumber);
    const userSelection = queryAll('.js-swatch-selection-wrapper .swatch-item-selected', swatchContainerEl);
    const selections = Array.from(userSelection);
    const answerArr = selections.map(element => element.dataset.optionTitle);
    if (questionNumberIntValue) {
        const previousAnswer = Object.keys(previouslySelectedRes)[questionNumberIntValue - 1];
        previouslySelectedRes[previousAnswer] = answerArr.join(',');
    }
    return !!answerArr.length;
}
/**
 * Initialize vody events, executed just onve
 */
function initBodyEvents() {
    if (bodyEventsInitialized) {
        return;
    }
    bodyEventsInitialized = true;

    const $body = $('body');
    $body.on('click', '.loyalty-next-question-cta', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        const { target } = e;
        const { stepCount } = target.dataset;
        const countValue = parseInt(stepCount, 10);
        const $modalSpinner = $('#loyalty-quiz .sheet-dialog').spinner();
        const questionResponses = queryFirst('.js-question-responses');
        questionResponses.dataset.stepCount = countValue + 1;
        const isValid = updateResponseSelected(stepCount);

        toggleValidationMessage(countValue, !isValid);
        if (!isValid) {
            return;
        }

        const form = {
            isSubmitted: 'false'
        };
        Object.assign(form, previouslySelectedRes);
        $modalSpinner.start();
        $.ajax({
            url: target.dataset.action,
            type: 'post',
            data: form,
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    resetState();
                    showNextQuestion(countValue);
                }
                $modalSpinner.stop();
            },
            error: () => {
                $modalSpinner.stop();
            }
        });
    });

    $body.on('click', '.loyalty-previous-question-cta, .loyalty-previous-question-top', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        const { target } = e;
        const { stepCount } = target.dataset;
        const countValue = parseInt(stepCount, 10);

        if (countValue > 1) {
            const questionResponses = queryFirst('.js-question-responses');
            questionResponses.dataset.stepCount = countValue - 1;
            removeAllSelectedAnswers(countValue);
            resetState();
            showPreviousQuestion(countValue);
        }
    });

    $body.on('click', '.loyalty-skip-question-cta', function (e) {
        e.preventDefault();
        const { target } = e;
        const { stepCount } = target.dataset;
        const countValue = parseInt(stepCount, 10);
        resetState();
        removeAllSelectedAnswers(countValue);
        showNextQuestion(countValue);
    });

    $body.on('click', '.loyalty-finish-later-cta', function (e) {
        e.preventDefault();
        const { href, questionNumber, action } = e.target.dataset;
        const quizModalElement = queryFirst('#loyalty-quiz');
        const sheetContentBody = queryFirst('.sheet-body-content', quizModalElement);
        const $modalSpinner = $('#loyalty-quiz .sheet-dialog').spinner();
        $modalSpinner.start();
        // Saving the answer on click of finish later CTA
        updateResponseSelected(questionNumber);
        const form = {
            isSubmitted: 'false'
        };
        Object.assign(form, previouslySelectedRes);

        $.ajax({
            url: action,
            type: 'post',
            data: form,
            dataType: 'json',
            success: function () {
                $modalSpinner.stop();
                urlWithHash.hash = href;
                sheetContentBody.scrollTo(sheetContentBody.offsetTop, 0);
                window.history.replaceState({}, '', urlWithHash.href);
                resetState();

                const quizThankYouPage = queryFirst('.loyalty-thankyou-page');
                const completeQuizEl = queryFirst('.complete-quiz-thankyou', quizThankYouPage);
                const completeQuizImg = queryFirst('.thankyou-img', quizThankYouPage);
                const thankyouCTAs = queryFirst('.js-thankyou-btn-container-ctas');

                removeClass([quizThankYouPage, completeQuizEl, completeQuizImg, thankyouCTAs], HIDDEN_CLASS);
            },
            error: function () {
                $modalSpinner.stop();
            }
        });
    });

    $body.on('click', '.swatch-circle-options, .swatch-square-options', function (e) {
        e.preventDefault();
        const { currentTarget } = e;
        const squareContainerEl = queryFirst('.square-container', currentTarget);
        const circularContainerEl = queryFirst('.circular-container', currentTarget);
        const circularContainerImg = queryFirst('.circular-container .swatch-img', currentTarget);
        const swatchTitleSelected = queryFirst(swatchImgTitle, currentTarget);
        const categoryChecked = queryFirst('.select-swatch-checked', currentTarget);
        if ((squareContainerEl && hasClass(squareContainerEl, swatchSelected)) || (circularContainerImg && hasClass(circularContainerImg, swatchSelected))) {
            removeClass(squareContainerEl, swatchSelected);
            removeClass(circularContainerImg, swatchSelected);
            removeClass(swatchTitleSelected, swatchTextSelected);
            if (squareContainerEl) {
                squareContainerEl.setAttribute('aria-selected', false);
            }
            if (circularContainerEl) {
                circularContainerEl.setAttribute('aria-selected', false);
            }
        } else {
            addClass(squareContainerEl, swatchSelected);
            addClass(circularContainerImg, swatchSelected);
            addClass(swatchTitleSelected, swatchTextSelected);
            removeClass(categoryChecked, HIDDEN_CLASS);
            if (squareContainerEl) {
                squareContainerEl.setAttribute('aria-selected', true);
            }
            if (circularContainerEl) {
                circularContainerEl.setAttribute('aria-selected', true);
            }
        }
    });
}

module.exports = {
    init: function () {
        const questionsCnt = 3;
        const quizLandingPage = queryFirst('.loyalty-style-landing');
        const quizLandingCtas = queryFirst('.js-style-quiz-buttons');

        urlWithHash = new URL(window.location.href);

        for (let i = 1, len = questionsCnt; i <= len; i++) {
            previouslySelectedRes[`question${i}Response`] = queryFirst(`.question${i}-response-data`).value || '';
        }

        const quizStartButton = queryFirst('.style-quiz-start-cta');
        const quizSubmitBtn = queryFirst('.loyalty-submit-quiz-cta');
        const quizThankYouPage = queryFirst('.loyalty-thankyou-page');
        const completeQuizEl = queryFirst('.complete-quiz-thankyou', quizThankYouPage);
        const thankyouCTAs = queryFirst('.js-thankyou-btn-container-ctas');
        const completeQuizImg = queryFirst('.thankyou-img', quizThankYouPage);
        const quizQuestionPage = queryFirst('.style-quiz-questions-container');
        const question1El = queryFirst('.question-wrapper-1');
        const ctasContainerEl1 = queryFirst('.js-quiz-ctas-container-1');
        const { questionStep } = queryFirst('.question-steps', question1El).dataset;

        refreshValidationMessage(1);

        urlWithHash.hash = questionStep;

        if (queryFirst('.style-quiz-questions-container.pending-or-completed')) {
            removeClass(quizQuestionPage, HIDDEN_CLASS);
            resetState();
            removeClass(question1El, HIDDEN_CLASS);
            removeClass(ctasContainerEl1, HIDDEN_CLASS);
            window.history.replaceState({}, '', urlWithHash.href);
            if (previouslySelectedRes.question1Response) {
                const colorArray = previouslySelectedRes.question1Response.split(',');
                showSelectedAnswers(colorArray);
            }
        } else {
            quizStartButton.addEventListener('click', e => {
                e.preventDefault();
                addClass(quizLandingPage, HIDDEN_CLASS);
                addClass(quizLandingCtas, HIDDEN_CLASS);
                removeClass(quizQuestionPage, HIDDEN_CLASS);
                resetState();
                removeClass(question1El, HIDDEN_CLASS);
                removeClass(ctasContainerEl1, HIDDEN_CLASS);
                window.history.replaceState({}, '', urlWithHash.href);
            });
        }

        quizSubmitBtn.addEventListener('click', e => {
            e.preventDefault();
            const { target } = e;
            const { stepCount, action, href } = target.dataset;
            const countValue = parseInt(stepCount, 10);
            const questionResponses = queryFirst('.js-question-responses');
            questionResponses.dataset.stepCount = countValue + 1;
            const swatchContainerEl = queryFirst('.quiz-swatch-' + stepCount);
            const userSelection = queryAll('.js-swatch-selection-wrapper .swatch-item-selected', swatchContainerEl);
            const selections = Array.from(userSelection);
            const answerArr = selections.map(element => element.dataset.optionTitle);
            const quizModalElement = queryFirst('#loyalty-quiz');
            const sheetContentBody = queryFirst('.sheet-body-content', quizModalElement);
            const $modalSpinner = $('#loyalty-quiz .sheet-dialog').spinner();
            if (countValue) {
                const previousAnswer = Object.keys(previouslySelectedRes)[countValue - 1];
                previouslySelectedRes[previousAnswer] = answerArr.join(',');
            }
            const form = {
                isSubmitted: 'true'
            };
            Object.assign(form, previouslySelectedRes);
            $modalSpinner.start();
            $.ajax({
                url: action,
                type: 'post',
                data: form,
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        urlWithHash.hash = href;
                        window.history.replaceState({}, '', urlWithHash.href);
                        resetState();
                        removeClass([quizThankYouPage, completeQuizEl, completeQuizImg, thankyouCTAs], HIDDEN_CLASS);
                    }
                    $modalSpinner.stop();
                    sheetContentBody.scrollTo(sheetContentBody.offsetTop, 0);
                },
                error: function () {
                    $modalSpinner.stop();
                }
            });
        });
        initBodyEvents();
    }
};

/* globals google */
/* handles store selection sheet from called from header or MA */
'use strict';

const { queryFirst, queryAll, addClass, removeClass } = require('../domUtil');
const { HIDDEN_CLASS } = require('../constants');
const { DISABLED_CLASS } = require('../components/shippingPreference/constants');

const { getJSON, geolocate } = require('../util');
const { getLatLong } = require('../googleMaps');
const storeLocatorContainerEl = queryFirst('.preferred-store-container');
let searchData = {
    latitude: '',
    longitude: '',
    radius: '',
    storeType: 'LILLY_STORES'
};
let isISPUInit = true;
const prefStoreContainer = queryFirst('.preferred-store-container');
const setPrefStoreBtn = queryFirst('.set-preferred-store', prefStoreContainer);
const $spinner = $('#account-preferred-store .modal-dialog').spinner();

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    const resultsDiv = queryFirst('.js-results', prefStoreContainer);
    const findStoresForm = queryFirst('.find-stores-form', prefStoreContainer);
    const hasResults = data.stores.length > 0;
    resultsDiv.innerHTML = '';
    if (!hasResults && !isISPUInit) {
        addClass(setPrefStoreBtn, HIDDEN_CLASS);
        removeClass([findStoresForm], HIDDEN_CLASS);
    } else if (hasResults) {
        removeClass(setPrefStoreBtn, HIDDEN_CLASS);
        addClass(setPrefStoreBtn, DISABLED_CLASS);
        setPrefStoreBtn.disabled = true;
        addClass([findStoresForm], HIDDEN_CLASS);
    }
    if (data.storesResultsHtml) {
        resultsDiv.innerHTML = data.storesResultsHtml;
        const preferredStoreNoResults = queryFirst('.preferred-store-no-results', prefStoreContainer);

        removeClass(resultsDiv, HIDDEN_CLASS);

        if (!isISPUInit) {
            removeClass(preferredStoreNoResults, HIDDEN_CLASS);
        }
    }
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function handleStoreResults(data) {
    $spinner.stop();
    updateStoresResults(data);
}

/**
 * Handles error response of search
 */
function errorCallBack() {
    $spinner.stop();
}

/**
 * Function that gets stores list as per location data passeds
 * @param {Object} locationData - Latitude, longitude, radius for the particular location
 */
function getStores(locationData) {
    const { latitude, longitude, radius, actionUrl, address } = locationData;
    const { storeType } = searchData;
    const locationInactiveIcon = queryFirst('.geolocation-not-activated', storeLocatorContainerEl);
    const locationActiveIcon = queryFirst('.geolocation-activated', storeLocatorContainerEl);

    if (isISPUInit && latitude && longitude) {
        removeClass(locationActiveIcon, HIDDEN_CLASS);
        addClass(locationInactiveIcon, HIDDEN_CLASS);
    } else if (isISPUInit) {
        removeClass(locationInactiveIcon, HIDDEN_CLASS);
        addClass(locationActiveIcon, HIDDEN_CLASS);
    }

    const searchDataToSend = {
        address,
        storeType,
        radius,
        latitude,
        longitude,
        isAccount: true
    };
    // Updates searchData state
    searchData = Object.assign(searchData, {
        radius,
        latitude,
        longitude
    });

    $spinner.start();
    getJSON(actionUrl, 'GET', searchDataToSend, handleStoreResults, errorCallBack);
}

/**
 * Search for stores with entered address
 */
function search() {
    const $form = $('.store-search-form');
    const postalCodeEl = $form.find('[name="postalCode"]');
    const address = postalCodeEl.val().trim();

    if (address) {
        getLatLong(getStores, {
            address,
            radius: $form.find('.radius').val(),
            actionUrl: $form.attr('action')
        });
    } else {
        postalCodeEl.addClass('is-invalid');
    }
}

/**
 * Function to set preferred store
 */
function setPreferredStore() {
    $('.preferred-store-container').on('click', '.set-preferred-store', event => {
        const setPreferredStoreLink = event.target;
        const { actionUrl, storeId } = setPreferredStoreLink.dataset;

        $spinner.start();
        getJSON(
            actionUrl,
            'POST',
            {
                storeID: storeId
            },
            () => {
                location.reload();
            },
            () => {
                $spinner.stop();
            }
        );
    });
}

module.exports = {
    onISPUSlideShow: () => {
        $('.preferred-store-container').on('click', '.select-store-input', event => {
            const currentStoreCheckbox = event.target;
            const { storeId } = currentStoreCheckbox.dataset;

            queryAll('.select-store-input', prefStoreContainer)?.forEach(el => {
                if (storeId !== el.dataset.storeId) {
                    el.checked = false;
                }
            });
            setPrefStoreBtn.dataset.storeId = storeId;

            removeClass(setPrefStoreBtn, DISABLED_CLASS);
            setPrefStoreBtn.disabled = false;
        });

        $('#account-preferred-store').on('show.bs.modal', e => {
            addClass(setPrefStoreBtn, HIDDEN_CLASS);
            const $form = $('.store-search-form');
            const postalCodeInputEl = document.getElementById('store-postal-code');
            const postalCodeVal = postalCodeInputEl.dataset.value;
            postalCodeInputEl.value = postalCodeVal;

            if (postalCodeVal) {
                getLatLong(getStores, {
                    address: postalCodeVal,
                    radius: $form.find('.radius').val(),
                    actionUrl: $form.attr('action')
                });
            } else {
                geolocate(
                    getStores,
                    {
                        radius: $form.find('.radius').val(),
                        actionUrl: $form.attr('action')
                    },
                    getStores
                );
            }
        });
    },
    search: function () {
        const storeLocatorFormEl = queryFirst('.store-search-form', storeLocatorContainerEl);
        const storeLocatorSearchBtn = queryFirst('.btn-store-search', storeLocatorContainerEl);

        const callback = event => {
            event.preventDefault();
            isISPUInit = false;
            search();
        };

        if (storeLocatorFormEl) {
            storeLocatorFormEl.addEventListener('submit', callback);
        }
        if (storeLocatorSearchBtn) {
            storeLocatorSearchBtn.addEventListener('click', callback);
        }
    },
    onISPUSlideClose: () => {
        $('#account-preferred-store').on('hidden.bs.modal', e => {
            addClass(setPrefStoreBtn, HIDDEN_CLASS);
            const { target } = e;
            isISPUInit = true;
            const storeResults = queryFirst('.results', target);
            if (storeResults) {
                storeResults.innerHTML = '';
            }
            const postalCodeInputEl = document.getElementById('store-postal-code');
            postalCodeInputEl.value = '';
            removeClass(postalCodeInputEl, 'is-invalid');
            addClass(queryFirst('.preferred-store-no-results', target), HIDDEN_CLASS);
            addClass(queryFirst('.geo-location-error', storeLocatorContainerEl), HIDDEN_CLASS);
        });
    },
    onDetectLocation: () => {
        //Event listener to retrieve geo location based store results on click of use current location
        $('.preferred-store-container').on('click', '.detect-location', event => {
            isISPUInit = false;
            const { target } = event;
            const { actionUrl } = target.dataset;
            const radius = queryFirst('.radius', storeLocatorContainerEl).value;
            const mixinData = { radius, actionUrl };
            const geoLocationError = queryFirst('.geo-location-error', storeLocatorContainerEl);
            const noStoresMessage = queryFirst('.preferred-store-no-results', storeLocatorContainerEl);
            const storeResults = queryFirst('.results', storeLocatorContainerEl);
            const postalCodeInputEl = document.getElementById('store-postal-code');
            postalCodeInputEl.value = '';
            removeClass(postalCodeInputEl, 'is-invalid');
            addClass(geoLocationError, HIDDEN_CLASS);

            geolocate(getStores, mixinData, () => {
                removeClass(geoLocationError, HIDDEN_CLASS);
                addClass([storeResults, noStoresMessage], HIDDEN_CLASS);
            });
        });
    },
    removePreferredStore: () => {
        $('.preferred-store-container').on('change', '.preferred-store-input', event => {
            const { target } = event;
            const { actionUrl } = target.dataset;
            $spinner.start();
            getJSON(
                actionUrl,
                'POST',
                null,
                () => {
                    location.reload();
                },
                () => {
                    $spinner.stop();
                }
            );
        });
    },
    onPostalCodeChange: () => {
        $('.preferred-store-container').on('keyup', '#store-postal-code', event => {
            const { target } = event;
            addClass(queryFirst('.geo-location-error', storeLocatorContainerEl), HIDDEN_CLASS);
            addClass(queryFirst('.preferred-store-no-results'), HIDDEN_CLASS);
            if (target.value) {
                removeClass(target, 'is-invalid');
            }
        });
    },
    setPreferredStore: setPreferredStore
};

/**
 * HTML Templates for use in client-side rendering.
 *
 * @module dom.templates
 * @version 1.0.0
 */

'use strict';

/**
 * QuickView Templates
 */
exports.quickView = {
    container: '<div class="quickview-container col-12 hidden-md-down"></div>',
    quickViewModal: `
        <div class="modal fade" id="quickViewModal" role="dialog" tabindex="-1">
            <span class="enter-message sr-only"></span>
            <div class="modal-dialog modal-dialog-centered quick-view-dialog">
                <div class="modal-content">
                    <div class="modal-body"></div>
                </div>
            </div>
        </div>
    `
};

/**
 * Get All Wishlist Collection Templates
 */
exports.addToWishlist = {
    container: '<div class="addToWishlist-container col-12 hidden-md-down"></div>',
    addToWishlistModal: `
    <div class="modal fade add-product-to-wishlist-dialog" id="addToWishlistModal" tabindex="-1" role="dialog">
            <span class="enter-message sr-only"></span>
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="generic-confirmation-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <svg role="img" aria-describedby="add-to-wishlist-cross-title add-to-wishlist-cross-desc" class="icon svg-icon svg-logo-dims" width="16.0" height="16.0">
                                <title id="add-to-wishlist-cross-title">Close dialog</title>
                                <desc id="add-to-wishlist-cross-desc">Cross mark icon to close this dialog</desc>
                                <use xlink:href="#cross" role="img"></use>
                            </svg>
                        </button>
                    </div>
                    <div class="modal-body"></div>
                </div>
            </div>
    </div>
    <isinclude template="wishlist/components/createWishlist" />

    `
};

/**
 * Cart error message template
 */
exports.cart = {
    errorMessage: '<div class="cart-error-message">{0}</div>',
    cartAvailabilityMessageOOS: (message, url) => `
        <li><p class="availability-message-text" role="alert">${message}
            <a href="${url}" class="similar-styles-category-link icon-link">Browse Similar Styles</a></p>
        </li>
    `
};

/**
 * Cart & Checkout promo template
 */
exports.promo = {
    shippingMethod: `
        <div class="cart-totals-line shipping-item">
            <div class="shipping-item-name">
                {0}:
                <span class="shipping-method-name">{1}</span>
            </div>
            <div class="shipping-total-cost">{2}</div>
            <div class="shipping-arrival">
                <span class="shipping-arrival-time">{3}</span>
            </div>
        </div>`,
    checkoutNormalShipping: '<span class="text-right pricing shipping-method-price">{0}</span>',
    checkoutDiscountShipping: `
        <del class="text-right pricing shipping-method-price">{0}</del>
        <span>{1}</span>
    `
};

/**
 * Checkout template
 */
exports.checkout = {
    discountPrice: '<del class="shipping-cost">{0}</del><span>{1}</span>'
};

/**
 * Selected swatch name template
 */
exports.swatchNameTemplate = {
    swatchNameHtml: '<p class="selected-swatch-name">Color: {0}</p>'
};

exports.emptySwatchTemplate = '<span class="swatches-empty">{0}</span>';

/*
 * Address Suggestions template
 */
exports.addressSuggestions = {
    suggestionTextTemplate: '<p class="address-suggestion-text"></p>',
    suggestionsTemplate: `
        <div id="address-suggestions-template">
            <div class="form-check custom-radio">
            <input type="radio" class="form-check-input" id="addressSuggestion" name="addressSuggestion">
            <label class="form-check-label shipping-address-option" for="addressSuggestion">
                <span class="address-suggestion"></span>
            </label>
            </div>
        </div>`
};

/*
 * Approaching Discounts template
 */
exports.approachingDiscounts = {
    nonCouponBasedAdjustmentTemplate: `<div class="cart-totals-line price-container promo-container">
            <div class="price-text">{0}</div>
            <div class="price-value">{1}</div>
            <div class="promo-description">{2}</div>
        </div>`
};

/**
 * Video Template
 * @param  {Object} options video setting values
 * @return {html} video html template
 */
exports.videoTemplate = (options = {}) => {
    // Note: I don't believe this template is used anywhere anymore
    const { loop = '', videoUrl = '', imgUrl = '', classNames = '', autoplay = false, muted = true, controls = false, icontitle = '', icondesc = '' } = options;

    return `
        <div class="video-component ${classNames}">
            <video class="video" playsinline ${autoplay ? 'autoplay' : ''} ${loop ? 'loop' : ''}  ${muted ? 'muted' : ''} poster="${imgUrl} ${controls ? 'controls' : ''}>
                <source src="${videoUrl}" type="video/mp4" />
            </video>
            <button class="play-video">
                <svg role="img" class="icon svg-icon svg-video-play" width="16" height="16" aria-labelledby="title" aria-describedby="desc">
                    <title>${icontitle}</title>
                    <desc>${icondesc}</desc>
                    <use xlink:href="#play" role="img"></use>
                </svg>
            </button>
        </div>
    `;
};

/**
 * Image Template
 * @param  {string} imgUrl value
 * @param  {string} altText value
 * @param  {string} classNames value
 * @return {html} video html template
 */
exports.imageTemplate = (imgUrl, altText, classNames) => {
    return `
        <div class="image-component ${classNames}">
            <img class="w-100" src="${imgUrl}" alt="${altText}"/>
        </div>
    `;
};

/**
 * Size option template
 * @param  {string} url - Size variant url
 * @param  {string} value - Size value
 * @return {html} size option html template
 */
exports.sizeOptionTemplate = (url, value) => {
    return `<option value="${url}" data-attr-value="${value}">${value}</option>`;
};

/**
 * Function to rerender size button element
 * @param {Object} variantData - variant details
 * @param {string} ariaLabel - aria label
 * @param {string} ariaDescribedby - aria described by
 * @param {string} selectedText - selected text
 * @param {string} oosMessage - out of the stock text
 * @param {number} length - length of sizes
 * @return {html} size button html template
 */
exports.sizeButtonTemplate = (variantData, ariaLabel, ariaDescribedby, selectedText, oosMessage, length) => {
    const { selected, forceOutOfStock, inStock, value, url, sizeId } = variantData;
    return `<li class="size-list js-size-list ${length === 1 ? 'd-none' : ''}">
                <button class="btn size-btn ${selected ? 'selected' : ''} ${!inStock || forceOutOfStock ? 'not-available' : ''}" title="${ariaLabel}" data-attr-url="${url || ''}"
                data-attr-value="${value}" data-value-id="${value}" aria-label="${ariaLabel}" aria-describedby="${ariaDescribedby}" data-variation-id="${sizeId}"><span>${value}</span></button>
                <span id="${ariaDescribedby}" class="sr-only selected-assistive-text" data-selected-text="${selectedText}" data-out-of-stock="${oosMessage}">${selected ? selectedText : ''} ${!inStock || forceOutOfStock ? oosMessage : ''}</span>
            </li>`;
};

/**
 * Function to render empty wishlist markup for guest user
 * @param {string} emptyWishlistMessage - empty wishlist message
 * @return {html} wishlist html template
 */
exports.guestEmptyWishlistTemplate = (emptyWishlistMessage, emptyWishlistContentAssetBody) => {
    return `<div class="col-12 col-md-6 empty-wishlist">
                <div class="empty-list-user-section">
                    <p class="non-logged-user-message">${emptyWishlistMessage}</p>
                    ${emptyWishlistContentAssetBody}
                </div>
            </div>`;
};

/**
 * Function to render empty wishlist markup for guest user
 * @param {string} emptySFLMessage - empty SFL message
 * @param {string} continueShopURL - continue shopping URL
 * @param {string} continueShopMsg - continue shopping message
 * @param {string} signInMsg - sign In message
 * @param {string} signInSFLURL - sign in URL
 * @return {html} SFL html template
 */
exports.emptySFL = (emptySFLMessage, continueShopURL, continueShopMsg, signInMsg, signInSFLURL) => {
    return `<div class="sfl-cart-products">
                <div class="container cart-empty sfl-empty">
                    <div class="row">
                        <div class="col-12 text-center">
                            <p class="sfl-empty-msg empty-message">${emptySFLMessage}</p>
                            <a href="${signInSFLURL}"
                                class="btn btn-block button-secondary account-login-btn enable-button-onvalidate sfl-login-btn col-md-6">${signInMsg}
                            </a>
                            <a href="${continueShopURL}" class="d-inline-block tertiary-link continue-shop-link" role="link">${continueShopMsg}</a></div>
                        </div>
                    </div>
                </div>
            </div>`;
};

/**
 * GWP Site Templates
 */
exports.gwpSizeTemplate = {
    singleSize: '<span class="selected-size">{0}</span>',
    multiSize: `
        <ul class="size-list">
            <span class="sr-only selected-assistive-text"></span>
            {1}
        </ul>
    `,
    multiDefaultSizeOption: '',
    multiSizeOption: '<li class="size-option"><button title="Select Size: {1}" class="btn size-btn {4} {2}" data-attr-url="{0}" data-attr-value="{1}" data-value-id="{1}" {5}><span>{3}</span></button></li>'
};

/**
 * Address autocomplete templates
 */
exports.autoComplete = {
    suggestionLink: (suggestion, addressLine, secondaryEntryAddressLine) => `
        <a href="javascript:void(0);"
            class="autocomplete-suggestion list-group-item"
            data-address-one="${suggestion.street_line}"
            data-address-two="${suggestion.secondary || ''}"
            data-state="${suggestion.state}"
            data-city="${suggestion.city}"
            data-zipcode="${suggestion.zipcode}"
            data-has-secondary-entries="${suggestion.entries > 1}"
            data-secondary-entry-search-value="${secondaryEntryAddressLine}"
        >${addressLine}</a>
    `,
    suggestionContainer: containerClass => `<div class="autocomplete-suggestions list-group ${containerClass || ''}"></div>`
};

exports.contactUsTemplate = {
    signupError: msg => `<div class="custom-error-alert" role="alert">${msg}</div>`,
    defaultSignupError: `<div class="custom-error-alert" role="alert">We're sorry, an unexpected error occurred. Please try again.</div>` // eslint-disable-line quotes
};

exports.browserWarningTemplate = `
    We're sorry, this browser is no longer supported.<br/>
    Please update your browser for a smoother, more secure shopping experience. We recommend downloading the latest version of
    <a href="https://www.google.com/chrome/browser/desktop/index.html" target="_blank" rel="nofollow">Google Chrome</a>,
    <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="nofollow">Microsoft Edge</a> or
    <a href="https://www.mozilla.org/en-US/firefox/desktop/" target="_blank" rel="nofollow">Mozilla Firefox</a>.
`;

/*
 * Shipping preference radio buttons
 */
exports.shippingPreferenceTemplates = {
    shippingPreferences: (ispuRadioLabel, ispuRadioValue, shipToRadioLabel, shipToRadioValue, changeStoreLabel, preferISPU = false) => `
        <div class="form-check custom-radio preference-container ispu-preference-container">
            <input id="preference-ispu" name="shipping-preference" type="radio" class="form-check-input shipping-preference preference-ispu" ${preferISPU ? 'checked' : ''} value="${ispuRadioValue}">
            <label class="form-check-label" for="preference-ispu">
                <svg role="img" aria-describedby="store-title store-desc" class="icon svg-icon" width="21.0" height="16.0">
                    <title id="store-title">Pick Up in Store</title>
                    <desc id="store-desc">Pick Up in Store icon</desc>
                    <use xlink:href="#store" role="img"></use>
                </svg>
                ${ispuRadioLabel}
            </label>
            <button class="btn-link tertiary-link change-store-link">${changeStoreLabel}</button>
            <div class="content">
                <div class="select-size-info-msg"></div>
                <div class="ispu-info-message"></div>
                <div class="message-wrapper">
                    <div class="inventory-message"></div>
                    <div class="ispu-delivery-estimation-message"></div>
                </div>
                <div class="service-info-message"></div>
                <div class="store-detail-container"></div>
            </div>
        </div>
        <div class="form-check custom-radio preference-container ship-to-address-preference-container">
            <input id="preference-ship-to-address" name="shipping-preference" type="radio" class="form-check-input shipping-preference preference-ship-to-address" value="${shipToRadioValue}" ${!preferISPU ? 'checked' : ''}>
            <label class="form-check-label" for="preference-ship-to-address">
                <svg role="img" aria-describedby="shipping-title shipping-desc" class="icon svg-icon" width="21.0" height="16.0">
                    <title id="shipping-title">Ship to an Address</title>
                    <desc id="shipping-desc">Ship to an Address icon</desc>
                    <use xlink:href="#shipping" role="img"></use>
                </svg>
                ${shipToRadioLabel}
            </label>
            <div class="content">
                <div class="narvar-edd-info"></div>
                <div class="ship-to-info-message"></div>
            </div>
        </div>
    `,
    storeDetail: (storeId, name, address1, city, stateCode, postalCode) => `
        <div class="store-detail">
            <div class="name" data-store-id="${storeId}">${name}</div>
            <div class="address">
                <div class="address-line-1">${address1}<span class="comma-separate">,</span>
                    <span class="city-state-postalcode"> ${city}<span class="city-comma-separate">,</span> ${stateCode} ${postalCode}</span>
                </div>
            </div>
        </div>
    `
};

/*
 * Product detail page - messaging templates
 */
exports.productDetail = {
    selectStyleMessageTmpl: message => `<li><p class="availability-message-text select-size-info" role="alert">${message}</p></li>`,
    availabilityMessageTmpl: message => `<li><p class="availability-message-text" role="alert">${message}</p></li>`,
    availabilityMessageOOS: (message, flexibleMessageLink = '') => `
        <li><p class="availability-message-text" role="alert">${message}
            ${flexibleMessageLink}</p>
        </li>
    `,
    availabilityMessagePreorder: message => `
        <div class="availability-message-text-preorder" role="alert">
            <p class="message">${message}</p>
        </div>

    `,
    ispuSelectStyleMessageTmpl: message => `<p class="availability-message-text" role="alert">${message}</p>`,
    ispuAvailabilityMessageTmpl: message => `<p class="availability-message-text" role="alert">${message}</p>`,
    ispuLowStockMessageTmpl: message => `<li><p class="ispu-availability-message-text" role="alert">${message}</p></li>`,
    promotionMessageTmpl: (calloutMsg, details) =>
        `
        <div class="js-promo-details-link promo-block-link">
            <div class="promotion promo-block-item">
                <span class="promotion-header promo-block-generic">
                    ${calloutMsg}
                </span>
                ` +
        (details
            ? `
                    <svg class="promo-block-chevron-right" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <use xlink:href="#chevron-right-small"></use>
                    </svg>
                    <div class="js-promo-details d-none">${details}</div>`
            : '') +
        `</div>
        </div>`
};

exports.dialogTemplate = options => {
    const { buttons = [{ text: 'OK', primary: true, link: '' }], className = '', modalContentHeading = '', modalContentBody = '', id = 'dialog-' + Date.now(), slideOut = false } = options;

    const buttonSpace = Math.floor(12 / buttons.length);

    return `
        <div class="modal fade ${slideOut ? 'lp-slideout-modal' : 'generic-modal-dialog'} ${className}" id="${id}" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content${slideOut ? ' lp-scrollbar' : ''}">
                    <div class="${slideOut ? 'modal-header' : 'generic-confirmation-header'}">
                        ${slideOut && modalContentHeading ? `<h4>${modalContentHeading}</h4>` : ''}
                        <button type="button" class="close close-current-modal" data-dismiss="modal" aria-label="Close">
                            <svg role="img" aria-describedby="${id}-cross-title ${id}-cross-desc" class="icon svg-icon svg-logo-dims" width="16.0" height="16.0">
                                <title id="${id}-cross-title">Close dialog</title>
                                <desc id="${id}-cross-desc">Cross mark icon to close this dialog</desc>
                                <use xlink:href="#cross" role="img"></use>
                            </svg>
                        </button>
                    </div>
                    ${!slideOut && modalContentHeading ? `<p class="modal-body generic-confirmation-body-heading">${modalContentHeading}</p>` : ''}
                    <div class="modal-body generic-confirmation-body-body${slideOut ? ' veiled' : ''}">${modalContentBody}</div>
                    <div class="row">
                        ${buttons
                            .map(
                                button => `
                            <div class="col-${buttonSpace}">
                            ${
                                button.link
                                    ? `<a href="${button.link}" class="btn btn-block button-${button.primary ? 'primary' : 'secondary'}">${button.text}</a>`
                                    : `<button type="button" class="btn btn-block button-${button.primary ? 'primary' : 'secondary'}" data-dismiss="modal">${button.text}</button>`
                            }
                            </div>`
                            )
                            .join('')}
                    </div>
                </div>
            </div>
        </div>
    `;
};

exports.errorTextTemplate = errorMessage => `<div class="container"><p class="error-message-text">${errorMessage}</p></div>`;

exports.oneTrust = {
    privacyDialogCookieBanner: `
        <div class="onetrust-dialog-cookie-banner">
            <div class="onetrust-dialog-cookie-banner-text">
                You can opt out of 3rd party cookies by clicking <button class="cookie-preferences">Cookie Preferences</button>.
            </div>
        </div>
    `
};
exports.loyalty = {
    carouselBadge: '<div class="js-loyalty-badge pdp-loyalty-badge product-tile-badge hide"><span class="js-loyalty-badge-text product-tile-badge-text">{0}</span></div>'
};

exports.styliticsTemplates = {
    shopTheModelBadge: '<p class="stylitics-badge-text">As seen above</p>'
};

exports.returnsPageTemplates = {
    defaultError: 'We&#39;re sorry, an unexpected error occurred. Please try again.',
    errorMessage: message => `<div class="custom-error-alert" role="alert">${message}</div>`
};

exports.cyberSource = {
    secureAcceptanceTag: paymentType => `<span>Secure Acceptance ${paymentType}</span>`,
    boltPayPaymentTag: (paymentType, paymentLastFour, expMonth, expYear) => `
                        <div class="credit-card-type"><span>${paymentType}
                        </span></div><div class="credit-card-number"><span>${paymentLastFour}</span></div>
                        <div class="credit-card-expiration-date"><span>${expMonth}/${expYear}</span></div>`,
    paypalPaymentTag: (paypalMethod, paypalAmount) => `<span>${paypalMethod}</span><div><span>${paypalAmount}</span></div>`,
    creditCardPaymentTag: (cardType, creditCardType, maskedCardNumber, cardEndIn, cardExpMonth, cardExpYear) => `<div class="credit-card-type"><span>${cardType} ${creditCardType}</span></div><div class="credit-card-number">
                                ${maskedCardNumber}</div><div class="credit-card-expiration-date"><span>${cardEndIn} ${cardExpMonth}/${cardExpYear}</span></div>`
};

exports.checkoutErrorTemplate = {
    checkoutErrorMsg: errorMsg => `<div class="alert alert-danger alert-dismissible valid-cart-error fade show" role="alert">
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>${errorMsg}
                                        </div>`
};

exports.minitoteError = message => `<p class="cart-error-message">${message}</p>`;

exports.addToCartAlert = (messageType, message) => `<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${message}</div>`;

exports.productTileSlide = (el, index) => `<div class="swiper-slide js-swiper-slide" role="option">
    <img src="${el.url}" srcset="${el.srcset}" class="tile-image" alt='${el.alt} ${index > 0 ? ` image${index}` : ''}' itemprop="image" />
</div>`;

exports.noImageIcon = `<svg role="img" aria-label="Image not available" class="icon svg-icon icon" width="63" height="86">
    <use xlink:href="#no-image"></use>
</svg>`;

exports.productTileSlidePicture = (el, index, imageType) => {
    const breakpoints = {
        sm: '(max-width: 767px)',
        md: '(max-width: 1024px)',
        lg: '(min-width: 1025px)'
    };
    const sourceTags = Object.entries(el.sources)
        .map(([key, srcset]) => {
            return `<source srcset="${srcset}" media="${breakpoints[key]}">`;
        })
        .join('');
    return `<div class="swiper-slide js-swiper-slide" role="option">
        <picture
            class="d-block img-fluid w-100">
            ${sourceTags}
            <img
            loading="lazy"
            src="${el.url}"
            alt="${el.alt} image number ${index + 1}"
            title="${el.title}"
            data-image="${imageType}"
            itemprop="image"/>
        </picture>
    </div>`;
};

exports.sliderPaginationTemplate = (currentClass, totalClass) => `<span class="${currentClass}"></span> of <span class="${totalClass}"></span>`;

/**
 * get recent search history list
 * @param {Object} searchHistoryStorage - search history array
 * @param {string} baseUrl - base Search-Show url
 * @param {string} clearButtonText - clear all button text
 * @return {string} SFL html template
 */
exports.searchHistoryTemplate = (searchHistoryStorage, baseUrl, clearButtonText) => {
    let list = '';
    for (let i = 0, len = searchHistoryStorage.length; i < len; i++) {
        const searchTerm = searchHistoryStorage[i];
        const url = baseUrl + '?q=' + searchTerm;
        list += `<div class="suggestions-section-items js-recent-search-item">
                    <div class="item term">
                    <svg role="img" class="icon svg-icon svg-logo-dims" width="12" height="12">
                        <use xlink:href="#search"></use>
                    </svg>
                        <a class="link js-recent-link" href="${url}">${searchTerm}</a>
                    </div>
                    <button type="button" class="remove-recent-search js-remove-recent-search">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 13.8097L18.4697 20.2794L19.5304 19.2188L13.0607 12.7491L19.5304 6.27941L18.4697 5.21875L12.0001 11.6884L5.53039 5.21875L4.46973 6.27941L10.9394 12.7491L4.46973 19.2188L5.53039 20.2794L12.0001 13.8097Z" fill="black"/>
                        </svg>
                    </button>
                </div>`;
    }

    if (clearButtonText) list += `<button type="button" class="remove-all-recent js-remove-all-recent">${clearButtonText}</button>`;

    return list;
};

exports.productTilePriceContent = (priceMainHtml, priceVariationHtml) => {
    return `<div class="price-variation">${priceVariationHtml}</div><div class="price-main">${priceMainHtml}</div>`;
};

exports.videoInnerContent = (poster, videoUrl) => {
    return `<div class="video-component video-PDP">
                <video class="video lazy-video video-component-main"
                    poster="${poster}"
                    playsinline
                    controlslist="nodownload"
                >
                    <source data-src="${videoUrl}"
                        type="video/mp4"
                    >
                </video>
                <div class="video-controls">
                    <button class="mute-video unmuted">
                        <svg role="img" aria-label="mute" class="icon svg-icon svg-video-mute" width="16.0" height="16.0">
                            <use xlink:href="#mute"></use>
                        </svg>
                        <svg role="img" aria-label="unmute" class="icon svg-icon svg-video-unmute" width="16.0" height="16.0">
                            <use xlink:href="#unmute"></use>
                        </svg>
                    </button>
                    <button type="button" class="play-video">
                        <svg role="img" aria-label="Play" class="icon svg-icon svg-video-play" width="16.0" height="16.0">
                        <use xlink:href="#play"></use>
                        </svg>
                        <svg role="img" aria-label="Pause" class="icon svg-icon svg-video-pause" width="16.0" height="16.0">
                        <use xlink:href="#pause"></use>
                        </svg>
                    </button>
                </div>
            </div>
            `;
};

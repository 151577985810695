import { queryFirst } from '../../domUtil';
import { SELECTOR_SIZE_BTN_SELECTED } from '../productTileConstants';

/**
 * Get Product info
 *
 * @param {HTMLElement} container tile container
 * @returns {Object} product info
 */
export function getProductInfo(container) {
    const { masterId, productInfo: productInfoRaw, productType } = container.dataset;
    let productInfo = null;
    if (productType === 'set') {
        return productInfo;
    }

    try {
        productInfo = JSON.parse(productInfoRaw);
    } catch (error) {
        return null;
    }

    if (!productInfo || (!masterId) in productInfo) {
        return null;
    }

    return productInfo[masterId].variants;
}

/**
 * Get Selected size data
 *
 * @param {HTMLElement} container tile container
 * @returns {Object} product info
 */
export function getSelectedSizeData(container) {
    const selectedSizeBtn = queryFirst(SELECTOR_SIZE_BTN_SELECTED, container);

    // Check if size is selected on single product PDP
    if (!selectedSizeBtn) {
        return null;
    }

    const selectedColorElement = queryFirst('.js-tile-swatch.selected', container);
    if (!selectedColorElement) {
        return null;
    }

    const selectedColorId = selectedColorElement.dataset.attrValue;
    const productInfoVariants = getProductInfo(container);
    const variantData = productInfoVariants[selectedColorId];
    const selectedSizeValue = selectedSizeBtn.dataset.attrValue;
    const selectedSizeData = variantData.sizes[selectedSizeValue];

    return selectedSizeData;
}

/**
 * Get Selected Product ID
 *
 * @param {HTMLElement} container tile container
 * @returns {null|number} product ID
 */
export function getSelectedPid(container) {
    const selectedSizeBtn = queryFirst(SELECTOR_SIZE_BTN_SELECTED, container);

    // Check if size is selected on single product PDP
    if (!selectedSizeBtn) {
        return null;
    }
    const selectedSizeData = getSelectedSizeData(container);

    return selectedSizeData.ID;
}

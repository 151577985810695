'use strict';
const { queryFirst, addClass, removeClass, hasClass, throttle } = require('../domUtil');
const { MENU_OPENED } = require('../constants');
const { getJSON } = require('../util');

const $body = $('body');
const bodyEl = $body[0];
const $mainNav = $('.main-nav');
const $mainMenu = $('.main-menu', $mainNav);
const $modalBG = $('.modal-background');
const mobileMenuOpenClass = 'mobile-menu-open';
const mobileHeader = window.matchMedia('(max-width: 1024px)');
const desktopHeader = window.matchMedia('(min-width: 1025px)');
const scrollBarWidth = `${window.innerWidth - bodyEl.scrollWidth}px`;

/**
 * Shows or hides the modal-background element
 * @param {boolean} [show=true] Whether to show the element (Default: true)
 */
function modal(show = true) {
    $modalBG[show ? 'show' : 'hide']();
}

/* OOTB file. Update logic for show/hide logic. Removed unwanted close icon logic. */
var keyboardAccessibility = require('base/components/keyboardAccessibility');

module.exports = function () {
    const plp = queryFirst('.plp-title');
    const categoryName = queryFirst('.category-name');
    if (plp && categoryName) {
        categoryName.textContent = plp.textContent;
    }

    const stripeBannerContainer = queryFirst('.carousel-stripe-banner-container');

    const hiddenClass = 'd-none';
    const stripeBanner = queryFirst('.carousel-stripe-banner', stripeBannerContainer);
    const stripePauseBtn = queryFirst('.stripe-banner-pause', stripeBannerContainer);
    const stripeArrowBtn = queryFirst('.stripe-banner-next', stripeBannerContainer);

    stripePauseBtn?.addEventListener('click', (e)=> {
        e.preventDefault();
        addClass(stripePauseBtn, hiddenClass);
        removeClass(stripeArrowBtn, hiddenClass);
        $('.carousel-stripe-banner').carousel('pause');
    });

    const headerBannerStatus = window.sessionStorage.getItem(
        'hide_header_banner'
    );
    if (!headerBannerStatus || headerBannerStatus < 0) {
        removeClass(stripeBanner, hiddenClass);
    } else {
        addClass(stripeBanner, hiddenClass);
        removeClass(stripeBannerContainer, 'show');
    }

    $('.navbar-nav > li').children('.dropdown-menu').find('a').attr('tabindex', '-1');
    $('.main-header .banner-wrapper a').addClass('dropdown-item');

    $('.carousel-stripe-banner .close-banner').on('click', function () {
        closeStripeBanner();
    });

    $('.close-banner').on('keydown', function (e) {
        const { key } = e;

        if (key === 'Enter' || key === ' ') {
            closeStripeBanner();
            $(this).off(e);
        }
    });

    function closeStripeBanner() {
        addClass(stripeBannerContainer, hiddenClass);
        removeClass(stripeBannerContainer, 'show');
        const data = { bannerOptOut: '1' };
        const actionUrl = stripeBannerContainer.dataset.closeactionurl;

        getJSON(actionUrl, 'POST', data, () => {
            window.sessionStorage.setItem('hide_header_banner', '1');
        }, () => {
            window.sessionStorage.setItem('hide_header_banner', '0');
        });
    }

    keyboardAccessibility(
        '.main-nav .nav-link, .main-nav .dropdown-link',
        {
            40: function (menuItem) {
                // down
                modal();
                if (menuItem.hasClass('nav-item')) {
                    // top level

                    //If item doesn't have dropdown-menu move focus to the next element
                    if (menuItem.children('.dropdown-menu').length === 0) {
                        menuItem
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');

                        menuItem.children('.dropdown-menu')
                            .find('a').attr('tabindex', '-1');

                        $(this).attr('aria-expanded', 'false');

                        menuItem
                            .next()
                            .children()
                            .first()
                            .focus();

                        menuItem
                            .find('.dropdown-item')
                            .first()
                            .focus();

                        // Hide background overlay if menu item doesn't have a sub menu
                        if (menuItem.next().children('.dropdown-menu').length === 0) {
                            modal(false);
                        }

                        return;
                    }

                    $('.navbar-nav .show')
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');

                    menuItem
                        .addClass('show')
                        .children('.dropdown-menu')
                        .addClass('show')
                        .attr('aria-hidden', 'false');

                    menuItem
                        .find('a').removeAttr('tabindex');

                    $(this).attr('aria-expanded', 'true');

                    menuItem
                        .find('.dropdown-link')
                        .first()
                        .focus();
                } else {
                    menuItem
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show')
                        .attr('aria-hidden', 'true');

                    if (!(menuItem.next().length > 0)) {
                        // if this is the last menuItem
                        // set focus to the first menuitem
                        menuItem
                            .parent()
                            .children()
                            .first()
                            .children()
                            .first()
                            .focus();
                    } else {
                        menuItem
                            .next()
                            .children()
                            .first()[0]
                            .focus();
                    }
                }
            },
            39: function (menuItem) {
                // right
                modal();
                // Move to first item if next item doesn't exist.
                if (menuItem.hasClass('nav-item') && menuItem.next().length === 0) {
                    const firstElement = queryFirst('.nav-link', menuItem.parent().children().first()[0]);
                    firstElement.focus();
                }

                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');

                    menuItem.children('.dropdown-menu')
                        .find('a').attr('tabindex', '-1');

                    $(this).attr('aria-expanded', 'false');

                    menuItem
                        .next()
                        .children('.nav-link ')
                        .first()
                        .focus();

                    menuItem
                        .find('.dropdown-item')
                        .first()
                        .focus();

                    // Hide background overlay if menu item doesn't have a sub menu
                    if (menuItem.next().children('.dropdown-menu').length === 0) {
                        modal(false);
                    }
                } else if (menuItem.hasClass('dropdown-container-menu-item')) {
                    //Sub menu
                    const subMenuItem = menuItem.parent().parent().parent().next()[0];
                    const firstCategory = queryFirst('.dropdown-link', subMenuItem);
                    firstCategory.focus();
                } else {
                    $(this).closest('.dropdown-container').find('a').attr('tabindex', '-1');

                    menuItem
                        .closest('.show')
                        .removeClass('show')
                        .closest('li.show')
                        .removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            38: function (menuItem) {
                // up
                modal();

                if (menuItem.hasClass('nav-item')) {
                    // top level

                    //If item doesn't have dropdown-menu move focus to the previous element
                    if (menuItem.children('.dropdown-menu').length === 0) {
                        menuItem
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show')
                        .attr('aria-hidden', 'true');

                        $(this).attr('aria-expanded', 'false');

                        $(this).closest('.dropdown-container').find('a').attr('tabindex', '-1');

                        menuItem
                            .prev()
                            .children()
                            .first()
                            .focus();

                        // Hide background overlay if menu item doesn't have a sub menu
                        if (menuItem.next().children('.dropdown-menu').length === 0) {
                            modal(false);
                        }

                        return;
                    }

                    $('.navbar-nav .show')
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');

                    menuItem
                        .addClass('show')
                        .children('.dropdown-menu')
                        .addClass('show')
                        .attr('aria-hidden', 'false');

                    menuItem
                        .find('a').removeAttr('tabindex');

                    $(this).attr('aria-expanded', 'true');

                    menuItem
                        .children('.dropdown-menu')
                        .find('.dropdown-link')
                        .last()[0]
                        .focus();
                } else if (menuItem.prev().length === 0) {
                    // first menuItem
                    menuItem
                        .parent()
                        .parent()
                        .removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');

                    // set focus to the last menu item
                    menuItem
                        .parent()
                        .children()
                        .last()
                        .children()
                        .first()
                        .focus();
                } else {
                    menuItem
                        .prev()
                        .children()
                        .first()
                        .focus();
                }
            },
            37: function (menuItem) {
                // left
                modal();

                // Move to last item if prev item doesn't exist.
                if (menuItem.hasClass('nav-item') && menuItem.prev().length === 0) {
                    const lastElement = queryFirst('.nav-link', menuItem.parent().children().last()[0]);
                    lastElement.focus();
                    return;
                }

                if (menuItem.hasClass('nav-item')) {
                    // top level
                    menuItem
                        .removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show')
                        .attr('aria-hidden', 'true');

                    $(this).attr('aria-expanded', 'false');

                    $(this).closest('.dropdown-container').find('a').attr('tabindex', '-1');

                    menuItem
                        .prev()
                        .children('.nav-link ')
                        .first()
                        .focus();

                    // Hide background overlay if menu item doesn't have a sub menu
                    if (menuItem.prev().children('.dropdown-menu').length === 0) {
                        modal(false);
                    }
                } else if (menuItem.hasClass('dropdown-container-menu-item')) {
                    //Sub menu
                    const subMenuItem = menuItem.parent().parent().parent().prev()[0];
                    const firstCategory = queryFirst('.dropdown-link', subMenuItem);
                    firstCategory.focus();
                } else {
                    $(this).closest('.dropdown-container').find('a').attr('tabindex', '-1');

                    menuItem
                        .closest('.show')
                        .removeClass('show')
                        .closest('li.show')
                        .removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) {
                // escape
                $(this).closest('.dropdown-container').find('a').attr('tabindex', '-1');

                modal(false);

                let parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');

                parentMenu
                    .removeClass('show')
                    .attr('aria-hidden', 'true')
                    .children('.dropdown-menu')
                    .removeClass('show');

                parentMenu
                    .children('.nav-link')
                    .attr('aria-expanded', 'false');
            },
            9: function (menuItem) {
                //Tab
                if ($(this).hasClass('dropdown-link')) {
                    $(this).closest('.dropdown-container').find('.dropdown-link').last().focusout(function () {
                        $(this).closest('.dropdown-container').find('.dropdown-link').attr('tabindex', '-1');

                        modal(false);

                        var parentMenu = menuItem.hasClass('show')
                            ? menuItem
                            : menuItem.closest('li.show');

                        parentMenu.children('.show').removeClass('show').attr('aria-hidden', 'true');

                        parentMenu
                            .removeClass('show')
                            .children('.nav-link')
                            .attr('aria-expanded', 'false');
                    });
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );

    // To close mobile nav when clicked on modal overlay
    $modalBG.on('click', () => {
        const $menu = $('.menu-toggleable-left');

        if ($menu.hasClass('in')) {
            modal(false);
            $body.removeClass(mobileMenuOpenClass);
            $menu.removeClass('in');
            $('.navbar-nav .nav-item').removeClass('show');
        }
    });

    let hoverIntentTimer; // controls small delay before showing subnav drop-down when hovering over top-level nav items
    const hoverIntentDelay = 250; // delay before subnav drop-down will be shown on hover
    let closeNavTimer; // controls small delay in closing subnav drop-downs after the mouse leaves
    const closeNavDelay = 200; // delay before subnav drop-down will hide after the mouse leaves

    let isNavActive = false; // state variable denoting whether or not the subnav drop-down is currently displayed
    let activeNavItem; // which .nav-item is currently being displayed

    /**
     * Gets commonly used child elements of the currently active .nav-item element
     * @param {element} navItem .nav-item.dropdown element
     * @returns {Object} object containing child nodes of navItem
     */
    function getActiveNavElements(navItem) {
        return {
            activeDropDown: queryFirst('.dropdown-container.dropdown-menu', navItem),
            activeNavLink: queryFirst('.dropdown-toggle', navItem) // categories are .nav-link.dropdown-toggle, but my account is only .dropdown-toggle
        };
    }

    /**
     * Expands a navigation drop-down
     * @param {element} navItem .nav-item.dropdown element
     */
    function openNavItem(navItem) {
        const { activeDropDown, activeNavLink } = getActiveNavElements(navItem);

        if (activeDropDown && activeNavLink) {
            addClass([navItem, activeDropDown], 'show');
            activeNavLink.setAttribute('aria-expanded', 'true');

            // all drop-downs other than the my account drop-down
            if (!hasClass(activeDropDown, 'user-account-dropdown-menu') || !hasClass(activeDropDown, 'store-locator-dropdown-menu')) {
                activeDropDown.setAttribute('aria-hidden', 'false');
            }

            addClass(queryFirst('.page-top-navigation'), MENU_OPENED);

            modal();
        }
    }

    /**
     * Closes a navigation drop-down
     * @param {element} navItem .nav-item.dropdown element
     */
    function closeNavItem(navItem) {
        const { activeDropDown, activeNavLink } = getActiveNavElements(navItem);

        if (activeDropDown && activeNavLink) {
            removeClass([navItem, activeDropDown], 'show');
            activeNavLink.setAttribute('aria-expanded', 'false');

            // all drop-downs other than the my account drop-down
            if (!hasClass(activeDropDown, 'user-account-dropdown-menu')) {
                activeDropDown.setAttribute('aria-hidden', 'true');
                $(activeDropDown).find('a').attr('tabindex', '-1');
            }

            // mobile-specific items
            if (mobileHeader.matches) {
                $('.top-category', activeDropDown).detach(); // remove top heading
                $('.navbar-nav .nav-item > a').attr('aria-hidden', 'false'); // aria-show top-level nav links
            }

            removeClass(queryFirst('.page-top-navigation'), MENU_OPENED);

            modal(false);
        }
    }

    /**
     * Toggles subnav drop-down menu visibility
     */
    function toggleSubmenu() {
        if (isNavActive) {
            openNavItem(activeNavItem);
        } else {
            closeNavItem(activeNavItem);
        }
    }

    /**
     * closes other drop-downs that are not the direct parent of the current drop-down
     * @param {element} eventElement The nav element that the user is currently hovering over
     */
    function hideOtherMenus(eventElement) {
        $('.nav-item').each((i, e) => {
            // for all .nav-items which do not contain the .nav-link.dropdown-toggle which the user hovered over
            if (!$.contains(e, eventElement)) {
                if (hasClass(e, 'show')) {
                    closeNavItem(e);
                }
            }
        });

        $(eventElement).siblings('.dropdown-menu').find('a').removeAttr('tabindex');
    }

    $('.dropdown:not(.disabled) .dropdown-toggle')
        .on('click', function (e) {
            if (mobileHeader.matches) {
                modal();
                const { target } = e;
                // copy parent element into current UL
                const element = $('<div class="top-category"></div>');
                const link = $(target)
                    .clone()
                    .removeClass('dropdown-toggle')
                    .addClass('back')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                element.append('<button class="back pull-left" aria-label="Back to previous menu"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 4L8 12L16 20" stroke="black" stroke-width="1.5"/></svg></button>');
                element.append(link);
                $(target)
                    .parent()
                    .children('.dropdown-menu')
                    .children('.sub-menu-grid')
                    .prepend(element)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(target)
                    .parent()
                    .addClass('show');
                $(target).attr('aria-expanded', 'true');
                $(link).trigger('focus');
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter focus', function (e) {
            if (desktopHeader.matches) {
                hoverIntentTimer = setTimeout(() => {
                    clearTimeout(closeNavTimer); // this listener will close other active nav items -- no need for the other event listener to fire after the timer.
                    hoverIntentTimer = null;

                    isNavActive = true;
                    const { currentTarget } = e;
                    activeNavItem = currentTarget.closest('.nav-item');

                    $('nav.main-nav').trigger('navdropdown:show', e);
                    hideOtherMenus(currentTarget);
                    toggleSubmenu();
                }, isNavActive ? 0 : hoverIntentDelay);
            }
        })
        .parent()
        .on('mouseleave blur', function (e) {
            if (desktopHeader.matches) {
                if (hoverIntentTimer) { // if the hoverIntent timer is still active, cancel it -- user moved away
                    clearTimeout(hoverIntentTimer);
                } else {
                    closeNavTimer = setTimeout(() => {
                        isNavActive = false;
                        $('nav.main-nav').trigger('navdropdown:hide', e);
                        toggleSubmenu();
                    }, closeNavDelay);
                }
            }
        });

    // If the user's cursor accidentally leaves then comes back into the currently active dropdown, keep it open
    $('.dropdown-container').on('mouseenter', e => {
        if (hasClass(e.currentTarget, 'show')) {
            clearTimeout(closeNavTimer);
        }
    });

    $('.navbar>.close-menu>.close-button').on('click', function (e) {
        e.preventDefault();
        $('.menu-toggleable-left').removeClass('in');
        modal(false);
        $('.navbar-nav .nav-item').removeClass('show');
        $body.removeClass(mobileMenuOpenClass);
        $('.navbar-toggler').trigger('focus');

        $('.main-menu').attr('aria-hidden', 'true');
        $('.main-menu')
            .siblings()
            .attr('aria-hidden', 'false');
        $('header')
            .siblings()
            .attr('aria-hidden', 'false');
    });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        closeNavItem(e.currentTarget.closest('.nav-item'));
    });

    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        $('.navbar-nav')
            .find('.top-category')
            .detach();
        $('.navbar-nav')
            .find('.nav-menu')
            .detach();
        $('.navbar-nav')
            .find('.show')
            .removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu')
            .siblings()
            .attr('aria-hidden', 'false');
        $('header')
            .siblings()
            .attr('aria-hidden', 'false');
    });

    $('.navbar-toggler').on('click', e => {
        e.preventDefault();

        if ($('#carousel-stripe-banner').is(':visible')) {
            $mainNav.addClass('has-banner');
        } else {
            $mainNav.removeClass('has-banner');
        }

        if ($('#evg-hero-banner').is(':visible')) {
            $mainNav.addClass('has-skinny-banner');
        } else {
            $mainNav.removeClass('has-skinny-banner');
        }

        // mobile-specific items
        if (mobileHeader.matches && $('.top-category')) {
            $('.top-category').detach(); // remove top heading
        }

        $mainMenu.toggleClass('in');
        $('.menu-group .nav-item').removeClass('show');
        $body.toggleClass(mobileMenuOpenClass);

        const isMenuOpen = $body.hasClass(mobileMenuOpenClass);

        $mainMenu.attr('aria-hidden', !isMenuOpen);
        $mainMenu.siblings().attr('aria-hidden', isMenuOpen);
        $('header').siblings().attr('aria-hidden', isMenuOpen);

        if (isMenuOpen) {
            $mainMenu.find('.nav.navbar-nav .nav-link').first().trigger('focus');
        }

        modal(isMenuOpen);
    });

    /**
     * Detect when the active breakpoint changes from mobile -- if the mobile menu is open, close it
     * This prevents the mobile menu scrim from still being visible on desktop after breakpoint change
     */
    let prevBreakpointIsDesktop = desktopHeader.matches;
    let currentBreakpointIsDesktop = desktopHeader.matches;

    window.addEventListener('resize', throttle(() => {
        currentBreakpointIsDesktop = desktopHeader.matches;
        if ($body.hasClass(mobileMenuOpenClass) && !prevBreakpointIsDesktop && currentBreakpointIsDesktop) {
            $('.navbar-toggler').trigger('click');
        }

        prevBreakpointIsDesktop = currentBreakpointIsDesktop;
    }));

    /**
     * Toggles extra padding when preventing scrolling on the body
     * Perevents layout shifting due to hiding scrollbars
     * @param {boolean} shouldAdd whether or not the function should add padding. if false will remove padding.
     */
    function toggleNoScrollPadding(shouldAdd) {
        const padding = shouldAdd ? scrollBarWidth : '0px';
        const stickyHeaderEl = queryFirst('.main-header.sticky, .header-transparent .main-header');
        if (stickyHeaderEl) {
            stickyHeaderEl.style.paddingRight = padding;
        }
        document.body.style.paddingRight = padding;
    }

    /**
     * Initializes a mutation observer to toggle extra padding in order to
     * prevent layout shifts when modals are open/scrolling is disabled on the page
     */
    function modalPaddingObserver() {
        const observerConfig = {
            attributes: true,
            childList: false,
            subtree: false,
            characterData: false,
            attributeFilter: ['class'],
            attributeOldValue: true
        };

        const toggleClasses = ['modal-open', 'no-scroll'];

        const observer = new MutationObserver((mutations, observer) => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                    let classRemoved = false;
                    let classAdded = false;
                    let classStillExists = false;
                    const needsToggle = toggleClasses.reduce((prev, targetClass) => {
                        // on first run, mutation.oldValue can be null because body doesn't have a class attribute by default
                        const classCurrentlyExists = hasClass(mutation.target, targetClass);
                        const classUsedToExist = mutation.oldValue && mutation.oldValue.includes(targetClass);
                        classRemoved = classRemoved || (classUsedToExist && !classCurrentlyExists);
                        classAdded = classAdded || (!classUsedToExist && classCurrentlyExists);
                        // sometimes multiple scroll-preventing classes can exist on an element at the same time -- we don't want to remove padding if one still exists
                        classStillExists = classStillExists || (!classRemoved && !classAdded && classCurrentlyExists);

                        return prev || classRemoved || classAdded;
                    }, false); // initial value for reducer

                    if (needsToggle && !classStillExists) {
                        toggleNoScrollPadding(classAdded);
                    }
                }
            });
        });

        observer.observe(bodyEl, observerConfig);
    }

    modalPaddingObserver();
};

'use strict';

import { matchesBreakpoint, queryFirst, queryAll, addClass, removeClass, hasClass } from '../domUtil';
import { HIDDEN_CLASS, PLAYED_CLASS, MUTED_CLASS, UNMUTED_CLASS } from '../constants';
const mainVideoClass = 'video-component-main';
const mobileVideoClass = 'video-component-mobile';
const videoComponents = queryAll('.video-component');

/**
 * @param {Element} videoComponent represents a video component
 * @returns {Element} existing video mainVideo or mobileVideo
 */
function hasVideos(videoComponent) {
    const mainVideo = queryFirst(`.${mainVideoClass}`, videoComponent);
    const mobileVideo = queryFirst(`.${mobileVideoClass}`, videoComponent);
    return mainVideo || mobileVideo;
}

/**
 * @param {Element} videoComponent represents a video component
 */
function initVideos(videoComponent) {
    if (hasClass(videoComponent, 'initialized')) return;

    addClass(videoComponent, 'initialized');
    const mainVideo = queryFirst(`.${mainVideoClass}`, videoComponent);
    const mobileVideo = queryFirst(`.${mobileVideoClass}`, videoComponent);
    const playButton = queryFirst('.play-video', videoComponent);
    const muteButton = queryFirst('.mute-video', videoComponent);
    const captionVideoElement = queryFirst('.caption-video', videoComponent);
    const allVideos = [mainVideo, mobileVideo].filter(Boolean);

    let touchStart;
    let touchEnd;
    const scrollThreshold = 2;

    /**
     * Changes the state of video and the play button depending on whether or not the video is play/pause.
     * @param {HTMLElement} video Element representing  a video component
     */
    function togglePlay(video) {
        if (video.paused) {
            video.play();
            addClass(playButton, PLAYED_CLASS);
        } else {
            video.pause();
            removeClass(playButton, PLAYED_CLASS);
        }
    }

    /**
     * Changes the state of the mute button depending on whether or not the video is off/on.
     * @param {HTMLElement} video Element representing  a video component
     */
    function toggleMute(video) {
        video.muted = !video.muted;
        if (video.muted) {
            removeClass(muteButton, UNMUTED_CLASS);
            addClass(muteButton, MUTED_CLASS);
        } else {
            removeClass(muteButton, MUTED_CLASS);
            addClass(muteButton, UNMUTED_CLASS);
        }
    }

    /**
     * Shows or hides the caption button depending on whether or not a video caption is off/on.
     * @param {Array} allVideos Array containing all video elements within a video component (mobile and desktop)
     * @param {HTMLElement} captionButton Element representing the caption button icon for a video component
     */
    function toggleCaption(allVideos, captionButton) {
        const captionOnIcon = queryFirst('.svg-video-caption-on', captionButton);
        const captionOffIcon = queryFirst('.svg-video-caption-off', captionButton);

        // Find the currently visible video (either mobile or desktop)
        const visibleVideo = allVideos.find(video => video.offsetParent !== null);

        if (visibleVideo?.textTracks?.length) {
            const track = visibleVideo.textTracks[0];

            // Check if cues are not null
            if (track.cues && track.cues.length > 0) {
                const videoCaptionIsOn = track.mode === 'showing';

                if (videoCaptionIsOn) {
                    track.mode = 'hidden';
                    addClass(captionOnIcon, HIDDEN_CLASS);
                    removeClass(captionOffIcon, HIDDEN_CLASS);
                } else {
                    track.mode = 'showing';
                    removeClass(captionOnIcon, HIDDEN_CLASS);
                    addClass(captionOffIcon, HIDDEN_CLASS);
                }
            } else {
                // If cues are null or empty, hide the caption button
                addClass(captionButton, HIDDEN_CLASS);
            }
        } else {
            // Hide caption button if no text tracks
            addClass(captionButton, HIDDEN_CLASS);
        }
    }

    /**
     * @returns {Element} active video
     */
    function getActiveVideo() {
        return matchesBreakpoint('lg') ? mainVideo : mobileVideo || mainVideo;
    }

    playButton?.addEventListener('click', function () {
        const activeVideo = getActiveVideo();
        togglePlay(activeVideo);
    });

    muteButton?.addEventListener('click', function () {
        const activeVideo = getActiveVideo();
        toggleMute(activeVideo);
    });

    if (captionVideoElement) {
        captionVideoElement.addEventListener('click', () => {
            const visibleVideo = allVideos.find(video => video.offsetParent !== null);
            if (visibleVideo?.textTracks?.length) {
                const track = visibleVideo.textTracks[0];
                track.mode = track.mode === 'hidden' ? 'showing' : 'hidden';
                toggleCaption([visibleVideo], captionVideoElement);
            }
        });

        toggleCaption(allVideos, captionVideoElement);
    }

    /**
     * @param {Element} video touch event handling
     */
    function handleVideoTouch(video) {
        video.addEventListener('touchstart', e => {
            touchStart = e.touches[0].clientY;
        });

        video.addEventListener('touchend', e => {
            touchEnd = e.changedTouches[0].clientY;

            if (Math.abs(touchEnd - touchStart) < scrollThreshold) {
                togglePlay(video);
            }
        });
    }

    [mainVideo, mobileVideo].forEach(video => {
        if (video) {
            handleVideoTouch(video);

            video.addEventListener('play', () => {
                addClass(playButton, PLAYED_CLASS);
            });

            video.addEventListener('pause', () => {
                removeClass(playButton, PLAYED_CLASS);
            });

            video.addEventListener('ended', () => {
                removeClass(playButton, PLAYED_CLASS);
            });
        }
    });

    const activeVideo = getActiveVideo();

    if (activeVideo?.paused) {
        removeClass(playButton, PLAYED_CLASS);
    } else {
        addClass(playButton, PLAYED_CLASS);
    }

    if (activeVideo?.muted) {
        addClass(muteButton, MUTED_CLASS);
        removeClass(muteButton, UNMUTED_CLASS);
    } else {
        addClass(muteButton, UNMUTED_CLASS);
        removeClass(muteButton, MUTED_CLASS);
    }

    /**
     * Synchronization of video behavior when the screen is rotated
     */
    function syncVideoState() {
        const activeVideo = getActiveVideo();
        const inactiveVideo = activeVideo === mainVideo ? mobileVideo : mainVideo;

        if (inactiveVideo) {
            const wasPlaying = !inactiveVideo.paused;
            inactiveVideo.pause();
            inactiveVideo.muted = true;

            if (wasPlaying) {
                activeVideo.play();
                addClass(playButton, PLAYED_CLASS);
            } else {
                activeVideo.pause();
                removeClass(playButton, PLAYED_CLASS);
            }
        }

        if (activeVideo) {
            activeVideo.muted = muteButton?.classList.contains(MUTED_CLASS);
        }
    }

    let currentOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

    window.addEventListener('resize', () => {
        let newOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';

        if (newOrientation !== currentOrientation) {
            currentOrientation = newOrientation;
            syncVideoState();
        }
    });

    syncVideoState();
}

videoComponents.forEach(videoComponent => {
    if (hasVideos(videoComponent)) {
        initVideos(videoComponent);
    }
});

export default {
    initVideos
};

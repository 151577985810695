/**
 * OOTB code. Added animations which are under LP customization section.
 */

'use strict';

const { queryFirst, addClass, removeClass } = require('../domUtil');
const { setUserInfo } = require('./etmc');
const { HIDDEN_CLASS, INVALID_CLASS } = require('../constants');

const $body = $('body');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    // LP customization
    $.spinner().stop();
    const invalidFeedback = queryFirst('footer .footer-email-signup .invalid-feedback');
    const emailField = queryFirst('.email-signup');

    if (data.success) {
        removeClass(queryFirst('#success-message'), HIDDEN_CLASS);
        if (emailField) {
            emailField.value = '';
            emailField.blur();
            removeClass(emailField, INVALID_CLASS);
        }
    } else {
        invalidFeedback.textContent = data.msg;
        addClass(emailField, INVALID_CLASS);
        $body.trigger('subscribe:fail');
    }
    button.disabled = false;
    // LP customization
}

module.exports = function () {
    queryFirst('.email-signup')?.addEventListener('keydown', function () {
        addClass(queryFirst('#success-message'), HIDDEN_CLASS);
    });

    queryFirst('.footer-email-signup')?.addEventListener('submit', function (e) {
        e.preventDefault();
        const button = queryFirst('.subscribe-email', this);
        const url = button.dataset.href;
        const emailInput = queryFirst('.email-signup');
        const emailId = emailInput.value;
        if (!emailId) {
            const invalidFeedback = queryFirst('footer .invalid-feedback');
            invalidFeedback.textContent = emailInput.dataset.missingError;
            addClass(emailInput, INVALID_CLASS);
            $body.trigger('subscribe:fail');
            return;
        }
        removeClass(emailInput, INVALID_CLASS);
        $.spinner().start();
        addClass(queryFirst('#success-message'), HIDDEN_CLASS);
        button.disabled = true;
        $(button).attr('aria-disabled', 'button disabled');
        $body.trigger('emailSubmit');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId,
                source: 'footerNewsletterSignup'
            },
            success: function (data) {
                displayMessage(data, button);
                $(button).removeAttr('aria-disabled');
                // update Marketing Cloud Analytics trackCart event
                setUserInfo(data.updatedUserInfo);
                if (data.error) return;
                $body.trigger('subscribe:success');
            },
            error: function (err) {
                displayMessage(err, button);
                $body.trigger('subscribe:fail');
            }
        });
    });
};

const { queryFirst, getCookieMap } = require('../domUtil');
const { getItemFromLocalStorage, setItemInLocalStorage, removeItemFromLocalStorage } = require('lilly/util');

class WishlistItemsMgr {
    static instance;

    loadWishlistItemsPromise;

    /**
     * @description Returns instance of WishlistItemsMgr
     * @returns {WishlistItemsMgr} Instance of WishlistItemsMgr
     */
    static getInstance() {
        if (WishlistItemsMgr.instance) {
            return WishlistItemsMgr.instance;
        }

        WishlistItemsMgr.instance = new WishlistItemsMgr();

        return WishlistItemsMgr.instance;
    }

    /**
     * @description Check if product is in wish list
     * @param {string|number} productId Target product id
     * @param {string} listId Optional list id if added check product only in this WL
     * @returns {Promise<boolean>} Result
     */
    isItemInWishlist(productId, listId) {
        return this.getWishlistItems()
            .then((response) => {
                let result = false;

                if (!response) {
                    return Promise.resolve(result);
                }

                const items = response.wishlistItems;
                if (listId) {
                    const list = items.find(list => list.UUID === listId);
                    result = list ? list.items.includes(productId) : false;
                } else {
                    result = items.some(list => list.items.includes(productId));
                }

                return Promise.resolve(result);
            });
    }

    /**
     * @description Checks if wish list contains items
     * @returns {Promise<boolean>} Indicates if items in the wish list
     */
    isItemsInWishList() {
        return this.getWishlistItems()
            .then(result => Promise.resolve(result?.wishlistItems?.length > 0));
    }

    /**
     * @description Do call to get items in the wish list from the server
     * @param {string} url URL to get items in the wish list
     * @returns {Promise} Fetching result promise
     */
    async loadWishListItems(url) {
        return await fetch(url)
            .then((result) => {
                if (!result.ok) {
                    throw new Error(`HTTP error! status: ${result.status}`);
                }
                return result.json();
            }).then(data => {
                this.saveWishListItems(data.wishlistItems);
                return Promise.resolve(data);
            })
            .catch(error => {
                return error;
            });
    }

    /**
     * @description Set wish list items to the local storage
     * @param {Array} wishlistItems Object contains items in the wish list
     */
    saveWishListItems(wishlistItems) {
        const { sid } = getCookieMap();
        setItemInLocalStorage('currentSid', sid);
        setItemInLocalStorage('wishlistItems', wishlistItems || []);
    }

    /**
     * @description Update local storage with new items in the wish list
     * @param {Array} wishlistItems Object contains items in the wish list
     */
    updateWishlistItems(wishlistItems) {
        removeItemFromLocalStorage('wishlistItems');
        this.saveWishListItems(wishlistItems);
    }

    /**
     * @description Returns fetching result promise
     * @returns {Promise} Promise result
     */
    getWishlistItems() {
        return new Promise((resolve) => {
            const url = queryFirst('#wishlist-settings')?.dataset?.wishlistItemsLink || null;

            if (!url) {
                return resolve(null);
            }
            const { sid } = getCookieMap();
            const items = getItemFromLocalStorage('wishlistItems' || 'null');
            const isSameSession = getItemFromLocalStorage('currentSid') === sid;

            if (items && isSameSession) {
                return resolve({ wishlistItems: items });
            }

            if (this.loadWishlistItemsPromise) {
                return resolve(this.loadWishlistItemsPromise);
            }

            this.loadWishlistItemsPromise = this.loadWishListItems(url);
            return resolve(this.loadWishlistItemsPromise);
        });
    }
}

WishlistItemsMgr.instance = undefined;

export default WishlistItemsMgr;

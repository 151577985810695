export class AlertDialog {
    checkIcon = [
        '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">',
        '<path d="M1 6.4646L4.5 9.9646L11 3.4646" stroke="black"/>',
        '</svg>'
    ].join('\n');

    /**
     * Alert non-modal dialog used for showing messages to the user. Could be inserted
     * in different elements on the page. So it could be styled and positioned differently.
     * It has a timeout for auto-closing. Could be extened to use with Manager.
     *
     * @constructor
     * @param {AlertConfig} alertConfig - The configuration object for setting up the alert dialog.
     *
     * @typedef {Object} CtaItem
     * @property {string} text - The text displayed on the CTA button.
     * @property {Function} callback - The function to be executed when the CTA button is clicked.
     *
     * @typedef {Object} AlertItem
     * @property {string} text - The main text for the alert item.
     * @property {string[]} customCSSClass - An array of CSS classes applied to the alert item.
     * @property {Function} callback - A function executed when the alert item is clicked.
     * @property {CtaItem[]} cta - An array of call-to-action buttons, each with a text and callback.
     * @property {string} icon - HTML string representing an icon (e.g., an image) displayed with the alert item.
     *
     * @typedef {Object} AlertConfig
     * @property {number} alertTimeout - Optional. The time of displaying the alert until closed automatically. Default: 2500
     * @property {AlertItem[]} items - An array of alert items, each containing text, CSS classes, callback functions, CTAs, and an icon.
     * @property {Element} focusAfterClose - The DOM element that should regain focus after the alert dialog is closed.
     * @property {string[]} customCSSClass - An array of CSS classes applied to the alert dialog itself.
     * @property {string} style - Inline styles applied to the alert dialog for positioning or additional styling.
     * @property {boolean} remainOnScroll - Optional. Determines if alert keeps on showing when page is scrolled. Default: false
     *
     * @type {AlertConfig}
     * @example
     * const alertConfig = {
     *    alertTimeout: 2500,
     *    items: [
     *              {
     *                  text: 'Removed from Wishlist',
     *                  customCSSClass: [''],
     *                  callback: () => console.log('Alert clicked'),
     *                  cta: [
     *                          { text: 'OK', callback: () => console.log('OK clicked') },
     *                          { text: 'Cancel', callback: () => console.log('Cancel clicked') }
     *                        ],
     *                  icon: '<img src="product.png" alt="product-name" width=60 height=75 />'
     *              }
     *    ],
     *    focusAfterClose: document.querySelector('.header-button'),
     *    customCSSClass: ['m-modifier'],
     *    style: '',
     *    remainOnScroll: false
     *  };
     *  new AlertDialog(alertConfig);
     */
    constructor(alertConfig) {
        this.alertHost = document.body;
        this.alertConfig = alertConfig;
        this.alert = null;
        this.timeout = null;
        this.createAlert();
        this.startTimer();
    }

    createAlert() {
        if (!this.alertConfig) {
            console.error('Alert not configured');
            return;
        }
        const titleID = `alert-dialog-title-${this.generateRandomString()}`;
        const alert = document.createElement('div');
        alert.classList.add('alert-dialog');
        alert.setAttribute('role', 'alertdialog');
        alert.setAttribute('aria-modal', 'false');
        alert.setAttribute('aria-labeled', titleID);
        alert.setAttribute('tabindex', '-1');
        alert.setAttribute('style', this.alertConfig.style);

        if (this.alertConfig.customCSSClass?.length) {
            this.alertConfig.customCSSClass.forEach(cssClass => alert.classList.add(cssClass));
        }

        const { icon } = this.alertConfig;
        if (icon) {
            const iconElement = document.createElement('div');
            iconElement.classList.add('alert-dialog-icon');
            if (icon === 'default') {
                iconElement.innerHTML = this.checkIcon;
            } else {
                iconElement.innerHTML = icon;
            }

            alert.appendChild(iconElement);
        }

        if (this.alertConfig?.items) {
            this.alertConfig.items.forEach(item => {
                const alertInner = document.createElement('div');
                alertInner.textContent = item.text;
                alertInner.classList.add('alert-dialog-text');
                if (item.customCSSClass?.length) {
                    item.customCSSClass.forEach(cssClass => alertInner.classList.add(cssClass));
                }

                const { icon } = item;
                if (icon) {
                    const iconElement = document.createElement('div');
                    iconElement.classList.add('alert-inner-icon');
                    iconElement.appendChild(icon);
                    alert.appendChild(iconElement);
                }

                alertInner.setAttribute('id', titleID);
                alert.appendChild(alertInner);

                if (item?.cta?.length) {
                    const ctaContainer = document.createElement('div');
                    ctaContainer.classList.add('alert-dialog-ctas');

                    item.cta.forEach(cta => {
                        const button = document.createElement('button');
                        button.textContent = cta.text;
                        button.setAttribute('type', 'button');
                        if (typeof cta.callback === 'function') {
                            button.addEventListener('click', e => {
                                e.stopPropagation();
                                cta.callback();
                                this.closeAlert();
                            });
                        }
                        ctaContainer.appendChild(button);
                    });

                    alert.appendChild(ctaContainer);
                }
                alertInner.addEventListener('click', () => {
                    if (typeof item.callback === 'function') {
                        item.callback();
                    }
                    this.closeAlert();
                });
            });
        }

        alert.addEventListener('mouseenter', () => this.pauseTimer());
        alert.addEventListener('mouseleave', () => this.resumeTimer());
        alert.addEventListener('focusout', () => this.resumeTimer());
        alert.addEventListener('keydown', e => {
            if (e.key === 'Escape') {
                this.closeAlert();
            }
        });
        // In design, we have this strange requirements to close the alert on scroll
        window.addEventListener('scroll', (this.handleScroll = () => {
            if (!this.alertConfig?.remainOnScroll) {
                this.closeAlert();
            }            
        }));

        this.alertHost.insertAdjacentElement('beforeend', alert); // FIFO
        this.alert = alert;
        this.alert.focus();
        // We add this event listener to separate our focus from the user interaction
        alert.addEventListener('focusin', () => this.pauseTimer());
    }

    startTimer() {
        this.timeout = setTimeout(() => this.closeAlert(), this.alertConfig?.alertTimeout ?? 2500);
    }

    pauseTimer() {
        clearTimeout(this.timeout);
    }

    resumeTimer() {
        this.startTimer();
    }

    closeAlert() {
        if (this.alert) {
            window.removeEventListener('scroll', this.handleScroll); // need to collect listener manually to prevent leaks
            if (this.alert.parentNode) {
                this.alert.classList.add('m-onclose');
                setTimeout(() => this.alert.parentNode.removeChild(this.alert), 150);
            }
        }
        if (this.alertConfig.focusAfterClose instanceof HTMLElement) {
            this.alertConfig.focusAfterClose.focus();
        }
    }

    generateRandomString() {
        return (Math.random() + 1).toString(36).substring(3);
    }
}

'use strict';

const { getProductData, queryFirst, queryAll } = require('lilly/domUtil');
const { formatMessage } = require('lilly/util');
const afterpayTimeout = Date.now() + 5000;

/**
 * Converts an array of selectors/elements to an array of elements (Uses queryAll to query selector strings)
 * @param {string|element|array} items A selector, element, or array of selectors/elements
 * @param {element|array} [scope] The scope(s) in which to search for elements
 * @returns {array} An array of elements
 */
const elementArray = (items, scope) => {
    if (!Array.isArray(items)) items = [items];
    if (!Array.isArray(scope)) scope = [scope || document];

    return items.reduce((results, item) => {
        if (typeof item === 'string') {
            scope.forEach(parent => {
                if (!!parent.matches && parent.matches(item)) {
                    results.push(parent);
                } else {
                    results = results.concat(queryAll(item, parent));
                }
            });
        } else {
            results.push(item);
        }

        return results;
    }, []);
};

/**
 * Initializes Afterpay messaging
 * @param {object} options Afterpay configuration options
 * @param {boolean} [options.alwaysShow] Whether the Afterpay messaging should always show, even when outside the threshold range (Default: true)
 * @param {string|element|array|NodeList} options.anchors The anchor element(s) referenced when rendering Afterpay messaging
 * @param {number} [options.price] The static price of the product in any format (if possible, use a priceTarget instead)
 * @param {string|element|array} options.observerTargets A selector, element or array of selectors/elements to observe for price changes and trigger Afterpay messaging updates (if a selector is passed, it will be queried relative to the anchor unless observerIgnoreAnchor is set)
 * @param {boolean} options.observerIgnoreAnchor Ignores the anchor element(s) when querying for the observer targets (if the anchor needs to be separate from the observed element(s))
 * @param {string|element|array} options.priceTargets A selector, element or array of selectors/elements to use for price values. The "value" will be used if possible, or the innerText as a fallback
 * @param {string} [options.renderMode] The mode to use when rendering Afterpay messaging (E.g. adjacent, append, before, replace [Default: append])
 * @param {string|element} [options.renderTarget] The selector or element to use in conjunction with the renderMode (Defaults to the anchor)
 * @param {boolean} [options.showExcluded] Whether to show Afterpay messaging when a product is excluded
 * @param {object} [options.thresholds] The min/max thresholds to determine when Afterpay messaging shows (E.g. { min: 100, max: 1000 })
 * @param {string} [options.logoPosition] How to position the logo in Afterpay messaging (E.g. before, after [Default: after])
 */
module.exports = function afterpay(options = {}) {
    let { alwaysShow = true, anchors, observerTargets, observerIgnoreAnchor, price, priceTargets, renderMode, renderTarget, showExcluded = true, thresholds = {}, logoPosition = 'after' } = options;

    // No anchors OR no observerTargets OR (no price AND no price targets)
    if (!anchors || !anchors.length || !observerTargets || !observerTargets.length || (!price && (!priceTargets || !priceTargets.length))) return false;

    // Waiting for Afterpay API
    if (!window.presentAfterpay) return (Date.now() >= afterpayTimeout) ? false : setTimeout(function (args) { afterpay.apply(this, args); }.bind(this, arguments), 250);

    // Anchors to array
    anchors = elementArray(anchors);

    // Missing anchor(s)
    if (!anchors.length) return false;

    // Afterpay preferences element
    const afterpayPrefsEl = document.getElementById('afterpayEnabled');

    // Afterpay is disabled
    if (!afterpayPrefsEl || afterpayPrefsEl.value !== 'true') return false;

    // Afterpay preferences data
    const afterpayPrefs = afterpayPrefsEl.dataset;

    // Base config
    const apConfig = {
        afterpayLogoColor: 'black',
        currency: 'USD',
        locale: 'en_US'
    };

    // Get product exclusions
    const exclusions = JSON.parse(afterpayPrefs.exclusions || '[]');

    // Threshold mixin
    if (thresholds) {
        const apThresholds = apConfig.minMaxThreshold = {};
        let { min, max } = thresholds;

        // Threshold fallbacks
        if (!min) min = afterpayPrefs.min;
        if (!max) max = afterpayPrefs.max;

        // Afterpay wants cents
        if (min) apThresholds.min = min * 100;
        if (max) apThresholds.max = max * 100;
    }

    /**
     * Updates the product price in an Afterpay config object
     * @param {object} config Afterpay config object
     * @param {*} price Price in any format
     */
    const updateAmount = (config, price) => {
        const stringValue = String(price);
        const isDecimalValue = ~stringValue.indexOf('.');
        const intValue = parseInt(stringValue.replace(/[^0-9]/g, '').trim(), 10);
        const amount = config.amount = intValue * (isDecimalValue ? 1 : 100);

        return amount;
    };

    /**
     * Gets the price target value for the given observer target
     * @param {element} observerTarget The observer target to search within
     * @returns {*} Returns the price, the price target's text content, or an empty string
     */
    const getPrice = observerTarget => {
        return (priceTargets ? elementArray(priceTargets, observerTarget) : [observerTarget]).reduce((value, element) => {
            if (value) return value;
            return element.value || element.innerText || '';
        }, '');
    };

    /**
     * Renders Afterpay messaging
     * @param {object} instance Afterpay instance
     * @param {element} anchor Anchor element
     * @param {string} [mode] Render mode (E.g. adjacent, append, before, replace [Default: append]) 
     */
    const renderAfterpay = (instance, anchor, mode) => {
        const { config } = instance;
        const { amount, minMaxThreshold = {} } = config;
        const { min, max } = minMaxThreshold;
        const { utag_data: uData } = window;
        const notWithinThreshold = (min && min > amount) || (max && max < amount);
        const excluded = [];
        const hidden = 'hide';

        let excludedMessageKey = 'defaultExcludedMessage';
        let productElements = [anchor];

        // If this is the cart, we're displaying with the context of all products
        if (uData?.action?.split('-').shift().toLowerCase() === 'cart') {
            excludedMessageKey = 'excludedMessage';
            productElements = queryAll('#myTote .cart-product-line-item');
        }

        // Look for any exclusions
        productElements.forEach(element => {
            const { masterId, productName } = getProductData(element);

            if (~exclusions.indexOf(masterId) && !~excluded.indexOf(productName)) {
                excluded.push(productName);
            }
        });

        let renderMode = (!alwaysShow && notWithinThreshold) ? hidden : mode.toLowerCase();

        if (excluded.length && !showExcluded) {
            renderMode = hidden;
        }

        const afterpayNode = renderMode === hidden ? document.createElement('p') : instance.renderAfterpay();
        const afterpayMessage = queryFirst('span[class^="afterpay-text"]', afterpayNode);

        if (afterpayMessage) {
            if (logoPosition === 'after') {
                const { parentElement } = afterpayMessage;

                parentElement.insertBefore(afterpayMessage, parentElement.firstChild);
            }

            if (excluded.length) {
                if (showExcluded) {
                    const lastToken = excluded.pop();
                    const tokenText = excluded.length ? `${excluded.join(', ')}${afterpayPrefs.excludedMultiSuffix} ${lastToken}` : lastToken;

                    afterpayMessage.textContent = formatMessage(afterpayPrefs[excludedMessageKey], `${tokenText} `);
                }
            } else if (notWithinThreshold) {
                afterpayMessage.textContent = `${afterpayPrefs.notWithinThresholdMessage} `;
            }
        }

        const instanceNode = instance.afterpayNode;
        const target = (typeof renderTarget === 'string' ? queryFirst(renderTarget, anchor) : renderTarget) || anchor;

        instance.afterpayNode = afterpayNode;

        switch (renderMode) {
            case 'adjacent':
                return target.insertAdjacentElement('afterend', afterpayNode);

            case 'before':
                return target.parentElement.insertBefore(afterpayNode, target);

            case hidden:
            case 'replace':
                return instanceNode && instanceNode.parentElement && instanceNode.parentElement.replaceChild(afterpayNode, instanceNode);

            default:
                return target.appendChild(afterpayNode);
        }
    };

    [].slice.call(anchors, 0).forEach(anchor => {
        const instanceConfig = Object.assign({}, apConfig);
        const anchorObserverTargets = elementArray(observerTargets, observerIgnoreAnchor ? document : anchor);

        if (price) {
            updateAmount(instanceConfig, price);
        } else {
            const priceValue = anchorObserverTargets.reduce((result, target) => {
                if (result) return result;
                return getPrice(target);
            }, 0);

            updateAmount(instanceConfig, priceValue);
        }

        if (isNaN(instanceConfig.amount)) return;

        const apInstance = new presentAfterpay(instanceConfig);
        const observer = new MutationObserver(mutations => {
            const { afterpayNode } = apInstance;

            if (!afterpayNode) return;

            updateAmount(apInstance.config, getPrice(mutations[0].target));
            renderAfterpay(apInstance, afterpayNode, 'replace');
        });

        anchorObserverTargets.forEach(target => {
            observer.observe(target, { childList: true, subtree: true });
        });

        renderAfterpay(apInstance, anchor, renderMode);
    });
};

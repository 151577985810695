'use strict';

import { queryFirst, addClass, queryAll } from '../domUtil';
import { getNestedValue } from '../util';
import { productDetail } from '../templates';
import { getStoredDataLayerInfo } from 'analytics/util/analytics-util';
import { P_PRODUCT_LIST_KEY, P_PROMO_NAME_KEY, P_PROMO_CREATIVE_NAME } from 'analytics/util/constants';

const { selectStyleMessageTmpl, ispuSelectStyleMessageTmpl } = productDetail;

/**
 * Function to check if shipping preferences view is enabled or not
 * @returns { boolean } - returns isEnabled flag value
 */
function isShippingPreferencesViewEnabled() {
    return getNestedValue(window, 'lillyUtils.shippingPreferencesConfig.isEnabled') || false;
}

/**
 * Function to toggle select color or size info
 * @param {HTMLElement} productContainer - current product element
 */
function toggleSelectSizeInfo(productContainer) {
    const selectedSizeEl = queryFirst('.size-btn.selected', productContainer);
    const addProductErrorEl = queryFirst('.js-add-product-errors', productContainer);
    if (addProductErrorEl) {
        if (!selectedSizeEl) {
            addProductErrorEl.innerHTML = selectStyleMessageTmpl(addProductErrorEl.dataset.selectStylesMessage);
            addClass(queryFirst('.pdp-attribute-name', productContainer), 'm-error');
        }
    }

    if (isShippingPreferencesViewEnabled()) {
        const ispuAvailabilityMessageEl = queryFirst('.ispu-preference-container .select-size-info-msg', productContainer);
        if (ispuAvailabilityMessageEl) {
            const messages = getNestedValue(window, 'lillyUtils.shippingPreferencesConfig.messages') || {};
            const { selectStylesMessage } = messages;
            if (!selectedSizeEl && selectStylesMessage) {
                ispuAvailabilityMessageEl.innerHTML = ispuSelectStyleMessageTmpl(selectStylesMessage);
            }
        }
    }
}
/**
 * Helper method to check if product is on early access or not
 * @param {Object|String} earlyAccess - product | early access date
 * @return {Boolean} true, if early access product or false for not
 */
function isEarlyAccessElement(earlyAccess) {
    let isEarlyAccessItem = false;
    const earlyAccessDate = (earlyAccess && earlyAccess.earlyAccessUntilDate) || earlyAccess;
    if (earlyAccessDate && earlyAccessDate !== 'false') {
        if (new Date(earlyAccessDate).getTime() > new Date().getTime()) {
            isEarlyAccessItem = true;
        }
    }
    return isEarlyAccessItem;
}

/**
 * enable/disable addAllToTote button for the shopTheLook
 * @param {HTMLElement} container - shopThelook container
 */
function enableAddAllToToteButton(container) {
    if (!container) return;

    const addAllToCartButton = queryFirst('.js-add-to-cart-global', container);
    if (queryAll('.js-set-item', container).length === queryAll('.size-btn.selected', container).length) {
        addAllToCartButton.removeAttribute('disabled');
        addAllToCartButton.innerHTML = addAllToCartButton.dataset.addAllLabel;
    } else {
        addAllToCartButton.setAttribute('disabled', true);
        addAllToCartButton.innerHTML = addAllToCartButton.dataset.selectSizeLabel;
    }
}

/**
 * Get analytics data to be associated with a product line item when adding to cart
 * @returns {Object} analytics data
 */
function getAddToCartAnalyticsData() {
    const promoName = getStoredDataLayerInfo(P_PROMO_NAME_KEY);
    const promoCreativeName = getStoredDataLayerInfo(P_PROMO_CREATIVE_NAME);
    const listName = getStoredDataLayerInfo(P_PRODUCT_LIST_KEY);
    const data = {};

    if (promoName && Array.isArray(promoName)) data.utagPromoName = promoName[0]; // promo_name should always be an array, but we don't want to store it that way on the BE
    if (promoCreativeName && Array.isArray(promoCreativeName)) data.utagPromoCreativeName = promoCreativeName[0];
    if (listName) data.utagListName = listName;

    return data;
}

export { toggleSelectSizeInfo, isEarlyAccessElement, isShippingPreferencesViewEnabled, enableAddAllToToteButton, getAddToCartAnalyticsData };

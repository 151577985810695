import { getCookieMap } from '../../domUtil';
const SELECTOR_PRODUCT_GRID = '.js-product-grid';

/**
 * Get density value from cookies
 * @returns {null|string} cookie density value
 */
export function getCookieDensityValue() {
    const cookieMap = getCookieMap();
    if ('density' in cookieMap) {
        return cookieMap.density;
    }
    return 'medium'; // if empty in cookie return default;
}

/**
 * Check is low density grid
 * @param {HTMLElement} productTileEl product Tile element
 * @returns {boolean} returns true if low density grid
 */
export function isLowGridDensity() {
    if (!document.querySelector(SELECTOR_PRODUCT_GRID)) {
        return false;
    }
    return getCookieDensityValue() === 'low';
}

/**
 * Check is medium density grid
 * @param {HTMLElement} productTileEl product Tile element
 * @returns {boolean} returns true if low density grid
 */
export function isMediumGridDensity() {
    if (!document.querySelector(SELECTOR_PRODUCT_GRID)) {
        return false;
    }
    return getCookieDensityValue() === 'medium';
}

/**
 * Check is high density grid
 * @param {HTMLElement} productTileEl product Tile element
 * @returns {boolean} returns true if low density grid
 */
export function isHighGridDensity() {
    if (!document.querySelector(SELECTOR_PRODUCT_GRID)) {
        return false;
    }
    return getCookieDensityValue() === 'high';
}

'use strict';

const { queryFirst, addClass, queryAll, removeClass, hasClass } = require('./domUtil.js');

/**
 * Handler for toggle password
 * */
function togglePasswordHandler() {
    const currentFormGroup = this.closest('.form-group');
    const currentPasswordInput = queryFirst('input', currentFormGroup);
    const currentForm = this.closest('form');
    let passwordInputs;
    let passwordToggles;
    if (hasClass(currentFormGroup, 'linked-password-toggle')) {
        // This is a "linked" password toggle. Toggling this field will also toggle any other linked password fields within the same form
        passwordInputs = queryAll('.linked-password-toggle .toggle-password-input', currentForm);
        passwordToggles = queryAll('.linked-password-toggle .toggle-password', currentForm);
    } else {
        // This password field is not linked to any others. We only need to toggle this password field
        passwordInputs = [currentPasswordInput];
        passwordToggles = this;
    }

    currentPasswordInput.focus();
    if (hasClass(this, 'show-password')) {
        removeClass(passwordToggles, 'show-password');
        passwordInputs.forEach(el => {
            el.type = 'password';
        });
    } else {
        addClass(passwordToggles, 'show-password');
        passwordInputs.forEach(el => {
            el.type = 'text';
        });
    }
}

module.exports = {
    enableTogglePassword: function () {
        const togglePassword = queryAll('.toggle-password');
        togglePassword.forEach(eachIcon => {
            eachIcon.removeEventListener('click', togglePasswordHandler);
            eachIcon.addEventListener('click', togglePasswordHandler);
            eachIcon.addEventListener('mousedown', e => {
                // This is needed to from prevent the eye icon from briefly getting focus
                e.preventDefault();
            });
        });
    }
};

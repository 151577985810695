'use strict';

const $body = $('body');
const { queryFirst, hasClass, removeClass, addClass, matchesBreakpoint, queryAll, renderFragment } = require('../domUtil');
const { appendToUrl } = require('../util');
const { trackWishlist } = require('../components/etmc');
const { AlertDialog } = require('../components/alertDialog');
const { addToWishlist: addToWishlistTemplates } = require('../templates');
const { WISHLIST_NAME_REGEX } = require('../components/shippingPreference/constants');
const { HIDDEN_CLASS, ANIM_TIME_STANDARD_1, INVALID_CLASS } = require('../constants');
const { SELECTOR_PRODUCT_TILE } = require('../productTiles/productTileConstants');
const { getSelectedSizeData } = require('../productTiles/utils/productTileData');
const { showStrikeHearts, hideStrikeHearts } = require('../wishlist/helper');
const WishlistItemsMgr = require('../wishlist/wishlistItemsMgr').default;
let $wishlistTriggerElement = null;
const configElement = queryFirst('#wishlist-settings');
let hoverTimeout;

/**
 * Callback for change button on mobile devices
 * @param {Array} lists lists to show
 */
function mobileChangeButtonCallback(lists) {
    const wishlistsContainer = queryFirst('.js-wishlist-items-container');
    while (wishlistsContainer.firstChild) {
        wishlistsContainer.removeChild(wishlistsContainer.firstChild);
    }
    lists.forEach(item => {
        const alertInner = document.createElement('div');
        alertInner.textContent = item.text;
        alertInner.classList.add('wishlist-sheet-item');
        if (item.customCSSClass?.length) {
            item.customCSSClass.forEach(cssClass => alertInner.classList.add(cssClass));
        }
        wishlistsContainer.appendChild(alertInner);
        alertInner.addEventListener('click', () => {
            if (typeof item.callback === 'function') {
                item.callback();
            }
        });
    });
    $('#wishlists').modal('show');
}

/**
 * Return information about product
 * @param {HTMLElement} icon clicked WL icon
 * @returns {Object} product info
 */
function getClickedProductInfo(icon) {
    const { productId: pid } = icon.dataset;
    let giftCardAmount = 0;
    let isGiftCard = false;

    isGiftCard = hasClass(icon.closest('.js-product-tile'), 'js-gift-card-tile') || hasClass(icon.closest('.product-detail'), 'gift-card-main');

    if (isGiftCard) {
        giftCardAmount = queryFirst('#gift_card_amount').value || 0;
    }

    let optionId = queryFirst('.js-color-attribute .swatch-circle.selected', icon.closest('.product-detail'))?.dataset?.attrValue || queryFirst('.js-tile-swatch.selected', icon.closest('.js-product-tile'))?.dataset?.attrValue || null;
    let optionVal = queryFirst('.size-btn.selected', icon.closest('.product-detail'))?.dataset?.valueId || queryFirst('.size-btn.selected', icon.closest('.js-product-tile'))?.dataset?.valueId || null;

    return { pid, isGiftCard, giftCardAmount, optionId, optionVal };
}

/**
 * Callback for WL heart
 * @param {HTMLElement} icon WL icon
 * @param {string} listId Wishlist ID
 * @returns {boolean} - succesfull or failure result
 */
function wishlistIconCallback(icon, listId = false) {
    const href = configElement?.dataset?.wishlistAdditemLink || '';

    const addWishlistButtonData = getClickedProductInfo(icon);

    if (!href || !addWishlistButtonData.pid) {
        return false;
    }
    addProductToWishlist(href, addWishlistButtonData, false, listId, icon);

    return true;
}

/**
 * Mark item as added to Wishlist
 * @param {HTMLElement} icon wishlist icon
 */
function fillWishlistIcon(icon) {
    addClass(icon, 'added-to-wish-list');
}
/**
 * Mark item as added to Wishlist
 * @param {HTMLElement} icon wishlist icon
 */
function unfillWishlistIcon(icon) {
    removeClass(icon, 'added-to-wish-list');
}

/**
 * Generates the modal window on the first call.
 * @param {HTMLElement} srcElement - add to wishlist initiator element
 */
function getModalHtmlElement(srcElement) {
    const dialog = queryFirst('#addToWishlistModal');
    if (!dialog) {
        const addToWishlistElement = renderFragment(addToWishlistTemplates.addToWishlistModal);
        addToWishlistElement.dataset.srcElement = srcElement;
        $body.append(addToWishlistElement);
    } else {
        dialog.textContent = '';
    }
}

/**
 * Returns configuration on lists
 * @param {HTMLElemet} button clicked WL icon
 * @returns {Promise} Promise with items
 */
function getWishlistItemsWithConfig(button) {
    const wishlistMgr = WishlistItemsMgr.getInstance();
    const { productId: pid } = button.dataset;
    return wishlistMgr
        .getWishlistItems()
        .then(response => {
            const { wishlistItems: items } = response;

            return Promise.all(
                items.map(item => {
                    const cta = [];
                    const customCSSClass = [];
                    let callback = null;

                    return wishlistMgr.isItemInWishlist(pid, item.UUID).then(state => {
                        if (state) {
                            customCSSClass.push('m-selected');
                        } else {
                            callback = () => wishlistIconCallback(button, item.UUID);
                        }
                        customCSSClass.push(`listid-${item.UUID}`);
                        return {
                            text: item.listName,
                            callback: callback,
                            cta,
                            customCSSClass
                        };
                    });
                })
            );
        })
        .then(resolvedTexts => {
            if (!resolvedTexts.length) {
                // added for perfomance purpose to avoid creating lists on hovering and first load
                // first WL will be create on adding first item to WL or oppening WL page directrly
                resolvedTexts.push({
                    text: configElement?.dataset.defaultName,
                    callback: () => wishlistIconCallback(button)
                });
            }
            const createNewListBtn = {
                text: 'Create a new list',
                customCSSClass: ['m-new'],
                callback: () => {
                    let productMovedToWishlist = getClickedProductInfo(button);
                    $('#create-wishlist').modal('show');
                    $('#wishlists').modal('hide');
                    handleAddToWishlistActions(productMovedToWishlist, false, button);
                }
            };
            resolvedTexts.push(createNewListBtn);

            return resolvedTexts;
        });
}

/**
 * Show allert message
 * @param {*} button clicked WL icon
 * @param {Object} data add to WL response
 * @returns {void}
 */
function displayAlert(button, data) {
    const isMobile = !matchesBreakpoint('lg');
    const alertConfig = {
        items: [
            {
                text: data.text,
                cta: [
                    {
                        text: 'View',
                        callback: () => {
                            const redirectLink = configElement?.dataset?.wishlistShowLink || '';
                            if (redirectLink && data.wishListUUID) {
                                const url = new URL(redirectLink, window.location.origin);
                                url.searchParams.set('id', data.wishListUUID);
                                window.location.href = url.toString();
                            }
                        }
                    }
                ]
            }
        ],
        customCSSClass: data.containerClass ? data.containerClass : []
    };

    if (isMobile) {
        alertConfig.items[0].cta.splice(0, 0, {
            text: '+ to another list',
            callback: () => {
                getWishlistItemsWithConfig(button).then(resolvedTexts => {
                    mobileChangeButtonCallback(resolvedTexts, button);
                });
            }
        });
    } else {
        // not mobile
        const position = button.getBoundingClientRect();
        const isRightPos = window.innerWidth - position.right < 160;

        alertConfig.style = 'top:' + position.top + 'px;left:' + position.left + 'px;';

        if (isRightPos) {
            alertConfig.customCSSClass.push('m-right');
        }
    }

    return new AlertDialog(alertConfig);
}

/**
 * Input validation
 * @param {HTMLInputElement} input input
 * @param {HTMLElement} invalidFeedback validation message
 */
function validateInputCallback(input, invalidFeedback) {
    const listNameTrimText = input && input.value && input.value.trim();
    const { missingError, patternMismatch } = input.dataset;
    if (!listNameTrimText) {
        addClass(input, INVALID_CLASS);
        invalidFeedback.textContent = missingError;
    } else if (!WISHLIST_NAME_REGEX.test(listNameTrimText)) {
        addClass(input, INVALID_CLASS);
        invalidFeedback.textContent = patternMismatch;
    } else {
        removeClass(input, INVALID_CLASS);
    }
}

/**
 * handles all the actions to add the product to wishlist.
 * @param {Object} wishlistedProductData - Object with product id, option id, option value, isGiftCard and giftCardAmount details
 * @param {boolean} isCart - param to identify cart page or not
 * @param {jQuery.event} event - Event object which trigger the action
 */
function handleAddToWishlistActions(wishlistedProductData, isCart, event) {
    const existingListCollectionContent = queryFirst('.existing-list-collections');
    const createNewCollection = queryFirst('.create-list-collection');
    const listNameInput = queryFirst('#list-name');
    const sheetContainer = queryFirst('.wishlist-sheet');
    const invalidFeedback = queryFirst('.invalid-feedback', createNewCollection);
    listNameInput.value = '';
    removeClass(sheetContainer, INVALID_CLASS);
    removeClass(listNameInput, INVALID_CLASS);
    invalidFeedback.textContent = '';
    listNameInput.addEventListener('blur', function (e) {
        validateInputCallback(listNameInput, invalidFeedback);
    });

    $(queryFirst('.wishlist-sheet'))
        .off('click')
        .on('click', '.add-new-list', function (e) {
            e.preventDefault();
            const createCollectionLink = configElement?.dataset?.createCollectionLink || '';
            if (!createCollectionLink) {
                return;
            }
            let listName = listNameInput.value && listNameInput.value.trim();

            if (!listName || !WISHLIST_NAME_REGEX.test(listName)) {
                addClass(sheetContainer, INVALID_CLASS);
                return;
            }
            invalidFeedback.textContent = '';

            removeClass(sheetContainer, INVALID_CLASS);
            removeClass(listNameInput, INVALID_CLASS);
            listName = listName.replace(/\s\s+/g, ' ');
            $.spinner().start();
            $.ajax({
                url: createCollectionLink,
                type: 'post',
                dataType: 'json',
                data: {
                    wishlistName: listName
                },
                success: function (data) {
                    $.spinner().stop();
                    if (data.success) {
                        const wishlistAdditemLink = configElement?.dataset?.wishlistAdditemLink || '';
                        const listUUID = data.wishListUUID;
                        addClass(existingListCollectionContent, HIDDEN_CLASS);
                        const wishlistMgr = WishlistItemsMgr.getInstance();
                        wishlistMgr.updateWishlistItems(data.wishlistItems);
                        $('#create-wishlist').modal('hide');
                        addProductToWishlist(wishlistAdditemLink, wishlistedProductData, isCart, listUUID, event);
                    } else {
                        invalidFeedback.textContent = data.msg;
                        addClass(listNameInput, INVALID_CLASS);
                    }
                },
                error: function (err) {
                    $.spinner().stop();
                    $('#create-wishlist').modal('hide');
                }
            });
        });
}

/**
 * Adds Product to wishlist
 * @param {string} url - service url to add product to wishlist
 * @param {Object} wishlistedProductData - Object with product id, option id, option value, isGiftCard and giftCardAmount Details
 * @param {boolean} isCart - param to identify cart page or not
 * @param {boolean} listId - wishlist ID
 * @param {Object} buttonElement - button that was clicked to add a product to the wishlist
 */
function addProductToWishlist(url, wishlistedProductData, isCart, listId, buttonElement) {
    const { pid, isGiftCard, giftCardAmount, optionId, optionVal } = wishlistedProductData;
    $body.trigger('addItemToList', buttonElement.closest('.product-tile'));
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            pid: pid,
            optionId: optionId,
            optionVal: optionVal,
            isGiftCard,
            id: listId,
            giftCardAmount
        },
        success: function (data) {
            if (data.success) {
                // update Marketing Analytics trackWishlist event
                if (data.mcAnalyticsArray) {
                    trackWishlist(data.mcAnalyticsArray);
                }
                const wishlistMgr = WishlistItemsMgr.getInstance();
                addClass(queryFirst(`.listid-${listId}`), 'm-selected');
                wishlistMgr.updateWishlistItems(data.wishlistItems);
                initWishlistIconState();
                displayAlert(buttonElement, { text: data.msg, wishListUUID: data.listId, containerClass: ['wishlist-alert-dialog-success'] });
            }

            if (data.error) {
                if (data.errorReason === 'Restricted') {
                    const parentContainer = buttonElement.closest('.wishlist-actions');
                    addClass(queryFirst(`.add-to-wish-list[data-product-id="${pid}"]`, parentContainer)?.parentElement, HIDDEN_CLASS);
                    const strikeHeart = queryFirst(`.strike-wish-list[data-product-id="${pid}"]`, parentContainer);
                    if (strikeHeart) {
                        removeClass(strikeHeart.parentElement, HIDDEN_CLASS);
                        strikeHeart.click();
                    }
                } else if (data.msg) {
                    displayAlert(buttonElement, { text: data.msg });
                }
            }
        },
        error: function (err) {
            displayAlert(buttonElement, err);
        }
    });
}

/**
 * Shows alert with wishlists
 * @param {Array} resolvedTexts array of wishlists
 * @param {HTMLElemet} button clicked heart
 * @returns {AlertDialog} Alert dialog
 */
function showAlertDialog(resolvedTexts, button) {
    const alertConfig = {
        items: resolvedTexts,
        customCSSClass: ['wishlist-alert-dialog']
    };

    const position = button.getBoundingClientRect();
    const isRightPos = window.innerWidth - position.right < 160;

    alertConfig.style = 'top:' + position.top + 'px;left:' + position.left + 'px;';

    if (isRightPos) {
        alertConfig.customCSSClass.push('m-right');
    }

    return new AlertDialog(alertConfig);
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {Object} wishlistedProductData - Object with product id, option id, option value, isGiftCard and giftCardAmount details
 * @param {boolean} isCart - param to identify cart page or not
 * @param {jQuery.event} event - Event object which trigger the action
 */
async function fillModalElement(selectedValueUrl, wishlistedProductData, isCart, event) {
    const $spinner = $.spinner();
    $spinner.start();
    const { pid, optionId, optionVal, isGiftCard, giftCardAmount } = wishlistedProductData;
    const url = appendToUrl(selectedValueUrl, { pid: pid });
    const createNewCollectionPLP = queryFirst('.create-list-collection');
    if (createNewCollectionPLP) {
        addClass(createNewCollectionPLP, HIDDEN_CLASS);
    }

    try {
        const res = await fetch(url);
        if (!res.ok) throw new Error('response was not OK');
        const data = await res.json();

        $spinner.stop();

        if (data.error && data.errorReason === 'Restricted') {
            const parentContainer = event.closest('.wishlist-actions');
            addClass(queryFirst(`.add-to-wish-list[data-product-id="${pid}"]`, parentContainer)?.parentElement, HIDDEN_CLASS);
            const strikeHeart = queryFirst(`.strike-wish-list[data-product-id="${pid}"]`, parentContainer);
            if (strikeHeart) {
                removeClass(strikeHeart.parentElement, HIDDEN_CLASS);
                strikeHeart.click();
            }
            return;
        }
        getModalHtmlElement(event);
        const addToWishlistModal = queryFirst('#addToWishlistModal');
        const addToWishlistBody = queryFirst('.modal-body', addToWishlistModal);
        addToWishlistBody.innerHTML = data.html;
        $(addToWishlistModal).modal('show');

        const quickViewModal = queryFirst('#quickViewModal');
        if (hasClass(quickViewModal, 'show')) $(quickViewModal).modal('hide');
        const addToWishlistAttributes = queryFirst('.existing-list-collections .add-list-item', addToWishlistModal);
        if (isCart) addToWishlistAttributes.setAttribute('data-uuid', event.dataset.uuid);
        addToWishlistAttributes.setAttribute('data-pid', pid);
        addToWishlistAttributes.setAttribute('data-option-id', optionId);
        addToWishlistAttributes.setAttribute('data-option-value', optionVal);
        addToWishlistAttributes.setAttribute('data-is-gift-card', isGiftCard);
        addToWishlistAttributes.setAttribute('data-gift-card-amount', giftCardAmount);
        addToWishlistAttributes.setAttribute('data-list-id', queryFirst('input[name="listsNameRadio"]:checked').dataset.id);
        handleAddToWishlistActions(wishlistedProductData, isCart, event);
    } catch (err) {
        console.error(`wishlist error: ${err.message}`);
        $spinner.stop();
    }
}

/**
 * Update wishlist icon state depend on existing in WL
 * @param {HTMLElement} icon WL icon
 * @param {string} pid product ID
 */
function updateWishlistIcon(icon, pid) {
    const wishlistMgr = WishlistItemsMgr.getInstance();
    wishlistMgr.isItemInWishlist(pid).then(state => {
        if (state) {
            fillWishlistIcon(icon);
        } else {
            unfillWishlistIcon(icon);
        }
    });
}

/**
 * Initialize wishlist icons
 */
function initWishlistIconState() {
    const wishlistIcons = queryAll('.add-to-wish-list');
    wishlistIcons.forEach(icon => {
        const pid = icon.dataset?.productId;
        if (pid) {
            updateWishlistIcon(icon, pid);
        }
    });
}

module.exports = {
    addToWishlist: function () {
        $body.on('click', '.js-move-to-wishlist', function (e) {
            e.preventDefault();
            $.spinner().start();
            const { target } = e;
            const url = target.getAttribute('data-href');
            const { pid, isGiftCard, giftCardAmount } = target.dataset;
            if (!url || !pid) {
                return;
            }
            let productMovedToWishlist = { pid, optionId: null, optionVal: null, isGiftCard, giftCardAmount };
            fillModalElement(url, productMovedToWishlist, true, target);
            $wishlistTriggerElement = target;
        });

        $body.on('click', '.add-to-wish-list', function (e) {
            clearTimeout(hoverTimeout);
            e.preventDefault();
            const button = this;
            if (hasClass(button, 'added-to-wish-list')) {
                return false;
            }
            const isMobile = !matchesBreakpoint('lg');
            if (isMobile) {
                wishlistIconCallback(button, false);
            } else {
                getWishlistItemsWithConfig(button).then(resolvedTexts => {
                    showAlertDialog(resolvedTexts, button);
                    queryAll('.wishlist-alert-dialog').forEach(dialog => {
                        addClass(dialog, 'wishlist-alert-dialog-shown');
                    });
                });
            }
        });
        $body.on('touchstart', '.added-to-wish-list', function (e) {
            const button = this;
            getWishlistItemsWithConfig(button).then(resolvedTexts => {
                mobileChangeButtonCallback(resolvedTexts, button);
            });
        });
        $body.on('mouseenter', '.add-to-wish-list', function (e) {
            const button = this;
            hoverTimeout = setTimeout(function () {
                getWishlistItemsWithConfig(button).then(resolvedTexts => {
                    showAlertDialog(resolvedTexts, button);
                    queryAll('.wishlist-alert-dialog').forEach(dialog => {
                        addClass(dialog, 'wishlist-alert-dialog-shown');
                    });
                });
            }, ANIM_TIME_STANDARD_1);
        });
        $body.on('mouseleave', '.add-to-wish-list', function (e) {
            clearTimeout(hoverTimeout);
        });
    },
    handleWishlistClose: function () {
        $body.on('click', '.wishlist-sheet-btn-done', function () {
            $('#wishlists').modal('hide');
        });
        $body.on('hidden.bs.modal', '#addToWishlistModal', function () {
            if ($wishlistTriggerElement) {
                $wishlistTriggerElement.focus();
                $wishlistTriggerElement = null;
            }
        });
    },
    handleSwatchUpdate: function () {
        $body.on('swatchChangeEventCustom', function (e, data) {
            const { el, productData } = data;

            if (!el || !productData) {
                return;
            }

            const pTileEl = el.closest(SELECTOR_PRODUCT_TILE);

            if (productData.wishlistDisabled || !productData.isDirectlyPurchasable || productData.isFinalSale) {
                showStrikeHearts(pTileEl);
            } else {
                hideStrikeHearts(pTileEl);
            }

            const wishlistIcon = queryFirst('.add-to-wish-list', pTileEl);
            const wishlistStrikeIcon = queryFirst('.strike-wish-list', pTileEl);
            wishlistIcon.dataset.productId = productData.id;
            wishlistStrikeIcon.dataset.productId = productData.id;
            updateWishlistIcon(wishlistIcon, productData.id);
        });

        $body.on('product:afterAttributeChange', function (e, eventData) {
            if (!eventData) {
                return;
            }
            const { container, variantGroupId, groupId } = eventData;
            const { masterid } = container[0].dataset;
            const productInfo = window.productInfo[masterid];
            const variantGroupData = productInfo.variants[variantGroupId];
            if (!variantGroupData) {
                return;
            }

            const { isDirectlyPurchasable, wishlistDisabled, isFinalSale } = variantGroupData;

            const productEl = container[0].closest('.product-detail, .set-items .product-detail, .choose-bonus-product-modal .product-detail, .gift-card-main');

            if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
                showStrikeHearts(productEl);
            } else {
                hideStrikeHearts(productEl);
            }
            const wishlistIcons = queryAll('.pdp-details .add-to-wish-list', productEl);
            const wishlistStrikeIcons = queryAll('.pdp-details .strike-wish-list', productEl);
            wishlistIcons.forEach(wishlistIcon => {
                wishlistIcon.dataset.productId = groupId;
                updateWishlistIcon(wishlistIcon, groupId);
            });
            wishlistStrikeIcons.forEach(wishlistStrikeIcon => {
                wishlistStrikeIcon.dataset.productId = groupId;
            });
        });
    },
    handleSizeUpdate: function () {
        $body.on('product:tileSizeSelected', function (e, data) {
            const { container: pTileEl } = data;
            const { ID, isDirectlyPurchasable, isFinalSale, wishlistDisabled } = getSelectedSizeData(pTileEl) || {};
            if (!ID) {
                return;
            }

            if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
                showStrikeHearts(pTileEl);
            } else {
                hideStrikeHearts(pTileEl);
            }

            const wishlistIcon = queryFirst('.add-to-wish-list', pTileEl);
            const wishlistStrikeIcon = queryFirst('.strike-wish-list', pTileEl);
            wishlistIcon.dataset.productId = ID;
            wishlistStrikeIcon.dataset.productId = ID;
            updateWishlistIcon(wishlistIcon, ID);
        });
        $body.on('product:afterSizeChange', function (e, data) {
            const { productContainer: container, productSizeData: productData, allowWishlist } = data;

            const { ID, isDirectlyPurchasable, isFinalSale, wishlistDisabled } = productData;

            if (!ID) {
                return;
            }

            const pdpContainer = queryFirst('.pdp-details', container);
            if (!pdpContainer) {
                return;
            }

            if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale || !allowWishlist) {
                showStrikeHearts(pdpContainer);
            } else {
                hideStrikeHearts(pdpContainer);
            }

            const wishlistIcons = queryAll('.add-to-wish-list', pdpContainer);
            const wishlistStrikeIcons = queryAll('.strike-wish-list', pdpContainer);
            wishlistIcons.forEach(wishlistIcon => {
                wishlistIcon.dataset.productId = ID;
                updateWishlistIcon(wishlistIcon, ID);
            });
            wishlistStrikeIcons.forEach(wishlistStrikeIcon => {
                wishlistStrikeIcon.dataset.productId = ID;
            });
        });
    },

    initWishListItems: () => {
        initWishlistIconState();
        $body.on('search:showMore', () => {
            initWishlistIconState();
        });
        $body.on('search:sortOptions', () => {
            initWishlistIconState();
        });
        $body.on('search:updateProducts', () => {
            initWishlistIconState();
        });
        $body.on('search:densityChange', () => {
            initWishlistIconState();
        });
    }
};

'use strict';
const { HIDDEN_CLASS } = require('../constants');
const { removeClass, queryFirst, addClass, queryAll } = require('../domUtil');

/**
 * Show strike heart
 * @param {Element} productContainer product container with hearts
 */
function showStrikeHearts(productContainer) {
    const strikeWishList = queryAll('.wishlist-strike', productContainer);
    const addWishList = queryAll('.wishlist-add-update', productContainer);
    strikeWishList.forEach(item => {
        removeClass(item, HIDDEN_CLASS);
    });
    addWishList.forEach(item => {
        addClass(item, HIDDEN_CLASS);
    });
}

/**
 * Hide strike heart
 * @param {Element} productContainer product container with hearts
 */
function hideStrikeHearts(productContainer) {
    const strikeWishList = queryAll('.wishlist-strike', productContainer);
    const addWishList = queryAll('.wishlist-add-update', productContainer);
    strikeWishList.forEach(item => {
        addClass(item, HIDDEN_CLASS);
    });
    addWishList.forEach(item => {
        removeClass(item, HIDDEN_CLASS);
    });
}

/**
 * Update wishlists data attribute to theirs product ID
 * @param {string} id product ID string
 * @param  {string | Object} container  Parent element, if it not provided
 */
function setWishlistProductId(id, container = document.body) {
    if (id) {
        const wishlistBtn = queryFirst('.wishlist-add-update button', container);
        const wishlistStrikeBtn = queryFirst('.wishlist-strike button', container);

        if (wishlistBtn) wishlistBtn.dataset.productId = id;
        if (wishlistStrikeBtn) wishlistStrikeBtn.dataset.productId = id;
    }
}
module.exports = {
    hideStrikeHearts: hideStrikeHearts,
    showStrikeHearts: showStrikeHearts,
    setWishlistProductId: setWishlistProductId
};

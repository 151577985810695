'use strict';
import cart from '../cart/cart';
import { formatMessage, appendToUrl } from '../util';
import { queryAll, queryFirst, removeClass, addClass, hasClass, remove, slideUp } from '../domUtil';
import { trackCart } from './etmc';
import { SHOW_CLASS, HIDDEN_CLASS, ANIM_TIME_QUICK_2 } from '../constants';
import { minitoteError } from '../templates';

const $body = $('body');
const miniCartBtnContainer = queryFirst('.minicart-toggle');
const $miniCartBtnContainer = $(miniCartBtnContainer);
const miniCartSheet = queryFirst('#minicart');
const $miniCartSheet = $(miniCartSheet);
const miniCartContainer = queryFirst('.mini-cart-container', miniCartSheet);
const $miniCartContainer = $(miniCartContainer);
const $miniCartQuantity = $('.minicart-quantity');
const miniCartLink = queryFirst('.minicart-link', miniCartBtnContainer);
const priceElem = queryFirst('.subtotal-price', miniCartSheet);
const promoBanner = queryFirst('.promo-banner', miniCartSheet);
const mql = window.matchMedia('(min-width: 1024px)');
const ACTIVE_SHEET_PAGE_CLASS = 'active';

let isMiniCartUpdating = true;

/**
 * Updates mini cart title and close button with latest quantity.
 */
const updateTitle = () => {
    const OVERLAY_CLASS = 'title-overlay';

    const labelText = formatMessage($miniCartContainer.data('title-label'), $miniCartQuantity.text());
    const closeText = formatMessage($miniCartContainer.data('close-label'), labelText);

    const titleElem = queryFirst('.modal-header-title', miniCartSheet);
    const closeBtn = queryFirst('#minicart .close-current-modal');
    const sheetPage = queryFirst('.sheet-page', miniCartSheet);
    const miniCartContents = queryFirst('.mini-cart-contents', miniCartContainer);

    if (hasClass(miniCartContents, HIDDEN_CLASS)) addClass(sheetPage, OVERLAY_CLASS);
    else removeClass(sheetPage, OVERLAY_CLASS);

    if (titleElem) titleElem.textContent = labelText;
    if (closeBtn) closeBtn.ariaLabel = closeText;
    miniCartSheet.setAttribute('aria-label', labelText);
};

const updateMiniCartIcon = () => {
    const countVal = parseInt($miniCartQuantity.text(), 10);
    const iconToteActive = queryFirst('.icon-tote-active');
    const iconTote = queryFirst('.icon-tote');

    $miniCartQuantity.text(countVal);

    if (countVal) {
        removeClass(iconToteActive, HIDDEN_CLASS);

        if (!hasClass(iconTote, HIDDEN_CLASS)) {
            addClass(iconTote, HIDDEN_CLASS);
        }
    } else {
        removeClass(iconTote, HIDDEN_CLASS);

        if (!hasClass(iconToteActive, HIDDEN_CLASS)) {
            addClass(iconToteActive, HIDDEN_CLASS);
        }
    }
};

/**
 * Updates the subtotal section in mini-cart
 */
function updateSubtotal() {
    priceElem.textContent = queryFirst('.mini-cart-contents', miniCartContainer)?.dataset.cartSubtotal || '';
}

/**
 * Displays mini cart error message
 * @param {string} message - Error message to display
 */
function createErrorNotification(message) {
    const cartError = queryFirst('.cart-error', miniCartSheet);
    if (cartError) cartError.innerHTML = minitoteError(message);
}

/**
 * Gets mini-cart sheet page elements
 * @returns {Object} mini cart page elements
 */
const getMiniCartPages = () => ({
    promoDetailsPage: queryFirst('.promo-details', miniCartSheet),
    miniCartPage: queryFirst('.minicart-page', miniCartSheet)
});

/**
 * Hides the promo page in the mini-cart sheet and show the cart page
 */
function hidePromoPage() {
    const { promoDetailsPage, miniCartPage } = getMiniCartPages();
    if (promoDetailsPage && miniCartPage) {
        removeClass(miniCartPage, HIDDEN_CLASS);
        removeClass(promoDetailsPage, ACTIVE_SHEET_PAGE_CLASS);
    }
}

/**
 * Initializes the event handlers
 */
function init() {
    cart();

    $miniCartBtnContainer.on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $miniCartBtnContainer.find('.minicart-quantity').text(count.quantityTotal);
            updateMiniCartIcon();
            updateTitle();
            updateSubtotal();
            $miniCartBtnContainer.find('.minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });
        }
    });

    $(miniCartLink).on('touchstart click', async e => {
        const { currentTarget } = e;
        const url = miniCartBtnContainer.dataset.actionUrl;
        const count = parseInt(queryFirst('.minicart-quantity', miniCartBtnContainer)?.textContent, 10);
        const cartContainer = queryFirst('.cart-container');

        if ((currentTarget.dataset.sflEnabled === 'true' && !count) || cartContainer) {
            e.stopImmediatePropagation();
            e.preventDefault();
            window.location.href = currentTarget.dataset.actionUrl;
        } else if (!hasClass(miniCartSheet, SHOW_CLASS)) {
            if (isMiniCartUpdating && !miniCartSheet.getAttribute('aria-busy')) {
                miniCartSheet.setAttribute('aria-busy', true);
                const $dialog = $miniCartSheet.find('.modal-dialog');
                $dialog.spinner().start();

                try {
                    const res = await fetch(url);
                    if (!res.ok) throw new Error('response was not OK');

                    // append response data into the mini-cart container on the page
                    const resData = await res.text();
                    const resHtml = document.createElement('template');
                    resHtml.innerHTML = resData;
                    const minicartHtml = queryFirst('.mini-cart-template', resHtml.content);
                    $miniCartContainer.empty().append(minicartHtml.content); // Needs to be jquery -- vanilla options don't eval script tags

                    const promoBannerTemplate = queryFirst('.mini-cart-promoblock-template', resHtml.content);
                    promoBanner.innerHTML = '';
                    promoBanner.appendChild(promoBannerTemplate.content);

                    const promoDetailsTemplate = queryFirst('.mini-cart-promodetails-template', resHtml.content);
                    queryFirst('.sheet-dialog', miniCartSheet)?.appendChild(promoDetailsTemplate.content);

                    const emptyCartContent = queryFirst('.empty-cart-content');
                    const miniCartContents = queryFirst('.mini-cart-contents', miniCartContainer);

                    // if tote is empty
                    if (!queryFirst('.line-item', miniCartContainer)) {
                        $miniCartBtnContainer.trigger('count:update', [
                            {
                                quantityTotal: 0,
                                minicartCountOfItems: miniCartLink?.dataset.dataZeroCount
                            }
                        ]);
                        addClass(miniCartContents, HIDDEN_CLASS);
                        removeClass(emptyCartContent, HIDDEN_CLASS);
                        updateTitle();
                    }

                    $miniCartContainer.closest('.modal-content').scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    isMiniCartUpdating = false;
                } catch (err) {
                    console.error('Mini-cart error:', err.message);
                } finally {
                    miniCartSheet.removeAttribute('aria-busy');
                    $dialog.spinner().stop();
                }
            }
        }

        if (count === 0) {
            removeClass(emptyCartContent, HIDDEN_CLASS);
            const minicart = queryFirst('.cart', miniCartSheet);
            if (minicart) {
                minicart.innerHTML = '';
            }
        } else {
            addClass(emptyCartContent, HIDDEN_CLASS);
        }

        const windowTest = w => {
            if (w.matches) {
                return;
            }
            if (hasClass(queryFirst('.main-menu'), 'in')) {
                queryFirst('.navbar-toggler')?.click();
            }
        };
        windowTest(mql);
    });

    $body.on('cart:update', () => {
        isMiniCartUpdating = true;
        updateTitle();
        updateSubtotal();
        updateMiniCartIcon();
    });

    // After a product is added to cart
    $body.on('product:afterAddToCart', (e, data) => {
        isMiniCartUpdating = true;
        updateTitle();

        // update Marketing Cloud Analytics trackCart event
        const { marketingCloudAnalyticsData: mcData } = data;
        if (mcData) trackCart(mcData);

        if (!queryFirst('.cart-container') && data && !data.error) {
            // Use jQuery so GA binding doesn't fire
            $('.minicart-link').click();
        }
    });

    $body.on('click', '.remove-product-btn', async e => {
        e.preventDefault();

        const { target } = e;
        const { uuid, action, name, pid } = target.dataset;

        const url = appendToUrl(action, { pid, uuid, minitote: true });
        const removedLineItem = target.closest('.line-item');
        slideUp(removedLineItem, ANIM_TIME_QUICK_2).then(() => {
            remove(removedLineItem);
        });

        let resData;

        try {
            const res = await fetch(url);
            resData = await res.json(); // calling json before error checking is intentional

            if (!res.ok) throw new Error('response was not OK');

            if (resData.basket.items.length) {
                const { toBeDeletedUUIDs } = resData;

                // Remove line items
                if (toBeDeletedUUIDs?.length > 0) {
                    toBeDeletedUUIDs.forEach(resUuid => {
                        const removeItem = queryFirst(`.line-item[data-product-line-item="${resUuid}"]`, miniCartContainer);
                        slideUp(removeItem, ANIM_TIME_QUICK_2).then(() => {
                            remove(removeItem);
                        });
                    });
                }
            }

            // set attributes on the mini-tote nav button
            miniCartLink.setAttribute('aria-label', resData.basket.resources.minicartCountOfItems);
            miniCartLink.setAttribute('title', resData.basket.resources.minicartCountOfItems);

            // Update total + errors
            const miniCartContents = queryFirst('.mini-cart-contents', miniCartContainer);
            const { totals, valid, numItems, approachingDiscounts } = resData.basket;
            if (miniCartContents) miniCartContents.dataset.cartSubtotal = totals.subTotal;

            if (!valid.error) {
                const cartError = queryFirst('.cart-error');
                if (cartError) cartError.textContent = '';
            } else {
                createErrorNotification(valid.message);
            }

            // Show empty messaging, if tote is empty
            if (numItems === 0) {
                removeClass(queryFirst('.empty-cart-content'), HIDDEN_CLASS);
                addClass(miniCartContents, HIDDEN_CLASS);
            }

            // Replace promo banner
            promoBanner.innerHTML = resData.promoBlockHtml;

            // Update count/totals/approaching discounts
            $miniCartQuantity.empty().append(numItems);
            updateMiniCartIcon();

            $body.trigger('cart:update');
            $('.cart-container, .mini-cart-container').trigger('cart:updateTotals', resData);
            $body.trigger('removeFromMinicart', name);

            // update Marketing Cloud Analytics trackCart event
            trackCart(resData.mcAnalyticsArray);
        } catch (err) {
            if (typeof resData === 'object') {
                const { redirectUrl, errorMessage } = resData;
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                }

                if (errorMessage) {
                    createErrorNotification(errorMessage);
                }
            }

            console.error('Mini-cart error:', err.message);
        }
    });

    $miniCartSheet.on('shown.bs.modal', function () {
        addClass(queryFirst('body'), 'modal-open');
    });

    /**
     * Shows mini-tote / hides promo details on click of promo details back and close buttons
     * event delegate -- elements won't be available on page load
     */
    $miniCartSheet.on('hidden.bs.modal', hidePromoPage);

    /**
     * Reset scroll position on mini-tote close
     * This ensures that the user is anchored to the top of the mini-tote every time it opens.
     */
    $miniCartSheet.on('hide.bs.modal', e => {
        queryAll('.sheet-body-content', e.currentTarget)?.forEach(sheetBody => {
            sheetBody.scrollTop = 0;
        });
    });

    /**
     * Shows promo details / hides mini-tote on click of the promo banner in mini-tote
     * event delegate -- elements won't be available on page load
     */
    $miniCartSheet.on('click', 'button.promo-block', () => {
        const { promoDetailsPage, miniCartPage } = getMiniCartPages();
        if (promoDetailsPage && miniCartPage) {
            addClass(miniCartPage, HIDDEN_CLASS);
            addClass(promoDetailsPage, ACTIVE_SHEET_PAGE_CLASS);
        }
    });

    $miniCartSheet.on('click', '.promo-details .modal-back', hidePromoPage);
}

export default init;

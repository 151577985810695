'use strict';

import { addClass, queryAll, hasClass, removeClass, queryFirst } from './domUtil';
import { isLowGridDensity } from './productTiles/utils/gridDensity';

const imageClass = 'lazy-image';
const videoClass = 'lazy-video';
const divClass = 'lazy-div';
const loadedClass = 'lz-loaded';
const pendingClass = 'lz-pending';

const $body = $('body');
let observer;

/**
 * Function to update image attributes
 * @param {HTMLElement} imageEl
 */
function updateImageAttributes(imageEl) {
    const { src, srcset } = imageEl.dataset;
    imageEl.src = src;
    if (srcset) {
        imageEl.srcset = srcset;
    }

    // Supports lazyload of <source> element's srcset
    const imageParentEl = imageEl.parentElement;
    if (imageParentEl.tagName.toLowerCase() === 'picture') {
        queryAll('source', imageParentEl).forEach(sourceEl => {
            sourceEl.srcset = sourceEl.dataset.srcset;
        });
    }

    removeClass(imageEl, 'invisible', pendingClass);
    imageEl.onload = () => addClass(imageEl, loadedClass);
}

/**
 * Function to update video source element attributes
 * @param {HTMLElement} videoEl The <video> element
 */
function updateVideoAttributes(videoEl) {
    const sources = queryAll('source', videoEl);
    sources.forEach(source => {
        source.src = source.dataset.src;
    });

    videoEl.load();
    removeClass(videoEl, pendingClass);
    addClass(videoEl, loadedClass);
}

/**
 * Function to update div lazy element attributes
 * @param {HTMLDivElement} divEl The <div> element
 */
function updateDivAttributes(divEl) {
    const { src, errorMsg } = divEl.dataset;
    if (src) {
        const $divEl = $(divEl);
        $divEl.load(src, function (res, status) {
            if (status === 'error' && errorMsg) {
                $divEl.html(errorMsg);
            }
        });
    }
    removeClass(divEl, pendingClass);
    addClass(divEl, loadedClass);
}

/**
 * Callback for intersection observer - determines whether or not to load images
 * @param {*} elements list of observered images/videos
 */
function onIntersection(elements) {
    elements.forEach(function (media) {
        // Is the media in viewport?
        if (media.intersectionRatio > 0) {
            const { target } = media;
            observer.unobserve(target);
            if (hasClass(target, videoClass)) {
                updateVideoAttributes(target);
            } else if (hasClass(target, divClass)) {
                updateDivAttributes(target);
            } else {
                updateImageAttributes(target);
            }
        }
    });
}

/**
 *  functions loads the image once it is in viewport
 */
function lazyLoad() {
    if (!observer && window.IntersectionObserver) {
        observer = new IntersectionObserver(onIntersection, {
            // If the image gets within 200px of the top/bottom of the viewport, start the download.
            rootMargin: '200px 0px',
            threshold: 0.01
        });
    }

    if (!observer) return;

    const lazyQuery = [`.${imageClass}:not(.${loadedClass}):not(.${pendingClass})`, `video.${videoClass}:not(.${loadedClass}):not(.${pendingClass})`, `div.${divClass}:not(.${loadedClass}):not(.${pendingClass})`].join(', ');
    const lazyMedia = queryAll(lazyQuery);

    lazyMedia.forEach(elem => {
        addClass(elem, pendingClass);

        observer.observe(elem);
    });
}

lazyLoad();

/**
 * Function to update the image attributes on class change (used for nav drop-down images)
 */
function attributeChangeHandler() {
    const mutationHandler = function (mutationList) {
        for (let mutation of mutationList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                const { target } = mutation;
                queryAll('.lazy-load-nav', target).forEach(eachEl => {
                    if (hasClass(target, 'show') && eachEl.dataset.src && !hasClass(eachEl, loadedClass)) {
                        updateImageAttributes(eachEl);
                    }
                });
            }
        }
    };
    const observerConfig = { attributeFilter: ['class'], attributeOldValue: true, attributes: true };
    const observer = new MutationObserver(mutationHandler);
    queryAll('.main-header .nav-item.dropdown').forEach(eachElement => observer.observe(eachElement, observerConfig));
}

attributeChangeHandler();

$body.on('search:updateProducts', function () {
    setTimeout(() => {
        // without a very small delay there seems to be a race condition with PLP product loading
        lazyLoad();
    }, 10);
});

$body.on('search:densityChange', function () {
    setTimeout(() => {
        lazyLoad();
    }, 10);
});

$('.refinement .print-swatch-img')
    .closest('.accordion-container')
    .on('show.bs.collapse', function () {
        [...queryAll('.lazy-load')].forEach(image => {
            if (!hasClass(image, loadedClass)) {
                updateImageAttributes(image);
            }
        });
    });

// load print filter images when automatically expanded on page load
const printRefinements = queryFirst('.refinement-print--pattern-and-solid');
const printBlockBtn = queryFirst('.btn-block', printRefinements);
if (printRefinements && printBlockBtn.getAttribute('aria-expanded') === 'true') {
    queryAll('.lazy-load', printRefinements).forEach(image => {
        if (!hasClass(image, loadedClass)) {
            updateImageAttributes(image);
        }
    });
}

// Carousel alt image lazy loading (on next/prev button click)
$(document).on('slide.bs.carousel', '.primary-images .carousel', function (e) {
    const image = queryFirst(`.${imageClass}`, e.relatedTarget);
    if (image && !hasClass(image, loadedClass)) {
        updateImageAttributes(image);
    }
});

document.addEventListener('lazyload:load:image', function (e) {
    const { element } = e.detail;
    updateImageAttributes(element);
});

'use strict';
const focusHelper = require('base/components/focus');
import { addClass, hasClass, queryAll, queryFirst, remove, removeClass, renderFragment, toFormData, setAttribute } from '../domUtil';
import { formatMessage, getJSON, getNestedValue, tokenize, setUrlParamValue } from '../util';
import { getAddToCartAnalyticsData } from './helper';
import { setWishlistProductId, showStrikeHearts } from '../wishlist/helper';
import { EMPTY_STRING, NOT_AVAILABLE_STATUS, SELECTED_CLASS } from '../constants';

const { trackPageView } = require('../components/etmc');
const { toggleSelectSizeInfo, isEarlyAccessElement } = require('./helper');
const { swatchNameTemplate, sizeOptionTemplate, sizeButtonTemplate, gwpSizeTemplate, dialogTemplate, loyalty: loyaltyTemplates } = require('../templates');
const $body = $('body');
const gwpDialog = queryFirst('#chooseBonusProductModal');
const $gwpDialog = $(gwpDialog);
const hiddenClass = 'd-none';
const readyClass = 'ready-to-add';
const selectedProductClass = 'selected-product';
const notAvailable = 'not-available';
const { updateVisibilityOfLowInventoryMsg } = require('../components/shippingPreference/shippingPreferenceHelper');
const { PRODUCT_DETAIL_CONTAINER_SELECTOR } = require('../components/shippingPreference/constants');
const { HIDDEN_CLASS } = require('../constants');
const loyaltyProfile = getNestedValue(window, 'lillyUtils.profile') || {};
const { isLoyaltyProgramMember, isLoyaltyAuthenticated, isLoyaltyEnabled } = loyaltyProfile;
const { videoInnerContent } = require('lilly/templates');
const { initVideos } = require('../components/video').default;

let observer;
let isContentModulesFetched = false;

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').attr('data-pid');
    } else if ($('.quickview-container').length && !$('.product-set').length) {
        pid = $($el).closest('.quickview-container').find('.product-quickview').attr('data-pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').first().text();
    } else if ($('.custom-set-detail-modal').length) {
        $($el).closest('.custom-set-detail-modal').find('.product-id').first().text();
    } else {
        pid = $($el).closest('.product-detail').find('.product-id').first().text();
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length ? $el.closest('.product-detail').find('.quantity-select') : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        const { bonusVariationUrl, url } = attrValue;

        if (isChoiceOfBonusProducts) {
            if (bonusVariationUrl) {
                $swatchButton.attr('data-url', bonusVariationUrl);
            } else {
                $swatchButton.removeAttr('data-url');
            }
        } else if (url) {
            $swatchButton.attr('data-url', url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    if ($productContainer.hasClass('bonus-product-item')) {
        const sizes = attr.values;
        const sizeContainer = queryFirst('.selected-size-container', $productContainer[0]);
        if (!sizes.length || !sizeContainer) {
            return;
        }

        if (sizes.length === 1) {
            sizeContainer.innerHTML = formatMessage(gwpSizeTemplate.singleSize, sizes[0].displayValue);
        } else {
            const loopStatus = sizeContainer.dataset.loopStatus;
            const resetUrl = attr.resetUrl;
            const selectLabel = sizeContainer.dataset.selectLabel;
            const selectId = `bonus-size-${loopStatus.count || '1'}`;

            let sizeOptions = formatMessage(gwpSizeTemplate.multiDefaultSizeOption, resetUrl, selectLabel);
            sizeOptions += sizes
                .map(function (size) {
                    const selected = size.selected ? ' selected ' : '';
                    const className = !size.inStock || size.forceOutOfStock ? notAvailable : '';
                    return formatMessage(gwpSizeTemplate.multiSizeOption, size.url, size.value, selected, size.displayValue, className, className ? 'disabled' : '');
                })
                .join('');
            sizeContainer.innerHTML = formatMessage(gwpSizeTemplate.multiSize, selectId, sizeOptions);
        }
    } else {
        var $attr = '[data-attr="' + attr.id + '"]';
        const selectSizeEl = $productContainer.find('.select-size')[0];
        let selectedValue = '';
        if (selectSizeEl) {
            $(selectSizeEl).find('option').not(':first').remove();
            $(selectSizeEl).find('option:first').attr('value', attr.resetUrl);
        }
        attr.values.forEach(function (attrValue) {
            if (selectSizeEl) {
                const assistiveElement = queryFirst('.selected-assistive-text', selectSizeEl.closest('.size-list'));
                assistiveElement.textContent = selectSizeEl.selectedOptions[0].dataset.attrValue;
                if (!attrValue.isOffline) {
                    selectSizeEl.innerHTML += sizeOptionTemplate(attrValue.url, attrValue.value);
                }
                if (attrValue.selected) {
                    selectedValue = attrValue.value;
                }
            } else {
                var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
                const sizeListItemEl = $attrValue.closest('.size-list');
                if (!attrValue.isOffline) {
                    sizeListItemEl.removeClass(hiddenClass);
                } else {
                    sizeListItemEl.addClass(hiddenClass);
                }
                if ($attrValue.length > 0) {
                    $attrValue.val(attrValue.url).attr('data-attr-url', attrValue.url).removeClass(notAvailable);
                    const assistiveElement = queryFirst('.selected-assistive-text', $attrValue[0].closest('.size-list'));
                    assistiveElement.textContent = '';
                    const { selectedText, outOfStock } = assistiveElement.dataset;
                    if (hasClass($attrValue[0], 'selected')) {
                        assistiveElement.textContent += selectedText;
                    }
                    if (!attrValue.inStock || attrValue.forceOutOfStock) {
                        $attrValue.addClass(notAvailable);
                        assistiveElement.textContent += outOfStock;
                    }
                }
            }
        });
        if (selectSizeEl && selectedValue) {
            const optionEl = queryFirst('[data-attr-value="' + selectedValue + '"]', selectSizeEl);
            if (optionEl) {
                optionEl.selected = true;
            } else {
                selectSizeEl.selectedIndex = 0;
            }
        }
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    const { isDirectlyPurchasable } = response.product;
    const availabilityMsgEl = queryFirst('.availability-msg', $productContainer[0]);
    if (isDirectlyPurchasable === false && availabilityMsgEl) {
        availabilityValue = `<li><p class="availability-message-text" role="alert">${availabilityMsgEl.dataset.notPurchasable}</p></li>`;
    } else if (response.product.readyToOrder) {
        availabilityMessages.forEach(function (message) {
            availabilityValue += `<li><p class="availability-message-text" role="alert">
                ${message}
                </p></li>`;
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

/**
 * Handles the CTAs to be shown for Early Access Product
 * @param {jQuery} productContainer - DOM element for current product
 * @param {boolean} isEarlyAccessProduct - Is the product Early Access Product
 * @param {boolean} productContainerEarlyAccessIcon - Early access icon
 */
function handleEarlyAccessCta(productContainer, isEarlyAccessProduct, productContainerEarlyAccessIcon) {
    const earlyAccessLockIcon = productContainerEarlyAccessIcon || queryAll('.loyalty-early-access-lock-container', productContainer);
    const earlyAccessWishlistIcon = productContainerEarlyAccessIcon ? queryAll('.pdp-wish-list', productContainerEarlyAccessIcon.parentElement) : queryAll('.pdp-wish-list', productContainer);
    const earlyAccessCta = queryFirst('.loyalty-early-access-restricted', productContainer);
    const addToCartCta = queryFirst('.add-to-cart', productContainer);
    const earlyAccessPdpEl = queryFirst('.js-loyalty-badge', productContainer);
    let elements;
    if (isLoyaltyEnabled && earlyAccessCta) {
        if (earlyAccessPdpEl) {
            addClass([earlyAccessPdpEl], hiddenClass);
        }
        if (isEarlyAccessProduct) {
            if (isLoyaltyProgramMember) {
                addClass(earlyAccessLockIcon, HIDDEN_CLASS);
                elements = earlyAccessWishlistIcon;
                elements.push(addToCartCta);
                removeClass(elements, HIDDEN_CLASS);
            } else {
                removeClass(earlyAccessLockIcon, HIDDEN_CLASS);
                elements = earlyAccessWishlistIcon;
                elements.push(addToCartCta);
                addClass(elements, HIDDEN_CLASS);

                const earlyAccessGuest = queryFirst('.early-access-guest', earlyAccessCta);
                const earlyAccessRegistered = queryFirst('.early-access-registered', earlyAccessCta);
                if (isLoyaltyAuthenticated) {
                    addClass(earlyAccessGuest, HIDDEN_CLASS);
                    removeClass(earlyAccessRegistered, HIDDEN_CLASS);
                } else {
                    removeClass(earlyAccessGuest, HIDDEN_CLASS);
                    addClass(earlyAccessRegistered, HIDDEN_CLASS);
                }
            }
            if (earlyAccessPdpEl) {
                removeClass(earlyAccessPdpEl, HIDDEN_CLASS);
            }
        } else {
            elements = earlyAccessWishlistIcon;
            elements.push(addToCartCta);
            removeClass(elements, HIDDEN_CLASS);
            addClass(earlyAccessLockIcon, HIDDEN_CLASS);
        }
    }
}
/**
 * Dynamically creates Bootstrap carousel from response containing images
 * @param {Object[]} imgs - Array of product images, along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {boolean} isEarlyAccess - Check if the product is early access product
 * @param {string} productId - Id of the product, used for ID attribute construction
 * @param {string} videoUrl - Selected variant group video url
 */
function createCarousel(imgs, $productContainer, isEarlyAccess, productId, videoUrl) {
    const productContainerEl = $productContainer[0];
    const carouselEls = queryAll('.js-swiper-main, .js-swiper-modal-thumbs, .js-swiper-modal-description, .js-swiper-modal', productContainerEl);
    const mainCarousel = queryFirst('.js-swiper-main', productContainerEl);

    carouselEls.forEach(carouselEl => {
        const { imageType, cache } = carouselEl.dataset;
        const { swiper } = carouselEl;
        const slideEls = queryAll('.swiper-slide', carouselEl);
        const count = imgs?.large.length || 0;
        const galleryClasses = {
            '0': 'm-no-images',
            '1': 'm-single-image',
            '2': 'm-no-controls'
        };

        removeClass(mainCarousel, 'm-no-images', 'm-single-image', 'm-no-controls');

        if (count < 3) {
            addClass(mainCarousel, galleryClasses[count]);
        }

        let cacheParam = `${cache === 'true' ? '&cache_img=true' : ''}`;
        let slideTpl = '';
        let slideInnerTpl = '';
        let linkTagOpen = '';
        let linkTagClose = '';
        let src = '';

        let updatedImages = imgs[imageType];
        // Only in this case we need to make img obj with common structure
        // PDP: { imageScene7: 'generidURL', srcset: '[{"breakpoint": "2x"}]' ...}
        // PDPSet: { url: 'fullURL', srcset: 'prepairedSrcSet' ... }
        if (imgs[imageType][0]?.imageScene7 !== undefined) {
            updatedImages = normalizeImagesObject(imgs[imageType]);
        }

        slideEls.forEach(slideEl => {
            slideEl.remove();
        });

        updatedImages.forEach((updatedImage, index) => {
            if (hasClass(carouselEl, 'js-swiper-modal-description')) {
                slideInnerTpl = updatedImage.title;
            } else {
                if (imageType === 'extralarge') {
                    linkTagOpen = `<a data-toggle="modal" href="#pdp-lightbox-modal-${productId}" data-target="#pdp-lightbox-modal-${productId}" data-index="${index}">`;
                    linkTagClose = `</a>`;
                    src = `${updatedImage.url}${cacheParam}`;
                } else if (imageType === 'extrasmall') {
                    src = `${updatedImage.url}${cacheParam}`;
                } else if (imageType === 'full') {
                    const { image, preset } = carouselEl.dataset;
                    src = `${updatedImage.url.replace(preset, image)}${cacheParam}`;
                }

                if (Object.keys(updatedImage.sources).length !== 0) {
                    const sourceTags = Object.entries(updatedImage.sources)
                        .map(([key, srcset]) => {
                            return `<source srcset="${srcset}" media="${updatedImage.breakpoints[key]}">`;
                        })
                        .join('');

                    slideInnerTpl = `<picture>
                        ${sourceTags}
                        <img
                        loading="lazy"
                        src="${src || updatedImage.url}"
                        alt="${updatedImage.alt} image number ${index + 1}"
                        title="${updatedImage.title}"
                        itemprop="image"/>
                    </picture>`;
                } else {
                    slideInnerTpl = `<img
                    srcset="${updatedImage.srcset}"
                    src="${src || updatedImage.url}"
                    class="d-block img-fluid w-100"
                    loading="lazy"
                    alt="${updatedImage.alt} image number ${index + 1}"
                    title="${updatedImage.title}" itemprop="image"
                    />`;
                }
            }

            slideTpl += `<div class="swiper-slide">${linkTagOpen}${slideInnerTpl}${linkTagClose}</div>`;
        });

        queryFirst('.swiper-wrapper', carouselEl).innerHTML = slideTpl;

        const baseVideoTemplate = queryFirst('.product-video-template');

        if (videoUrl) {
            baseVideoTemplate.innerHTML = '';
            // eslint-disable-next-line require-jsdoc, no-inner-declarations
            function addVideoComponent(container, poster, videoUrl) {
                const videoWrapper = document.createElement('div');
                videoWrapper.className = 'swiper-slide video-carousel-item';
                videoWrapper.setAttribute('role', 'option');
                videoWrapper.innerHTML = videoInnerContent(poster, videoUrl);

                container.appendChild(videoWrapper);
            }

            addVideoComponent(baseVideoTemplate, imgs.large[0].imageScene7 + imgs.large[0].url, videoUrl);

            const videoTemplate = baseVideoTemplate.cloneNode(true);
            const templateVideoElement = queryFirst('.swiper-slide video', videoTemplate);
            if (templateVideoElement) {
                addClass(templateVideoElement, 'cloned-video');

                if (hasClass(carouselEl, 'js-swiper-main')) {
                    queryFirst('.swiper-wrapper', carouselEl).appendChild(queryFirst('.swiper-slide', videoTemplate));
                    queryFirst('.swiper-slide video', carouselEl).load();
                }
            }

            baseVideoTemplate.innerHTML = '';
            initVideos(templateVideoElement.parentElement);
        }

        if (swiper) {
            if (swiper.params.loop) {
                swiper.loopCreate();
            }
            swiper.slideTo(0);
            swiper.updateProgress();
            swiper.updateSize();
            swiper.updateSlides();
            swiper.update();
        }
    });

    const addToWishlistIconPdp = queryFirst('.pdp-wish-list', productContainerEl);
    const earlyAccessPdpEl = queryFirst('.js-loyalty-badge', productContainerEl);
    const addToCartButtonPdp = queryFirst('.pdp-container .add-to-cart', productContainerEl);
    const pdpNonEarlyAccessLoginEl = queryFirst('.pdp-sign-in-create-account-section', productContainerEl);

    if (pdpNonEarlyAccessLoginEl) {
        const { isLoyaltyEnabled } = pdpNonEarlyAccessLoginEl.dataset;
        if (isLoyaltyEnabled === 'true' && !isEarlyAccess) {
            removeClass(pdpNonEarlyAccessLoginEl, hiddenClass);
        } else {
            addClass(pdpNonEarlyAccessLoginEl, hiddenClass);
        }
    }
    if (addToCartButtonPdp) {
        removeClass(addToCartButtonPdp, hiddenClass);
    }
    if (earlyAccessPdpEl) {
        addClass([earlyAccessPdpEl], hiddenClass);
    }
    removeClass(addToWishlistIconPdp, hiddenClass);
    if (isEarlyAccess) {
        const loyaltyEarlyAccessLockContainer = queryFirst('.primary-images .loyalty-early-access-lock-container', productContainerEl);
        if (loyaltyEarlyAccessLockContainer) {
            addClass(loyaltyEarlyAccessLockContainer, hiddenClass);
        }
    }
    handleEarlyAccessCta(productContainerEl, isEarlyAccess);

    const firstImageElement = queryFirst('.swiper-wrapper .swiper-slide img.img-fluid', productContainerEl);
    if (firstImageElement) {
        firstImageElement.addEventListener('load', () => {
            $body.trigger('product:imageLoad', {
                container: productContainerEl
            });
        });
    }
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    const { variationAttributes, productType, readyToOrder, bonusVariationAtrributes, isFinalSale, isDirectlyPurchasable, earlyAccess } = response.product;
    const saleMessageEl = queryFirst('.on-sale-message', $productContainer[0]);
    if (saleMessageEl) {
        if (isFinalSale) {
            removeClass(saleMessageEl, hiddenClass);
        } else {
            addClass(saleMessageEl, hiddenClass);
        }
    }

    if (isChoiceOfBonusProducts) {
        if (bonusVariationAtrributes) {
            updateAttrs(bonusVariationAtrributes, $productContainer, response.resources);
        }
        if (productType === 'variant') {
            $productContainer.data('ready-to-order', readyToOrder);
        }
    } else if (variationAttributes) {
        updateAttrs(variationAttributes, $productContainer, response.resources);
        if (isDirectlyPurchasable === false) {
            const sizeElements = queryAll('.size-btn', $productContainer[0]);
            addClass(sizeElements, notAvailable);
        }
    }

    const productContainer = $productContainer[0];
    if (isChoiceOfBonusProducts) {
        // LP customization changes start
        const { alt, url, srcset } = response.product.images.extrasmall[0] || {};
        const productImageElement = queryFirst('.product-image', productContainer);
        productImageElement.setAttribute('src', url);
        productImageElement.setAttribute('srcset', srcset);
        productImageElement.setAttribute('alt', alt);
        // LP customization changes end
    } else {
        createCarousel(response.product.images, $productContainer, earlyAccess && earlyAccess.isEarlyAccessProduct, response.product.id);
    }

    // Updated logic here
    const swatchNameElement = queryFirst('.selected-swatch-name', productContainer);
    const selectedSwatch = queryFirst('.swatch-circle.selected', productContainer);
    const selectedSwatchElement = queryFirst('.selected-swatch', productContainer);
    let selectedSwatchName = '';

    if (selectedSwatch) {
        if (selectedSwatchElement) {
            const { colorLabel } = selectedSwatchElement.dataset;
            if (colorLabel) {
                selectedSwatchName += colorLabel;
            }
        }

        const { swatchName } = selectedSwatch.dataset;

        selectedSwatchName += swatchName;
    }

    if (swatchNameElement) {
        swatchNameElement.textContent = selectedSwatchName;
    } else {
        const swatchHtml = formatMessage(swatchNameTemplate.swatchNameHtml, selectedSwatchName);
        if (selectedSwatchElement) {
            selectedSwatchElement.innerHTML = swatchHtml;
        }
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        let $priceSelector = $('.prices .price', $productContainer);
        if (!$priceSelector.length) $priceSelector = $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    const promotionElement = queryFirst('.promotions', productContainer);
    if (promotionElement) {
        promotionElement.innerHTML = response.product.promotionsHtml;
    }

    const promotionsPopover = queryFirst('.promotions-info-popover .promotions-info-popover-content');
    $('.custom-set-detail').find('.promotions-info').popover({
        trigger: 'click',
        placement: 'bottom',
        html: true,
        content: promotionsPopover
    });
    $('[data-toggle="popover"]')
        .off('shown.bs.popover')
        .on('shown.bs.popover', function (e) {
            var target = e.target;
            $(document).one('click', function (clickEvent) {
                var clicked = clickEvent.target;
                if (clicked !== target && clicked.parentElement !== target) {
                    $(target).trigger('click');
                }
            });
        });

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else if ($productContainer.closest('#productSetModal').length > 0) {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart-global, button.update-cart-product-global')
            .trigger('product:updateAddToCart', {
                product: response.product,
                $productContainer: $productContainer
            })
            .trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!$productContainer.hasClass('.bonus-product-item')) {
        var optionsHtml = quantities
            .map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' + selected + '>' + quantity.value + '</option>';
            })
            .join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {Object} selectedSizeElement - DOM element for selected size button
 */
async function attributeSelect(selectedValueUrl, $productContainer, selectedSizeElement) {
    if (selectedValueUrl) {
        $body.trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });

        // Updated logic here
        const $choiceOfBonusProductEl = $productContainer.closest('.choice-of-bonus-product');
        if ($choiceOfBonusProductEl.length) {
            $productContainer.spinner().start();
        }

        try {
            const res = await fetch(selectedValueUrl);
            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            handleVariantResponse(data, $productContainer);
            updateOptions(data.product.options, $productContainer);
            updateQuantities(data.product.quantities, $productContainer);
            $body.trigger('product:afterAttributeSelect', {
                data: data,
                container: $productContainer,
                selectedSizeElement
            });
            if ($choiceOfBonusProductEl.length) {
                $choiceOfBonusProductEl.trigger('bonus:afterAttributeSelect');
            }

            $.spinner().stop();
        } catch (err) {
            console.error(`base error: ${err.message}`);
            $.spinner().stop();
        }
    }
}

/**
 * add size parameter to the Product-Variation call if it is 'set-items' page
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @returns {string|boolean} url with size parameter
 */
function addSizeParamForSetItems(selectedValueUrl, $productContainer) {
    const selectedSizeElement = queryFirst('.js-set-items .size-btn.selected', $productContainer[0]);
    if (!selectedSizeElement || !selectedValueUrl) return false;

    const masterId = $productContainer[0]?.dataset.masterid;
    const selectedSizeValue = selectedSizeElement?.dataset.attrValue;
    if (!masterId || !selectedSizeValue) return false;

    const isSizeExist = selectedValueUrl.indexOf('_size') !== -1;
    const sizeParamName = isSizeExist ? '_size' : `dwvar_${masterId}_size`;
    return setUrlParamValue(selectedValueUrl, sizeParamName, selectedSizeValue, isSizeExist);
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function selectColorAttribute(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $body.trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });

        // Updated logic here
        const $choiceOfBonusProductEl = $productContainer.closest('.choice-of-bonus-product');
        if ($choiceOfBonusProductEl.length) {
            $productContainer.spinner().start();
        }

        // add 'size' parameter if it is 'set-items' page
        selectedValueUrl = addSizeParamForSetItems(selectedValueUrl, $productContainer) || selectedValueUrl;

        getJSON(
            selectedValueUrl,
            'GET',
            null,
            data => {
                const { options, quantities, uuid } = data.product;
                handleVariantResponse(data, $productContainer);
                updateOptions(options, $productContainer);
                updateQuantities(quantities, $productContainer, uuid);
                const selectedSizeElement = queryFirst('.size-btn.selected', $productContainer[0]);
                $body.trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer,
                    selectedSizeElement
                });
                if ($choiceOfBonusProductEl.length) {
                    const sizeEl = queryFirst('.select-size', $choiceOfBonusProductEl[0]);
                    if (sizeEl) sizeEl.selectedIndex = 0;
                    $choiceOfBonusProductEl.trigger('bonus:afterAttributeSelect');
                }
                const swatchContainer = queryFirst('.color-container', $productContainer[0]);
                const assistiveElements = queryAll('.selected-assistive-text', swatchContainer);
                const selectedSwatchElement = queryFirst('.swatch-circle.selected', $productContainer[0]);
                const assistiveTextElement = queryFirst('.selected-assistive-text', selectedSwatchElement.closest('.color-list'));
                assistiveElements.forEach(eachElement => {
                    eachElement.textContent = '';
                });
                assistiveTextElement.textContent = assistiveTextElement.dataset.selectedText;
            },
            () => {
                $.spinner().stop();
            }
        );
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} data - Shop the print data
 * @param {Object} productContainer - DOM container for a given product
 */
function updateShopThePrint(data, productContainer) {
    const shopThePrintElement = queryFirst('.shop-the-print', productContainer);
    if (shopThePrintElement) {
        if (data) {
            shopThePrintElement.outerHTML = data;
        } else {
            shopThePrintElement.outerHTML = '<div class="shop-the-print"></div>';
        }
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {int} price - product sale price
 * @param {int} standardPrice - product standard price
 * @param {Object} productContainer - DOM container for a given product
 * @param {int} percentOff - percent off value
 */
function updateProductPrice(price, standardPrice, productContainer, percentOff) {
    const priceTemplate = document.getElementById('price-template').cloneNode(true);
    const priceSection = queryFirst('.prices .price', productContainer);
    const buybarPriceSection = queryFirst('.pdp-buybar-price .price');
    const priceContainer = queryFirst('.price-section', priceTemplate);
    const strikeThroughContainer = queryFirst('.strike-through-section', priceContainer);
    const priceElement = queryFirst('.strike-through-price', strikeThroughContainer);
    const salesContainer = queryFirst('.sales', priceContainer);
    const salesPriceElement = queryFirst('.price-value', salesContainer);
    const percentOffContainer = percentOff && queryFirst('.percent-off', priceContainer);
    let fixedPrice = price;
    let fixedStandardPrice = standardPrice;

    // Fix decimal places for non-whole-number prices
    if (price % 1 !== 0) {
        fixedPrice = price.toFixed(2);
    }
    if (standardPrice % 1 !== 0) {
        fixedStandardPrice = standardPrice.toFixed(2);
    }

    removeClass(strikeThroughContainer, hiddenClass);
    priceElement.textContent = `$${fixedStandardPrice}`;
    salesPriceElement.textContent = `$${fixedPrice}`;
    if (price === standardPrice) {
        addClass(strikeThroughContainer, hiddenClass);
    }

    // add/change percent off value on PDP
    const template = percentOffContainer && percentOffContainer.dataset.template;
    if (percentOffContainer && percentOff && template) {
        percentOffContainer.textContent = template.replace('{0}', percentOff);
        removeClass(percentOffContainer.parentElement, hiddenClass);
    } else if (percentOffContainer) {
        addClass(percentOffContainer.parentElement, hiddenClass);
    }

    priceSection.innerHTML = priceTemplate.innerHTML;
    buybarPriceSection.innerHTML = priceTemplate.innerHTML;
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} sizes - sizes object
 * @param {Object} productContainer - DOM container for a given product
 * @param {Object} variantsList - Variations list data
 */
function updateSizeElements(sizes, productContainer, variantsList) {
    const masterId = productContainer.dataset.masterid;
    const isQuickView = hasClass(productContainer, 'cached-quick-view');
    const { variants } = isQuickView ? window.quickviewProductInfo.productInventory : window.productInventory[masterId];
    const sizeContainer = queryFirst('.size-container', productContainer);

    if (!sizeContainer) return;

    const { selectedText, outOfStock, label, describedby, attrValue } = sizeContainer.dataset;
    let sizeButtonHtml = '';

    variantsList.forEach((eachVariant, index) => {
        const eachSize = sizes[eachVariant];
        const sizeElement = queryFirst('[data-attr="size"] [data-attr-value="' + eachVariant + '"]', productContainer);
        const sizeId = eachSize.ID;
        const { forceOutOfStock } = eachSize;
        const inventoryData = variants[sizeId];
        const variantData = {
            value: eachVariant,
            forceOutOfStock,
            selected: sizeElement ? hasClass(sizeElement, 'selected') : false,
            inStock: inventoryData.availabilityStatus !== NOT_AVAILABLE_STATUS && !inventoryData.isBackOrder,
            sizeId: sizeId
        };
        const ariaLabelValue = label + ' ' + attrValue + ' ' + eachVariant;
        sizeButtonHtml += sizeButtonTemplate(variantData, ariaLabelValue, describedby + eachVariant + index, selectedText, outOfStock, variantsList.length);
    });
    remove(queryAll('.size-list', sizeContainer));
    sizeButtonHtml += sizeContainer.innerHTML;
    sizeContainer.innerHTML = sizeButtonHtml;
}

/**
 * Function to update Ways to wear content based on the response
 * @param {string} groupId - selected variation group id
 * @param {string} variationUrl - url to fetch the content
 * @param {HTMLElement} waysToWearContainer - container with recommendations
 */
function updateContentModules(groupId, variationUrl, waysToWearContainer, entry) {
    if (isContentModulesFetched || !entry.isIntersecting) {
        return;
    }

    $(waysToWearContainer).spinner().start();
    $.ajax({
        url: variationUrl,
        method: 'post',
        data: {
            variationGroup: groupId
        }
    })
        .done(function (response) {
            if (response) {
                waysToWearContainer.innerHTML = response;
            } else {
                waysToWearContainer.innerHTML = '';
            }
            $body.trigger('search:updateProducts');
        })
        .always(function () {
            $(waysToWearContainer).spinner().stop();
            isContentModulesFetched = true;
        });

    destroyContentModulesIntersectionObserver(waysToWearContainer);
}

/**
 * Fetch Ways to Wear components only when user scroll near their position
 * @param {string} groupId - selected variation group id
 * @param {string} variationUrl - url to fetch the content
 */
function dynamicallyFetchContentModules(groupId, variationUrl) {
    const waysToWearContainer = queryFirst('.ways-to-wear');
    if (!waysToWearContainer) {
        return;
    }

    observer = new IntersectionObserver(([entry]) => updateContentModules(groupId, variationUrl, waysToWearContainer, entry), { rootMargin: '500px', threshold: 0 });
    observer.observe(waysToWearContainer);
}

/**
 * Destroy Intersection Observer of Ways to Wear components since it needed only once
 * @param {HTMLElement} waysToWearContainer - container with recommendations
 */
function destroyContentModulesIntersectionObserver(waysToWearContainer) {
    if (observer instanceof IntersectionObserver) {
        observer.unobserve(waysToWearContainer);
        observer = null;
    }
}

/**
 * updates image urls and presets by appending the scene7 url
 * @param {Object} images - images array to be updated
 * @returns {Object} - updated images array
 */
function normalizeImagesObject(images) {
    return images.map(imageObj => {
        const srcsetPresets = JSON.parse(imageObj.srcset);
        const mediaSources = JSON.parse(imageObj.sources);
        const breakpoints = JSON.parse(imageObj.breakpoints);
        let srcsetString = [];
        let sources = {};

        mediaSources.forEach(source => {
            Object.keys(source).forEach(breakpointKey => {
                if (!breakpoints[breakpointKey]) {
                    return;
                }
                const resolutions = source[breakpointKey];
                if (!resolutions || !Array.isArray(resolutions)) {
                    return;
                }
                const sourceString = resolutions
                    .map(resolution => {
                        const [scale, value] = Object.entries(resolution)[0];
                        return `${imageObj.imageScene7}?${value}${scale === '1x' ? '' : ` ${scale}`}`;
                    })
                    .join(', ');

                sources[breakpointKey] = sourceString;
            });
        });

        srcsetPresets.forEach(function (source) {
            if (Object.prototype.hasOwnProperty.call(source, 'all')) {
                source.all.forEach(function (item) {
                    Object.entries(item).forEach(function ([scale, value]) {
                        srcsetString.push(`${imageObj.imageScene7}?${value}${scale === '1x' ? '' : ` ${scale}`}`);
                    });
                });
            }
        });

        return {
            alt: imageObj.alt,
            url: imageObj.imageScene7 + imageObj.url,
            srcset: srcsetString.join(', '),
            title: imageObj.title,
            sources: sources,
            breakpoints
        };
    });
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Object} currentElement - selected color element
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateProductDetails(currentElement, $productContainer) {
    const { promotionMessageTmpl } = require('../templates').productDetail;
    const { initPopoverPromotions, initPopoverClose } = require('../popover');
    const elementData = currentElement.dataset;
    const variantGroupId = elementData.attrValue;
    const productContainer = $productContainer[0];
    const swatchNameElement = queryFirst('.selected-swatch-name', productContainer);
    const swatchContainer = queryFirst('.color-container', productContainer);
    const assistiveElements = queryAll('.selected-assistive-text', swatchContainer);
    const assistiveTextElement = queryFirst('.selected-assistive-text', currentElement.closest('.color-list'));
    const monogramBtn = queryFirst('.pdp-monogram-btn', productContainer);
    const masterId = productContainer.dataset.masterid;
    const productInfo = window.productInfo[masterId];
    const { groupId, sizes, images, shopThePrint, price, standardPrice, percentOff, videoUrl, variantsList, mgFlag, mgLocs, hasWaysToWear, isFinalSale, isDirectlyPurchasable, earlyAccess, promotions } = productInfo.variants[variantGroupId];
    productContainer.dataset.wishlistId = groupId;
    setWishlistProductId(groupId, productContainer);
    const colorElements = queryAll('.color-attribute .swatch-circle', productContainer);
    const { attrDisplayvalue, customPageTitle, pageTitle, includeLillyColor, lillyColorName, customPageDescription, pageDescription } = elementData;
    const titleElement = queryFirst('.meta-page-title');
    const descriptionElement = queryFirst('.meta-page-description');
    const waysToWearEl = queryFirst('.ways-to-wear', productContainer);
    const variationGridUrl = waysToWearEl.dataset.variationGridUrl;
    const earlyAccessBadgeTextEl = queryFirst('.js-loyalty-badge .js-loyalty-badge-text', productContainer);
    const earlyAccessText = earlyAccessBadgeTextEl.textContent;
    // update Marketing Analytics trackWishlist event
    let marketingCloudPageAnalytics = [{ item: groupId }];
    trackPageView(marketingCloudPageAnalytics);
    // Updated logic here
    if (swatchNameElement) {
        const selectedSwatchElement = queryFirst('.selected-swatch', productContainer);
        const colorlabel = selectedSwatchElement.dataset.colorLabel;
        swatchNameElement.textContent = colorlabel + attrDisplayvalue;
    } else {
        const swatchHtml = formatMessage(swatchNameTemplate.swatchNameHtml, attrDisplayvalue);
        queryFirst('.selected-swatch', productContainer).innerHTML = swatchHtml;
    }

    if (customPageTitle && titleElement) {
        titleElement.textContent = customPageTitle;
    } else if (pageTitle && titleElement) {
        titleElement.textContent = pageTitle;
    }
    const longDescriptionElement = queryFirst('.long-description-container .long-description', productContainer);
    if (longDescriptionElement) {
        const { longDescription } = longDescriptionElement.dataset;
        let description;
        if (includeLillyColor !== 'false' && lillyColorName) {
            description = tokenize(longDescription, { lillyColor: lillyColorName }, '{{', '}}');
        } else {
            description = tokenize(longDescription, { lillyColor: '' }, '<li>{{', '}}</li>');
        }
        longDescriptionElement.innerHTML = description;
    }
    if (customPageDescription && descriptionElement) {
        descriptionElement.content = customPageDescription;
    } else if (pageDescription && descriptionElement) {
        descriptionElement.content = pageDescription;
    }

    removeClass(colorElements, 'selected');
    addClass(queryFirst('.swatch-circle', currentElement), 'selected');
    queryFirst('.product-id', productContainer).textContent = masterId;
    setAttribute(queryFirst('.modal.js-pdp-lightbox-modal', productContainer), 'id', 'pdp-lightbox-modal-' + currentElement.dataset.attrValue);

    assistiveElements.forEach(eachElement => {
        eachElement.textContent = '';
    });

    assistiveTextElement.textContent = assistiveTextElement.dataset.selectedText;

    if (mgFlag && mgLocs && mgLocs.length > 0) {
        removeClass(monogramBtn, hiddenClass);
    } else {
        addClass(monogramBtn, hiddenClass);
    }

    if (hasWaysToWear) {
        isContentModulesFetched = false;
        dynamicallyFetchContentModules(groupId, variationGridUrl);
    } else {
        waysToWearEl.innerHTML = '';
    }
    updateSizeElements(sizes, productContainer, variantsList);

    $body.trigger('product:updateStoreInventory', {
        productContainer
    });
    if (!isDirectlyPurchasable) {
        const sizeElements = queryAll('.size-btn', productContainer);
        addClass(sizeElements, notAvailable);
    }
    updateShopThePrint(shopThePrint, productContainer);
    updateProductPrice(price, standardPrice, productContainer, percentOff);

    const productFinalSaleMessage = queryFirst('.on-sale-message', productContainer);
    const productFreeReturnsMessage = queryFirst('.pdp-header-promo');
    const percentOffContainer = percentOff && queryFirst('.percent-off', productContainer);

    if (productFinalSaleMessage) {
        if (isFinalSale) {
            removeClass(productFinalSaleMessage, hiddenClass);
            addClass(productFreeReturnsMessage, 'd-none');
            if (percentOff) addClass(percentOffContainer.parentElement, hiddenClass);
        } else {
            addClass(productFinalSaleMessage, hiddenClass);
            removeClass(productFreeReturnsMessage, 'd-none');
        }
    }

    const ssSizeElement = queryFirst('.size-btn.selected', productContainer);

    if (!ssSizeElement) {
        $('.availability-msg', $productContainer).empty();
        removeClass(queryFirst('.pdp-attribute-name', productContainer), 'm-error');
        // update promotion messages display for selected color (size not selected yet here)
        let promoBlockMsg = queryFirst('.promo-block-messages', productContainer);
        if (promoBlockMsg) {
            const promoWithSheet = queryFirst('.js-promotions-with-sheet', productContainer);
            if (promoWithSheet) promoBlockMsg = promoWithSheet;
            promoBlockMsg.innerHTML = promotions
                ? promotions
                      .filter(promotion => promotion.calloutMsg)
                      .map((promotion, promotionIdx) => {
                          return promotionMessageTmpl(promotion.calloutMsg, promoWithSheet ? promotion.detailsFromAsset : promotion.details, promotionIdx);
                      })
                      .join('')
                : '';
        }
        // pick new promotion popover elements
        initPopoverPromotions();
        initPopoverClose();
    }

    const isEarlyAccessItem = isEarlyAccessElement(earlyAccess);

    createCarousel(images, $productContainer, isEarlyAccessItem, variantGroupId, videoUrl);
    $body.trigger('product:afterAttributeChange', {
        container: $productContainer,
        variantGroupId,
        groupId
    });
    $body.trigger('product:handleImageZoom');
    const wishlistButton = queryFirst('.add-to-wish-list', productContainer);
    if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
        removeClass(wishlistButton, 'added-to-wish-list');
        wishlistButton.disabled = false;
        const assistiveText = wishlistButton.getAttribute('data-assistive-text');
        wishlistButton.setAttribute('aria-label', assistiveText);
    }
    // Updated logic here
    const swatchParam = `?dwvar_${masterId}_color`;
    const queryParams = location.search;
    let UpdatedParams = `${swatchParam}=${variantGroupId}`;
    if (queryParams) {
        UpdatedParams = queryParams
            .split('&')
            .map(eachParam => {
                if (eachParam.indexOf(swatchParam) > -1) {
                    return `${swatchParam}=${variantGroupId}`;
                }
                return eachParam;
            })
            .join('&');
    }
    history.replaceState({ variantGroupId }, document.title, UpdatedParams);
}

/**
 * updates the quick view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Object} currentElement - selected color element
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateQuickViewDetails(currentElement, $productContainer) {
    const elementData = currentElement.dataset;
    const variantGroupId = elementData.attrValue;
    const productContainer = $productContainer[0];
    const swatchNameElement = queryFirst('.selected-swatch-name', productContainer);
    const swatchContainer = queryFirst('.color-container', productContainer);
    const assistiveElements = queryAll('.selected-assistive-text', swatchContainer);
    const assistiveTextElement = queryFirst('.selected-assistive-text', currentElement.closest('.color-list'));
    const quickViewInfo = window.quickviewProductInfo;
    const { productInfo } = quickViewInfo;
    const { groupId, sizes, images, price, standardPrice, variantsList, isDirectlyPurchasable, pdpBreadCrumbs, earlyAccess } = productInfo.variants[variantGroupId];
    productContainer.dataset.wishlistId = groupId;
    setWishlistProductId(groupId, productContainer);
    handleEarlyAccessCta(productContainer, earlyAccess && earlyAccess.isEarlyAccessProduct);
    const colorElements = queryAll('.color-attribute .swatch-circle', productContainer);
    const { attrDisplayvalue } = elementData;
    // Updated logic here
    if (swatchNameElement) {
        swatchNameElement.textContent = attrDisplayvalue;
    } else {
        const swatchHtml = formatMessage(swatchNameTemplate.swatchNameHtml, attrDisplayvalue);
        queryFirst('.selected-swatch', productContainer).innerHTML = swatchHtml;
    }
    removeClass(colorElements, 'selected');
    addClass(queryFirst('.swatch-circle', currentElement), 'selected');

    assistiveElements.forEach(eachElement => {
        eachElement.textContent = '';
    });

    assistiveTextElement.textContent = assistiveTextElement.dataset.selectedText;

    updateSizeElements(sizes, productContainer, variantsList);
    if (!isDirectlyPurchasable) {
        const sizeElements = queryAll('.size-btn', productContainer);
        addClass(sizeElements, notAvailable);
    }

    updateProductPrice(price, standardPrice, productContainer);
    const ssSizeElement = queryFirst('.size-btn.selected', productContainer);

    if (!ssSizeElement) {
        $('.availability-msg', $productContainer).empty();
        removeClass(queryFirst('.pdp-attribute-name', productContainer), 'm-error');
    }

    const isEarlyAccessItem = isEarlyAccessElement(earlyAccess);
    createCarousel(images, $productContainer, isEarlyAccessItem, variantGroupId);
    $body.trigger('product:quickViewAttributeChange', {
        container: $productContainer,
        variantGroupId,
        groupId,
        productContainer,
        monetateData: {
            pdpBreadCrumbs
        }
    });
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choose-gwp-sheet-gift');
    const selectedProducts = $html.find('.selected-products-container');
    const footer = $html.find('.modal-footer');

    return {
        body,
        footer,
        selectedProducts
    };
}

/**
 * Function to update GWP elements on swatch or size change
 */
function updateGwpElements() {
    $('.choice-of-bonus-product').on('bonus:afterAttributeSelect', function () {
        const productDialog = queryFirst('.choose-bonus-product-dialog');
        const accordionContainer = this.closest('.choose-gwp-sheet-gift', productDialog);
        const bonusAccordionElements = queryAll('.choose-gwp-sheet-gift', productDialog);
        const addToCart = queryFirst('.add-bonus-products', productDialog);
        const sizeEl = queryFirst('.size-selection', this);
        const selectedSize = queryFirst('.size-btn.selected', sizeEl);
        const isOneSize = queryFirst('.selected-size', sizeEl);
        const isSizeSelected = !!isOneSize || (sizeEl ? !!selectedSize : true);
        const selectedSwatch = queryFirst('.color-attribute .swatch-circle.selected', this);
        const isSelectedSizeUnavailable = hasClass(selectedSize, notAvailable);

        if (isSelectedSizeUnavailable) {
            addClass(sizeEl, notAvailable);
        } else {
            removeClass(sizeEl, notAvailable);
        }

        let isEnabled = true;
        const maxItems = parseInt(accordionContainer.dataset.maxItems, 10);
        const selectedItemsCount = queryAll('.select-bonus-product:checked', accordionContainer).length;

        if (selectedItemsCount < maxItems) {
            isEnabled = false;
        }

        if (isEnabled && isSizeSelected && !!selectedSwatch && !isSelectedSizeUnavailable) {
            addClass(accordionContainer, readyClass);
        } else {
            isEnabled = false;
            removeClass(accordionContainer, readyClass);
        }

        const validProducts = queryAll('.choose-gwp-sheet-gift.ready-to-add', productDialog);

        addToCart.disabled = !isEnabled || hasClass(addToCart, notAvailable) || bonusAccordionElements.length !== validProducts.length;
    });
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
async function chooseBonusProducts(data) {
    const $spinner = $('.choose-bonus-product-dialog').spinner();
    $spinner.start();

    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    const { maxBonusItems, addToCartUrl, uuid, pliUUID, pageSize, showProductsUrlRuleBased, bonusChoiceRuleBased, bonusDiscountLineItems } = data;
    $('.choose-bonus-product-dialog').attr({
        'data-total-qty': maxBonusItems,
        'data-addToCartUrl': addToCartUrl,
        'data-UUID': uuid,
        'data-pliUUID': pliUUID,
        'data-pageStart': 0,
        'data-pageSize': pageSize,
        'data-moreURL': showProductsUrlRuleBased,
        'data-bonusChoiceRuleBased': bonusChoiceRuleBased,
        'data-bonus-items': JSON.stringify(bonusDiscountLineItems)
    });

    const sheetHeaderText = queryFirst('.choose-gwp-sheet-subtitle', gwpDialog);
    if (sheetHeaderText) sheetHeaderText.textContent = data.labels.selectprods;

    try {
        const res = await fetch(bonusUrl);
        if (!res.ok) throw new Error('response was not OK');
        const data = await res.json();

        const parsedHtml = parseHtml(data.renderedTemplate);
        const enterMessage = queryFirst('.enter-message', gwpDialog);

        if (enterMessage) enterMessage.textContent = data.enterDialogMessage;
        $gwpDialog.find('.choose-gwp-sheet-select').html(parsedHtml.body);
        $gwpDialog.find('.choose-gwp-sheet-footer-submit, .selected-products-container').remove();
        $gwpDialog.find('.choose-gwp-sheet-footer').append(parsedHtml.footer.removeClass('modal-footer').addClass('choose-gwp-sheet-footer-submit'));
        $(parsedHtml.selectedProducts).insertAfter($gwpDialog.find('.choose-gwp-sheet-subtitle'));
        // LP custom changes start
        const { selectedBonusProducts } = data;
        if ((selectedBonusProducts || []).length) {
            const modalDialog = queryFirst('.choose-bonus-product-dialog');
            let selectedProductsCount = 0;
            selectedBonusProducts.forEach(eachProductList => {
                if (eachProductList.length) {
                    const bonusAccordionContainer = queryFirst(`.choose-gwp-sheet-gift[data-uuid="${eachProductList[0].uuid}"]`, modalDialog);
                    const maxCount = parseInt(bonusAccordionContainer.dataset.maxItems, 10);
                    const productListLength = eachProductList.length;
                    if (maxCount === productListLength) {
                        addClass(bonusAccordionContainer, 'selected');
                        addClass(bonusAccordionContainer, readyClass);
                    }
                    const bonusItemCount = queryFirst('.bonus-item-count span', bonusAccordionContainer);
                    if (bonusItemCount) bonusItemCount.textContent = productListLength;
                    selectedProductsCount += productListLength;
                    eachProductList.forEach(eachProduct => {
                        const selectedProduct = queryFirst(`.choice-of-bonus-product[data-pid="${eachProduct.pid}"][data-uuid="${eachProduct.uuid}"]`);
                        const selectBonusCheckbox = queryFirst('.select-bonus-product', selectedProduct);

                        if (selectBonusCheckbox) {
                            selectBonusCheckbox.checked = true;
                        }

                        addClass(selectedProduct, selectedProductClass);
                    });
                }
            });
            const addToCart = queryFirst('.add-bonus-products', modalDialog);
            addToCart.disabled = maxBonusItems !== selectedProductsCount;
        }

        // Default all size dropdowns that aren't selected products
        $('.bonus-product-item:not(.selected-product) .select-size').each(function () {
            this.selectedIndex = 0;
        });

        updateGwpElements();
        // LP custom changes end
        $gwpDialog.modal('show');
        $spinner().stop();
    } catch (err) {
        console.error(`choose gwp error: ${err.message}`);
        $spinner.stop();
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    if (!response.error) {
        return;
    }

    if (response.displayModal) {
        let addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');

        if (!addToCartWarningDialog) {
            const isOnCartPage = window.location.pathname === '/cart/';
            const fragment = renderFragment(
                dialogTemplate({
                    buttons: isOnCartPage ? [{ text: 'OK', primary: true }] : [{ text: 'Close' }, { text: 'Review Tote', primary: true, link: response.redirectLink }],
                    modalContentHeading: response.messageHeading,
                    modalContentBody: isOnCartPage ? response.messageBodyOnCart : response.messageBodyOffCart,
                    id: 'add-to-cart-warning-dialog'
                })
            );

            document.body.appendChild(fragment);
            addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');
        }

        $(addToCartWarningDialog).modal('show');
    } else {
        var messageType = response.error ? 'alert-danger' : 'alert-success';
        // show add to cart toast
        $('.add-to-cart-messages').remove();
        if ($('.add-to-cart-messages').length === 0) {
            $('.main-header').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append('<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' + response.message + '</div>');

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        })
        .toArray();

    return JSON.stringify(options);
}

export default {
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        }
    },
    attributeSelect,
    updateProductDetails,
    updateImageDetails: normalizeImagesObject,
    focusChooseBonusProductModal: function () {
        $gwpDialog.on('shown.bs.modal', () => {
            $gwpDialog.siblings().attr('aria-hidden', 'true');
            $gwpDialog.attr('aria-hidden', 'false');
            $gwpDialog.find('.close').trigger('focus');

            const sheet = queryFirst('.choose-bonus-product-dialog', gwpDialog);
            sheet?.setAttribute('aria-hidden', 'false');
        });
    },

    onClosingChooseBonusProductModal: function () {
        $gwpDialog.on('hidden.bs.modal', () => {
            $gwpDialog.siblings().attr('aria-hidden', 'false');

            const sheet = queryFirst('.choose-bonus-product-dialog', gwpDialog);
            sheet?.setAttribute('aria-hidden', 'false');
            removeClass(sheet, 'selected');
        });
    },

    trapChooseBonusProductModalFocus: function () {
        $body.on('keydown', '#chooseBonusProductModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#chooseBonusProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.add-bonus-products'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },

    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] button.color-attribute', function (e) {
            e.preventDefault();
            const productDetailContainer = queryFirst(PRODUCT_DETAIL_CONTAINER_SELECTOR);

            if ($(this).attr('disabled') || hasClass(queryFirst('.swatch-circle', this), 'selected')) {
                return;
            }
            var $productContainer = $(this).closest('.set-items .product-detail, .choose-bonus-product-modal .product-detail, .gift-card-main, .product-quickview');
            if ($productContainer.hasClass('cached-quick-view')) {
                updateQuickViewDetails(this, $productContainer);
            } else if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
                updateProductDetails(this, $productContainer);
            } else {
                selectColorAttribute($(this).attr('data-url'), $productContainer);
            }
            $body.trigger('swatchChangeEvent', this);
            $body.trigger('search:updateProducts');
            updateVisibilityOfLowInventoryMsg(productDetailContainer);
        });
    },

    renderSizeElements: function () {
        const swatchEls = queryAll('.pdp-container:not(.gift-card-main) button.color-attribute .swatch-circle.selected, .product-set-item button.color-attribute .swatch-circle.selected');

        swatchEls.forEach(swatchEl => {
            if (swatchEl) {
                const productContainer = swatchEl.closest('.pdp-container, .product-set-item');
                const selectedSizeEl = queryFirst('.size-btn.selected', productContainer);

                if (!selectedSizeEl) {
                    const selectedSwtachBtn = swatchEl.parentElement;
                    const variantGroupId = selectedSwtachBtn.dataset.attrValue;
                    const masterId = productContainer.dataset.masterid;
                    const productInfo = window.productInfo[masterId];
                    const { sizes, variantsList, isDirectlyPurchasable, wishlistDisabled, isFinalSale } = productInfo.variants[variantGroupId];

                    updateSizeElements(sizes, productContainer, variantsList);
                    const sizeElements = queryAll('.size-btn', productContainer);
                    if (!isDirectlyPurchasable) {
                        addClass(sizeElements, notAvailable);
                    }
                    if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
                        showStrikeHearts(productContainer);
                    }

                    // select size if it is 'one-size' product AND 'set-items' page
                    if (sizeElements && sizeElements.length === 1 && hasClass(productContainer, 'js-product-set-item')) {
                        sizeElements[0].click();
                    }
                }

                let savedStoreId = getNestedValue(window, 'lillyUtils.shippingPreferencesConfig.storeId') || EMPTY_STRING;
                let preferredStoreId = getNestedValue(window, 'lillyUtils.shippingPreferencesConfig.preferredStoreId') || EMPTY_STRING;
                const storeId = savedStoreId && savedStoreId !== 'null' ? savedStoreId : preferredStoreId;

                $body.trigger('product:updateStoreInventory', {
                    productContainer: productContainer,
                    storeId
                });
            }
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            if (!e.target.closest('.fp-root')) {
                e.preventDefault();
                var $productContainer = $(this).closest('.set-item');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.product-detail');
                }
                attributeSelect(e.currentTarget.value, $productContainer);
            }
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
            }
        });
    },

    addToCart: () => {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', async e => {
            // Elements
            const { target } = e;
            const $target = $(target);
            const productContainer = target.closest('.product-detail');
            const $productContainer = $(productContainer);
            const storeLocatorContainer = queryFirst('.pickup-in-store-content', productContainer);
            const ispuAddToCart = queryFirst('.add-to-cart', storeLocatorContainer);

            if (ispuAddToCart) {
                ispuAddToCart.disabled = true;
            }

            const pdpSetData = {};
            let isPDPSet = false;

            // PDP Set - add all to cart button
            if (hasClass(target, 'add-to-cart-global')) {
                isPDPSet = true;
                const setPids = [];

                const setModal = target.closest('.custom-set-detail-modal');
                // all visible set products -- "removed" set products are not included (removed set products do not have the custom-set-product class)
                const modalProducts = queryAll('.custom-set-items.set-items .custom-set-product', setModal);

                // set products which have a selected size
                const sizeSelectedProducts = modalProducts.filter(modalProduct => {
                    toggleSelectSizeInfo(modalProduct);
                    const sizeSelected = queryFirst('.size-btn.selected', modalProduct);

                    if (sizeSelected) {
                        // collect some data for the ATC call while we're looping anyway
                        setPids.push({
                            pid: queryFirst('.product-id', modalProduct)?.textContent,
                            options: getOptions($(modalProduct))
                        });
                    }

                    return !!sizeSelected;
                });

                // exit if not all sizes are selected
                if (sizeSelectedProducts.length !== modalProducts.length) {
                    return;
                }

                Object.assign(pdpSetData, {
                    pidsObj: JSON.stringify(setPids)
                });
            } else {
                // Not PDP Set -- Check if size is selected on single product PDP
                toggleSelectSizeInfo(productContainer);
                if (!queryFirst('.size-btn.selected', productContainer)) {
                    return;
                }
            }

            $body.trigger('product:beforeAddToCart', target);

            const analyticsData = getAddToCartAnalyticsData();

            const form = {
                pid: getPidValue($target),
                childProducts: getChildProducts(),
                ...pdpSetData,
                ...analyticsData
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

            $target.trigger('updateAddToCartFormData', form);

            let addToCartUrl = getAddToCartUrl();

            // it needs for cases when mincart has a recommendation block with empty value in the '.add-to-cart-url'
            if (!addToCartUrl && hasClass(productContainer, 'is-stylitics-gallery')) {
                let closestUrl = queryFirst('.add-to-cart-url', productContainer);
                if (closestUrl && closestUrl.value) addToCartUrl = closestUrl.value;
            }

            if (addToCartUrl) {
                try {
                    const res = await fetch(addToCartUrl, {
                        method: 'POST',
                        body: toFormData(form)
                    });

                    if (!res.ok) throw new Error('Add to cart response was not OK');

                    const resData = await res.json();

                    if (isPDPSet) {
                        $('#productSetModal').modal('hide');

                        // hide shop the look popup after closing cart
                        const shopTheLookModal = $('#productSetModal-' + $target.data('pid'));
                        if (shopTheLookModal.length) shopTheLookModal.modal('hide');
                    }

                    if (ispuAddToCart) {
                        ispuAddToCart.disabled = false;
                        $productContainer.find('.sheet[id*="pick-up-in-store"]').modal('hide');
                    }

                    handlePostCartAdd(resData);

                    $.spinner().stop();
                    $body.trigger('product:afterAddToCart', resData);

                    // send analytics data (trackAddItemToCart) if it is Stylitics Gallery QuickView on PLP
                    // and Stylitics Gallery Widget exists
                    if (hasClass(productContainer, 'is-stylitics-gallery')) {
                        $body.trigger('stylitics:gallery:addItem', {
                            pid: form.pid,
                            price: queryFirst('.price-section .sales .ssSalesPrice', productContainer)?.value
                        });
                    }
                } catch (err) {
                    $.spinner().stop();
                    if (ispuAddToCart) {
                        ispuAddToCart.disabled = false;
                    }
                    console.error('addToCart error:', err.message);
                }
            }
        });
    },
    updateBonusSelection: function () {
        $body.on('bonus:updateSelection', (event, data) => {
            const { target } = data;
            if (!target) return;

            const accordionContainer = target.closest('.choose-gwp-sheet-gift');

            // reset selection for the tier currently being updated
            remove(queryFirst(`.selected-pid[data-uuid="${accordionContainer.dataset.uuid}"]`));

            const choiceOfBonusProduct = target.closest('.choice-of-bonus-product');
            const $choiceOfBonusProduct = $(choiceOfBonusProduct);
            const { pid, uuid } = choiceOfBonusProduct.dataset;
            const maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            const submittedQty = 1;
            let totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });

            const gwpCheckbox = hasClass(target, 'size-btn') || hasClass(target, 'js-color-attribute') ? queryFirst('.select-bonus-product', $choiceOfBonusProduct[0]) : target;
            const { checked } = gwpCheckbox;
            const productDialog = queryFirst('.choose-bonus-product-dialog');
            const addToCart = queryFirst('.add-bonus-products', productDialog);
            const selectedProductElement = queryFirst(`.selected-pid[data-pid="${pid}"][data-uuid="${uuid}"]`, productDialog);
            let bonusAccordionElements = queryAll('.choose-gwp-sheet-gift', productDialog);
            const bonusCountElement = queryFirst('.bonus-item-count span', accordionContainer);
            const selectedCount = queryAll('.select-bonus-product:checked', accordionContainer).length;
            const maxCount = parseInt(accordionContainer.dataset.maxItems, 10);
            const sizeEl = queryFirst('.size-selection', choiceOfBonusProduct);
            const isOneSize = queryFirst('.selected-size', sizeEl);
            const selectedSizeBtn = queryFirst(`.size-btn.${SELECTED_CLASS}`, sizeEl);
            const isSizeSelected = !!selectedSizeBtn || !!isOneSize;
            let selectedSwatch = queryFirst('.color-attribute .swatch-circle.selected', choiceOfBonusProduct);

            if (selectedCount < maxCount) {
                removeClass(accordionContainer, 'selected');
            } else {
                addClass(accordionContainer, 'selected');
            }
            if (bonusCountElement) bonusCountElement.textContent = selectedCount;

            let enableAddTocart = true;
            const maxItems = parseInt(accordionContainer.dataset.maxItems, 10);
            const selectedItemsCount = queryAll('.select-bonus-product:checked', accordionContainer).length;
            if (selectedItemsCount < maxItems) {
                enableAddTocart = false;
            }
            enableAddTocart = isSizeSelected && !!selectedSwatch && enableAddTocart;

            if (enableAddTocart) {
                addClass(accordionContainer, readyClass);
            } else {
                removeClass(accordionContainer, readyClass);
            }

            let validProducts = queryAll('.choose-gwp-sheet-gift.ready-to-add', productDialog);

            if (!hasClass(addToCart, notAvailable)) {
                addToCart.disabled = bonusAccordionElements.length !== validProducts.length;
            }

            if (checked) {
                addClass(choiceOfBonusProduct, selectedProductClass);
            } else {
                removeClass(choiceOfBonusProduct, selectedProductClass);
                if (selectedProductElement) {
                    selectedProductElement.click();
                }
            }
            if (selectedCount < maxCount) {
                $(accordionContainer).find('.choice-of-bonus-product').find('.select-bonus-product, .color-attribute, select').removeAttr('tabindex');
            } else {
                $(accordionContainer).find('.choice-of-bonus-product:not(.selected-product)').find('.select-bonus-product, .color-attribute, select').attr('tabindex', -1);
            }
            if (!checked) {
                return;
            }

            totalQty += submittedQty;
            const optionID = $choiceOfBonusProduct.find('.product-option').data('option-id');
            const valueId = $choiceOfBonusProduct.find('.options-select option:selected').data('valueId');
            if (totalQty <= maxPids) {
                if (isSizeSelected) {
                    const selectedBonusProductHtml = `
                        <div class="selected-pid row" data-pid="${pid}" data-uuid="${uuid}" data-qty="${submittedQty}" data-optionID="${optionID || ''}" data-option-selected-value="${valueId || ''}">
                            <div class="col-sm-11 col-9 bonus-product-name" >${$choiceOfBonusProduct.find('.product-name').html()}</div>
                            <div class="col-1"><i class="fa fa-times" aria-hidden="true"></i></div>
                        </div>`;
                    $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                    $('.pre-cart-products').html(totalQty);
                    $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
                }
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },

    selectBonusProduct: function () {
        // Bonus product selection process:
        // - user clicks on the product
        // - this code checks the box and triggers the bonus:updateSelection custom event
        //     - if it is a one-size product, a new HTML structure is appended into a hidden div (.selected-products-container) on the page containing information about the selected product
        //     - if it is not a one-size product, that HTML structure is not added
        //         - upon clicking a size, attributeSelect() is called which fires off an ajax request to get updated product information. The response is then used to
        //           rewrite the size elements on the page.
        //         - a PDP CUSTOM EVENT is triggered (product:afterAttributeSelect from detail.js). The main reason why this is needed is to update the data-pid value on
        //           the .product-detail element on the page (updates to the SKU value corresponding to the selected size).
        //         - the same bonus:updateSelection custom event is then called again, which now sees that a size has been selected. It reads from that updated
        //           data-pid attribute and appends a new HTML structure into a hidden div (.selected-products-container) on the page
        // - upon clicking the add to tote button in the select gwp sheet, the event listener will read the product information from the hidden div (.selected-products-container)
        //   and fire off an ajax request to add the selected products to tote.
        $body.on('click', '.select-bonus-product', function () {
            $body.trigger('bonus:updateSelection', { target: this });
        });

        const bonusDrawer = document.getElementById('chooseBonusProductModal');

        if (bonusDrawer) {
            bonusDrawer.addEventListener('click', e => {
                const { target } = e;
                const isSize = hasClass(target, 'size-btn');
                const isColor = hasClass(target, 'color-value') || hasClass(target, 'js-color-attribute');

                if (hasClass(target, 'bonus-product-item')) {
                    e.preventDefault();
                    const NO_SIZE_ERROR_CLASS = 'no-sz';
                    const targetCheckbox = queryFirst('.select-bonus-product', target);
                    const parentTier = target.closest('.choose-gwp-sheet-gift');

                    // reset errors for all gifts in this tier
                    removeClass(queryAll('.bonus-product-item', parentTier), NO_SIZE_ERROR_CLASS);

                    if (!targetCheckbox.checked) {
                        const hasSizes = queryFirst('.size-btn', target);
                        const selectedSize = queryFirst('.size-btn.selected', target);

                        if (!hasSizes || selectedSize) {
                            // unselect the checked checkbox as long as the user didn't click the already-checked option
                            const accordion = target.closest('.choose-gwp-sheet-gift');
                            const checkedBox = queryFirst('input:checked', accordion);
                            if (checkedBox) {
                                checkedBox.click();
                            }
                        } else {
                            addClass(target, NO_SIZE_ERROR_CLASS);
                            return;
                        }
                    }

                    // toggle the checkbox for the clicked tile
                    targetCheckbox.click();
                } else if (isSize || isColor) {
                    const wrapper = target.closest('.product-detail');
                    const checkbox = queryFirst('.select-bonus-product', wrapper);
                    const sheet = wrapper.closest('.choose-gwp-sheet');
                    const sizeEl = queryFirst('.size-selection', wrapper);
                    const isOneSize = queryFirst('.selected-size', sizeEl);
                    const sizeBtns = queryAll('.size-btn', sizeEl);
                    const colorBtn = isColor && (hasClass(target, 'color-value') ? target.closest('.js-color-attribute') : target);
                    const attrUrl = isColor && isOneSize ? colorBtn.dataset.url : target.dataset.attrUrl;
                    const { pid: prevId } = wrapper.dataset;

                    if (isSize) {
                        removeClass(sizeBtns, SELECTED_CLASS);
                        addClass(target, SELECTED_CLASS);
                    }

                    if (checkbox && !checkbox.checked && (isSize || (isColor && isOneSize))) {
                        wrapper.click();
                    }

                    remove(queryFirst(`.selected-pid[data-pid="${prevId}"]`, sheet));

                    attributeSelect(attrUrl, $(wrapper), isColor && isOneSize ? colorBtn : target);
                }
            });
        }
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $body.on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr('disabled', !response.product.readyToOrder || !response.product.available);
            var pid = response.product.id;
            $('button.select-bonus-product', response.$productContainer).data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                }
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: []
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this).data('qty'), 10);
                const pid = $(this).data('pid');
                const uuid = $(this).data('uuid');
                const productId = $(`.choice-of-bonus-product.selected-product[data-pid="${pid}"][data-uuid="${uuid}"]`).attr('data-pid');
                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = productId;
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        uuid: uuid,
                        pid: productId,
                        qty: qtyOption,
                        options: option ? [option] : []
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&bonusItems=' + JSON.stringify($('.choose-bonus-product-dialog').data('bonusItems'));
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');
                        if ($('.add-to-cart-messages').length === 0) {
                            $body.append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.add-to-cart-messages').append('<div class="alert alert-danger add-to-basket-alert text-center" role="alert">' + data.errorMessage + '</div>');
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                        }, 3000);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');
                        $('.minicart-quantity').html(data.totalQty);
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    revealRecommendations: function () {
        const { initSpecificCarousel } = require('../components/carousel');
        queryAll('.recommendations:not(.product-listing-header)').forEach(eachRecommendation => {
            const titleEl = queryFirst('.title', eachRecommendation);
            const productEl = queryFirst('.grid-tile', eachRecommendation);
            const scrollableContent = queryFirst('.scrollable-content', eachRecommendation);

            if (titleEl && !productEl) {
                eachRecommendation.outerHTML = '';
            } else if (titleEl && productEl) {
                eachRecommendation.style.display = 'block';
                if (scrollableContent) initSpecificCarousel(scrollableContent);
            }
        });
    },
    handleEarlyAccessPLPLockIcon: function () {
        const earlyAccessPLPContainer = queryAll('.js-early-access-plp-container');
        if (earlyAccessPLPContainer.length) {
            earlyAccessPLPContainer.forEach(earlyAccessPlpIcon => {
                const lockIconContainer = queryFirst('.loyalty-early-access-lock-container', earlyAccessPlpIcon);
                const earlyAccessWishlistIcon = queryFirst('.product-tile .pdp-wish-list', earlyAccessPlpIcon);
                const earlyAccessPLPBadge = queryFirst('.js-loyalty-badge', earlyAccessPlpIcon);
                const { earlyAccessDate } = lockIconContainer.dataset;
                const isEarlyAccessItem = isEarlyAccessElement(earlyAccessDate);
                if (isLoyaltyProgramMember || !isEarlyAccessItem) {
                    removeClass(earlyAccessWishlistIcon, HIDDEN_CLASS);
                    addClass(lockIconContainer, HIDDEN_CLASS);
                    if (!isEarlyAccessItem) {
                        addClass(earlyAccessPLPBadge, HIDDEN_CLASS);
                    }
                } else {
                    addClass(earlyAccessWishlistIcon, HIDDEN_CLASS);
                    removeClass([lockIconContainer, earlyAccessPLPBadge], HIDDEN_CLASS);
                }
            });
        }
    },

    getPidValue: getPidValue,
    getQuantitySelected: getQuantitySelected,
    handleEarlyAccessCta: handleEarlyAccessCta
};

/**
 * OOTB SFRA JS code
 */

import base from '../product/base';
import { queryFirst, addClass, removeClass, queryAll, remove, hasClass, scrollTo, throttle, slideUp, toFormData, setAttribute, removeAttribute } from '../domUtil';
import { appendToUrl, getJSON, formatMessage } from '../util';
import { trackCart } from '../components/etmc';
import { ANIM_TIME_QUICK_2, HIDDEN_CLASS, INVALID_CLASS, IS_SAFARI, SHOW_CLASS } from '../constants';
import { checkAccountAndFetchDetail } from 'bolt/bolt/account';
// detail.js was removed from productTile.js in LPE-1024, commit 3e2694d. Some code in detail.js is necessary for rarely-used functionalty in cart -- like select GWP/bonus choice
import detail from '../product/detail';
const { cart: cartTemplates, approachingDiscounts: approachingDiscountsTemplates, promo: promoTemplates, emptySFL: emptySFLTemplates } = require('../templates');
const checkoutContainer = queryFirst('.data-checkout-stage');
const formValidation = require('base/components/formValidation');
const displayFlex = 'd-flex';
const selectedTabClass = 'active';
const $body = $('body');
const loyaltyVoucherApplied = 'applied-reward-bg';
const lpRegisteredUserEmailEl = queryFirst('.registered-user-email');
const lpRegisteredUserEmail = lpRegisteredUserEmailEl ? lpRegisteredUserEmailEl.value : '';
const boltCheckoutAccountExistEl = queryFirst('.check-bolt-account-exist');
const cartTopLevel = queryFirst('.cart');
const cartPage = queryFirst('.cart-page', cartTopLevel);
const $cartPage = $(cartPage);
const sflPage = queryFirst('.cart-sfl-tab', cartTopLevel);
const isCheckout = !!checkoutContainer;
const checkoutOrderSummary = queryFirst('.checkout-summary', checkoutContainer);

/**
 * Updates error messaging in the sticky bar
 */
function updateStickyBarErrors() {
    const stickyBar = queryFirst('.cart-stickybar', cartTopLevel);
    if (stickyBar) {
        const stickyBarErrors = queryFirst('.cart-stickybar-errors', stickyBar);
        const cartErrorMsg = queryFirst('.cart-error-message', cartPage);

        stickyBarErrors.innerHTML = '';

        if (cartErrorMsg) stickyBarErrors.append(cartErrorMsg.cloneNode(true));
    }
}

/**
 * Displays cart error message
 * @param {string} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = formatMessage(cartTemplates.errorMessage, message);
    const cartError = queryFirst('.cart-error', cartPage);
    if (cartError) cartError.innerHTML = errorHtml;

    updateStickyBarErrors();
}

/**
 * Update the GWP HTML if contained in an ajax response
 * @param {Object} data The response data from an ajax call containing a 'gwpProductsHtml' attribute
 * @param {string} data.gwpProductsHtml The GWP HTML to insert
 */
function updateGWP(data) {
    if ('gwpProductsHtml' in data) {
        $('.js-gwp-container').html(data.gwpProductsHtml);
    }
}

/**
 * Updates cart items display (shipping & ISPU)
 * @param {Object} data - response data containing a 'cartProductsHtml' key
 */
function updateCartItems(data) {
    if ('cartProductsHtml' in data) {
        const shipClass = '.cart-products-ship';
        const ispuClass = '.cart-products-ispu';
        const sflClass = '.see-all-items-wrapeer';

        const cartProductsRes = document.createElement('template');
        cartProductsRes.innerHTML = data.cartProductsHtml;
        const shipRes = queryFirst(shipClass, cartProductsRes.content);
        const ispuRes = queryFirst(ispuClass, cartProductsRes.content);
        const sflRes = queryFirst(sflClass, cartProductsRes.content);

        const cartItemsWrapper = queryFirst('.cart-tab-items');
        const shipSection = queryFirst(shipClass, cartItemsWrapper);
        const ispuSection = queryFirst(ispuClass, cartItemsWrapper);
        const sflSection = queryFirst(sflClass, cartItemsWrapper);

        if (shipSection) shipSection.innerHTML = shipRes.innerHTML;
        if (ispuSection) ispuSection.innerHTML = ispuRes.innerHTML;
        if (sflSection) sflSection.innerHTML = sflRes.innerHTML;
    }
}

/**
 * enable or disables the checkout button
 */
function enableOrDisableCheckoutButton() {
    const checkoutButtonElement = queryAll('.checkout-btn');

    if (!checkoutButtonElement?.length) return;

    const cartSummary = queryFirst('.cart-summary', cartPage);
    const expressCheckoutSection = queryFirst('.express-checkout-actions', cartSummary);
    const gwpProductsLength = queryAll('.gwp-option-bonus').length;
    const errorMessage = queryFirst('.cart-container .cart-error .cart-error-message');
    const cartWithSFLElement = queryFirst('.js-cart-container .js-cart-with-items');
    const oosProduct = queryFirst('.line-item-attributes.out-of-stock', cartWithSFLElement || document);
    const chooseGwpMessage = queryFirst('.gwp-message');
    const monogramRestrictionLength = queryAll('.js-monogram-restriction').length;
    const numLowInventoryWarnings = queryAll('.low-inventory-warning').length;

    if (gwpProductsLength || errorMessage || oosProduct || monogramRestrictionLength || numLowInventoryWarnings) {
        addClass(checkoutButtonElement, 'disabled');
        removeAttribute(checkoutButtonElement, 'href');
        addClass(expressCheckoutSection, HIDDEN_CLASS);
        updateStickyBarErrors();
    } else {
        removeClass(checkoutButtonElement, 'disabled');
        setAttribute(checkoutButtonElement, 'href', checkoutButtonElement[0].dataset.href);
        const grandTotalVal = queryFirst('.grand-total-value.grand-total');

        if (grandTotalVal && cartPage && grandTotalVal.textContent === cartPage.dataset.zeroTotal) {
            addClass(expressCheckoutSection, HIDDEN_CLASS);
        } else {
            removeClass(expressCheckoutSection, HIDDEN_CLASS);
        }
        updateStickyBarErrors();
    }

    if (chooseGwpMessage) {
        if (gwpProductsLength && !errorMessage) {
            removeClass(chooseGwpMessage, HIDDEN_CLASS);
        } else {
            addClass(chooseGwpMessage, HIDDEN_CLASS);
        }
    }

    if (!hasClass(checkoutButtonElement, 'disabled')) {
        const BOLT_BTN_CLASS = '.bolt-checkout-wrapper';
        const boltBtnTemplateWrapper = queryFirst('.bolt-button-template', cartPage);
        const boltBtnTemplate = queryFirst(BOLT_BTN_CLASS, boltBtnTemplateWrapper);
        const { isBoltEnable, isBoltUserLoggedIn } = checkoutButtonElement[0].dataset;

        if (boltBtnTemplate && isBoltEnable === 'true' && isBoltUserLoggedIn !== 'true' && lpRegisteredUserEmail && boltCheckoutAccountExistEl) {
            const checkBoltAccountUrl = boltCheckoutAccountExistEl.value + '=' + encodeURIComponent(lpRegisteredUserEmail);
            $.ajax({
                url: checkBoltAccountUrl,
                method: 'GET',
                success(data) {
                    const orderSummaryBtns = queryFirst('.express-checkout-actions', cartPage);
                    const stickyBarBtns = queryFirst('.cart-stickybar-buttons', cartTopLevel);
                    if (data && data.has_bolt_account) {
                        // add a button to the express checkout section only if the button does not already exist
                        const boltBtn = queryFirst(BOLT_BTN_CLASS, orderSummaryBtns);
                        if (!boltBtn) {
                            orderSummaryBtns.append(boltBtnTemplate.cloneNode(true));
                            stickyBarBtns.append(boltBtnTemplate.cloneNode(true));
                        }
                    } else {
                        remove(queryFirst(BOLT_BTN_CLASS, orderSummaryBtns));
                        remove(queryFirst(BOLT_BTN_CLASS, stickyBarBtns));
                    }
                },
                error: function () {}
            });
        }
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            createErrorNotification(data.valid.message);
        } else {
            $('.number-of-items').empty();
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }
    } else {
        const cartErrors = queryFirst('.cart-container .cart-error');
        if (cartErrors) {
            cartErrors.textContent = '';
        }
    }
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    enableOrDisableCheckoutButton();
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    const { giftWrapQuantity, giftWrapTotalPrice, totals, giftWrapISPUTotalPrice, giftWrapISPUQuantity, numItems } = data;
    const giftWrapContainer = queryFirst('.gift-wrap-container');
    const giftWrapISPUContainer = queryFirst('.gift-wrap-ispu-container');
    const nonCouponContainer = queryFirst('.non-coupon-price-container');
    if (giftWrapContainer) {
        if (giftWrapQuantity > 0) {
            queryFirst('.gift-wrap-quantity', giftWrapContainer).textContent = giftWrapQuantity.toString();
            queryFirst('.gift-wrap-price', giftWrapContainer).textContent = giftWrapTotalPrice.toString();
            if (!hasClass(giftWrapContainer, displayFlex)) {
                removeClass(giftWrapContainer, HIDDEN_CLASS);
                addClass(giftWrapContainer, displayFlex);
            }
        } else {
            removeClass(giftWrapContainer, displayFlex);
            addClass(giftWrapContainer, HIDDEN_CLASS);
        }
    }
    if (giftWrapISPUContainer) {
        if (giftWrapISPUQuantity > 0) {
            const { freeprice } = giftWrapISPUContainer.dataset;
            queryFirst('.gift-wrap-ispu-quantity', giftWrapISPUContainer).textContent = giftWrapISPUQuantity.toString();
            queryFirst('.gift-wrap-ispu-price', giftWrapISPUContainer).textContent = giftWrapISPUTotalPrice.totalPrice == 0 ? freeprice : giftWrapISPUTotalPrice.formattedPrice.toString();
            if (!hasClass(giftWrapISPUContainer, displayFlex)) {
                removeClass(giftWrapISPUContainer, HIDDEN_CLASS);
                addClass(giftWrapISPUContainer, displayFlex);
            }
        } else {
            removeClass(giftWrapISPUContainer, displayFlex);
            addClass(giftWrapISPUContainer, HIDDEN_CLASS);
        }
    }
    const cartWithSFLCountTotal = queryFirst('.cart-items-count.item-count-tote');
    if (cartWithSFLCountTotal) {
        cartWithSFLCountTotal.textContent = numItems;
    }
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.grand-total').empty().append(data.totals.subTotal);
    $('.sub-total').empty().append(data.totals.totalAmount);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.minicart-quantity').empty().append(data.numItems);
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total')
            .empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    const cartErrors = queryAll('.cart-error');
    if (!data.valid.error) {
        addClass(cartErrors, HIDDEN_CLASS);
    } else {
        removeClass(cartErrors, HIDDEN_CLASS);
    }

    if (nonCouponContainer) {
        let nonCouponPromotionsHTML = '';
        if (totals.nonCouponBasedAdjustments && totals.nonCouponBasedAdjustments.length) {
            totals.nonCouponBasedAdjustments.forEach(function (couponItem) {
                nonCouponPromotionsHTML += formatMessage(approachingDiscountsTemplates.nonCouponBasedAdjustmentTemplate, couponItem.promoTitle, couponItem.discountPrice, couponItem.callOutMsg);
            });
        }
        nonCouponContainer.innerHTML = nonCouponPromotionsHTML;
    }

    data.items.forEach(function (item) {
        const itemRenderedPrice = item?.priceTotal?.renderedPrice;
        const wrapper = isCheckout ? checkoutOrderSummary : cartPage;

        if (itemRenderedPrice) {
            const itemEl = queryFirst(`.uuid-${item.UUID}`, wrapper);
            const itemPrice = queryFirst('.js-line-price', itemEl);
            if (itemPrice) itemPrice.innerHTML = itemRenderedPrice;
        }
    });
}

/**
 * Updates the promotion block in order summary
 * @param {Object} data - AJAX response from the server
 */
function updateCartPromoBlock(data) {
    // Update Promo block
    const wrapper = queryFirst('.discounts-section', cartPage);
    if (wrapper) wrapper.innerHTML = data?.promoBlockHtml || '';

    // Update promo details sheet
    const sheet = queryFirst('.js-promo-details-container', cartPage);
    if (sheet) sheet.innerHTML = data?.promoSheetHtml || '';
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += `<p class="line-item-attributes ${!lineItem.available ? 'out-of-stock' : ''}"> ${message} </p>`;
            });
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 * @param {boolean} giftWrapAvailable - flag to check gift wrap applicability
 */
function updateProductDetails(data, uuid, giftWrapAvailable) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            $(colorSelector).find('.line-item-text').text(colorAttr.displayValue);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            $(sizeSelector).find('.line-item-text').text(sizeAttr.displayValue);
        }

        const { newProductId } = data || {};
        if (newProductId) {
            const skuElement = queryFirst(`.sku-${uuid}`);
            const skuLabel = skuElement && skuElement.dataset.skuLabel;

            if (skuElement && skuLabel) {
                skuElement.textContent = `${skuLabel} ${newProductId}`;
            }
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('srcset', lineItem.images.small[0].srcset);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        var option = lineItem.options[0];
        var optSelector = '.lineItem-options-values[data-option-id="' + option.optionId + '"]';
        $(optSelector).attr('data-value-id', option.selectedValueId);
        $(optSelector + ' .line-item-attributes').text(option.displayName);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"],
        .move-to-wishlist-${uuid} a,
        .gift-wrap-input[data-uuid="${uuid}"],
        .btn-in-store-pickup[data-uuid="${uuid}"],
        .js-move-to-shipping[data-uuid="${uuid}"],
        .quantity[data-uuid="${uuid}"],
        .cart-product-line-item[data-uuid="${uuid}"]`).attr('data-pid', data.newProductId);

    const cartStoreLinksSelector = '.product-info.uuid-' + uuid + ' .cart-store-links';
    if (giftWrapAvailable === 'false') {
        $('.gift-wrap-input[data-uuid="' + uuid + '"]').prop('checked', false);
        $(cartStoreLinksSelector + ' form').addClass(HIDDEN_CLASS);
        $(cartStoreLinksSelector + ' .seperator').addClass(HIDDEN_CLASS);
    } else if ($(cartStoreLinksSelector).closest('.cart-products-ispu').length === 0) {
        $(cartStoreLinksSelector + ' form').removeClass(HIDDEN_CLASS);
        $(cartStoreLinksSelector + ' .seperator').removeClass(HIDDEN_CLASS);
    }

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }

    if (lineItem.isGiftCard) {
        const { giftCardAmount } = lineItem;

        const giftCardHiddenAmountField = queryFirst(`.product-info.uuid-${uuid} .gift-card-amount`);
        if (giftCardHiddenAmountField) {
            giftCardHiddenAmountField.value = giftCardAmount;
        }

        queryAll(`.move-to-wishlist-${uuid} .move`).forEach(el => {
            el.dataset.giftCardAmount = giftCardAmount;
        });
        queryFirst(`.remove-product-${uuid}`).dataset.giftCardAmount = giftCardAmount;
    }
}

/**
 * Updates the promo/coupon accordion HTML
 * @param {string} discountsHtml - promo accordion html string from ajax response
 */
function updatePromoAccordion(discountsHtml) {
    if (!discountsHtml) return;
    const promoSection = queryFirst('.coupon-wrapper');
    if (promoSection) promoSection.innerHTML = discountsHtml;
}

/**
 * Update promo code details
 * @param {array} discounts - updated discounts data
 * @param {Object} shippingMethod - Shipping method detail object
 */
function updatePromoCodeDetails(discounts, shippingMethod) {
    const summaryWrapper = queryFirst('.cart-summary, .checkout-summary');
    const promotionsListContainer = queryFirst('.promo-code-section', summaryWrapper);
    const cartShippingMethodContainer = queryFirst('.cart-shipping-method', summaryWrapper);
    const checkoutShippingMethodContainer = queryFirst('.shipping-method-price-container', summaryWrapper);
    const checkoutShippingMethodTitle = queryFirst('.shipping-method-title', summaryWrapper);
    let promotionsHtml = '';
    if (discounts && discounts.length) {
        const promoTemplate = document.getElementById('promo-code-details-template').cloneNode(true);
        const promoContainer = queryFirst('.price-container', promoTemplate);
        const promoText = queryFirst('.price-text', promoContainer);
        const promoTitle = promoText.dataset.promoTitle;
        const promoValue = queryFirst('.price-value', promoContainer);
        const description = queryFirst('.promo-description', promoContainer);
        discounts.forEach(eachDiscount => {
            if (eachDiscount.type === 'coupon' && eachDiscount.applied) {
                const couponData = eachDiscount.relationship;
                promoText.textContent = `${promoTitle} ${eachDiscount.couponCode}`;
                if (couponData && couponData.length) {
                    promoValue.textContent = eachDiscount.totalPrice;
                    description.textContent = couponData[0].name;
                } else {
                    promoValue.textContent = '';
                    description.textContent = '';
                }
                promotionsHtml += promoTemplate.innerHTML;
            }
        });
        if (cartShippingMethodContainer) {
            if (shippingMethod && shippingMethod.isDiscounted) {
                cartShippingMethodContainer.innerHTML = formatMessage(promoTemplates.shippingMethod, shippingMethod.shippingLabel, shippingMethod.displayName, shippingMethod.shippingCost, shippingMethod.estimatedArrivalTime);
            } else {
                cartShippingMethodContainer.innerHTML = '';
            }
        }

        if (checkoutShippingMethodContainer) {
            if (shippingMethod && shippingMethod.isDiscounted) {
                checkoutShippingMethodContainer.innerHTML = formatMessage(promoTemplates.checkoutDiscountShipping, shippingMethod.shippingCost, shippingMethod.adjustedShippingCost);
            } else {
                checkoutShippingMethodContainer.innerHTML = formatMessage(promoTemplates.checkoutNormalShipping, shippingMethod.shippingCost);
            }
        }
    } else if (cartShippingMethodContainer) {
        cartShippingMethodContainer.innerHTML = '';
    } else if (checkoutShippingMethodContainer) {
        checkoutShippingMethodContainer.innerHTML = formatMessage(promoTemplates.checkoutNormalShipping, shippingMethod.shippingCost);
    }
    if (checkoutShippingMethodTitle) {
        checkoutShippingMethodTitle.innerHTML = shippingMethod.displayName;
    }
    if (promotionsListContainer) {
        promotionsListContainer.innerHTML = promotionsHtml;
    }
}

/**
 * This function updates Form Object for Gift card values
 * @param {Object} formObject - object to edit to add gift card amount
 * @param {HTMLElement} formEl - form node to fetch gift card amount
 */
function updateFormObjectForGiftCard(formObject, formEl) {
    const amountField = queryFirst('.gift-card-amount', formEl);

    if (amountField) {
        formObject.giftCardAmount = amountField.value;
    }
}

/**
 * updates the monogram elements on cart page
 * @param {Object} data - update quantity and remove from cart ajax response
 */
function updateMonogramDetails(data) {
    const { monogramPrice, monogramQuantity } = data;
    const monogramContainer = queryFirst('.cart-totals .monogram-container');
    if (monogramContainer) {
        queryFirst('.price-value', monogramContainer).textContent = monogramPrice;

        queryFirst('.monogram-quantity', monogramContainer).textContent = monogramQuantity;
        if (!monogramQuantity) {
            addClass(monogramContainer, 'd-none');
        } else {
            removeClass(monogramContainer, 'd-none');
        }
    }
}

/**
 * updates the Save for later on cart page
 * @param {Object} data - add product to tote from SFL cart ajax response
 */
function updateSaveForLaterDetails(data) {
    const { saveForLaterHtml, sflCountMsg, count, isLoggedIn } = data;
    const sflProductElement = queryFirst('.sfl-cart-products');
    const sflTitleCount = queryFirst('.number-of-items-sfl');
    const signInSFLBottomEl = queryFirst('.sfl-login-wrapper');
    const moveAllToCartCTA = queryFirst('.move-all-items-to-tote');

    if (sflProductElement) {
        sflProductElement.innerHTML = saveForLaterHtml;
    }

    if (sflTitleCount) {
        sflTitleCount.textContent = sflCountMsg;
    }
    if (count === 0) {
        addClass([signInSFLBottomEl, moveAllToCartCTA], HIDDEN_CLASS);
        if (isLoggedIn) {
            const loginSFL = queryFirst('.sfl-login-btn');
            addClass(loginSFL, HIDDEN_CLASS);
        }
    }
}
/**
 * Updates the loyalty rewards and offers in cart and checkout page
 * @param {Object} summarySection - Rewards or Offers Section
 * @param {Object} couponData - data of the rewards or offers
 * @param {number} couponCount - count of redeemed rewards/offers
 * @param {string} couponPrice - total price of redeemed rewards/offers
 * @param {string} ctaClass - wrapper class for apply and remove CTAs in offers and rewards
 */
function updateLoyaltyRewardsOffers(summarySection, couponData, couponCount, couponPrice, ctaClass) {
    if (couponCount) {
        removeClass(summarySection, HIDDEN_CLASS);
        const redeemedCouponCountEl = queryFirst('.loyalty-cart-reward-quantity', summarySection);
        const redeemedCouponPriceEl = queryFirst('.loyalty-cart-reward-price', summarySection);
        redeemedCouponCountEl.textContent = couponCount;
        redeemedCouponPriceEl.textContent = couponPrice;
        queryAll(ctaClass).forEach(el => {
            const { voucherCode } = el.dataset;
            const removeCouponCta = queryFirst('.remove-voucher-tote-cta', el);
            const applyCouponCta = queryFirst('.apply-voucher-tote-cta', el);
            const couponCardEl = el.closest('.voucher-card-content');
            const matchingCoupon = couponData.find(voucher => voucher.code === voucherCode);
            if (matchingCoupon) {
                const { applied, UUID } = matchingCoupon;
                if (applied) {
                    removeClass(removeCouponCta, HIDDEN_CLASS);
                    addClass(applyCouponCta, HIDDEN_CLASS);
                    addClass(couponCardEl, loyaltyVoucherApplied);
                    el.dataset.uuid = UUID;
                } else {
                    addClass(removeCouponCta, HIDDEN_CLASS);
                    removeClass(applyCouponCta, HIDDEN_CLASS);
                    removeClass(couponCardEl, loyaltyVoucherApplied);
                }
            }
        });
    } else {
        queryAll(ctaClass).forEach(el => {
            const removeCouponCta = queryFirst('.remove-voucher-tote-cta', el);
            const applyCouponCta = queryFirst('.apply-voucher-tote-cta', el);
            const couponCardEl = el.closest('.voucher-card-content');
            addClass(removeCouponCta, HIDDEN_CLASS);
            removeClass(applyCouponCta, HIDDEN_CLASS);
            removeClass(couponCardEl, loyaltyVoucherApplied);
        });
        addClass(summarySection, HIDDEN_CLASS);
    }
}

/**
 * updates the loyalty vouchers on cart page
 * @param {Object} data - add product to tote from SFL cart ajax response
 */
function updateLoyaltyVouchers(data) {
    const { loyalty, subTotal } = data.totals;
    if (loyalty) {
        const { memberVouchers, memberOffers, redeemedVouchersCount, redeemedVoucherPrice, redeemedOffersCount, redeemedOffersPrice } = loyalty;
        const loyaltyVouchersSummarySection = queryFirst('.loyalty-cart-reward-section');
        const loyaltyOffersSummarySection = queryFirst('.loyalty-cart-offer-section');
        const applyVoucherCtas = queryAll('.apply-voucher-tote-cta');
        if (loyaltyVouchersSummarySection) {
            updateLoyaltyRewardsOffers(loyaltyVouchersSummarySection, memberVouchers, redeemedVouchersCount, redeemedVoucherPrice, '.js-vouchers-cta');
        }
        if (loyaltyOffersSummarySection) {
            updateLoyaltyRewardsOffers(loyaltyOffersSummarySection, memberOffers, redeemedOffersCount, redeemedOffersPrice, '.js-offers-cta');
        }
        applyVoucherCtas.forEach(applyCta => {
            const voucherCardContentEl = applyCta.closest('.voucher-card-content');
            const voucherCardDisabledClass = 'voucher-card-disabled';
            if (subTotal === '$0' && !hasClass(applyCta, HIDDEN_CLASS)) {
                applyCta.disabled = true;
                addClass(voucherCardContentEl, voucherCardDisabledClass);
            } else {
                applyCta.disabled = false;
                removeClass(voucherCardContentEl, voucherCardDisabledClass);
            }
        });
    }
}

/**
 * Get the account details for Bolt User
 * @param {string} checkoutUrl - URL to redirect to Checkout page if error comes from the API call
 */
function fetchBoltAccountDetails(checkoutUrl) {
    const accountDetailUrl = queryFirst('.get-bolt-account-details').value;
    $.ajax({
        url: accountDetailUrl,
        method: 'GET',
        success: function ({ success, redirectUrl }) {
            //Remove the bolt logout cookie
            document.cookie = 'bolt_sfcc_session_logout=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            window.location.href = (success && redirectUrl) || checkoutUrl;
        },
        error: function () {
            window.location.href = checkoutUrl;
        }
    });
}

/**
 * Checks if Apple Pay is available. If not, removes uninitialized Apple Pay button elements.
 */
function checkApplePay() {
    const { ApplePaySession } = window;
    if (!ApplePaySession || !ApplePaySession.canMakePayments()) {
        remove(queryAll('.apple-pay-cart'));
    }
}

/**
 * Initializes the sticky bar (with totals and checkout CTAs) in cart.
 * The sticky bar should hide when the order summary section enters the viewport.
 */
function initStickyBar() {
    const stickyBar = queryFirst('.cart-stickybar');
    const cartSummary = queryFirst('.cart-summary-title'); // targeting title element because gift card widget can appear above it
    const isDesktop = window.matchMedia('(min-width: 1025px)');

    const showStickyBar = () => {
        if (cartSummary.getBoundingClientRect().top > window.innerHeight && !isDesktop.matches) {
            // Cart summary is out of view/below the viewport, show the sticky bar
            addClass(stickyBar, SHOW_CLASS);
            stickyBar.ariaHidden = 'false';
        }
    };

    const hideStickyBar = () => {
        removeClass(stickyBar, SHOW_CLASS);
        stickyBar.ariaHidden = 'true';
    };

    if (!cartSummary || !stickyBar) return;

    const handleIntersection = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Cart summary is in view, hide the sticky bar
                hideStickyBar();
            } else {
                showStickyBar();
            }
        });
    };

    const observer = new IntersectionObserver(handleIntersection);

    observer.observe(cartSummary);

    // hide sticky bar on desktop
    let wasDesktop = isDesktop.matches;

    window.addEventListener(
        'resize',
        throttle(() => {
            if (isDesktop.matches) {
                hideStickyBar();
            } else if (wasDesktop) {
                // Mobile's window can "resize" when elements like the address/tab bar are shown/hidden.
                // We only want to trigger this when a user is resizing from a desktop breakpoint.
                // In all other cases, the intersction observer should handle show/hide.
                showStickyBar();
            }

            wasDesktop = isDesktop.matches;
        })
    );
}

/**
 * Fixes element rendering in Safari.
 * LPE-2423 - Safari seems to have a bug where certain :has() selectors don't render properly.
 */
function safariFix() {
    if (IS_SAFARI) {
        queryAll('.cart-tab-items-list, .cart-tab-items-empty, .cart-tab-actions, .cart-tab-summary, .cart-error, .cart-products-ship, .cart-products-gwp, .cart-products-ispu, .cart-sfl-tab-items, .cart-sfl-tab-empty, .cart-sfl-tab-actions').forEach(
            function (el) {
                el.style['-webkit-backface-visibility'] = 'visible';
                el.style['-webkit-backface-visibility'] = '';
            }
        );
    }
}

/**
 * Updates the displayed selected quantity value to match the value
 * in the 'data-pre-select-qty' attribute.
 */
function updateSelectedQuantity() {
    const selectElement = queryFirst('.form-control.quantity.custom-select');
    const msgQty = queryFirst('.updated-qty-message');

    if (selectElement && msgQty) {
        const preSelectQty = parseInt(selectElement.getAttribute('data-pre-select-qty'), 10);

        setTimeout(() => {
            if (selectElement.value !== preSelectQty) {
                selectElement.value = preSelectQty;
            }
        }, 500);
    }
}

/**
 * Initializes the event handlers
 */
function init() {
    checkApplePay();
    initStickyBar();
    updateSelectedQuantity();

    queryFirst('.js-save-for-later-select')?.addEventListener('click', e => {
        const sflTab = queryFirst('.cart-nav-list.save-for-later-tab');
        if (!hasClass(sflTab, selectedTabClass)) {
            let toTab = 'saved for later';
            if (hasClass(e.currentTarget, 'js-see-items-sfl')) {
                toTab += ': see items';
            }
            $body.trigger('tote:selectTab', { fromTab: 'my tote', toTab });
        }
    });

    queryFirst('.js-my-tote-selected')?.addEventListener('click', e => {
        if (!hasClass(e.currentTarget, selectedTabClass)) {
            $body.trigger('tote:selectTab', { fromTab: 'saved for later', toTab: 'my tote' });
        }
    });

    $body.on('click', '.js-move-to-sfl', async e => {
        e.preventDefault();
        const { target } = e;
        const {
            href,
            pid,
            quantity,
            uuid,
            isGiftCard,
            isMonogram,
            giftCardAmount,
            monogramColor,
            monogramLocation,
            monogramStyle,
            monogramLetter1,
            monogramLetter2,
            monogramLetter3,
            monogramInitials,
            giftCardRecipientEmail,
            giftCardRecipientName,
            giftCardSenderName,
            giftCardEmailMessage
        } = target.dataset;
        const monogramOptions = {
            itemPersonalizationColor: monogramColor,
            itemPersonalizationLocation: monogramLocation,
            itemPersonalizationLetterStyle: monogramStyle,
            itemPersonalizationLetter1: monogramLetter1,
            itemPersonalizationLetter2: monogramLetter2,
            itemPersonalizationLetter3: monogramLetter3,
            itemPersonalizationLetters: monogramInitials
        };
        const form = {
            pid,
            qty: quantity,
            isGiftCard,
            isMonogram,
            giftCardAmount,
            monogramOptions: JSON.stringify(monogramOptions),
            giftCardRecipientEmail,
            giftCardRecipientName,
            giftCardSenderName,
            giftCardEmailMessage
        };

        const $spinner = $(target.closest('.cart-item')).spinner();
        $spinner.start();

        try {
            const res = await fetch(href, {
                method: 'POST',
                body: toFormData(form)
            });

            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            $spinner.stop();

            if (data.success) {
                const { count, isLoggedIn } = data;
                const removeLink = queryFirst('.remove-product', target.closest(`.product-info[data-uuid="${uuid}"]`));
                removeLink?.click();
                if (count !== 0 && !isLoggedIn) {
                    const signInSFLBottomWrapperEl = queryFirst('.sfl-login-wrapper', sflPage);
                    const signInSFLBottomEl = queryFirst('.sfl-sign-in', sflPage);
                    removeClass([signInSFLBottomEl, signInSFLBottomWrapperEl], HIDDEN_CLASS);
                }
            } else if (data.error) {
                const errorTitleElem = queryFirst('.sfl-error-dialog-title');
                const errorBodyElem = queryFirst('.sfl-error-dialog-body');

                if (errorTitleElem) errorTitleElem.textContent = data.errorTitle;
                if (errorBodyElem) errorBodyElem.textContent = data.msg;

                $('.sfl-error-dialog').modal('show');
            }
        } catch (err) {
            $spinner.stop();
            console.error(`Cart error: ${err.message}`);
        }
    });

    $body.on('click', '.js-remove-sfl-product', async e => {
        e.preventDefault();
        const { target } = e;
        const { action, pid, uuid, quantity } = target.dataset;
        const cartProductCard = target.closest(`.product-info[data-uuid="${uuid}"]`);
        const url = appendToUrl(action, { pid, uuid, qty: quantity });

        slideUp(cartProductCard, ANIM_TIME_QUICK_2).then(() => {
            remove(cartProductCard);
            safariFix();
        });

        try {
            const res = await fetch(url);
            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            if (data.success) {
                const { count } = data;
                const itemsCountSFL = queryFirst('.item-count-sfl', cartTopLevel);
                const numberItemsSFL = queryFirst('.number-of-items-sfl');
                itemsCountSFL.textContent = count;
                numberItemsSFL.textContent = count;
            }
        } catch (err) {
            console.error(`cart error: ${err.message}`);
        }
    });

    $('.cart-container, .mini-cart-container').on('cart:updateTotals', function (e, data) {
        const { basket } = data;
        const { bonusProductLineItems } = basket;
        updateCartTotals(basket, true);
        if (bonusProductLineItems) {
            const { nonOptionBonusProducts = [], hasOptionBonusProducts } = bonusProductLineItems;

            const hasNonOptionsBonusProducts = !!nonOptionBonusProducts.length;
            const hasBonusProduct = hasNonOptionsBonusProducts || hasOptionBonusProducts;

            if (!hasNonOptionsBonusProducts) {
                remove(queryAll('.gwp-no-option-bonus'));
            }

            if (!hasOptionBonusProducts) {
                remove(queryAll('.gwp-option-bonus'));
            }

            if (!hasBonusProduct) {
                remove(queryAll('.gwp-container'));
            }
        }
        enableOrDisableCheckoutButton();
    });

    $body.on('click', '.remove-product', async e => {
        e.preventDefault();
        const { target } = e;
        const removeLink = target.closest('.remove-product');
        const { pid, action, uuid } = removeLink.dataset;
        const url = appendToUrl(action, { pid, uuid });
        const cartProductCard = target.closest(`.product-info[data-uuid="${uuid}"]`);
        const storePickupGroupElement = cartProductCard.closest('.cart-products-ispu');
        const cartShipProductsEl = cartProductCard.closest('.cart-products-ship');

        slideUp(cartProductCard, ANIM_TIME_QUICK_2).then(() => {
            remove(cartProductCard);
            safariFix();
        });
        let data;
        try {
            const res = await fetch(url);
            data = await res.json(); // calling json before error checking is intentional
            if (!res.ok) throw new Error('response was not OK');

            updateGWP(data);
            const { saveForLater, isSFLEnabled, basket, saveForLaterHtml, toBeDeletedUUIDs } = data;
            const { items, numItems, resources, totals } = basket;
            if (isSFLEnabled) {
                // Update product counts
                const productCountSFL = saveForLater.count || 0;
                const cartTabItemTotal = queryFirst('.item-count-tote', cartTopLevel);
                const sflTabItemTotal = queryFirst('.item-count-sfl', cartTopLevel);
                const sflTitleItemTotal = queryFirst('.number-of-items-sfl', cartTopLevel);

                if (cartTabItemTotal) cartTabItemTotal.textContent = numItems;
                if (sflTabItemTotal) sflTabItemTotal.textContent = productCountSFL;
                if (sflTitleItemTotal) sflTitleItemTotal.textContent = productCountSFL;

                // Refresh SFL Content
                if (saveForLater?.items?.length && sflPage) {
                    sflPage.innerHTML = saveForLaterHtml;
                }
            }

            if (items.length === 0) {
                // update item counts
                const cartTitleItems = queryFirst('.number-of-items', cartPage);
                const miniCartLink = queryFirst('.minicart-link');
                const miniCartItems = queryFirst('.minicart-quantity', miniCartLink);

                cartTitleItems.textContent = '';
                miniCartItems.textContent = numItems;
                ['aria-label', 'title'].forEach(attr => {
                    miniCartLink.setAttribute(attr, resources.minicartCountOfItems);
                });

                $('.cart-container').trigger('cart:updateTotals', data);
            } else {
                // Basket not empty
                const numToBeDeleted = toBeDeletedUUIDs?.length;
                if (numToBeDeleted > 0) {
                    for (let i = 0; i < numToBeDeleted; i++) {
                        remove(queryFirst(`.product-info[data-uuid="${toBeDeletedUUIDs[i]}"]`, cartTopLevel));
                    }
                }

                updatePromoAccordion(totals.discountsHtml);
                updatePromoCodeDetails(totals.discounts, basket.selectedShippingMethod);
                updateCartTotals(basket);
                updateLoyaltyVouchers(basket);
                updateCartPromoBlock(data);
                const { updatedStoreQuantity, storeGroupMessage, enablePayPal } = basket;

                const paypalContainerEl = queryFirst('.checkout-continue', cartPage);
                if (paypalContainerEl) {
                    if (enablePayPal) {
                        removeClass(paypalContainerEl, HIDDEN_CLASS);
                    } else {
                        addClass(paypalContainerEl, HIDDEN_CLASS);
                    }
                }

                if (storePickupGroupElement && updatedStoreQuantity && storeGroupMessage) {
                    const storeGroupMessageElement = queryFirst('.store-product-items-message', storePickupGroupElement);
                    if (storeGroupMessageElement) storeGroupMessageElement.textContent = storeGroupMessage;
                }

                $body.trigger('setShippingMethodSelection', basket);

                $('.cart-container, .mini-cart-container').trigger('cart:updateTotals', data);
                validateBasket(basket);
                updateMonogramDetails(basket);
            }

            $body.trigger('cart:update');
            safariFix();
            const applePayButton = queryFirst('.dw-apple-pay-button', cartPage);
            if (basket.enableApplePay) {
                removeClass(applePayButton, HIDDEN_CLASS);
            } else {
                addClass(applePayButton, HIDDEN_CLASS);
            }
            // update Marketing Cloud Analytics trackCart event
            trackCart(data.mcAnalyticsArray);
        } catch (err) {
            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            } else {
                createErrorNotification(data.errorMessage);
            }
        }
    });

    $body.on('change', '.quantity-form > .quantity', async e => {
        const { target } = e;
        const { preSelectQty, pid, action, uuid, storeId } = target.dataset;
        const quantity = target.value;
        const cartProductCard = queryFirst(`.product-info[data-uuid="${uuid}"]`);
        const $cartProductCard = $(cartProductCard);
        const storePickupGroupElement = cartProductCard.closest('.cart-products-ispu');
        const url = appendToUrl(action, { pid, quantity, uuid, storeId });
        $cartProductCard.spinner().start();

        let data;
        try {
            const res = await fetch(url);
            data = await res.json(); // calling json before error checking is intentional
            if (!res.ok) throw new Error('response was not OK');

            if (data.error) {
                const { lineItemErrorMessage } = data;
                const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                if (lineItemErrorMessage && availabilityFlashMessageEl) {
                    removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                    availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                    setTimeout(() => {
                        addClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                    }, 10000);
                    target.value = parseInt(preSelectQty, 10);
                }
                $cartProductCard.spinner().stop();
            } else {
                target.value = quantity;
                const { updatedStoreQuantity, storeGroupMessage } = data;
                if (storePickupGroupElement && updatedStoreQuantity && storeGroupMessage) {
                    const storeGroupMessageElement = queryFirst('.store-product-items-message', storePickupGroupElement);
                    if (storeGroupMessageElement) {
                        storeGroupMessageElement.textContent = storeGroupMessage;
                    }
                }
                const lineItem = data.items.find(element => element.UUID === uuid);
                if (lineItem && !storePickupGroupElement) {
                    const { giftWrapAvailableFlag, hasGiftWrap } = lineItem;
                    const cartStoreLinksForm = queryFirst('.cart-store-links form', cartProductCard);
                    const giftWrapCheckbox = queryFirst('.gift-wrap-input', cartProductCard);
                    if (giftWrapCheckbox) {
                        if (!giftWrapAvailableFlag) {
                            giftWrapCheckbox.checked = false;
                            addClass(cartStoreLinksForm, HIDDEN_CLASS);
                        } else {
                            giftWrapCheckbox.checked = hasGiftWrap;
                            removeClass(cartStoreLinksForm, HIDDEN_CLASS);
                        }
                    }
                }

                const removeBtn = queryFirst('.remove-product', cartProductCard);
                if (removeBtn) {
                    $body.trigger('cart:qtyUpdate', { oldQty: parseInt(preSelectQty, 10), newQty: parseInt(quantity, 10), analyticsData: removeBtn?.dataset.tealium });
                }

                updatePromoAccordion(data.totals.discountsHtml);
                updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                updateCartTotals(data);
                updateLoyaltyVouchers(data);
                updateCartPromoBlock(data);
                updateCartItems(data);
                updateGWP(data);
                updateMonogramDetails(data);
                validateBasket(data);

                $body.trigger('cart:update');

                // update Marketing Cloud Analytics trackCart event
                trackCart(data.mcAnalyticsArray);

                $cartProductCard.spinner().stop();
            }
        } catch (err) {
            if (data?.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(data?.errorMessage);
                target.value = parseInt(preSelectQty, 10);
                $cartProductCard.spinner().stop();
            }
        }
    });

    /**
     * Apply Loyalty Voucher/Offer
     */
    $body.on('click', '.apply-voucher-tote-cta', async e => {
        e.preventDefault();
        const { target } = e;
        const couponInvalidErr = queryFirst('.loyalty-error-message');
        const voucherCardEl = target.closest('.voucher-card-content');
        const removeRewardEl = queryFirst('.remove-voucher-tote-cta', voucherCardEl);
        const { couponCode } = voucherCardEl.dataset;
        const csrfToken = queryFirst('.csrf-token', voucherCardEl)?.value;
        const $spinner = $.spinner();

        addClass(couponInvalidErr, HIDDEN_CLASS);
        $spinner.start();

        let data;
        try {
            const res = await fetch(target.dataset.action, {
                method: 'POST',
                body: toFormData({
                    csrf_token: csrfToken,
                    couponCode,
                    isCheckout
                })
            });

            data = await res.json();
            if (!res.ok) throw new Error('response was not OK');

            if (data.error) {
                if (couponInvalidErr) {
                    couponInvalidErr.innerHTML = data?.errorMessage;
                    removeClass(couponInvalidErr, HIDDEN_CLASS);
                }

                $spinner.stop();
            } else {
                const { loyalty, discounts, discountsHtml } = data.totals;
                if (loyalty && (loyalty.redeemedVouchersCount || loyalty.redeemedOffersCount)) {
                    addClass(voucherCardEl, loyaltyVoucherApplied);
                    addClass(target, HIDDEN_CLASS);
                    removeClass(removeRewardEl, HIDDEN_CLASS);

                    const { memberVouchers, memberOffers } = loyalty;
                    const voucherCardCtaEl = target.closest('.js-vouchers-cta');
                    const offerCardCtaEl = target.closest('.js-offers-cta');
                    if (voucherCardCtaEl) {
                        const { voucherCode } = voucherCardCtaEl.dataset;
                        memberVouchers.forEach(voucher => {
                            const { applied, code } = voucher;
                            if (applied && code === voucherCode) {
                                voucherCardCtaEl.dataset.uuid = voucher.UUID;
                            }
                        });
                    }
                    if (offerCardCtaEl) {
                        const { voucherCode } = offerCardCtaEl.dataset;
                        memberOffers.forEach(offer => {
                            const { applied, code } = offer;
                            if (applied && code === voucherCode) {
                                offerCardCtaEl.dataset.uuid = offer.UUID;
                            }
                        });
                    }
                    updateLoyaltyVouchers(data);
                    if (discounts) {
                        updateGWP(data);
                        updatePromoCodeDetails(discounts, data.selectedShippingMethod);
                    }
                    updateCartTotals(data);
                    if (!checkoutContainer) {
                        updateCartPromoBlock(data);
                        validateBasket(data);
                    } else {
                        $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                    }
                }
                updatePromoAccordion(discountsHtml);
                $spinner.stop();
            }
        } catch (err) {
            console.error(`Cart error: ${err.message}`);
            $spinner.stop();

            if (data?.csrfError) {
                $body.trigger('csrf:error');
            } else {
                createErrorNotification(data?.errorMessage);
            }
        }
    });

    /**
     * Remove Loyalty Voucher/Offer
     */
    $body.on('click', '.remove-voucher-tote-cta', async e => {
        e.preventDefault();
        const { target } = e;
        const couponInvalidErr = queryFirst('.loyalty-error-message');
        const voucherCardEl = target.closest('.voucher-card-content');
        const voucherCardCtaEl = queryFirst('.js-vouchers-cta', voucherCardEl);
        const offerCardCtaEl = queryFirst('.js-offers-cta', voucherCardEl);
        const applyRewardEl = queryFirst('.apply-voucher-tote-cta', voucherCardEl);
        const { action } = target.dataset;
        const { uuid, voucherCode } = voucherCardCtaEl ? voucherCardCtaEl.dataset : offerCardCtaEl.dataset;
        const url = appendToUrl(action, { code: voucherCode, uuid, isCheckout });
        const $spinner = $.spinner();

        addClass(couponInvalidErr, HIDDEN_CLASS);
        $spinner.start();

        let data;
        try {
            const res = await fetch(url);

            data = await res.json();
            if (!res.ok) throw new Error('response was not OK');

            const { discounts } = data.totals;
            addClass(target, HIDDEN_CLASS);
            removeClass(applyRewardEl, HIDDEN_CLASS);
            removeClass(voucherCardEl, loyaltyVoucherApplied);
            updateLoyaltyVouchers(data);
            updateGWP(data);
            updateCartTotals(data);
            if (discounts) {
                updatePromoCodeDetails(discounts, data.selectedShippingMethod);
                if (!checkoutContainer) {
                    updateCartPromoBlock(data);
                    validateBasket(data);
                } else {
                    $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                }
                updatePromoAccordion(data.totals.discountsHtml);
                updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
            }

            $spinner.stop();
        } catch (err) {
            console.error(`Cart error: ${err.message}`);
            $spinner.stop();

            if (data?.redirectUrl) {
                window.location.href = data?.redirectUrl;
            } else {
                createErrorNotification(data?.errorMessage);
            }
        }
    });

    /**
     * Promo/Coupon code submit event listener
     */
    $('.promo-code-form').on('submit', async e => {
        e.preventDefault();
        const $spinner = $.spinner();
        $spinner.start();

        const { currentTarget: promoForm } = e;
        const couponMissingErr = queryFirst('.coupon-missing-error', promoForm);
        const couponInvalidErr = queryFirst('.coupon-error-message', promoForm);
        const couponField = queryFirst('.coupon-code-field', promoForm);
        const submitBtn = queryFirst('.promo-code-btn', promoForm);

        addClass(couponMissingErr, HIDDEN_CLASS);
        removeClass(couponField, INVALID_CLASS);
        couponInvalidErr.innerHTML = '';

        if (!couponField?.value) {
            addClass(couponField, INVALID_CLASS);
            couponField.setAttribute('aria-describedby', 'missingCouponCode');
            removeClass(couponMissingErr, HIDDEN_CLASS);
            $spinner.stop();
            return false;
        }

        let data;
        const reqBody = new FormData(promoForm);
        reqBody.append('isCheckout', isCheckout);

        try {
            const res = await fetch(promoForm.action, {
                method: 'POST',
                body: reqBody
            });

            data = await res.json();
            if (!res.ok) throw new Error('response was not OK');

            couponField.value = '';
            submitBtn.disabled = true;
            if (data.error) {
                addClass(couponField, INVALID_CLASS);
                couponField.setAttribute('aria-describedby', 'invalidCouponCode');
                couponInvalidErr.innerHTML = data.errorMessage;
            } else {
                updateGWP(data);
                updatePromoAccordion(data.totals.discountsHtml);
                updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                updateCartTotals(data);
                updateLoyaltyVouchers(data);
                if (!checkoutContainer) {
                    updateCartPromoBlock(data);
                    validateBasket(data);
                } else {
                    $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
                }
            }
            if (!checkoutContainer) {
                $('.cart-container').trigger('add-coupon', data);
            } else {
                $(checkoutContainer).trigger('add-coupon', data);
            }
            $spinner.stop();
        } catch (err) {
            console.error(`Cart error: ${err.message}`);
            $spinner.stop();

            if (data?.csrfError) {
                $body.trigger('csrf:error');
            } else {
                createErrorNotification(data?.errorMessage);
            }
        }
    });

    /**
     * Promo/Coupon Code Removal Event Listener
     */
    $body.on('click', '.delete-coupon-confirmation-btn', async e => {
        e.preventDefault();

        const { target } = e;
        const $spinner = $.spinner();
        const { action, uuid, code } = target.dataset;
        const url = appendToUrl(action, {
            code,
            uuid,
            isCheckout
        });

        $spinner.start();

        let data;
        try {
            const res = await fetch(url);

            data = await res.json();
            if (!res.ok) throw new Error('response was not OK');

            remove(queryFirst(`.coupon-uuid-${uuid}`));
            updateGWP(data);
            updateCartTotals(data);
            document.cookie = `dwapupreq=${new Date().getTime()}; `;
            if (!checkoutContainer) {
                updateCartPromoBlock(data);
                validateBasket(data);
            } else {
                $(checkoutContainer).trigger('coupon-update', data).trigger('togglePaymentMethods', data);
            }
            updatePromoAccordion(data.totals.discountsHtml);
            updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
            updateLoyaltyVouchers(data);
            $spinner.stop();
        } catch (err) {
            console.error(`Cart error: ${err.message}`);
            $spinner.stop();

            if (data?.redirectUrl) {
                window.location.href = data?.redirectUrl;
            } else {
                createErrorNotification(data?.errorMessage);
            }
        }
    });

    /**
     * Enable/disable the promo code apply button based on promo code entry
     */
    ['keyup', 'paste'].forEach(eventType => {
        queryFirst('.promo-accordion .coupon-code-field')?.addEventListener(eventType, e => {
            const { currentTarget } = e;
            const promoAccordion = currentTarget.closest('.promo-accordion');
            const submitBtn = queryFirst('.promo-code-btn', promoAccordion);

            let inputText = currentTarget.value;

            if (e.type === 'paste') {
                inputText = e.clipboardData.getData('text');
            }

            if (submitBtn) {
                if (inputText.length > 0) {
                    submitBtn.disabled = false;
                } else {
                    submitBtn.disabled = true;
                }
            }
        });
    });

    $body.on('click', '.cart-page .bonus-product-button', async event => {
        event.stopPropagation();
        const { target } = event;
        const btnWrapper = target.closest('.bonus-product-button');

        addClass(btnWrapper, 'launched-modal');

        try {
            const res = await fetch(btnWrapper.dataset.url);
            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            base.methods.editBonusProducts(data);
        } catch (err) {
            console.error(`cart error: ${err.message}`);
        }
    });

    // SFL - Move all to tote
    $body.on('click', '.js-move-sfl-to-tote, .move-all-to-tote', async e => {
        e.preventDefault();
        const { target } = e;
        const { qtyValue: quantity, pid: productID, action: url, uuid, isGiftCard, isMonogram: hasMonogram } = target.dataset;
        const cartProductCard = uuid ? target.closest(`.product-info[data-uuid="${uuid}"]`) : null;
        const storePickupGroupElement = uuid ? cartProductCard.closest('.cart-products-ispu') : queryFirst('.cart-products-ispu');
        const origPositionY = target.getBoundingClientRect().y;
        const reqData = {};

        if (productID) reqData.pid = productID;
        if (quantity) reqData.qty = parseInt(quantity, 10);
        if (uuid) reqData.uuid = uuid;
        if (isGiftCard) reqData.isGiftCard = isGiftCard;
        if (hasMonogram) reqData.isMonogram = hasMonogram;

        if (!hasClass(target, 'js-move-sfl-to-tote')) {
            $.spinner().start();
        }

        if (cartProductCard) {
            slideUp(cartProductCard, ANIM_TIME_QUICK_2).then(() => {
                remove(cartProductCard);
                safariFix();
            });
        }

        let data;
        try {
            const res = await fetch(url, {
                method: 'POST',
                body: toFormData(reqData)
            });

            data = await res.json();
            if (!res.ok) throw new Error('response was not OK');

            if (data.error) {
                const { lineItemErrorMessage } = data;
                const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                if (lineItemErrorMessage && availabilityFlashMessageEl) {
                    removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                    availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                }
                $.spinner().stop();
            } else {
                // When we come from Order Reciept page we do not have basket, so
                // things like PayPal skip to initilize. As workaround for this case
                // we force page to reload.
                const currentBasketStatusElement = document.querySelector('[data-has-initial-basket]');
                if (currentBasketStatusElement && currentBasketStatusElement.dataset.hasInitialBasket === 'false') {
                    trackCart(data.mcAnalyticsArray); // update Marketing Cloud Analytics trackCart event
                    window.history.replaceState({}, '', window.location.href.split('?')[0]);
                    window.location.reload();
                    return;
                }

                const { cartModel, count, toteCount } = data;
                const { updatedStoreQuantity, storeGroupMessage } = cartModel;
                queryFirst('.cart-items-count.item-count-sfl').textContent = count;
                queryFirst('.cart-items-count.item-count-tote').textContent = toteCount;

                if (storePickupGroupElement && updatedStoreQuantity && storeGroupMessage) {
                    const storeGroupMessageElement = queryFirst('.store-product-items-message', storePickupGroupElement);
                    if (storeGroupMessageElement) storeGroupMessageElement.textContent = storeGroupMessage;
                }

                updatePromoAccordion(cartModel.totals.discountsHtml);
                updatePromoCodeDetails(cartModel.totals.discounts, cartModel.selectedShippingMethod);
                updateCartTotals(cartModel);
                updateLoyaltyVouchers(cartModel);
                updateCartPromoBlock(cartModel);
                updateGWP(cartModel);
                updateCartItems(cartModel);
                updateMonogramDetails(cartModel);
                updateSaveForLaterDetails(data);
                validateBasket(cartModel);
                $body.trigger('cart:update');

                // update Marketing Cloud Analytics trackCart event
                trackCart(data.mcAnalyticsArray);

                $.spinner().stop();

                if (hasClass(target, 'move-all-to-tote')) {
                    scrollTo(0);
                }
            }
        } catch (err) {
            console.error(`Cart error: ${err.message}`);
            if (data) {
                const { errorMessage, redirectUrl } = data;
                if (redirectUrl) {
                    window.location.href = redirectUrl;
                } else {
                    createErrorNotification(errorMessage);
                    $.spinner().stop();
                }
            }
        }
    });

    $body.on('click', '.remove-all-sfl', async e => {
        e.preventDefault();

        const { target } = e;
        const { action: url } = target.dataset;
        const $spinner = $.spinner();

        $spinner.start();

        try {
            const res = await fetch(url);
            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            if (data.error) {
                throw new Error(data.msg);
            }

            if (data.success) {
                const { count } = data;
                const itemsCountSFL = queryFirst('.item-count-sfl', cartTopLevel);
                const numberItemsSFL = queryFirst('.number-of-items-sfl');
                remove(queryAll('.cart-item', sflPage));
                itemsCountSFL.textContent = count;
                numberItemsSFL.textContent = count;
                scrollTo(0);
            }

            safariFix();
            $spinner.stop();
        } catch (err) {
            $spinner.stop();
            console.error(`cart error: ${err.message}`);
        }
    });

    $body.on('click', '.js-remove-all-tote', async e => {
        e.preventDefault();

        const { target } = e;
        const { action: url } = target.dataset;
        const $spinner = $.spinner();

        $spinner.start();

        try {
            const res = await fetch(url);
            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            if (data.error) {
                throw new Error(data.msg);
            }

            if (data.success) {
                remove(queryAll('.cart-item', queryFirst('.cart-tab', cartPage)));
                $('.cart-container').trigger('cart:updateTotals', data);
                scrollTo(0);
            }

            safariFix();
            $spinner.stop();
        } catch (err) {
            $spinner.stop();
            console.error(`cart error: ${err.message}`);
            window.location.reload();
        }
    });

    $body.on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    detail.afterAttributeSelect();
    $body.on('product:afterAttributeSelect', function (_e, response) {
        const responseContainer = response.container[0];
        const { product } = response.data;
        const { categoryUrl, isCartPage } = responseContainer.dataset;
        const availabilityMessageEl = queryFirst('.product-availability .availability-message-text', responseContainer);

        if (!product.available && isCartPage && categoryUrl) {
            const message = availabilityMessageEl.innerText;
            availabilityMessageEl.innerHTML = cartTemplates.cartAvailabilityMessageOOS(message, categoryUrl);
        }

        const updateCartButton = queryFirst('.update-cart-product-global', responseContainer);
        if (updateCartButton) {
            updateCartButton.disabled = !product.readyToOrder || !product.available || product.isDirectlyPurchasable === false;
        }
    });

    $body.on('product:notifyMeHidden', function (_e, productContainer) {
        const updateCartButton = queryFirst('.update-cart-product-global', productContainer);
        removeClass(updateCartButton, 'd-none');
    });

    $body.on('product:notifyMeShown', function (_e, productContainer) {
        const updateCartButton = queryFirst('.update-cart-product-global', productContainer);
        addClass(updateCartButton, 'd-none');
    });

    $body.on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $body.on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    // This does not appear to be used currently -- perhaps related to the old edit product flow
    $body.on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        const { target } = e;
        const buttonContainer = target.closest('.cart-and-ipay');
        const updateCartHiddenField = queryFirst('.update-cart-url', buttonContainer);
        const updateProductUrl = updateCartHiddenField.value;
        const { selectedQuantity, selectedOption, uuid } = updateCartHiddenField.dataset;
        const { storeId } = target.dataset;
        const form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOption,
            storeId
        };
        const cartProductCard = queryFirst('.card.product-info.uuid-' + uuid);
        $('#quickViewModal').spinner().start();

        const giftCardFormEl = buttonContainer.closest('.gift-card');
        if (giftCardFormEl) {
            updateFormObjectForGiftCard(form, giftCardFormEl);
        }

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    if (data.error) {
                        const { lineItemErrorMessage } = data;
                        const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                        if (lineItemErrorMessage && availabilityFlashMessageEl) {
                            removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                            availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                            setTimeout(() => {
                                addClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                            }, 10000);
                        }
                        $('#quickViewModal').modal('hide').spinner().stop();
                    } else if (data.success) {
                        $('#quickViewModal').modal('hide').spinner().stop();

                        updatePromoAccordion(data.cartModel.totals.discountsHtml);
                        updatePromoCodeDetails(data.cartModel.totals.discounts, data.cartModel.selectedShippingMethod);
                        updateCartTotals(data.cartModel);
                        updateLoyaltyVouchers(data.cartModel);
                        updateCartPromoBlock(data.cartModel);
                        updateAvailability(data.cartModel, uuid);
                        updateProductDetails(data, uuid, target.dataset.giftWrapAvailable);
                        updateGWP(data.cartModel);
                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        validateBasket(data.cartModel);

                        const { enablePayPal, enableApplePay } = data.cartModel;

                        const paypalContainerEl = queryFirst('.checkout-continue');
                        if (paypalContainerEl) {
                            if (enablePayPal) {
                                removeClass(paypalContainerEl, HIDDEN_CLASS);
                            } else {
                                addClass(paypalContainerEl, HIDDEN_CLASS);
                            }
                        }

                        const applePayButton = queryFirst('.dw-apple-pay-button');
                        if (applePayButton) {
                            if (enableApplePay) {
                                removeClass(applePayButton, HIDDEN_CLASS);
                            } else {
                                addClass(applePayButton, HIDDEN_CLASS);
                            }
                        }

                        $body.trigger('cart:update');
                    } else {
                        formValidation(giftCardFormEl, data);
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    // Event listener for home delivery method link in cart page
    $cartPage.on('click', '.js-move-to-shipping', async event => {
        const { target } = event;
        const { uuid, pid, actionUrl } = target.dataset;
        const cartProductCard = target.closest(`.cart-product-line-item[data-uuid="${uuid}"]`);
        const $cartProductCard = $(cartProductCard);
        const quantity = queryFirst('.quantity', cartProductCard).value;
        $cartProductCard.spinner().start();

        try {
            const res = await fetch(actionUrl, {
                method: 'POST',
                body: toFormData({
                    uuid,
                    pid,
                    quantity
                })
            });

            if (!res.ok) throw new Error('response was not OK');
            const data = await res.json();

            if (data.error) {
                const { lineItemErrorMessage } = data;
                const availabilityFlashMessageEl = queryFirst('.availability-flash-message', cartProductCard);
                if (lineItemErrorMessage && availabilityFlashMessageEl) {
                    removeClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                    availabilityFlashMessageEl.textContent = lineItemErrorMessage;
                    setTimeout(() => {
                        addClass(availabilityFlashMessageEl, HIDDEN_CLASS);
                    }, 10000);
                }
                $cartProductCard.spinner().stop();
            } else {
                updatePromoAccordion(data.totals.discountsHtml);

                if (data.cartProductsHtml) {
                    document.cookie = `dwapupreq=${new Date().getTime()}; `;
                    updateCartItems(data);
                    updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
                    updateCartTotals(data);
                    updateLoyaltyVouchers(data);
                    validateBasket(data);
                    $body.trigger('cart:update');
                    $cartProductCard.spinner().stop();
                }
            }
        } catch (err) {
            console.error(`Cart error: ${err.message}`);
            $cartProductCard.spinner().stop();
        }
    });

    // Event listener for product gift wrap checkbox
    $cartPage.on('click', '.gift-wrap-input', async event => {
        const { target } = event;
        const { pid, uuid, actionUrl, ispu } = target.dataset;
        const cartProductCard = target.closest('.cart-product-line-item');
        const $cartProductCard = $(cartProductCard);
        let isGiftWrap = target.checked;
        let isGiftwrapISPU = false;
        let removeGwpISPU = false;
        if (ispu === 'true') {
            if (isGiftWrap) {
                isGiftWrap = false;
                isGiftwrapISPU = true;
            } else {
                removeGwpISPU = true;
            }
        }
        const quantity = queryFirst('.quantity', cartProductCard)?.value;
        if (quantity) {
            $cartProductCard.spinner().start();
            try {
                const res = await fetch(actionUrl, {
                    method: 'POST',
                    body: toFormData({
                        uuid,
                        pid,
                        quantity,
                        isGiftWrap,
                        isGiftwrapISPU,
                        removeGwpISPU
                    })
                });

                if (!res.ok) throw new Error('response was not OK');
                const data = await res.json();

                document.cookie = `dwapupreq=${new Date().getTime()}; `;
                updateCartTotals(data);
                enableOrDisableCheckoutButton();
                $cartProductCard.spinner().stop();
            } catch (err) {
                $cartProductCard.spinner().stop();
                console.error(`Cart error: ${err.message}`);
            }
        }
    });

    $body.on('checkout:updateCheckoutView', function (e, data) {
        const { totals, shipping } = data.order;
        const { discounts, discountsHtml } = totals;

        updatePromoAccordion(discountsHtml);

        if (discounts.length) {
            shipping.forEach(eachShipping => {
                const { selectedShippingMethod } = eachShipping;
                if (selectedShippingMethod && !selectedShippingMethod.storePickupEnabled) {
                    updatePromoCodeDetails(discounts, selectedShippingMethod);
                }
            });
        }
    });

    queryAll('.checkout-continue-section .checkout-btn, .cart-stickybar .checkout-btn').forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();
            const { target } = e;
            const { href, isBoltUserLoggedIn } = target.dataset;
            if (isBoltUserLoggedIn === 'true' && boltCheckoutAccountExistEl) {
                if (lpRegisteredUserEmail) {
                    const checkBoltAccountUrl = boltCheckoutAccountExistEl.value + '=' + encodeURIComponent(lpRegisteredUserEmail);
                    $.ajax({
                        url: checkBoltAccountUrl,
                        method: 'GET',
                        success(data) {
                            if (data && data.has_bolt_account) {
                                fetchBoltAccountDetails(href);
                            } else {
                                window.location.href = href;
                            }
                        },
                        error: function () {
                            window.location.href = href;
                        }
                    });
                } else {
                    fetchBoltAccountDetails(href);
                }
            } else {
                window.location.href = href;
            }
        });
    });

    $body.on('click', '.bolt-checkout-cta', function (e) {
        e.preventDefault();
        checkAccountAndFetchDetail();
    });

    // Show promo details sheet on click of the promo block button
    // Selector must be specific to cart page -- otherwise this will trigger incorrectly in mini-cart
    $body.on('click', '.discounts-section button.promo-block', () => {
        $('#promoDetailsModal').modal('show');
    });

    $body.on('cart:triggerUpdate', (e, data) => {
        updatePromoAccordion(data.totals.discountsHtml);
        updatePromoCodeDetails(data.totals.discounts, data.selectedShippingMethod);
        updateCartTotals(data);
        updateLoyaltyVouchers(data);
        updateCartPromoBlock(data);
        updateCartItems(data);
        updateGWP(data);
        updateMonogramDetails(data);
        validateBasket(data);

        $body.trigger('cart:update');
    });

    enableOrDisableCheckoutButton();

    // Afterpay
    require('afterpay/afterpay')({
        anchors: '.express-checkout-actions',
        observerTargets: '.grand-total-container',
        priceTargets: '.grand-total-value',
        renderMode: 'adjacent',
        observerIgnoreAnchor: true
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.updateBonusSelection();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
    window.setTimeout(function () {
        base.revealRecommendations();
        $body.trigger('search:updateProducts');
    }, 2000);

    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    };
}

export { updateCartTotals, updatePromoCodeDetails, updateCartItems, validateBasket };
export default init;

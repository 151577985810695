'use strict';

// OOTB Code
let googleMapScript;
let geocoder;

/**
 * This method includes google api for using google maps
 * @param {string} googleKey google api key
 */
function includeGoogleMaps(googleKey) {
    if (!googleMapScript && googleKey) {
        googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}`;
        document.head.append(googleMapScript);
    }
}

/**
 * This method renders location from google maps based on address
 * @param {function} callBack callback function
 * @param {Object} mixin additional parameters
 */
function getLatLong(callBack, mixin = {}) {
    const { address } = mixin;
    geocoder = new google.maps.Geocoder();
    if (!(geocoder && address)) return;
    const data = Object.assign({}, mixin);
    geocoder.geocode({ address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
            const { location } = results[0].geometry;
            data.latitude = location.lat();
            data.longitude = location.lng();
        }
        callBack(data);
    });
}

export { includeGoogleMaps, getLatLong };

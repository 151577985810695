// DEMO Simple HTML markup for SWIPER carousel
// Carousels inits on slot-loaded event for all slot carousels
// !! Please note that if two carousels are used - one inside the other -
// then the navigation block for the outer carousel should be placed above the block with slides.
// For manual initialization use initCarousels($parentElement: );
// < !--Slider config container-- >
// <div class="js-carousel-wrapper" data-carousel-context="recommendations">

//     < !--Slider main container-- >
//     <div class="js-swiper swiper">
//         <!-- If we need navigation buttons -->
//         <div class="js-swiper-button-prev swiper-button m-prev"></div>
//         <div class="js-swiper-button-next swiper-button m-next"></div>
//         <!-- Additional required wrapper -->
//         <div class="swiper-wrapper">
//             <!-- Slides -->
//             <div class="swiper-slide">Slide 1</div>
//             <div class="swiper-slide">Slide 2</div>
//             <div class="swiper-slide">Slide 3</div>
//             ...
//         </div>
//         <div class="swiper-bottom">
//             <!-- If we need scrollbar -->
//             <div class="js-swiper-scrollbar swiper-scrollbar"></div>
//             <!-- If we need pagination -->
//             <div class="js-swiper-pagination swiper-pagination"></div>
//         </div>
//     </div>
// </div>
import Swiper from 'swiper';
import { Navigation, Scrollbar, Pagination } from 'swiper/modules';

import { queryFirst, queryAll, addClass, removeClass, hasClass, matchesBreakpoint } from '../domUtil';

import { isHighGridDensity, isMediumGridDensity } from '../productTiles/utils/gridDensity';
import { SELECTOR_PRODUCT_TILE, SELECTOR_PRODUCT_GRID, SELECTOR_TILE_IMAGE_CAROUSEL_IMAGE } from '../productTiles/productTileConstants';

import { sliderPaginationTemplate } from '../templates';

const CLASS_CAROUSEL_IGNORED = 'm-no-slider';
const CLASS_LAZY_SLIDER = 'swiper-lazy-image';
const CLASS_CAROUSEL_INITIALIZED = 'swiper-initialized';
const CLASS_CAROUSEL_WRAPPER = 'js-carousel-wrapper';
const CLASS_SELECTOR_CAROUSEL = 'js-swiper';
const CLASS_CAROUSEL_BTN_NEXT = 'js-swiper-button-next';
const CLASS_CAROUSEL_BTN_PREV = 'js-swiper-button-prev';
const CLASS_CAROUSEL_SCROLLBAR = 'js-swiper-scrollbar';
const CLASS_CAROUSEL_OUTER_SCROLLBAR = '.js-swiper-scrollbar-outer';
const SELECTOR_CLASS_SELECTOR_CAROUSEL = `.${CLASS_SELECTOR_CAROUSEL}`;
const SELECTOR_CAROUSEL_WRAPPER = `.${CLASS_CAROUSEL_WRAPPER}`;
const SELECTOR_CAROUSEL_BTN_PREV = `.${CLASS_CAROUSEL_BTN_PREV}`;
const SELECTOR_CAROUSEL_BTN_NEXT = `.${CLASS_CAROUSEL_BTN_NEXT}`;
const SELECTOR_CAROUSEL_SCROLLBAR = `.${CLASS_CAROUSEL_SCROLLBAR}`;
const CONTEXT_STANDARD = 'feature-standard';
const CONTEXT_EMPHASIZED = 'feature-emphasized';
const CONTEXT_PERSONALIZED = 'feature-personalized';

const $body = $('body');

let observer;
/**
 * Returns carousel context
 * @param {HTMLElement} carouselWrapper corousel wraper element
 * @returns {string} returns carousel context (default, recommendations)
 */
function getContext(carouselWrapper) {
    const context = carouselWrapper?.dataset?.carouselContext;
    if (!context) {
        return 'default';
    }
    return context;
}

/**
 * Initializes the carousel and updates visibility for elements
 * @param {HTMLElement} carouselEl - the container element for the carousel
 */
function initCarouselElementDomLoaded(carouselEl) {
    if (isHighGridDensity()) {
        removeClass(carouselEl, CLASS_CAROUSEL_INITIALIZED, CLASS_SELECTOR_CAROUSEL);
        addClass(carouselEl, CLASS_CAROUSEL_IGNORED);
        removeClass(queryFirst('.swiper-lazy-image', carouselEl), CLASS_LAZY_SLIDER);

        return;
    }

    const carouselWrapper = carouselEl.closest(SELECTOR_CAROUSEL_WRAPPER);
    const context = getContext(carouselWrapper);

    const navigationConfig = {
        nextEl: queryFirst(SELECTOR_CAROUSEL_BTN_NEXT, carouselWrapper),
        prevEl: queryFirst(SELECTOR_CAROUSEL_BTN_PREV, carouselWrapper)
    };

    const customPadding = function (swiper) {
        if (matchesBreakpoint('md') && !matchesBreakpoint('lg')) {
            swiper.params.slidesOffsetBefore = swiper.width / 15;
            swiper.params.slidesOffsetAfter = swiper.width / 15;
        }
    };

    let swiperConfig = {
        modules: [Navigation, Scrollbar],
        simulateTouch: false,
        slidesPerView: 'auto',
        navigation: navigationConfig,
        roundLengths: true,
        spaceBetween: 1,
        scrollbar: {
            el: queryFirst(SELECTOR_CAROUSEL_SCROLLBAR, carouselWrapper)
        },
        on: {
            slideNextTransitionStart: (swiper) => {
                const { slidesPerView } = swiper.params;
                const { activeIndex } = swiper;
                const slideNumber = (Number.isFinite(activeIndex) ? activeIndex : 0) + (Number.isFinite(slidesPerView) ? Math.ceil(swiper.params.slidesPerView) : swiper.slidesPerViewDynamic()) - 1;
                let slide = swiper.slides[slideNumber];
                let img = queryFirst('.swiper-lazy-image', slide);
                removeClass(img, 'swiper-lazy-image');
            },
            slidePrevTransitionStart: () => {
                let img = queryFirst('.swiper-slide-active .swiper-lazy-image');
                removeClass(img, 'swiper-lazy-image');
            },
            afterInit: (swiper) => {
                const viewImages = Number.isFinite(swiper.params.slidesPerView) ? Math.ceil(swiper.params.slidesPerView) : swiper.slidesPerViewDynamic();
                for (let i = 0; i < viewImages; i++) {
                    let slide = swiper.slides[i];
                    if (slide) {
                        let img = queryFirst('.swiper-lazy-image', slide);
                        removeClass(img, 'swiper-lazy-image');
                    }
                }
            }
        }
    };

    switch (context) {
        case CONTEXT_STANDARD:
        case CONTEXT_EMPHASIZED:
        case CONTEXT_PERSONALIZED:
            swiperConfig = {
                ...swiperConfig,
                slidesPerView: context === CONTEXT_EMPHASIZED ? 1.5 : 2,
                slidesPerGroup: 1,
                spaceBetween: 4,
                scrollbar: {
                    el: queryFirst(CLASS_CAROUSEL_OUTER_SCROLLBAR, carouselWrapper)
                },
                breakpoints: {
                    768: {
                        slidesPerView: context === CONTEXT_STANDARD ? 3 : 2.5,
                        slidesPerGroup: 2
                    },
                    1025: {
                        slidesPerView: context === CONTEXT_STANDARD ? 5 : 3,
                        slidesPerGroup: context === CONTEXT_STANDARD ? 5 : 3,
                        draggable: false
                    }
                }
            };
            break;

        case 'filters':
            swiperConfig = {
                ...swiperConfig,
                slidesPerView: 1.65,
                centerInsufficientSlides: true,
                breakpoints: {
                    768: { slidesPerView: 'auto', draggable: false }
                }
            };
            break;

        case 'ugc':
            swiperConfig = {
                ...swiperConfig,
                slidesPerView: 1.5,
                watchSlidesProgress: true,
                slideFullyVisibleClass: 'ugc-visible',
                on: {
                    transitionStart: function () {
                        this.slides.forEach(slide => {
                            if (hasClass(slide, 'ugc-visible')) {
                                return;
                            }
                            queryFirst('.video', slide)?.pause();
                        });
                    },

                    transitionEnd: function () {
                        this.slides.forEach(slide => {
                            if (hasClass(slide, 'ugc-visible')) {
                                queryFirst('.video', slide)?.play();
                            }
                        });
                    }
                },
                breakpoints: {
                    768: { slidesPerView: 5 }
                }
            };
            break;

        case 'recently-viewed':
            swiperConfig = {
                ...swiperConfig,
                simulateTouch: true,
                slidesPerView: 3.3,
                spaceBetween: 4,
                scrollbar: {
                    el: queryFirst(CLASS_CAROUSEL_OUTER_SCROLLBAR, carouselWrapper)
                },
                breakpoints: {
                    768: { slidesPerView: 8, scrollbar: false, enabled: false },
                    1025: { slidesPerView: 10, draggable: false }
                }
            };
            break;

        case 'shop-by-print':
            swiperConfig = {
                ...swiperConfig,
                slidesPerView: 1.8,
                spaceBetween: 32,
                watchSlidesProgress: true,
                slideVisibleClass: 'spb-visible',
                breakpoints: {
                    768: { slidesPerView: 3.4 },
                    1025: { slidesPerView: 5, slidesPerGroup: 5, draggable: false }
                }
            };
            break;

        case 'shoppable-media':
            swiperConfig = {
                ...swiperConfig,
                slidesPerView: 1,
                breakpoints: {
                    768: { enabled: true, slidesPerView: 2 },
                    1025: { enabled: false }
                },
                on: {
                    beforeResize(swiper) {
                        swiper.slideTo(0);
                    }
                }
            };
            break;

        case 'store-events':
            swiperConfig = {
                ...swiperConfig,
                modules: [Navigation, Scrollbar],
                spaceBetween: 10,
                centerInsufficientSlides: true,
                scrollbar: {
                    el: queryFirst(CLASS_CAROUSEL_OUTER_SCROLLBAR, carouselWrapper)
                }
            };
            break;

        case 'loyalty-offers':
            swiperConfig = {
                ...swiperConfig,
                modules: [Navigation, Scrollbar],
                spaceBetween: 4,
                slidesPerView: 1.65,
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20,
                scrollbar: {
                    el: queryFirst(CLASS_CAROUSEL_OUTER_SCROLLBAR, carouselWrapper)
                },
                breakpoints: {
                    768: { enabled: true, slidesPerView: 2.5 },
                    1025: { enabled: true, slidesPerView: 4, slidesPerGroup: 4, slidesOffsetBefore: 0, slidesOffsetAfter: 0 }
                },
                on: {
                    beforeResize(swiper) {
                        swiper.slideTo(0);
                    },
                    init: customPadding,
                    resize: customPadding
                }
            };
            break;

        case 'loyalty-benefits':
            swiperConfig = {
                ...swiperConfig,
                modules: [Scrollbar],
                spaceBetween: 4,
                slidesPerView: 1.54,
                slidesOffsetBefore: 20,
                slidesOffsetAfter: 20,
                scrollbar: {
                    el: queryFirst(CLASS_CAROUSEL_OUTER_SCROLLBAR, carouselWrapper)
                },
                breakpoints: {
                    768: { enabled: true, slidesPerView: 2.67, slidesOffsetBefore: 0, slidesOffsetAfter: 0 },
                    1025: { enabled: false, slidesPerView: 5, slidesOffsetBefore: 0, slidesOffsetAfter: 0 }
                },
                on: {
                    beforeResize(swiper) {
                        swiper.slideTo(0);
                    }
                }
            };
            break;

        case 'auto':
            swiperConfig = {
                ...swiperConfig,
                modules: [Navigation, Scrollbar, Pagination],
                loop: true,
                centerInsufficientSlides: true,
                touchReleaseOnEdges: true,
                nested: true,
                pagination: {
                    el: queryFirst('.js-swiper-pagination', carouselWrapper),
                    type: 'fraction',
                    renderFraction: sliderPaginationTemplate
                }
            };
            break;

        default:
            swiperConfig = {
                ...swiperConfig,
                slidesPerView: 1,
                loop: true,
                centerInsufficientSlides: true
            };
            break;
    }

    // save carousel to element for future access
    carouselEl.carousel = new Swiper(carouselEl, swiperConfig);

    ['slideChange', 'slideChangeTransitionStart'].forEach(eventName => {
        carouselEl.carousel.on(eventName, swiper => {
            $(carouselEl).trigger(`swiper.${eventName}`, [carouselEl, swiper]);
        });
    });
}

/**
 * Custom hover image toggle
 * @param {HTMLElement} tile element
 */
function customHoverTile(tile) {
    const hoverSupported = window.matchMedia('(hover: hover)').matches;

    if (!hoverSupported) {
        return;
    }

    const carouselEl = queryFirst(SELECTOR_CLASS_SELECTOR_CAROUSEL, tile);
    const swiperInstance = carouselEl?.carousel;

    if (!swiperInstance) {
        return;
    }
    swiperInstance.slideToLoop(1, 0);
}

/**
 * Reset first image on mouse leave
 * @param {HTMLElement} tile element
 */
function customResetTile(tile) {
    const carouselEl = queryFirst(SELECTOR_CLASS_SELECTOR_CAROUSEL, tile);
    const swiperInstance = carouselEl?.carousel;

    if (!swiperInstance) {
        return;
    }
    swiperInstance.slideToLoop(0, 0);
}

/**
 * Init carousels in a specific container
 * @param {HTMLElement} carouselEl - the container element for the carousel
 */
function initCarouselElement(carouselEl) {
    if (!carouselEl || hasClass(carouselEl, CLASS_CAROUSEL_INITIALIZED) || hasClass(carouselEl, CLASS_CAROUSEL_IGNORED)) {
        return;
    }

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => initCarouselElementDomLoaded(carouselEl));
    } else {
        initCarouselElementDomLoaded(carouselEl);
    }
    addClass(carouselEl, CLASS_CAROUSEL_INITIALIZED);
}

/**
 * Callback for intersection observer - determines whether or not to initialize product tiles
 * @param {HTMLElement[]} productTiles list of observered product tiles
 */
function onIntersection(productTiles) {
    productTiles.forEach(async (media) => {
        // Is the media in viewport?
        if (media.intersectionRatio > 0) {
            const { target: productTileEl } = media;
            await initCarouselElement(productTileEl);
            observer.unobserve(productTileEl);
        }
    });
}

/**
 * Init carousels in single specific container
 * @param {HTMLElement} containerEl container element
 */
function initCarouselsInSingleContainer(containerEl) {
    const carousels = queryAll(SELECTOR_CLASS_SELECTOR_CAROUSEL, containerEl);

    if (!observer && window.IntersectionObserver) {
        observer = new IntersectionObserver(onIntersection, {
            // If the image gets within 200px of the top/bottom of the viewport, start the init slider.
            rootMargin: '200px 0px',
            threshold: 0.01
        });
    }

    if (!observer) return;

    carousels.forEach(productTileEl => {
        observer.observe(productTileEl);
    });
}

/**
 * Attach events listners
 */
function attachEventsListenersOnce() {
    /*
     Need to split lazy-loading triggering logic for correct handling swipe and controls clicks
     it needed because specific behavior on when swiper with loop: true + low density 2 slides per tile
    */
    const initCarouselsAfterFrame = () => {
        // eslint-disable-next-line no-use-before-define
        initCarousels(queryAll(SELECTOR_PRODUCT_GRID));
    };

    $body.on('search:updateProducts', function () {
        // eslint-disable-next-line no-use-before-define
        initCarousels(queryAll(SELECTOR_PRODUCT_GRID));
    });

    $body.on('search:showMore', () => {
        requestAnimationFrame(initCarouselsAfterFrame);
    });

    $body.on('search:densityChange', () => {
        requestAnimationFrame(initCarouselsAfterFrame);
    });

    $body.on('swatchChangeEventCustom', (e, data) => {
        const { el } = data;
        if (!el) {
            return;
        }

        const pTileEl = el.closest(SELECTOR_PRODUCT_TILE);
        const carouselEl = queryFirst(SELECTOR_CLASS_SELECTOR_CAROUSEL, pTileEl);

        if (carouselEl && carouselEl.carousel) {
            carouselEl.carousel.update();
        }
    });

    $body.on('product:tile:initialize', SELECTOR_PRODUCT_TILE, e => {
        e.preventDefault();
        const productTileEl = e.currentTarget;
        const { isTileCarouselInitialized } = productTileEl.dataset;

        if (isTileCarouselInitialized) {
            return;
        }
        productTileEl.dataset.isTileCarouselInitialized = true;

        if (isMediumGridDensity()) {
            const tileImages = queryAll('.tile-image', productTileEl);

            productTileEl.addEventListener('mouseenter', () => customHoverTile(productTileEl));
            productTileEl.addEventListener('mouseleave', () => customResetTile(productTileEl));

            if (tileImages.length > 1) {
                const secTileImageEl = tileImages[1];
                removeClass(secTileImageEl, CLASS_LAZY_SLIDER);
            }
        }
    });

    document.addEventListener('slot-loaded', function (e) {
        const { target } = e;
        if (target.classList.contains('js-slot')) {
            // eslint-disable-next-line no-use-before-define
            initCarousels(target);
        }
    });
}

/**
 * Code to process some funcions only once even multiple includes
 */
const initOnce = (() => {
    let initialized = false;

    return () => {
        if (!initialized) {
            initialized = true;
            attachEventsListenersOnce();
        }
    };
})();

/**
 * Init carousels in specific container(s)
 * @param {HTMLElement[] | HTMLElement} containerEl element or array of elements
 */
function initCarousels(containerEl) {
    initOnce();

    (Array.isArray(containerEl) ? containerEl : [containerEl]).forEach(initCarouselsInSingleContainer);
}

/**
 * destroy carousels in specific container
 * @param {HTMLElement[] | HTMLElement} containerEl element or array of elements
 */
function destroySwiper(containerEl) {
    containerEl?.carousel?.destroy(true, true);
}

export default {
    initCarousels,
    initCarouselElement,
    destroySwiper,
    CLASS_CAROUSEL_INITIALIZED,
    SELECTOR_CAROUSEL_WRAPPER,
    SELECTOR_CLASS_SELECTOR_CAROUSEL
};

'use strict';

import { queryFirst, addClass, removeClass } from '../domUtil';
import { HIDDEN_CLASS } from '../constants';
import { getJSON } from '../util';

const $body = $('body');

export default {
    validateEmail: function () {
        $body.on('blur', '.notify-email', e => {
            const { target } = e;
            const { value } = target;
            const emailFormGroup = target.closest('.form-group');
            const emailError = queryFirst('.email-error', emailFormGroup);
            const { missingError, patternMismatch } = target.dataset;
            const invalidClass = 'is-invalid';
            if (!value) {
                addClass(target, invalidClass);
                emailError.textContent = missingError;
            } else if (target.checkValidity && !target.checkValidity()) {
                addClass(target, invalidClass);
                emailError.textContent = patternMismatch;
            } else {
                removeClass(target, invalidClass);
            }
        });
    },
    notifyMeSubmit: function () {
        $body.on('submit', '.js-notify-form', e => {
            e.preventDefault();
            const notifyForm = e.target;
            const actionUrl = notifyForm.getAttribute('action');

            const data = {
                productID: queryFirst('.js-notify-sku', notifyForm).value,
                email: queryFirst('.js-notify-email', notifyForm).value,
                subscribe: queryFirst('.js-notify-checkbox', notifyForm).checked
            };
            const $notifyForm = $(notifyForm);
            $notifyForm.spinner().start();
            getJSON(actionUrl, 'POST', data, (data) => {
                const confirmMessage = data.message;
                const notifyContainer = notifyForm.closest('.js-notify-me-container');
                addClass(notifyForm, HIDDEN_CLASS);
                const successMessage = queryFirst('.js-notify-confirm-msg', notifyContainer);
                successMessage.innerHTML = confirmMessage;
                removeClass(queryFirst('.js-notify-confirm-block', notifyContainer), HIDDEN_CLASS);
                removeClass(queryFirst('.footer-close-link', notifyContainer), HIDDEN_CLASS);
                $notifyForm.spinner().stop();
            }, () => {
                $notifyForm.spinner().stop();
            });
        });
    }
};

'use strict';
const { HIDDEN_CLASS } = require('../constants');
const { queryFirst, queryAll, removeClass, addClass, hasClass } = require('../domUtil');
const $body = $('body');

// Code to close the nearest boostrap modal
$body.on('click', '.lp-slideout-modal .close-current-modal, .sheet .close-current-modal', function (event) {
    const currentModal = $(event.target).closest('.lp-slideout-modal, .sheet');

    if (currentModal.length) {
        currentModal.modal('hide');
    }
});

// Code to stop scrolling behind the modal and modal moving issue in nested modal scenario
$body.on('hidden.bs.modal', function () {
    if (queryFirst('.modal.show')) {
        $body.addClass('modal-open');
    } else {
        queryAll('.modal-backdrop').forEach(element => element.remove());
        $('html, body').css({ overflow: 'visible' });
    }
});

// Toggle the separator element between the sheet body and footer when the user scrolls
$(document).on('shown.bs.modal', () => {
    const sheet = queryFirst('.sheet.show');
    const modal = queryFirst('.modal.show');
    let modalBody = null;

    if (sheet) {
        const OBSERVING_CLASS = 'observing';
        const activePages = queryAll('.sheet-page', sheet);

        if (activePages.length) {
            activePages.forEach(page => {
                const sheetBody = queryFirst('.sheet-body-content', page);
                const separator = queryFirst('.sheet-body-separator', page);
                const sheetBodyEnd = queryFirst('.sheet-body-end', sheetBody);

                // footers are optional, so not all pages in the sheet will have separators
                if (separator) {
                    const sheetContentHeight = [].slice.call(sheetBody.children).reduce((totalHeight, child) => totalHeight + child.offsetHeight, 0);

                    if (sheetBody.offsetHeight < sheetContentHeight) {
                        removeClass(separator, HIDDEN_CLASS);
                    }

                    if (!hasClass(sheetBodyEnd, OBSERVING_CLASS)) {
                        const bodyEndObserver = new IntersectionObserver(entries => {
                            entries.forEach(entry => {
                                if (entry.isIntersecting && !hasClass(separator, HIDDEN_CLASS)) {
                                    addClass(separator, HIDDEN_CLASS);
                                } else if (!entry.isIntersecting && hasClass(separator, HIDDEN_CLASS)) {
                                    removeClass(separator, HIDDEN_CLASS);
                                }
                            });
                        });

                        addClass(sheetBodyEnd, OBSERVING_CLASS);
                        bodyEndObserver.observe(sheetBodyEnd);
                    }
                }

                if (!hasClass(page, 'minicart-page')) {
                    modalBody = queryFirst('.sheet-body', page);
                }
            });
        }

        const modalContent = queryFirst('.sheet-body-content', sheet);
        const scrollbarThumb = queryFirst('.custom-scrollbar-thumb', sheet);

        if (modalContent && scrollbarThumb) {
            const updateScrollbar = () => {
                const { scrollHeight: contentHeight, clientHeight: visibleHeight, scrollTop } = modalContent;
                const scrollHeight = contentHeight - visibleHeight;

                const thumbHeight = Math.max((visibleHeight / contentHeight) * 100, 10);
                scrollbarThumb.style.height = thumbHeight === 100 ? 0 : `${thumbHeight}%`;

                const thumbPosition = (scrollTop / scrollHeight) * (100 - thumbHeight);
                scrollbarThumb.style.top = `${thumbPosition}%`;
            };

            modalContent.addEventListener('scroll', updateScrollbar);
            window.addEventListener('resize', updateScrollbar);
            updateScrollbar();
        }
    } else if (modal) {
        const activePages = queryAll('.modal-content', modal);

        if (activePages.length) {
            activePages.forEach(page => {
                modalBody = queryFirst('.modal-body', page);

                const SEPARATOR_CLASS = 'separator';
                const OBSERVING_CLASS = 'observing';
                const modalFooter = queryFirst('.modal-footer', page);
                const modalBodyEnd = queryFirst('.modal-body-end', page);

                if (modalBody && modalFooter && modalBodyEnd) {
                    const modalHeight = [].slice.call(modalBody.children).reduce((totalHeight, child) => totalHeight + child.offsetHeight, 0);

                    if (modalBody.offsetHeight < modalHeight) {
                        addClass(modalFooter, SEPARATOR_CLASS);
                    } else {
                        removeClass(modalFooter, SEPARATOR_CLASS);
                    }

                    if (!hasClass(modalBodyEnd, OBSERVING_CLASS)) {
                        const bodyEndObserver = new IntersectionObserver(entries => {
                            entries.forEach(entry => {
                                if (!entry.isIntersecting && !hasClass(modalFooter, SEPARATOR_CLASS)) {
                                    addClass(modalFooter, SEPARATOR_CLASS);
                                } else if (entry.isIntersecting && hasClass(modalFooter, SEPARATOR_CLASS)) {
                                    removeClass(modalFooter, SEPARATOR_CLASS);
                                }
                            });
                        });

                        addClass(modalBodyEnd, OBSERVING_CLASS);
                        bodyEndObserver.observe(modalBodyEnd);
                    }
                }
                if (modalBody) {
                    modalBody.scrollTo(0, 0);
                }
            });
        }
    }
});

// close any opened modal with ESC key:
document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape' || e.key === 'Esc') {
        e.preventDefault();
        const openedModal = $('.modal.show');

        if (openedModal) {
            openedModal.modal('hide');
        }
    }
});

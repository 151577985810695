'use strict';

var formValidation = require('base/components/formValidation');
const baseLogin = require('base/login/login');

const { queryFirst, addClass, removeClass, loadInput, queryAll, validateDate, hasClass } = require('../domUtil');
const { formatPhoneNumber, getItemFromLocalStorage } = require('../util');
const { clearForm } = require('../clientSideValidation').functions;
const registrationFormPhone = queryFirst('.registration-form-phone');
const loyaltyGuestReferralModal = queryFirst('#loyalty-referral');
const $body = $('body');
const fullLoginPage = queryFirst('.login-page-container');
const loginPageNav = queryFirst('.login-nav-container');
const accountSheet = queryFirst('#account-sheet');
const loginHeaderLink = queryFirst('.login-item.dropdown-item');
const orderStatusHeaderLink = queryFirst('.order-status-item.dropdown-item');
const checkoutContainer = queryFirst('#checkout-main');
const pdpContainer = queryFirst('.pdp.container');
const mobileHeaderCloseBtn = queryFirst('.navbar > .close-menu > .close-button');
const { HIDDEN_CLASS, ACTIVE_CLASS } = require('../constants');
const LOGIN_PAGE = 'login';
const REGISTER_PAGE = 'register';
const RESET_PASSWORD_PAGE = 'password-reset';
const RESET_PASSWORD_CONFIRM_PAGE = 'password-reset-confirm';
const TRACK_ORDER_PAGE = 'track-order';
const EXISTING_CUSTOMER_PAGE = 'existing-customer';
let PREVIOUS_PAGE = LOGIN_PAGE;

window.addEventListener('load', () => {
    validateDate();
    const formElement = queryFirst('.change-password-form');
    const referrerID = getItemFromLocalStorage('REFERRED_ID');

    if (fullLoginPage) {
        if (queryFirst('#login-form.active', fullLoginPage)) {
            addClass(loginHeaderLink, HIDDEN_CLASS);
        } else if (queryFirst('#order-form.active', fullLoginPage)) {
            addClass(orderStatusHeaderLink, HIDDEN_CLASS);
        }
    }
    if (referrerID && formElement) {
        loadInput(referrerID, formElement);
    }
    if (loyaltyGuestReferralModal) {
        $(loyaltyGuestReferralModal).modal('show');
    }
    if (checkoutContainer) {
        const guestCheckoutSignInModal = queryFirst('#guest-checkout-login');
        const cancelBtnRegistrationModal = queryFirst('.registration-right .cancel a', guestCheckoutSignInModal);
        if (cancelBtnRegistrationModal) {
            cancelBtnRegistrationModal.addEventListener('click', e => {
                e.preventDefault();
                $(guestCheckoutSignInModal).modal('hide');
            });
        }
        if (guestCheckoutSignInModal) {
            addClass(queryFirst('.account-need-help'), HIDDEN_CLASS);
            const registerLeft = queryFirst('.registration-left');
            const registerRight = queryFirst('.registration-right');
            removeClass(registerLeft, 'offset-md-2');
            addClass([registerLeft, registerRight], 'col-md-6');
        }
    }
});

/**
 * Enables the login button - to be called after this script loads to prevent early login button clicks.
 */
function enableLoginButton() {
    $body.off('login:dialogLoaded').on('login:dialogLoaded', () => {
        enableLoginButton();
    });

    const loginBtns = queryAll('.account-login-btn');
    if (loginBtns.length) {
        loginBtns.forEach(btn => (btn.disabled = false));
    }
}

/**
 * Switches the active page contained within the Login Sheet to the specified page
 * @param {string} page - Name of sheet page to switch to
 */
function setActivePage(page) {
    const activePage = queryFirst('.sheet-page.active', accountSheet);
    const activeForm = queryFirst('form', activePage);
    removeClass(activePage, ACTIVE_CLASS);
    activeForm?.reset();
    clearForm(activeForm);
    addClass(queryFirst(`.sheet-page.${page}-sheet`, accountSheet), ACTIVE_CLASS);
}

/**
 * Opens the Login Sheet to the specified page
 * @param {string} page - Name of page that should be visible when the sheet opens
 */
function openSheet(page) {
    mobileHeaderCloseBtn?.click(); // Close Mobile Menu
    $('.modal.show:not(#account-sheet)').modal('hide');
    setActivePage(page);
    $(accountSheet).modal('show');
}

module.exports = {
    ...baseLogin,

    login: function () {
        $('form.login').submit(function (e) {
            const $sheet = $('.sheet.show .sheet-dialog');
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $sheet.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        $sheet.spinner().stop();
                        $('form.login').trigger('login:error', data);
                    } else {
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                        $sheet.spinner().stop();
                    }
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            const $sheet = $('.sheet.show .sheet-dialog');
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            $sheet.spinner().start();
            form.trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (data.success) {
                        form.trigger('register:success');
                        if (checkoutContainer || pdpContainer) {
                            // reloading the page when account is created from checkout page
                            location.reload();
                        } else {
                            // redirecting to my account when account is created from anywhere else
                            location.href = data.redirectUrl;
                        }
                    } else {
                        formValidation(form, data);
                        $sheet.spinner().stop();
                        form.trigger('register:error');
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
                    }

                    $sheet.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        const $resetPasswordForm = $('.reset-password-form');
        const $sheet = $('.sheet.show .sheet-dialog');
        $resetPasswordForm.on('submit', function (e) {
            const form = $(this);
            e.preventDefault();
            const url = form.attr('action');
            $sheet.spinner().start();
            $resetPasswordForm.trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    $sheet.spinner().stop();
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        setActivePage(RESET_PASSWORD_CONFIRM_PAGE);
                    }
                },
                error: function () {
                    $sheet.spinner().stop();
                    accountSheet.modal('hide').one('hidden.bs.modal', () => {
                        $body.trigger('csrf:error');
                    });
                }
            });

            return false;
        });
    },
    handleLogin: () => {
        const loginBtnEl = queryFirst('.account-login-btn');
        const $loginFormEl = $('form.login');
        $loginFormEl.on('login:submit', () => {
            loginBtnEl.disabled = true;
        });

        $loginFormEl.on('login:error', () => {
            loginBtnEl.disabled = false;
        });
    },
    loginNavEvents: () => {
        loginPageNav?.addEventListener('click', e => {
            const { target } = e;
            if (hasClass(target, 'js-login-select')) {
                addClass(loginHeaderLink, HIDDEN_CLASS);
                removeClass(orderStatusHeaderLink, HIDDEN_CLASS);
            } else if (hasClass(target, 'js-order-select')) {
                removeClass(loginHeaderLink, HIDDEN_CLASS);
                addClass(orderStatusHeaderLink, HIDDEN_CLASS);
            } else {
                removeClass(loginHeaderLink, HIDDEN_CLASS);
                removeClass(orderStatusHeaderLink, HIDDEN_CLASS);
            }

            if (target instanceof HTMLAnchorElement && !hasClass(target, ACTIVE_CLASS)) {
                const activePage = queryFirst(`.${ACTIVE_CLASS}`, fullLoginPage);
                const activeForm = queryFirst('form', activePage);
                activeForm?.reset();
                clearForm(activeForm);
                addClass(queryFirst('.custom-error-alert', activeForm), HIDDEN_CLASS);
            }
        });
    },
    sheetEvents: () => {
        // Login click event
        $body.on('click', '.js-login-link', e => {
            e.preventDefault();
            if (!fullLoginPage) {
                openSheet(LOGIN_PAGE);
            } else {
                const loginNavTab = queryFirst('.js-login-select', loginPageNav);
                loginNavTab?.click();
            }
        });

        $body.on('click', '#myaccount', e => {
            e.preventDefault();
        });

        // Register click event
        $body.on('click', '.js-register-link', e => {
            e.preventDefault();
            if (!fullLoginPage) {
                openSheet(REGISTER_PAGE);
            } else {
                const registerNavTab = queryFirst('.js-register-select', loginPageNav);
                registerNavTab?.click();
            }
            if (queryFirst('.add-to-loyalty-program-checkbox')) {
                $('form.registration').trigger('register:optin_load');
            }
        });
        $body.on('click', '.js-password-reset-link', e => {
            e.preventDefault();
            const { target } = e;
            PREVIOUS_PAGE = target.closest('.existing-customer-sheet') ? EXISTING_CUSTOMER_PAGE : LOGIN_PAGE;
            openSheet(RESET_PASSWORD_PAGE);
        });
        $(accountSheet).on('click', '.modal-back', () => {
            setActivePage(PREVIOUS_PAGE);
        });
        $body.on('click', '.js-order-status-link', e => {
            e.preventDefault();
            if (!fullLoginPage) {
                openSheet(TRACK_ORDER_PAGE);
            } else {
                const registerNavTab = queryFirst('.js-order-select', loginPageNav);
                registerNavTab?.click();
            }
        });
    }
};

formatPhoneNumber(registrationFormPhone);
enableLoginButton();

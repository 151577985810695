'use strict';

const { addClass, queryAll, removeClass } = require('../domUtil.js');
const { CHANGED_CLASS, FILLED_CLASS } = require('../constants.js');

/**
 * Handler for custom select
 * @param {Object} event Change event
 *
 * */
function customSelectHandler(event) {
    const selectValue = event.target.value;
    if (selectValue === '') {
        removeClass(event.target, CHANGED_CLASS, FILLED_CLASS);
    } else {
        addClass(event.target, CHANGED_CLASS);
    }
}

module.exports = {
    enableSelectFloating: function () {
        const selectFloatingLabel = queryAll('.custom-select');
        selectFloatingLabel.forEach(select => {
            addClass(select, 'm-floating-label');
            Array.from(select.options).forEach(option => {
                if (option.value?.length && option.selected) {
                    addClass(select, FILLED_CLASS);
                }
            });

            select.removeEventListener('change', customSelectHandler);
            select.addEventListener('change', customSelectHandler);
        });
    }
};

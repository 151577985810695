'use strict';
const { queryFirst, queryAll, addClass, removeClass } = require('../domUtil');

// Scroll the navigation bar by wheel for desktop users on hover
$(document).ready(function () {
    const $navigationBar = $('.js-navigation-bar');
    let isHovering = false;
    let isScrolling = $navigationBar.length && $navigationBar[0].scrollWidth > $navigationBar[0].clientWidth;

    $navigationBar.on('mouseenter', function () {
        isHovering = true;
    });

    $navigationBar.on('mouseleave', function () {
        isHovering = false;
    });

    $navigationBar.on('wheel', function (e) {
        // Only trigger horizontal scroll when hovering over the navigation container
        if (isScrolling && isHovering) {
            e.preventDefault();
            $navigationBar.scrollLeft($navigationBar.scrollLeft() + e.originalEvent.deltaY); // You can adjust this value for smoother scrolling
        }
    });
});

const { queryAll, queryFirst, removeClass, addClass } = require('../domUtil');
const { appendToUrl } = require('../util');

module.exports = {
    init: () => {
        queryAll('.flex-tabs a').forEach(link => {
            const ACTIVE_CLASS = 'active';

            link.addEventListener('click', e => {
                e.preventDefault();
                const targetId = link.getAttribute('href');
                const { href: stateHref } = link.dataset;
                const parent = link.closest('.flex-tabs');

                const allLinks = queryAll('a', parent);

                const allTabContentIds = [];
                allLinks.forEach(tabLink => {
                    allTabContentIds.push(tabLink.getAttribute('href'));
                });

                const tabContentPool = queryAll(allTabContentIds.join(','));
                const targetContent = queryFirst(targetId);

                removeClass([...allLinks, ...tabContentPool], ACTIVE_CLASS);
                addClass([targetContent, link], ACTIVE_CLASS);

                if (stateHref) {
                    window.history.replaceState({}, '', stateHref);
                }
            });
        });
    }
};

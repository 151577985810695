'use strict';

const { queryFirst, toggleClass, removeClass, queryAll, hasClass, outerHeight, addClass, throttle, matchesBreakpoint } = require('../domUtil');
const { DISPLAY_CLASS, HIDDEN_CLASS, KEYCODE_ESCAPE, KEYCODE_TAB, NO_SCROLL_CLASS, ANIM_TIME_QUICK_2 } = require('../constants');

const topNav = queryFirst('.page-top-navigation');
const headerEl = queryFirst('.main-header');
const searchMenuEl = queryFirst('.header-search-type', headerEl);
const searchClearBtnEls = queryAll('.search-form-clear', headerEl);
const popularSearchEl = queryFirst('.popular-search', headerEl);
const mainNav = queryFirst('.main-nav', headerEl);
const bannerCloseEl = queryFirst('.close-banner');
const bannerInner = queryFirst('.carousel-stripe-banner-container');
const noSearchResultsCloseEl = queryFirst('.no-search-results-header .search-form-clear');
const noSearchResultsInput = queryFirst('.no-search-results-header .search-field');
const searchMenuContainer = queryFirst('.search-menu-container-scrollable', headerEl);
const headerSearchInput = queryFirst('.search-field', headerEl);
const TabKey = 'Tab';
const EscKey = 'Escape';

const bodyEl = document.body;
const showMenuClass = 'open';
const headerMenuShownClass = 'search-open';
const pageTopNav = queryFirst('.page-top-navigation', bodyEl);

let prevScrollPos = window.scrollY;
let initialHeight;

const hideSuggestionsMenu = e => {
    const { target } = e;
    const parentEl = target.closest('.search-global');
    const searchSuggEl = queryFirst('.suggestions-wrapper', parentEl);

    removeClass(searchSuggEl, DISPLAY_CLASS);
    removeClass(popularSearchEl, 'popular-search-with-error');
    addClass(searchSuggEl, HIDDEN_CLASS);
    $('.suggestions').show();
};

const toggleSearchMenu = () => {
    const openingMenu = !hasClass(searchMenuEl, showMenuClass);
    const searchField = queryFirst('.search-field');
    toggleClass(searchMenuEl, showMenuClass);
    toggleClass(topNav, headerMenuShownClass);
    const ariaValue = searchMenuEl.getAttribute('aria-expanded') !== 'true';
    searchMenuEl.setAttribute('aria-expanded', ariaValue);
    let timeDelay = openingMenu ? 0 : 500;
    initialHeight = window.visualViewport.height;

    setTimeout(() => {
        toggleClass(bodyEl, NO_SCROLL_CLASS);
    }, timeDelay);

    if (openingMenu) {
        searchField.setAttribute('autofocus', 'autofocus');
        setTimeout(() => {
            searchField.focus();
        }, timeDelay + 300);
    }

    searchField.removeAttribute('autofocus', 'autofocus');

    // show popular search on opening of search menu, hide search suggestions on close of search menu
    if (openingMenu && popularSearchEl) {
        popularSearchEl.style.display = 'block';
    } else if (!openingMenu) {
        const fullSuggEl = queryFirst('.full-suggestions');
        if (fullSuggEl) {
            fullSuggEl.style.display = 'none';
        }
    }
};

const closeSearchMenu = () => {
    removeClass(searchMenuEl, showMenuClass);
    removeClass(topNav, headerMenuShownClass);
    $('.suggestions').hide();
    searchMenuEl.setAttribute('aria-expanded', false);
    setTimeout(() => {
        removeClass(bodyEl, NO_SCROLL_CLASS);
        queryFirst('.search-toggle-btn-main').focus();
    }, 400);
};

const handleSearchMenuClose = e => {
    const { target } = e;
    if (target.matches('#search-close') || target.matches('.header-search-type .fade-bg') || target.matches('.header-search-type .search-form-close')) {
        closeSearchMenu();
    }
};

const checkNoResultsInputLength = e => {
    noSearchResultsCloseEl.classList[e.target.value.length ? 'remove' : 'add'](HIDDEN_CLASS);
};

const handleTabListeners = e => {
    const isEscPressed = e.key === EscKey || e.keyCode === KEYCODE_ESCAPE;
    if (isEscPressed) {
        closeSearchMenu();
        return;
    }

    const isTabPressed = e.key === TabKey || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
        return;
    }
    const focusable = queryAll('button:not(.d-md-none), [href]:not(.d-md-none), [tabindex]:not([tabindex="-1"])', searchMenuEl);
    const lastFocusable = focusable[focusable.length - 1];
    const searchField = queryFirst('.search-field');

    if (e.target === lastFocusable) {
        searchField.setAttribute('autofocus', 'autofocus');
        setTimeout(() => {
            searchField.focus();
        }, 300);
    }
};

/**
 * Adjust the height of the search menu container based on the keyboard state.
 * This function detects whether the keyboard is open or closed on mobile devices
 * and adjusts the height of the search menu container accordingly.
 */
const handleKeyboardOpen = () => {
    setTimeout(() => {
        const currentHeight = window.visualViewport.height;
        const keyboardHeight = initialHeight - currentHeight;
        searchMenuContainer.style.setProperty('--keyboard-height', `${keyboardHeight + 50}px`);

        if (currentHeight < initialHeight) {
            // Keyboard is open
            addClass(searchMenuContainer, 'm-keyboard-open');
        }
    }, ANIM_TIME_QUICK_2);
};

module.exports = () => {
    const searchToggles = queryAll('.search-toggle-btn, .search-toggle-btn-main, .try-search-again-btn');
    const homePage = queryFirst('[data-action="Home-Show"]', bodyEl);
    const productPage = queryFirst('[data-action="Product-Show"]', bodyEl);
    const productSetPage = queryFirst('.product-set-detail', bodyEl);

    // Set page-top-navigation height to css variable
    if (homePage && hasClass(pageTopNav, 'header-transparent')) {
        setTimeout(() => {
            const extraPadding = matchesBreakpoint('lg') ? 10 : 20;
            const pageTopNavHeight = window.scrollY < 150 ? outerHeight(pageTopNav) : outerHeight(bannerInner) + outerHeight(headerEl) + extraPadding;

            headerEl.style.setProperty('--top-nav-height', -pageTopNavHeight + 'px');
        }, ANIM_TIME_QUICK_2);
    }

    // Sticky function
    window.addEventListener(
        'scroll',
        throttle(() => {
            // add specific class to header element when user start scrolling
            if (hasClass(headerEl, 'logo-animated')) {
                if (window.scrollY > 10) {
                    if (!hasClass(headerEl, 'm-scrolled')) {
                        addClass(headerEl, 'm-scrolled');
                    }
                } else if (window.scrollY <= 10) {
                    if (hasClass(headerEl, 'm-scrolled')) {
                        removeClass(headerEl, 'm-scrolled');
                    }
                }
            }

            const isStuck = hasClass(headerEl, 'stuck');
            if (window.scrollY > 150 && !isStuck) {
                addClass(headerEl, 'stuck');
            } else if (window.scrollY <= 150 && isStuck) {
                removeClass(headerEl, 'stuck');
            }

            // add specific class to body element when user is scrolling down
            if (window.matchMedia('(max-width: 768px)').matches && productPage && !productSetPage) {
                const currentScrollPos = window.scrollY;

                if (prevScrollPos <= currentScrollPos) {
                    addClass(bodyEl, 'm-pdp-loosed');
                } else {
                    removeClass(bodyEl, 'm-pdp-loosed');
                }

                prevScrollPos = currentScrollPos;
            }
        }, 50)
    );

    if (bannerCloseEl) {
        bannerCloseEl.addEventListener('click', () => {
            removeClass(mainNav, 'has-banner');
        });
    }

    searchToggles.forEach(element => {
        element.addEventListener('click', toggleSearchMenu);
    });
    if (searchMenuEl) {
        searchMenuEl.addEventListener('click', handleSearchMenuClose);
    }
    if (searchClearBtnEls) {
        searchClearBtnEls.forEach(btn => {
            btn.addEventListener('click', hideSuggestionsMenu);
        });
    }

    if (noSearchResultsInput) {
        noSearchResultsInput.addEventListener('input', checkNoResultsInputLength);
    }

    if (searchMenuEl) {
        searchMenuEl.addEventListener('keydown', handleTabListeners);
    }

    if (searchMenuContainer && headerSearchInput) {
        headerSearchInput.addEventListener('resize', handleKeyboardOpen);
        headerSearchInput.addEventListener('click', handleKeyboardOpen);
        headerSearchInput.addEventListener('blur', function () {
            removeClass(searchMenuContainer, 'm-keyboard-open');
        });
    }
};

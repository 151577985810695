// css class constants
export const CLASS_PRODUCT_TILE = 'js-product-tile';
export const CLASS_PRODUCT_TILE_INIT_EVENT_ATTACHED = 'js-init-event-attached';
export const CLASS_PRODUCT_TILE_LINK = 'js-product-tile-link';
export const CLASS_PRODUCT_TILE_CONTAINER = 'js-product-tile-container';
export const CLASS_SIZE_SELECTED = 'selected';
export const CLASS_SWATCH_SELECTED = CLASS_SIZE_SELECTED;
export const CLASS_SIZE_SELECTED_ASSISTIVE_TEXT = 'js-selected-assistive-text';
export const CLASS_SIZE_LIST = 'js-size-list';
export const CLASS_ADD_TO_CART_BTN = 'js-tile-add-to-cart';
export const CLASS_TILE_SWATCH = 'js-tile-swatch';
export const CLASS_TILE_SWATCH_NOT_AVAILABLE = 'not-available';
export const CLASS_TILE_SIZE_SELECTOR = 'js-size-selector';
export const CLASS_TILE_SIZE_BTN = 'size-btn';
export const CLASS_NOTIFY_ME_BTN = 'js-product-tile-notify-me-btn';
export const CLASS_AVAILABILITY_MSG = 'js-availability-msg';
export const CLASS_AVAILABILITY_MSG_TEXT = 'js-availability-msg-text';
export const CLASS_EARLY_ACCESS_WRAPPER = 'js-early-access-wrapper';
export const CLASS_EARLY_ACCESS_CTA_SIGN_IN = 'js-early-access-sign-in-cta';
export const CLASS_EARLY_ACCESS_CTA_SIGN_UP = 'js-early-access-sign-up-cta';
export const CLASS_NOTIFY_ME_DIALOG_WRAPPER = 'js-notify-me-dialog-wrapper';
export const CLASS_NOTIFY_ME_DIALOG_PID_INPUT = 'js-notify-sku';
export const CLASS_NOTIFY_ME_DIALOG_CONTAINER = 'js-notify-me-container';
export const CLASS_TILE_IMAGE_CAROUSEL_IMAGE = 'lazy-image';
export const CLASS_PRODUCT_GRID = 'js-product-grid';
export const CLASS_BADGE_HIDE = 'hide';
export const CLASS_BADGE_SHOW = 'show';
export const CLASS_SOLDOUT_MSG = 'js-soldout-msg';
export const CLASS_TILE_PRICE_WRAPPER = 'js-tile-price-wrapper';

// global selectors
export const SELECTOR_PRODUCT_TILE = `.${CLASS_PRODUCT_TILE}`;
export const SELECTOR_PRODUCT_TILE_CONTAINER = `.${CLASS_PRODUCT_TILE_CONTAINER}`;
export const SELECTOR_PRODUCT_TILE_INIT_EVENT_NOT_ATTACHED = `.${CLASS_PRODUCT_TILE}:not([data-is-initialized])`;
export const SELECTOR_PRODUCT_TILE_LINK = `.${CLASS_PRODUCT_TILE_LINK}`;
export const SELECTOR_ADD_TO_CART_BTN = `.${CLASS_ADD_TO_CART_BTN}`;

export const SELECTOR_TILE_SWATCH = `.${CLASS_TILE_SWATCH}`;
export const SELECTOR_TILE_SWATCH_SELECTED = `${SELECTOR_TILE_SWATCH}.${CLASS_SIZE_SELECTED}`;
export const SELECTOR_SIZE_SELECTED_ASSISTIVE_TEXT = `.${CLASS_SIZE_SELECTED_ASSISTIVE_TEXT}`;
export const SELECTOR_SIZE_SELECTOR = `.${CLASS_TILE_SIZE_SELECTOR}`;
export const SELECTOR_SIZE_BTN = `.${CLASS_TILE_SIZE_BTN}`;
export const SELECTOR_SIZE_BTN_SELECTED = `.${CLASS_TILE_SIZE_BTN}.${CLASS_SIZE_SELECTED}`;
export const SELECTOR_SIZE_LIST = `.${CLASS_SIZE_LIST}`;
export const SELECTOR_SIZE_BTN_IN_TILE = `${SELECTOR_PRODUCT_TILE} ${SELECTOR_SIZE_BTN}`;
export const SELECTOR_NOTIFY_ME_BTN = `.${CLASS_NOTIFY_ME_BTN}`;
export const SELECTOR_AVAILABILITY_MSG = `.${CLASS_AVAILABILITY_MSG}`;
export const SELECTOR_AVAILABILITY_MSG_TEXT = `.${CLASS_AVAILABILITY_MSG} .${CLASS_AVAILABILITY_MSG_TEXT}`;

export const SELECTOR_EARLY_ACCESS_WRAPPER = `.${CLASS_EARLY_ACCESS_WRAPPER}`;
export const SELECTOR_EARLY_ACCESS_CTA_SIGN_IN = `${SELECTOR_EARLY_ACCESS_WRAPPER} .${CLASS_EARLY_ACCESS_CTA_SIGN_IN}`;
export const SELECTOR_EARLY_ACCESS_CTA_SIGN_UP = `${SELECTOR_EARLY_ACCESS_WRAPPER} .${CLASS_EARLY_ACCESS_CTA_SIGN_UP}`;

export const SELECTOR_NOTIFY_ME_DIALOG_PID_INPUT = `.${CLASS_NOTIFY_ME_DIALOG_WRAPPER} .${CLASS_NOTIFY_ME_DIALOG_PID_INPUT}`;
export const SELECTOR_NOTIFY_ME_DIALOG_CONTAINER = `.${CLASS_NOTIFY_ME_DIALOG_WRAPPER} .${CLASS_NOTIFY_ME_DIALOG_CONTAINER}`;
export const SELECTOR_NOTIFY_ME_DIALOG_FORM = 'form';
export const SELECTOR_TILE_IMAGE_CAROUSEL_IMAGE = `.${CLASS_TILE_IMAGE_CAROUSEL_IMAGE}`;
export const SELECTOR_PRODUCT_GRID = `.${CLASS_PRODUCT_GRID}`;
export const SELECTOR_SOLDOUT_MSG = `.${CLASS_SOLDOUT_MSG}`;
export const SELECTOR_TILE_PRICE_WRAPPER = `.${CLASS_TILE_PRICE_WRAPPER}`;

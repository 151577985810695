'use strict';

import { queryFirst, addClass, removeClass, hasClass, queryAll, scrollTo, setAttribute, toggleClass } from '../domUtil';
import base from './base';
import { updateProductData, handleColor, handleSize } from 'fitpredictor/product/secretsauce';

const { isShippingPreferencesViewEnabled, isEarlyAccessElement, enableAddAllToToteButton } = require('./helper');
const { availabilityMessageTmpl, availabilityMessageOOS, availabilityMessagePreorder, ispuAvailabilityMessageTmpl, ispuLowStockMessageTmpl, promotionMessageTmpl } = require('../templates').productDetail;
const $body = $('body');
const sizeChartClasses = 'no-scroll size-chart-visible';
var Hammer = require('hammerjs');
const KEYCODE_TAB = 9;
const TabKey = 'Tab';
const { IN_STOCK_STATUS: IN_STOCK, NOT_AVAILABLE_STATUS: NOT_AVAILABLE, PREORDER_STATUS: PREORDER, HIDDEN_CLASS, NOT_AVAILABLE_CLASS, ONE_SIZE_ID, ONE_SIZE_LABEL } = require('../constants');
const { SHIP_TO_LOW_INVENTORY_CLASS } = require('../components/shippingPreference/constants');
const { initPopoverPromotions, initPopoverClose, initPopoverCloseSelector, initPopoverWishList } = require('../popover');
const { initCarouselElement } = require('../components/swiperCarousel').default;
import { getNestedValue } from '../util';
import { hideStrikeHearts, setWishlistProductId, showStrikeHearts } from '../wishlist/helper';
const promoDetailsModal = queryFirst('#promoDetailsModal');
const promoDetailsContainer = queryFirst('.js-promo-details-container', promoDetailsModal);
const shoppableCarouselEl = queryFirst('.js-shoppable-media');
let zoomedState = false;

/**
 * Handling zoomin effect on product image slider
 */
function handleProductImageZoom(carousel) {
    const pdpModalCarouselEl = carousel;
    const imageZoomUrl = pdpModalCarouselEl.dataset.image;
    const imagePresetUrl = pdpModalCarouselEl.dataset.preset;

    const imageObserver = new MutationObserver(function (mutationList) {
        for (let mutation of mutationList) {
            if (mutation.type === 'childList') {
                const { target, addedNodes } = mutation;
                const baseImg = queryFirst('.img-fluid', target);
                const zoomAlt = baseImg.getAttribute('alt') + ' Zoomed';
                setAttribute(addedNodes, 'alt', zoomAlt);
            }
        }
    });

    if (pdpModalCarouselEl) {
        const pdpImages = queryAll('.swiper-slide > img.img-fluid', pdpModalCarouselEl);

        pdpImages.forEach((imageEl, index) => {
            if (!imageEl) {
                return;
            }
            if (window.matchMedia('(hover: none)').matches) {
                const hammer = new Hammer(imageEl, {});
                const panSpeed = 1.1;
                let transform = '';
                let xPos = { current: 0, last: 0, max: 0 };
                let yPos = { current: 0, last: 0, max: 0 };
                let scale = { current: 1, last: 1, min: 1, max: 2 };

                const disableZoom = function (el) {
                    xPos.current = 0;
                    yPos.current = 0;
                    scale.current = scale.min;
                    transformElement(el);
                    zoomedState = false;
                    removeClass(el.parentNode, 'zoomed');
                };

                const enableZoom = function (el) {
                    xPos.current = 0;
                    yPos.current = 0;
                    scale.current = scale.max;
                    transformElement(el);
                    zoomedState = true;
                    addClass(el.parentNode, 'zoomed');
                };

                const transformElement = function (el) {
                    transform = `translate(${xPos.current}px,${yPos.current}px) scale(${scale.current})`;
                    el.style.transform = transform;
                };

                hammer.domEvents = true;
                hammer.get('pinch').set({ enable: true });
                hammer.get('doubletap').set({ enable: true });

                hammer.on('tap pan panend pinch  pinchend doubletap', function (e) {
                    // Prevent tap events from interfering
                    e.srcEvent.stopPropagation();
                    e.srcEvent.preventDefault();

                    let el = e.target;
                    // If zoomed in, pan the image
                    if (zoomedState) {
                        xPos.current = xPos.last + e.deltaX * panSpeed;
                        yPos.current = yPos.last + e.deltaY * panSpeed;

                        xPos.shift = Math.ceil(el.clientWidth / scale.max);
                        yPos.shift = Math.ceil(el.clientHeight * scale.max - window.innerHeight);

                        // Keep coordinates within image bounds
                        if (xPos.current > xPos.shift) {
                            xPos.current = xPos.shift;
                        }
                        if (xPos.current < -xPos.shift) {
                            xPos.current = -xPos.shift;
                        }
                        if (yPos.current > 0) {
                            yPos.current = 0;
                        }
                        if (yPos.current < -yPos.shift) {
                            yPos.current = -yPos.shift;
                        }
                    }

                    switch (e.type) {
                        case 'tap': // tapping returns to default view in slider
                            disableZoom(el);
                            removeClass(el.parentNode, 'animated');
                            pdpModalCarouselEl.swiper.enable();
                            break;

                        case 'panend': // Finish panning
                            xPos.last = xPos.current;
                            yPos.last = yPos.current;
                            addClass(el.parentNode, 'animated');
                            break;

                        case 'pinch': // Scale image with pinch
                            scale.current = scale.max;
                            enableZoom(el);
                            pdpModalCarouselEl.swiper.disable();
                            break;

                        case 'doubletap':
                            if (!zoomedState) {
                                scale.current = scale.max;
                                enableZoom(el);
                                pdpModalCarouselEl.swiper.disable();
                            }
                            break;
                    }

                    transformElement(el);

                    $(el).on('zoom:imageChange', () => {
                        disableZoom(el);
                        zoomedState = false;
                    });
                });
            } else {
                const scaleOnHover = 2;
                let imageContainer = $(imageEl).parent();

                imageContainer.on('click', function () {
                    imageContainer.addClass('zoomed');
                    zoomedState = true;
                    pdpModalCarouselEl.swiper.disable();
                });

                imageContainer.zoom({
                    on: 'click',
                    url: imageEl.getAttribute('src'),
                    touch: false,
                    duration: 300,
                    magnify: scaleOnHover,
                    onZoomOut: function () {
                        imageContainer.removeClass('zoomed');
                        zoomedState = false;
                        pdpModalCarouselEl.swiper.enable();
                    }
                });

                imageObserver.observe(imageEl, { childList: true });
            }
        });
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {Array} variationAttributes - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateSelectedSwatchProductName(variationAttributes, $productContainer) {
    if (Array.isArray(variationAttributes) && variationAttributes.length) {
        const colorVariationObject = variationAttributes.find(attribute => attribute.attributeId === 'color');
        const colorLabel = $productContainer.find('.selected-swatch').data('color-label');

        if (colorVariationObject && Array.isArray(colorVariationObject.values) && colorVariationObject.values.length) {
            const selectedSwatchObject = colorVariationObject.values.find(eachValue => eachValue.selected);

            $productContainer.find('.selected-swatch-name').text(selectedSwatchObject ? `${(colorLabel && colorLabel + ' ') || ''}${selectedSwatchObject.displayValue}` : '');
        }
    }
}

/**
 * This method manages Notify me dialog content.
 *
 * !! For inline NotifyMe box please see handleNotifyMeBlock method
 *
 * @param {Object} productData - Product data
 * @param {Object} productContainer - Product Container DOM element
 */
function handleNotifyMe(productData, productContainer) {
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const buyBarAddToCart = queryFirst('.js-buybar-btn');
    const notifyContainer = queryFirst('.js-notify-me-container', productContainer);
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const buyBarNotifyMeButton = queryFirst('.js-buybar .notify-me-btn');
    const notifyMeDesc = queryFirst('.js-notify-me-desc', productContainer);
    const availabilityMessageEl = queryFirst('.product-availability', productContainer);
    const { available, isNotifyMeEnabled, id, formattedPrice, forceOutOfStock, imageData } = productData;
    if (!available && isNotifyMeEnabled && !forceOutOfStock) {
        addClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
        removeClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton, availabilityMessageEl], HIDDEN_CLASS);
        const notifyImageElement = queryFirst('.js-notify-img img', notifyContainer);
        if (imageData && notifyImageElement) {
            const { alt, url, srcset } = imageData[0];
            const imageHasLoaded = hasClass(notifyImageElement, 'lz-loaded');

            notifyImageElement.setAttribute(imageHasLoaded ? 'src' : 'data-src', url);
            notifyImageElement.setAttribute(imageHasLoaded ? 'srcset' : 'data-srcset', srcset);
            notifyImageElement.setAttribute('alt', alt);
        }
        // Insert price
        queryFirst('.js-notify-price', notifyContainer).textContent = formattedPrice;
        // Insert color value
        const notifyColor = queryFirst('.js-notify-color', notifyContainer);
        queryFirst('.js-notify-color-label', notifyColor).textContent = queryFirst('.selected-swatch', productContainer).dataset.colorLabel;
        queryFirst('.js-notify-color-value', notifyColor).textContent = queryFirst('.ssColor', productContainer).value;
        // Insert size value
        const notifySize = queryFirst('.js-notify-size', notifyContainer);
        queryFirst('.js-notify-size-label', notifySize).textContent = queryFirst('.size-display-name', productContainer).value + ':';
        queryFirst('.js-notify-size-value', notifySize).textContent = queryFirst('.size-btn.selected', productContainer).dataset.attrValue;
        if (id) {
            document.getElementById('notifySku').value = id;
        }
        const customerEmail = (document.getElementById('notifyEmail') || {}).value;
        if (customerEmail) {
            queryFirst('.notify-email').value = customerEmail;
        }
        removeClass(queryFirst('.js-notify-me-desc'), HIDDEN_CLASS);
        removeClass(queryFirst('.js-notify-form'), HIDDEN_CLASS);
        addClass(queryFirst('.js-notify-confirm-block'), HIDDEN_CLASS);

        $body.trigger('product:notifyMeShown', productContainer);
    } else {
        removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
        if (availabilityMessageEl) {
            removeClass(availabilityMessageEl, HIDDEN_CLASS);
        }
        addClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);

        $body.trigger('product:notifyMeHidden', productContainer);
    }
}

/**
 *
 * @param {*} productContainer
 * @param {*} selectedSizeValue
 * @returns
 */
function updateColorElements(productContainer, selectedSizeValue) {
    const masterId = productContainer.dataset.masterid;
    const productInfoVariants = window.productInfo[masterId].variants;
    const allColorElements = queryAll('button.color-attribute', productContainer);
    const { productInventory } = window;

    allColorElements.forEach(colorElement => {
        let selectedColorElement = queryFirst('.swatch-circle', colorElement);
        let selectedColorId = selectedColorElement.dataset.attrValue;
        let product = productInfoVariants[selectedColorId];

        if (colorElement && Object.prototype.hasOwnProperty.call(product, 'sizes') && Object.prototype.hasOwnProperty.call(product.sizes, selectedSizeValue)) {
            const sizeID = product.sizes[selectedSizeValue].ID;

            if (Object.prototype.hasOwnProperty.call(productInventory[masterId].variants, sizeID)) {
                const status = productInventory[masterId].variants[sizeID].availabilityStatus;

                if (status === NOT_AVAILABLE) {
                    addClass(colorElement, NOT_AVAILABLE_CLASS);
                } else {
                    removeClass(colorElement, NOT_AVAILABLE_CLASS);
                }
            } else if (!hasClass(colorElement, NOT_AVAILABLE_CLASS)) {
                addClass(colorElement, NOT_AVAILABLE_CLASS);
            }
        } else if (!hasClass(colorElement, NOT_AVAILABLE_CLASS)) {
            addClass(colorElement, NOT_AVAILABLE_CLASS);
        }
    });
}
/**
 * handles size change
 * @param {Object} productContainer - product container html element
 * @param {string} selectedSizeValue - Selected size value
 */
function onSizeChangeHandler(productContainer, selectedSizeValue) {
    updateColorElements(productContainer, selectedSizeValue);
    removeClass(queryAll('.size-btn', productContainer), 'selected');
    addClass(this, 'selected');

    const selecedSizeElements = queryAll(`.size-btn[data-value-id="${selectedSizeValue}"]:not(.selected)`, productContainer);
    addClass(selecedSizeElements, 'selected');

    const sizeContainer = this.closest('.size-container');
    const assistiveElements = queryAll('.selected-assistive-text', sizeContainer);
    assistiveElements.forEach(eachElement => {
        if (eachElement.textContent.includes(eachElement.dataset.outOfStock)) {
            eachElement.textContent = eachElement.dataset.outOfStock;
        } else {
            eachElement.textContent = '';
        }
    });
    const assistiveElementOfSelected = queryFirst('.selected-assistive-text', this.closest('.size-list'));
    const { selectedText, outOfStock } = assistiveElementOfSelected.dataset;
    assistiveElementOfSelected.textContent = selectedText;
    if (hasClass(this, NOT_AVAILABLE_CLASS)) {
        assistiveElementOfSelected.textContent += ' ' + outOfStock;
    }
    removeClass(queryFirst('.size-seperator', productContainer), HIDDEN_CLASS);
    const selectedSize = queryFirst('.selected-size', productContainer);
    if (selectedSize) {
        selectedSize.innerHTML = sizeContainer.innerHTML;
        removeClass(selectedSize, HIDDEN_CLASS);
    }

    // update selected size input
    const selectedSizeInput = queryFirst('.js-selected-size', productContainer);
    if (selectedSizeInput) {
        // change size label ('1 SZ' to 'One Size'), if it is '1 SZ'
        selectedSizeInput.textContent = selectedSizeValue === ONE_SIZE_ID ? ONE_SIZE_LABEL : selectedSizeValue;
    }

    // update buybar button
    const buybarButton = queryFirst('.js-buybar-btn');
    const buybarPriceEl = queryFirst('.js-buybar-price');
    const buybarSoldEl = queryFirst('.js-buybar-sold');
    if (buybarButton) {
        if (queryAll('.size-list', sizeContainer).length > 1) {
            if (hasClass(this, NOT_AVAILABLE_CLASS)) {
                buybarButton.textContent = buybarButton.dataset.selectLabel;
                addClass(buybarButton, 'button-secondary', 'js-buybar-select');
                removeClass(buybarButton, 'button-primary');
                addClass(buybarPriceEl, HIDDEN_CLASS);
                removeClass(buybarSoldEl, HIDDEN_CLASS);
            } else {
                buybarButton.textContent = buybarButton.dataset.addLabel + selectedSizeValue;
                addClass(buybarButton, 'button-primary');
                removeClass(buybarButton, 'button-secondary', 'js-buybar-select');
                removeClass(buybarPriceEl, HIDDEN_CLASS);
                addClass(buybarSoldEl, HIDDEN_CLASS);
            }
        }
        addClass(buybarButton, 'selected');
    }
}

/**
 * update quickview product info on product variation change
 * @param {string} selectedSizeValue - Selected size value
 * @param {int} selectedColorId - selected color id
 * @param {Object} productContainer - product container html element
 * @param {Object} currentSizeElement - current active size element
 */
function updateQuickViewProductInfo(selectedSizeValue, selectedColorId, productContainer, currentSizeElement) {
    const quickViewInfo = window.quickviewProductInfo;
    const { productInfo } = quickViewInfo;
    const variantGroupData = productInfo.variants[selectedColorId];
    const { sizes, images, formattedPrice, standardPrice, price, vgProductDetailsUrl, isDirectlyPurchasable, wishlistDisabled, isFinalSale } = variantGroupData;
    const ispu = base.updateImageDetails(images.extrasmall);
    const selectedSizeData = sizes[selectedSizeValue];
    const addToCartButton = queryFirst('.add-to-cart', productContainer);
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const buyBarNotifyMeButton = queryFirst('.js-buybar .notify-me-btn');
    const notifyMeDesc = queryFirst('.notify-me-desc', productContainer);
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const buyBarAddToCart = queryFirst('.js-buybar-btn');
    const availabilityMsgEl = queryFirst('.availability-msg', productContainer);
    let availabilityValue = '';
    let productDetailsUrl = vgProductDetailsUrl;
    const fullPDPLink = queryFirst('.full-pdp-link', productContainer);
    const wishlistButton = queryFirst('.add-to-wish-list', productContainer);

    if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
        removeClass(wishlistButton, 'added-to-wish-list');
        wishlistButton.disabled = false;
    }
    hideStrikeHearts(productContainer);
    if (wishlistDisabled || !isDirectlyPurchasable || isFinalSale) {
        showStrikeHearts(productContainer);
    }

    if (!selectedSizeData || !currentSizeElement) {
        removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
        addClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
        fullPDPLink.href = productDetailsUrl;
        if (!isDirectlyPurchasable) {
            addToCartButton.disabled = true;
            availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
            availabilityMsgEl.innerHTML = availabilityValue;
        } else {
            addToCartButton.disabled = false;
        }
        return;
    }

    const { isNotifyMeEnabled, ID, forceOutOfStock, variantProductDetailsUrl, isFinalSale: isFinalSaleVariant, isDirectlyPurchasable: isDirectlyPurchasableVariant, wishlistDisabled: wishlistDisabledVariant } = selectedSizeData;
    productDetailsUrl = variantProductDetailsUrl;
    const { productInventory } = quickViewInfo;
    const { variants } = productInventory;
    const inventoryData = variants[ID];
    const { message, availabilityStatus, isLowInventory } = inventoryData;
    const selectedColorName = queryFirst('.selected-swatch-name').textContent;

    if (!isDirectlyPurchasable) {
        availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
    } else if (availabilityStatus !== IN_STOCK || isLowInventory) {
        availabilityValue = availabilityMessageTmpl(message);
    }
    fullPDPLink.href = productDetailsUrl;

    if (!isDirectlyPurchasable) {
        addToCartButton.disabled = true;
        removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
        addClass([notifyMeDesc, notifyMeButton], HIDDEN_CLASS);
    } else if (availabilityStatus !== NOT_AVAILABLE && !forceOutOfStock) {
        addToCartButton.disabled = false;
        removeClass(currentSizeElement, NOT_AVAILABLE_CLASS);
        hideStrikeHearts(productContainer);
    } else {
        addClass(currentSizeElement, NOT_AVAILABLE_CLASS);
        addToCartButton.disabled = true;
        showStrikeHearts(productContainer);
        if (isNotifyMeEnabled) {
            addClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
            removeClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
        } else {
            removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
            addClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
        }
    }

    availabilityMsgEl.innerHTML = availabilityValue;
    productContainer.dataset.pid = ID;
    productContainer.dataset.wishlistId = ID;
    setWishlistProductId(ID, productContainer);
    const productData = {
        available: !hasClass(currentSizeElement, NOT_AVAILABLE_CLASS),
        isNotifyMeEnabled,
        id: ID,
        formattedPrice,
        forceOutOfStock,
        imageData: ispu
    };
    if (wishlistDisabledVariant || !isDirectlyPurchasableVariant || isFinalSaleVariant) {
        showStrikeHearts(productContainer);
    }
    handleNotifyMe(productData, productContainer);

    // secret sauce integration to update product data
    updateProductData(selectedColorName, selectedSizeValue, standardPrice, price, productContainer);
    handleSize(productContainer);

    $('body').trigger('product:afterQuickViewSizeChange', {
        ID,
        productContainer,
        monetateData: {
            pdpBreadCrumbs: variantGroupData.pdpBreadCrumbs
        }
    });
}

/**
 * This method manages Notify me block on PDP
 * @param {Object} productData - Product data
 * @param {Object} productContainer - Product Container DOM element
 */
function handleNotifyMeBlock(productData, productContainer) {
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const notifyMeButton = queryFirst('.js-notify-me-btn', productContainer);
    const notifySuccessBlock = queryFirst('.js-notify-confirm-block', productContainer);
    const notifyForm = queryFirst('.js-notify-form', productContainer);
    const buyBarAddToCart = queryFirst('.js-buybar-btn');
    const buyBarNotifyMeButton = queryFirst('.js-buybar .notify-me-btn');

    const { available, isNotifyMeEnabled, id, forceOutOfStock } = productData;
    if (!available && isNotifyMeEnabled && !forceOutOfStock) {
        addClass([addToCartSection, buyBarAddToCart, notifySuccessBlock], HIDDEN_CLASS);
        removeClass([notifyMeButton, buyBarNotifyMeButton, notifyForm], HIDDEN_CLASS);

        if (id) queryFirst('.js-notify-sku', productContainer).value = id;
        const customerEmail = (queryFirst('#notifyEmail') || {}).value;
        if (customerEmail) queryAll('.js-notify-email').forEach(field => (field.value = customerEmail));
    } else {
        removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
        addClass([notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
    }
}

/**
 *
 * @param {string} selectedSizeValue - Selected size value
 * @param {int} selectedColorId - selected color id
 * @param {Object} productContainer - product container html element
 * @param {Object} currentSizeElement - current active size element
 */
function updateProductInfo(selectedSizeValue, selectedColorId, productContainer, currentSizeElement) {
    const masterId = productContainer.dataset.masterid;
    const productInfo = window.productInfo[masterId];
    const variantGroupData = productInfo.variants[selectedColorId];
    const { sizes, images, formattedPrice, totalPrice, monogramProductPrice, standardPrice, price, mgFlag, mgLocs, isDirectlyPurchasable, wishlistDisabled, isFinalSale, earlyAccess } = variantGroupData;
    const ispu = base.updateImageDetails(images.extrasmall);
    const selectedSizeData = sizes[selectedSizeValue];
    let allowWishlist = true;
    let addToCartButton = queryFirst('.add-to-cart', productContainer);

    // set addToCart button element if it is ShopTheLook
    const shopTheLookEl = currentSizeElement && currentSizeElement.closest('.js-shop-the-look');
    if (shopTheLookEl) addToCartButton = queryFirst('.js-add-to-cart-global', shopTheLookEl);

    const monogramBtn = queryFirst('.pdp-monogram-btn', productContainer);
    const ispuButton = queryFirst('.btn-in-store-pickup', productContainer);
    const notifyMeButton = queryFirst('.notify-me-btn', productContainer);
    const buyBarNotifyMeButton = queryFirst('.js-buybar .notify-me-btn', productContainer);
    const notifyMeDesc = queryFirst('.notify-me-desc', productContainer);
    const addToCartSection = queryFirst('.prices-add-to-cart-actions', productContainer);
    const buyBarAddToCart = queryFirst('.js-buybar-btn');
    const availabilityMsgEl = queryFirst('.availability-msg', productContainer);
    let availabilityValue = '';

    if (ispuButton) {
        const pickupImageElement = queryFirst('.pickup-product-img img', productContainer);
        const noImages = queryFirst('.pickup-product-img .no-images', productContainer);
        if (pickupImageElement) {
            if (ispu.length) {
                removeClass(pickupImageElement.parent, 'm-no-images');
                addClass(noImages, HIDDEN_CLASS);
                removeClass(pickupImageElement, HIDDEN_CLASS);
                const { alt, url, srcset } = ispu[0];

                pickupImageElement.setAttribute('src', url);
                pickupImageElement.setAttribute('srcset', srcset);
                pickupImageElement.setAttribute('alt', alt);
            } else {
                addClass(pickupImageElement.parent, 'm-no-images');
                removeClass(noImages, HIDDEN_CLASS);
                addClass(pickupImageElement, HIDDEN_CLASS);
            }
        }
    }

    if (!selectedSizeData || !currentSizeElement) {
        if (ispuButton) {
            ispuButton.disabled = true;
        }
        if (monogramBtn) {
            monogramBtn.disabled = true;
        }

        removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
        addClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
        if (!isDirectlyPurchasable) {
            addToCartButton.disabled = true;
            availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
            if (availabilityMsgEl) availabilityMsgEl.innerHTML = availabilityValue;
            if (ispuButton) {
                ispuButton.disabled = true;
            }
        } else {
            addToCartButton.disabled = false;
        }
        return;
    }

    const { isNotifyMeEnabled, ID, forceOutOfStock, promotions, isFinalSale: isFinalSaleVariant, isDirectlyPurchasable: isDirectlyPurchasableVariant, wishlistDisabled: wishlistDisabledVariant } = selectedSizeData;
    const { variants } = window.productInventory[masterId];
    const inventoryData = variants[ID];
    const { message, availabilityStatus, isLowInventory, flexibleMessage, isBackOrder } = inventoryData;

    // Shipping preference view
    const shippingPreferencesEnabled = isShippingPreferencesViewEnabled();
    let ispuAvailabilityValue = '';
    let ispuLowStockValue = '';
    let ispuVariants;
    let ispuInventoryData;
    let ispuMessage;
    let ispuLowStockMessage;
    let ispuAvailabilityStatus;
    let isISPULowInventory;
    if (shippingPreferencesEnabled && window?.ispuProductInventory?.variants) {
        ispuVariants = window.ispuProductInventory.variants;
        ispuInventoryData = ispuVariants[ID];

        if (ispuInventoryData) {
            ispuMessage = ispuInventoryData.message;
            ispuLowStockMessage = ispuInventoryData.lowStockAvailabilityMessage;
            ispuAvailabilityStatus = ispuInventoryData.availabilityStatus;
            isISPULowInventory = ispuInventoryData.isLowInventory;
        }
    }

    const selectedColorName = queryFirst('.selected-swatch-name').textContent;
    if (!isDirectlyPurchasable) {
        availabilityValue = availabilityMessageTmpl(availabilityMsgEl.dataset.notPurchasable);
    } else {
        if (availabilityStatus !== IN_STOCK || isLowInventory) {
            const breadcrumbItems = queryAll('.breadcrumb-item');
            const categoryUrl = breadcrumbItems.length ? breadcrumbItems.pop().lastElementChild.href : null;
            if ((availabilityStatus === NOT_AVAILABLE || isBackOrder) && categoryUrl) {
                if (flexibleMessage?.includes('{0}')) {
                    availabilityValue = availabilityMessageOOS(message, flexibleMessage.replace('{0}', categoryUrl));
                } else {
                    availabilityValue = availabilityMessageOOS(message, flexibleMessage);
                }
            } else if (availabilityStatus !== PREORDER) {
                availabilityValue = availabilityMessageTmpl(message);
            }
            if (isLowInventory) addClass(currentSizeElement, SHIP_TO_LOW_INVENTORY_CLASS);
        }

        // Shipping preference view
        if (ispuAvailabilityStatus && (ispuAvailabilityStatus !== IN_STOCK || isISPULowInventory)) {
            ispuAvailabilityValue = ispuAvailabilityMessageTmpl(ispuMessage);
            ispuLowStockValue = ispuLowStockMessage ? ispuLowStockMessageTmpl(ispuLowStockMessage) : '';
        }
    }

    const availabilityMessageEl = queryFirst('.product-availability', productContainer);
    const freeShippingMessageEl = queryFirst('.js-free-shipping-promomessage', productContainer);
    const afterPayParagraphEl = queryFirst('.afterpay-paragraph', productContainer);
    const preorderMessageEl = queryFirst('.js-preorder-message', productContainer);

    if (!isDirectlyPurchasable) {
        addToCartButton.disabled = true;
        removeClass([addToCartSection, buyBarAddToCart, freeShippingMessageEl, afterPayParagraphEl], HIDDEN_CLASS);
        addClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
    } else if (availabilityStatus !== NOT_AVAILABLE && !forceOutOfStock && !isBackOrder) {
        addToCartButton.disabled = false;
        removeClass(currentSizeElement, NOT_AVAILABLE_CLASS);
        removeClass([addToCartButton, freeShippingMessageEl, afterPayParagraphEl], HIDDEN_CLASS);
        allowWishlist = true;

        const loyaltyProfile = getNestedValue(window, 'lillyUtils.profile') || {};
        const { isLoyaltyProgramMember, isLoyaltyEnabled } = loyaltyProfile;
        if (isLoyaltyEnabled && earlyAccess.isEarlyAccessProduct) {
            toggleClass(addToCartButton, HIDDEN_CLASS, !isLoyaltyProgramMember);
        }
    } else {
        addClass(currentSizeElement, NOT_AVAILABLE_CLASS);
        addClass([freeShippingMessageEl, afterPayParagraphEl], HIDDEN_CLASS);
        addToCartButton.disabled = true;
        allowWishlist = false;
        if (isNotifyMeEnabled) {
            addClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
            removeClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton, availabilityMessageEl], HIDDEN_CLASS);
        } else {
            removeClass([addToCartSection, buyBarAddToCart], HIDDEN_CLASS);
            addClass([notifyMeDesc, notifyMeButton, buyBarNotifyMeButton], HIDDEN_CLASS);
        }
    }

    if (preorderMessageEl) {
        if (availabilityStatus === PREORDER) {
            addClass(currentSizeElement, 'pre-order');
            preorderMessageEl.innerHTML = availabilityMessagePreorder(message);
        } else {
            preorderMessageEl.innerHTML = '';
        }
    }

    if (shippingPreferencesEnabled) {
        $body.trigger('product:inventoryStatus', {
            shipToAddressAvailabilityStatus: availabilityStatus,
            inStorePickUpAvailabilityStatus: ispuAvailabilityStatus || NOT_AVAILABLE,
            isStorePickUpLowInventory: isISPULowInventory,
            inStorePickUpAvailabilityMessage: ispuAvailabilityValue,
            inStorePickUpLowStockMessage: ispuLowStockValue,
            productContainer
        });
    }
    if (availabilityMsgEl) availabilityMsgEl.innerHTML = availabilityValue;
    queryFirst('.js-add-product-errors', productContainer).innerHTML = '';
    productContainer.dataset.pid = ID;
    queryFirst('.product-id', productContainer).textContent = ID;
    setWishlistProductId(ID, productContainer);
    productContainer.dataset.wishlistId = ID;
    const productData = {
        available: !hasClass(currentSizeElement, NOT_AVAILABLE_CLASS),
        isNotifyMeEnabled,
        id: ID,
        formattedPrice,
        forceOutOfStock,
        imageData: ispu
    };

    handleNotifyMeBlock(productData, productContainer);
    if (!isDirectlyPurchasable) {
        if (ispuButton) {
            ispuButton.disabled = true;
        }
    } else if (ispuButton) {
        ispuButton.disabled = false;
        ispuButton.dataset.pid = ID;

        queryFirst('.pickup-price').textContent = formattedPrice.replace('.00', '');
        queryFirst('.pickup-color .selected-color').textContent = selectedColorName;

        const pickupSize = queryFirst('.pickup-size');
        const sizeSelected = queryFirst('.size-card .size-btn.selected');
        if (sizeSelected) {
            queryFirst('.selected-size', pickupSize).textContent = sizeSelected.dataset.attrValue;
        }
        removeClass(queryFirst('.pdp-attribute-name', productContainer), 'm-error');
    }

    const product = {};
    if (mgFlag && mgLocs && mgLocs.length > 0) {
        const monogramImages = images.monogram || [];
        let url = '';
        let srcset = '';
        let sources = '';
        let breakpoints = '';
        if (monogramImages.length) {
            const updatedImageData = base.updateImageDetails(monogramImages)[0];
            url = updatedImageData.url;
            srcset = updatedImageData.srcset;
            sources = updatedImageData.sources;
            breakpoints = updatedImageData.breakpoints;
        }
        const monogramObject = {
            monogramProductID: ID,
            monogramImageURL: url,
            monogramImageSrcSetURL: srcset,
            monogramImageSources: sources,
            monogramImageBreakpoits: breakpoints,
            monogramLocations: mgLocs,
            monogramSelectedColor: selectedColorName,
            monogramSelectedSize: selectedSizeValue,
            productPrice: formattedPrice,
            monogramProductPrice,
            totalPrice: totalPrice
        };
        product.monogramObject = monogramObject;
    }

    // secret sauce integration to update product data
    updateProductData(selectedColorName, selectedSizeValue, standardPrice, price, productContainer);
    handleSize(productContainer);
    $('body').trigger('product:afterSizeChange', {
        data: {
            product
        },
        productSizeData: selectedSizeData,
        allowWishlist,
        ID,
        productContainer
    });
    if (monogramBtn) {
        monogramBtn.disabled = !(availabilityStatus !== NOT_AVAILABLE && !forceOutOfStock && !hasClass(monogramBtn, HIDDEN_CLASS) && isDirectlyPurchasable);
    }

    // update promotion messages display for selected variation
    let promoBlockMsg = queryFirst('.promo-block-messages', productContainer);
    if (promoBlockMsg) {
        const promoWithSheet = queryFirst('.js-promotions-with-sheet', productContainer);
        if (promoWithSheet) promoBlockMsg = promoWithSheet;
        promoBlockMsg.innerHTML = promotions
            ? promotions
                .filter(promotion => promotion.calloutMsg)
                .map((promotion, promotionIdx) => {
                    return promotionMessageTmpl(promotion.calloutMsg, promoWithSheet ? promotion.detailsFromAsset : promotion.details, promotionIdx);
                })
                .join('')
            : '';
    }

    if (wishlistDisabled) {
        showStrikeHearts(productContainer);
    }

    // pick new promotion popover elements
    initPopoverPromotions();
    initPopoverCloseSelector();
    initPopoverWishList();
    initPopoverClose();
}

function initCustomZoom() {
    queryAll('.js-swiper-modal').forEach(handleProductImageZoom);
}

const init = () => {
    initCustomZoom();

    if (shoppableCarouselEl) {
        initCarouselElement(shoppableCarouselEl);
    }

    $('body').on('product:handleImageZoom', initCustomZoom);

    $('.js-pdp-lightbox-modal')
        .on('shown.bs.modal', e => {
            const dialog = e.currentTarget;
            const pdpModalCarouselEl = queryFirst('.js-swiper-modal', dialog);
            const slideIndex = (e.relatedTarget && e.relatedTarget.hasAttribute('data-index')) ? e.relatedTarget.dataset.index : 0;

            if (pdpModalCarouselEl.swiper && e.relatedTarget) {
                pdpModalCarouselEl.swiper.update();
                pdpModalCarouselEl.swiper.enable();
                pdpModalCarouselEl.swiper.scrollbar.updateSize();
                pdpModalCarouselEl.swiper.setTranslate();
                pdpModalCarouselEl.swiper.slideTo(slideIndex);
                zoomedState = false;
            }
        })
        .on('hidden.bs.modal', e => {
            const dialog = e.currentTarget;
            const pdpModalCarouselEl = queryFirst('.js-swiper-modal', dialog);

            if (pdpModalCarouselEl.swiper) {
                $('.swiper-slide-active img', pdpModalCarouselEl).trigger('zoom:imageChange');
                $('.zoomed').removeClass('zoomed');
                pdpModalCarouselEl.swiper.disable();
                zoomedState = false;
            }
        });
};

const handleLastLinkTab = e => {
    const sizeChartCatBtn = queryFirst('#sizechart-menu-toggle');
    const isTabPressed = e.key === TabKey || e.keyCode === KEYCODE_TAB;
    if (!isTabPressed) {
        return;
    }

    if (!e.shiftKey) {
        sizeChartCatBtn.focus();
    }
};

/**
 * Toogle Size chart and select needed category
 * @param {Object} $this - Size chart link JQUERY element
 * @param {Object} $prodSizeChart) - Size chart window element
 */
function toogleActiveSizeChartTab($this, $prodSizeChart) {
    const $lpSlideout = $('.lp-sizechart');
    const { sizefamily: activeCategoryVal, defaulttext: defaultText } = $this.data();
    const $activeCategoryEl = $prodSizeChart.find(`a[href="#${activeCategoryVal}-size"]`);
    const $lpSizechartTitle = $prodSizeChart.find('.lp-sizechart-category-btn');
    $lpSizechartTitle.focus();
    $activeCategoryEl.tab('show');
    $lpSizechartTitle.text($activeCategoryEl.text().length ? $activeCategoryEl.text() : defaultText);

    $prodSizeChart.animate(
        {
            right: '0'
        },
        500
    );
    $prodSizeChart.addClass('active');
    $lpSlideout.toggleClass('lp-slideout-open');
    $body.toggleClass(sizeChartClasses);
}

export default {
    init: init,

    availability: base.availability,

    addToCart: base.addToCart,

    scrollFitRatingToReviews: function () {
        $(document).on('click', 'button.fit-review, button.no-fit-review, #navigateToReviews', function (e) {
            e.preventDefault();
            const bvReviews = document.getElementById('bazaarvoiceReviews');
            if (bvReviews) {
                scrollTo(window.scrollY + bvReviews.getBoundingClientRect().top, 700);
            }
        });
    },

    handleBuyBar: function () {
        const buyBarEl = queryFirst('.js-buybar');

        if (window.matchMedia('(max-width: 767px)').matches && buyBarEl) {
            const observer = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (window.scrollY > entry.boundingClientRect.y && !entry.isIntersecting) {
                            removeClass(buyBarEl, HIDDEN_CLASS);
                        } else {
                            addClass(buyBarEl, HIDDEN_CLASS);
                        }
                    });
                },
                {
                    rootMargin: '-40px 0px -40px 0px'
                }
            );

            queryAll('.js-buybar-breakpoint').forEach(el => {
                observer.observe(el);
            });

            queryFirst('.js-buybar-btn', buyBarEl).addEventListener('click', e => {
                e.preventDefault();
                if (hasClass(e.target, 'js-buybar-select') || !hasClass(e.target, 'selected')) {
                    queryFirst('.row[data-attr="size"]').scrollIntoView();
                }
                queryFirst('.add-to-cart').click();
            });
        }
    },

    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            const { container } = response;
            const { id, variationAttributes } = response.data.product;
            if ($('.product-detail>.bundle-items').length) {
                container.data('pid', id);
                container.find('.product-id').text(id);
            } else if ($('.product-set-detail').eq(0)) {
                container.data('pid', id);
                container.find('.product-id').text(id);
            } else {
                $('.product-id').text(id);
                $('.product-detail:not(".bundle-item")').data('pid', id);
            }
            updateSelectedSwatchProductName(variationAttributes, container);
            $('body').trigger('product:handleImageZoom');
        });
    },

    selectSizeAttribute: function () {
        $(document).on('click', '.product-quickview .size-btn', function (e) {
            e.preventDefault();
            if (!hasClass(this, 'selected')) {
                const $productContainer = $(this).closest('.product-detail');
                const url = this.dataset.attrUrl;
                if ($productContainer.hasClass('cached-quick-view')) {
                    const productContainer = this.closest('.product-detail');
                    const selectedColorElement = queryFirst('.color-attribute .swatch-circle.selected', productContainer);
                    const selectedColorId = selectedColorElement.dataset.attrValue;
                    const selectedSizeValue = this.dataset.attrValue;
                    onSizeChangeHandler.apply(this, [productContainer, selectedSizeValue]);
                    updateProductInfo(selectedSizeValue, selectedColorId, productContainer, this);
                } else {
                    base.attributeSelect(url, $productContainer, this);
                }
            }
        });
        $('body').on('product:quickViewAttributeChange', (e, response) => {
            const selectedSizeElement = queryFirst('.product-quickview.cached-quick-view .size-btn.selected');
            const selectedValue = selectedSizeElement ? selectedSizeElement.dataset.attrValue : '';
            updateQuickViewProductInfo(selectedValue, response.variantGroupId, response.container[0], selectedSizeElement);
        });
    },
    afterAttributeSelect: function () {
        $body.on('product:afterAttributeSelect', (event, response) => {
            const { selectedSizeElement } = response;
            const { product } = response.data;
            const { id, readyToOrder, available, forceOutOfStock, price, wishListID, images, isDirectlyPurchasable, wishlistDisabled } = product;
            const { ispu } = images;
            const responseContainer = response.container[0];
            const addToCartButton = queryFirst('.add-to-cart', responseContainer);
            const isBonusProduct = hasClass(responseContainer, 'bonus-product-item');
            responseContainer.dataset.pid = id;
            responseContainer.dataset.wishlistId = wishListID;
            setWishlistProductId(wishListID, responseContainer);

            let ssSize = 'unkown';
            if (hasClass(responseContainer, 'product-quickview') && addToCartButton) {
                addToCartButton.disabled = false;
            }
            if (selectedSizeElement && !isBonusProduct) {
                ssSize = selectedSizeElement.dataset.attrValue;
                removeClass(queryAll('.size-btn', responseContainer), 'selected');
                addClass(selectedSizeElement, 'selected');
                const sizeCard = selectedSizeElement.closest('.size-card');
                const selectedSize = queryFirst('.selected-size', sizeCard);
                if (selectedSize) {
                    const sizeSeparator = queryFirst('.size-seperator', sizeCard);
                    selectedSize.textContent = selectedSizeElement.dataset.attrValue;
                    removeClass([sizeSeparator, selectedSize], HIDDEN_CLASS);
                }
                const sizeContainer = selectedSizeElement.closest('.size-container');
                const assistiveElements = queryAll('.selected-assistive-text', sizeContainer);
                assistiveElements?.forEach(eachElement => {
                    if (eachElement.textContent.includes(eachElement.dataset.outOfStock)) {
                        eachElement.textContent = eachElement.dataset.outOfStock;
                    } else {
                        eachElement.textContent = '';
                    }
                });
                const assistiveElementOfSelected = queryFirst('.selected-assistive-text', selectedSizeElement.closest('.size-list'));
                const { selectedText, outOfStock } = assistiveElementOfSelected.dataset;
                assistiveElementOfSelected.textContent = selectedText;
                if (hasClass(selectedSizeElement, NOT_AVAILABLE_CLASS)) {
                    assistiveElementOfSelected.textContent += ' ' + outOfStock;
                }
                if (addToCartButton) {
                    const restrictedFromOrder = !readyToOrder || !available || forceOutOfStock;
                    addToCartButton.disabled = restrictedFromOrder;
                    if (restrictedFromOrder) {
                        showStrikeHearts(responseContainer);
                    } else {
                        hideStrikeHearts(responseContainer);
                    }
                }

                const quickviewContainer = selectedSizeElement.closest('.product-quickview');
                if (quickviewContainer) {
                    const addToCartSection = queryFirst('.prices-add-to-cart-actions', responseContainer);
                    const notifyMeButton = queryFirst('.notify-me-btn', responseContainer);
                    const notifyMeDesc = queryFirst('.notify-me-desc', responseContainer);
                    const availabilityMessageEl = queryFirst('.product-availability', responseContainer);
                    const { isNotifyMeEnabled, isDirectlyPurchasable } = product;
                    const salesPrice = price.type === 'range' ? price.min.sales : price.sales;
                    const productData = {
                        available,
                        isNotifyMeEnabled,
                        id,
                        formattedPrice: salesPrice.formatted,
                        forceOutOfStock,
                        imageData: ispu
                    };
                    handleNotifyMe(productData, quickviewContainer);
                    const updateCartButton = queryFirst('.update-cart-product-global', quickviewContainer);
                    if (updateCartButton) {
                        const { giftWrapAvailableFlag } = product;
                        updateCartButton.dataset.giftWrapAvailable = giftWrapAvailableFlag;
                    }
                    if (isDirectlyPurchasable === false) {
                        removeClass(addToCartSection, HIDDEN_CLASS);
                        if (availabilityMessageEl) {
                            removeClass(availabilityMessageEl, HIDDEN_CLASS);
                        }
                        addClass([notifyMeDesc, notifyMeButton], HIDDEN_CLASS);
                    }
                }

                const ispuButton = queryFirst('.btn-in-store-pickup', responseContainer);
                if (ispuButton) {
                    ispuButton.dataset.pid = id;
                    ispuButton.disabled = false;
                    const pickupImageElement = queryFirst('.pickup-product-img img', responseContainer);
                    const noImages = queryFirst('.pickup-product-img .no-images', responseContainer);
                    if (pickupImageElement) {
                        if (ispu.length) {
                            removeClass(pickupImageElement.parent, 'm-no-images');
                            addClass(noImages, HIDDEN_CLASS);
                            removeClass(pickupImageElement, HIDDEN_CLASS);
                            const { alt, url, srcset } = ispu[0];

                            pickupImageElement.setAttribute('src', url);
                            pickupImageElement.setAttribute('srcset', srcset);
                            pickupImageElement.setAttribute('alt', alt);
                        } else {
                            addClass(pickupImageElement.parent, 'm-no-images');
                            removeClass(noImages, HIDDEN_CLASS);
                            addClass(pickupImageElement, HIDDEN_CLASS);
                        }
                    }
                    queryFirst('.pickup-product-name', responseContainer).textContent = product.productName;
                    queryFirst('.pickup-price').textContent = product.price.sales.formatted.replace('.00', '');
                    const selectedSwatchName = queryFirst('.selected-swatch-name', responseContainer);
                    if (selectedSwatchName) {
                        queryFirst('.pickup-color .selected-color', responseContainer).textContent = selectedSwatchName.textContent;
                    }
                    const pickupSize = queryFirst('.pickup-size', responseContainer);
                    const sizeSelected = queryFirst('.size-card .size-btn.selected', responseContainer);
                    if (sizeSelected) {
                        queryFirst('.selected-size', pickupSize).textContent = sizeSelected.dataset.attrValue;
                    }
                }
            }
            if (hasClass(responseContainer, 'product-quickview') && isDirectlyPurchasable === false) {
                const addToCartSection = queryFirst('.prices-add-to-cart-actions', responseContainer);
                const notifyMeButton = queryFirst('.notify-me-btn', responseContainer);
                const notifyMeDesc = queryFirst('.notify-me-desc', responseContainer);
                const availabilityMessageEl = queryFirst('.product-availability', responseContainer);
                removeClass(addToCartSection, HIDDEN_CLASS);
                if (addToCartButton) {
                    addToCartButton.disabled = true;
                }
                if (availabilityMessageEl) {
                    removeClass(availabilityMessageEl, HIDDEN_CLASS);
                }
                addClass([notifyMeDesc, notifyMeButton], HIDDEN_CLASS);
            }
            const wishlistButton = queryFirst('.add-to-wish-list', responseContainer);
            if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
                removeClass(wishlistButton, 'added-to-wish-list');
                wishlistButton.disabled = false;
                const assistiveText = wishlistButton.getAttribute('data-assistive-text');
                wishlistButton.setAttribute('aria-label', assistiveText);
            }
            if (!hasClass(responseContainer, 'gift-card-main') && !document.getElementById('chooseBonusProductModal')) {
                const ssColorElement = queryFirst('.color-container .color-attribute.selected', responseContainer);
                const ssColor = (ssColorElement && ssColorElement.dataset.attrDisplayvalue) || 'unknown';
                const { type } = price;
                const pricesObject = type === 'range' ? price.min : price;
                const { sales, list } = pricesObject;
                const ssSalesPrice = (sales && sales.value.toString()) || 'unknown';
                const ssPrice = (list && list.value.toString()) || ssSalesPrice;
                updateProductData(ssColor, ssSize, ssPrice, ssSalesPrice, responseContainer);
                if (ssSize === 'unknown') {
                    handleColor(responseContainer);
                } else {
                    handleSize(responseContainer);
                }
            }
            if (selectedSizeElement) {
                const productSetModal = selectedSizeElement.closest('#productSetModal');
                if (productSetModal) {
                    const addAllToToteButton = queryFirst('.add-to-cart-global', productSetModal);
                    if (addAllToToteButton) {
                        const productsAvailability = queryAll('.product-availability', productSetModal).filter(item => !hasClass(item.closest('.product-detail'), 'hidden-set'));
                        const numberOfProducts = productsAvailability.length;

                        // Check to enable add to tote button, if atleast one product exists
                        let enable = true;
                        if (!numberOfProducts) {
                            enable = false;
                        }
                        addAllToToteButton.disabled = !enable;
                    }
                }
            }

            if (wishlistDisabled) {
                showStrikeHearts(responseContainer);
            }
            $.spinner().stop();

            const updatedSizeElement = queryFirst('.size-btn.selected', responseContainer);
            const isOneSize = !!queryFirst('.selected-size', responseContainer);
            const isColor = hasClass(selectedSizeElement, 'js-color-attribute');

            // pass the updated size element if it exists (for multi-size gwps). Otherwise, pass the old selected element (color) if an updated size element does not exist (single-size)
            $body.trigger('bonus:updateSelection', { target: updatedSizeElement || (isColor && isOneSize ? selectedSizeElement : null) });
        });
    },
    selectPdpSizeAttribute: function () {
        $(document).on('click', '.pdp-container .size-btn, .product-set-item .size-btn', function (e) {
            const { target } = e;

            if (target.closest('.js-product-tile')) {
                return;
            }

            e.preventDefault();
            // return, if an unavailable size was clicked inside ShopTheLook
            const shopTheLookEl = queryFirst('.js-shop-the-look');
            let isInsideShopTheLook = shopTheLookEl?.contains(target);
            if (hasClass(this, NOT_AVAILABLE_CLASS) && isInsideShopTheLook) return;

            const productContainer = this.closest('.product-detail');
            const selectedColorElement = queryFirst('.color-attribute .swatch-circle.selected', productContainer);
            const selectedColorId = selectedColorElement.dataset.attrValue;
            const selectedSizeValue = this.dataset.attrValue;
            onSizeChangeHandler.apply(this, [productContainer, selectedSizeValue]);
            updateProductInfo(selectedSizeValue, selectedColorId, productContainer, this);
            $body.trigger('product:pdpSizeSelected', {
                selectedColorElement,
                selectedSizeElement: this,
                productContainer
            });

            // additional actions if it is ShopTheLook
            if (isInsideShopTheLook) {
                enableAddAllToToteButton(shopTheLookEl);

                const jsAttributeEl = target.closest('.js-attribute');
                addClass(queryFirst('.js-size-selector', jsAttributeEl), HIDDEN_CLASS);
                removeClass(queryAll('.js-edit-size-btn, .js-selected-size-block', jsAttributeEl), HIDDEN_CLASS);
            }
        });
        $('body').on('product:afterAttributeChange', (e, response) => {
            const selectedSizeElement = queryFirst('.pdp-container .pdp-attributes .size-btn.selected');
            const selectedValue = selectedSizeElement ? selectedSizeElement.dataset.attrValue : '';
            updateProductInfo(selectedValue, response.variantGroupId, response.container[0], selectedSizeElement);
        });

        const pdpContainer = queryFirst('.pdp-container');
        const pdpAttributes = queryFirst('.pdp-attributes', pdpContainer);
        const swatchEl = queryFirst('button.color-attribute .swatch-circle.selected', pdpAttributes);

        if (swatchEl) {
            const masterId = hasClass(pdpContainer, 'product-set-detail') ? queryFirst('.js-product-set-item').dataset.masterid : pdpContainer.dataset.masterid;
            const sizeElements = queryAll('.size-btn', pdpAttributes);

            if (sizeElements.length) {
                if (sizeElements.length === 1) {
                    queryFirst('.size-btn', pdpAttributes).click();
                } else {
                    const selectedSizeEl = queryFirst('.size-btn.selected', pdpAttributes);

                    if (selectedSizeEl) {
                        selectedSizeEl.click();
                    }
                }

                if (!queryFirst('.size-btn.not-purchasable', pdpAttributes)) {
                    const allSizesSoldOut = sizeElements.every(sizeElement => hasClass(sizeElement, NOT_AVAILABLE_CLASS));
                    const productInfoVariants = window.productInfo[masterId].variants;
                    const selectedColorId = swatchEl.dataset.attrValue;
                    const sizeNotifyEnable = Object.entries(productInfoVariants[selectedColorId].sizes).some(([key, size]) => size.isNotifyMeEnabled);

                    // In case if all product sizes sold out and Notify me box not showed
                    // we hide this variation from swatches and select first color variation that have
                    // available sizes
                    if (allSizesSoldOut) {
                        removeClass(swatchEl, 'selectable');

                        const allSelectableSwatches = queryAll('button.color-attribute .swatch-circle.selectable', pdpAttributes);

                        if (allSelectableSwatches.length && !sizeNotifyEnable) {
                            // Hide the selected swatch because it's out of stock
                            addClass(swatchEl.closest('li'), HIDDEN_CLASS);
                            allSelectableSwatches[0].click();
                        }
                    }
                }
            }
        }
    },

    updateAttributesAndDetails: function () {
        $('body').on('product:statusUpdate', function (e, data) {
            var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

            $productContainer.find('.description-and-detail .product-attributes').empty().html(data.attributesHtml);

            if (data.shortDescription) {
                $productContainer.find('.description-and-detail .description').removeClass('hidden-xl-down');
                $productContainer.find('.description-and-detail .description .content').empty().html(data.shortDescription);
            } else {
                $productContainer.find('.description-and-detail .description').addClass('hidden-xl-down');
            }

            if (data.longDescription) {
                $productContainer.find('.description-and-detail .details').removeClass('hidden-xl-down');
                $productContainer.find('.description-and-detail .details .content').empty().html(data.longDescription);
            } else {
                $productContainer.find('.description-and-detail .details').addClass('hidden-xl-down');
            }
        });
    },

    showSpinner: function () {
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
            const productSetModal = document.getElementById('productSetModal');
            const quickViewModal = document.getElementById('quickViewModal');
            if (productSetModal && hasClass(productSetModal, 'show')) {
                $(productSetModal).spinner().start();
            } else if (quickViewModal && hasClass(quickViewModal, 'show')) {
                $(quickViewModal).spinner().start();
            } else {
                $.spinner().start();
            }
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            $('div.availability', response.$productContainer).attr('data-ready-to-order', response.product.readyToOrder).attr('data-available', response.product.available);

            var availabilityValue = '';
            var availabilityMessages = response.product.availability.messages;
            if (response.product.readyToOrder) {
                availabilityMessages.forEach(message => {
                    availabilityValue += availabilityMessageTmpl(message);
                });
            }

            $('.availability-msg', response.$productContainer).empty().html(availabilityValue);

            if ($('.global-availability').length) {
                var allAvailable = $('.product-availability')
                    .toArray()
                    .every(function (item) {
                        return $(item).data('available');
                    });

                var allReady = $('.product-availability')
                    .toArray()
                    .every(function (item) {
                        return $(item).data('ready-to-order');
                    });

                $('.global-availability').data('ready-to-order', allReady).data('available', allAvailable);

                $('.global-availability .availability-msg')
                    .empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            }
        });
    },
    sizeChart: function () {
        var $prodSizeChart = $('.size-chart-collapsible');
        $('body').on('click', '.size-chart .size-chart-link', function (e) {
            e.preventDefault();
            const element = $(this);
            const url = element.data('href');
            const $lpSlideout = $('.lp-sizechart');
            const setStickyNav = queryFirst('.custom-set-detail-sticky-nav');
            if ($prodSizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $prodSizeChart.append(data.content);
                    },
                    complete: function () {
                        toogleActiveSizeChartTab(element, $prodSizeChart);

                        const lastLinkEl = queryFirst('.return-policy-link');
                        if (lastLinkEl) {
                            lastLinkEl.addEventListener('keydown', handleLastLinkTab);
                        }
                    }
                });
            } else {
                toogleActiveSizeChartTab(element, $prodSizeChart);
            }
            if (setStickyNav) {
                addClass(setStickyNav, HIDDEN_CLASS);
            }
            $lpSlideout.trigger('shown.lp.sizeguide');
        });

        $('body').on('click touchstart', function (e) {
            if (e.target.matches('#sizechart-close') || (e.target.matches('.size-chart-bg') && $prodSizeChart.hasClass('active'))) {
                $prodSizeChart.removeClass('active');
                $('.lp-slideout').removeClass('lp-slideout-open');
                $body.removeClass(sizeChartClasses);
                queryFirst('.size-chart .size-chart-link').focus(); // set focus back to trigger element
            }
        });
    },

    focusChooseBonusProductModal: base.focusChooseBonusProductModal(),
    renderSizeElements: base.renderSizeElements,
    handleEarlyAccessPLPLockIcon: base.handleEarlyAccessPLPLockIcon(),
    handleEarlyAccessLockIcon: function () {
        const productContainer = queryFirst('.pdp-container');
        const productContainerEarlyAccessIcons = queryAll('.loyalty-early-access-lock-container', productContainer);
        productContainerEarlyAccessIcons.forEach((productContainerEarlyAccessIcon) => {
            if (productContainerEarlyAccessIcon) {
                const { earlyAccessDate } = productContainerEarlyAccessIcon.dataset;
                const earlyAccessItem = isEarlyAccessElement(earlyAccessDate);
                base.handleEarlyAccessCta(productContainer, earlyAccessItem, productContainerEarlyAccessIcon);
            }
        });
    },
    handleOfflineProduct: function () {
        const offlineContainerEl = queryFirst('.offline-product-detail');
        if (offlineContainerEl) {
            const pid = offlineContainerEl.dataset.pid;
            const availabilityStatus = getNestedValue(window, `productInventory.variants[${pid}].availabilityStatus`);
            if (availabilityStatus === IN_STOCK) {
                const { search: currentSearch } = window.location;
                const TS_PARAM = 'timestamp=';
                if (!currentSearch.includes(TS_PARAM)) {
                    window.location.search = (currentSearch ? currentSearch + '&' : '') + TS_PARAM + new Date().getTime();
                }
            }
        }
    },
    handlePdpNonEarlyAccessLoyalty: function () {
        // Sign in/Create account modal for non-early access product

        const signInCreateAccountModal = queryFirst('#account-sheet');
        const loyaltyEnrollNowModal = queryFirst('#loyalty-enrollnow-modal');
        const pdpSignInCreateAccountButton = queryAll('.pdp-non-early-access-login');
        const earlyAccessSignInInfo = queryAll('.early-access-sign-info');
        const pdpSignInInfo = queryFirst('.pdp-sign-in-info');
        const earlyAccessSignInText = queryFirst('.early-access-sign-in-text');
        const joinButton = queryFirst('.join-now-button');
        const earlyAccessWelcomeCta = queryFirst('.loyalty-early-access-welcome-cta');
        const loyaltyWelcomeDialog = queryFirst('#loyalty-welcome-popup');
        const $nonEarlyAccessLogin = $('.pdp-non-early-access-login');

        $nonEarlyAccessLogin.on('click', function () {
            const button = this;
            // return focus to trigger after modal is closed
            $(signInCreateAccountModal).one('hidden.bs.modal', () => button.focus());
        });

        if (joinButton) {
            joinButton.addEventListener('click', e => {
                e.preventDefault();
                $(loyaltyEnrollNowModal).modal('show');
                const welcomeDialogEarlyAccessMsgEl = queryAll('.js-loyalty-welcome-early-access');
                const welcomeDialogMsgEl = queryAll('.js-loyalty-welcome-msg');
                addClass(welcomeDialogMsgEl, HIDDEN_CLASS);
                removeClass(welcomeDialogEarlyAccessMsgEl, HIDDEN_CLASS);
            });
        }
        if (earlyAccessWelcomeCta) {
            earlyAccessWelcomeCta.addEventListener('click', function () {
                location.reload();
            });
        }
        $(loyaltyWelcomeDialog)
            .off('hidden.bs.modal')
            .on('hidden.bs.modal', function () {
                location.reload();
            });
    },
    promoDetailsModal: function () {
        $body.on('click', '.js-promo-details-link', e => {
            const currentPromo = e.currentTarget;
            const promoDetails = queryFirst('.js-promo-details', currentPromo)?.innerHTML;
            if (promoDetails && promoDetailsContainer) {
                promoDetailsContainer.innerHTML = promoDetails;
                $(promoDetailsModal)?.modal('show');
            }
        });
    }
};
